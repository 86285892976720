import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  CoreModule,
  DataPropertyGetterPipe,
  DateRangeHelper,
  FAwesomeModule,
  InfiniteScrollDirective,
} from '@intorqa-ui/core';
import { ComponentType } from '../../enums/table.enum';
import { IQueryColumn } from '../../interfaces/query-filters';
import { TableColumn } from '../../interfaces/table.interface';
import { QueryFilters } from '../../models/query-filters';
import { IDropdownItem } from '../dropdown/dropdown.interface';
import { VirtualTableFiltersComponent } from './components/virtual-table-filters/virtual-table-filters.component';

@Component({
  selector: 'itq-virtual-table',
  templateUrl: './virtual-table.component.html',
  styleUrls: ['./virtual-table.component.scss'],
  providers: [provideNativeDateAdapter()],
  standalone: true,
  imports: [
    CommonModule,
    FAwesomeModule,
    CoreModule,
    FormsModule,
    DataPropertyGetterPipe,
    VirtualTableFiltersComponent,
    InfiniteScrollDirective,
  ],
})
export class VirtualTableComponent implements OnInit {
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() open = new EventEmitter<void>();
  @Output() rowClick = new EventEmitter<any>();

  @Input() dataSource: Array<any> = [];
  @Input() tableColumns: Array<TableColumn>;
  @Input() initialState: QueryFilters;
  @Input() trackBy: string;
  @Input() emptyMessage = 'No results found';
  @Input() emptyMessageDescription =
    'Please update search term to show some results.';
  @Input() showFilters = false;
  @Input() active: string;

  @ViewChild('tbody') tbody: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue) {
      if (this.initialState.page === 1 && this.tbody) {
        this.tbody.nativeElement.scrollTop = 0;
      }
    }
  }

  public onScroll(): void {
    if (this.initialState && this.tbody?.nativeElement?.scrollTop > 0) {
      this.initialState.page++;
      this.dataBound.emit(this.initialState);
    }
  }

  onSort(item: TableColumn): void {
    if (item.isSortable) {
      this.initialState.sort = {
        active: item.searchField?.toString() || item.dataKey.toString(),
        direction:
          this.initialState?.sort?.direction === 'asc' ? 'desc' : 'asc',
        isMetadata: item.isMetadata,
      };
      this.dataBound.emit(this.initialState);
    }
  }

  public onClearSearch(params: TableColumn): void {
    const queryColumn = this.initialState.columnFilters.find(
      (item: IQueryColumn) => {
        return item.searchField === params.searchField;
      },
    );
    if (queryColumn) {
      this.initialState.removeQueryColumn(queryColumn.searchField);
    }

    this.dataBound.emit(this.initialState);
  }

  public onApplySearch(params: TableColumn): void {
    if (!params.searchValue || params.searchValue === '') {
      this.onClearSearch(params);
    } else {
      this.initialState.addQueryColumn({
        searchValues: [
          params.searchComponent === ComponentType.INPUT
            ? params.searchValue
            : typeof params.searchValue === 'number'
              ? params.searchValue
              : DateRangeHelper.convertToEpochSec(params.searchValue),
        ],
        searchField: params.searchField,
        searchFieldType: params.searchFieldType,
        isMetadata: params.isMetadata,
      });
      this.dataBound.emit(this.initialState);
    }
  }

  public onChangeValue(params: {
    column: TableColumn;
    value: IDropdownItem | string;
  }): void {
    params.column.searchValue = params?.value;
    if (typeof params?.value !== 'string') {
      params.value = params?.value as IDropdownItem;
      params.value = params?.value?.value;
    }
    if (!params?.value || params?.value === '') {
      this.onClearSearch(params?.column);
    } else {
      this.initialState.addQueryColumn({
        searchValues: [params?.value],
        searchField: params.column.searchField,
        searchFieldType: params.column.searchFieldType,
        isMetadata: params.column.isMetadata,
      });
      this.dataBound.emit(this.initialState);
    }
  }

  public onMultipleDropdownChange(column: TableColumn): void {
    const columns = this.initialState.columnFilters;
    const queryColumn = columns.find((item: IQueryColumn) => {
      return item.searchField === column.searchField;
    });
    const searchValues = column.searchValue?.map(
      (item: { name: string; value: string }) => item.value,
    );
    if (queryColumn) {
      if (searchValues?.length === 0) {
        this.initialState.removeQueryColumn(column.searchField);
      } else {
        queryColumn.searchValues = searchValues;
      }
    } else {
      this.initialState.addQueryColumn({
        searchValues: searchValues,
        searchField: column.searchField,
        searchFieldType: column.searchFieldType,
        isMetadata: column.isMetadata,
      });
    }
    this.dataBound.emit(this.initialState);
  }

  public onDataBound(column: TableColumn): void {
    column?.dataBound();
  }

  public onRowClick(row: any): void {
    this.rowClick.emit(row);
  }

  public onClearFilter(column: TableColumn): void {
    column.searchValue = undefined;
    this.onChangeValue({ column, value: undefined });
  }
}
