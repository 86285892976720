import { DTOQuery } from '@intorqa-ui/core';
import { Rule, RuleSet } from 'ngx-angular-query-builder';
import { QueryBuilderModel } from './qb-query-model';

export class BackEndQuery implements DTOQuery {
  constructor(
    public operator: string = 'and',
    public conditions: Array<Rule> = [],
    public queries: Array<DTOQuery> = [],
  ) {}

  public convertFromBackEndQuery(): QueryBuilderModel {
    const getNestedQueries = (query: Array<DTOQuery>) => {
      return query.map((item: DTOQuery) => {
        let queryDTO: RuleSet = {
          condition: item.operator,
          rules: item.conditions,
        };
        if (item.queries?.length > 0) {
          queryDTO.rules = [
            ...queryDTO.rules,
            ...getNestedQueries(item.queries),
          ];
        }
        return queryDTO;
      });
    };
    let queryDTO: RuleSet = {
      condition: this.operator,
      rules: this.conditions?.map((item: Rule) => item),
    };
    if (this.queries?.length > 0) {
      queryDTO.rules = [...queryDTO.rules, ...getNestedQueries(this.queries)];
    }
    return new QueryBuilderModel(queryDTO.rules, queryDTO.condition);
  }
}
