<div class="flex w-full max-h-[350px] min-h-[200px]">
  <mat-nav-list
    class="w-[275px] mr-4"
    [style.padding-right]="'8px !important'"
    [style.padding-bottom]="'0 !important'"
    [style.padding-top]="'0 !important'"
  >
    @for (item of sectionsDataSource; track item) {
      <mat-list-item
        [style.background-color]="
          item === selectedSection ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-semibold': item === selectedSection
          }"
        >
          <span>{{ item }}</span>
          <mat-icon>{{
            item === selectedSection ? 'chevron_right' : 'expand_more'
          }}</mat-icon>
        </div>
      </mat-list-item>
    }
  </mat-nav-list>
  <mat-nav-list
    class="flex-1 border-l w-[275px] border-border overflow-auto"
    [style.padding-right]="'8px !important'"
    [style.padding-left]="'8px !important'"
    [style.padding-bottom]="'0 !important'"
    [style.padding-top]="'0 !important'"
  >
    <mat-list-item
      class="hover:bg-background-over"
      *ngFor="let item of fieldsDataSurce"
      (click)="onSelectionChange(item)"
    >
      <div
        [ngClass]="{
          'text-primary': field === item.id,
          'flex items-center space-x-2 w-full': true
        }"
      >
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center space-x-2">
            <span>{{ item.label }}</span>
            @if (item.description) {
              <mat-icon
                class="sflex items-center"
                [matTooltip]="item.description"
                style="font-size: 20px"
                >info</mat-icon
              >
            }
          </div>
          @if (field === item.id) {
            <mat-icon>check</mat-icon>
          }
        </div>
      </div>
    </mat-list-item>
  </mat-nav-list>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
