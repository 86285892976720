import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Actions,
  Align,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  FieldSections,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import {
  ProfileDrildownScope,
  ViewMode,
} from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import moment from 'moment';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { ProfilesWizardComponent } from '../profiles-wizard/profiles-wizard.component';

@Component({
  selector: 'itq-profiles-tags',
  templateUrl: './profiles-tags.component.html',
  styleUrls: ['./profiles-tags.component.scss'],
})
export class ProfilesTagsComponent implements OnInit {
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() link: LinkTag;
  @Input() showLinkTags = false;

  public initialState = new QueryFilters(
    30,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public timelineData: IData;
  public queryModel: Query;
  private showAddLinkSubscription: Subscription;

  readonly ViewMode = ViewMode;
  readonly ChartType = ChartType;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly Sizes = Sizes;

  constructor(
    private profilesService: ProfileService,
    private linkTagsService: LinkTagsService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly customOverlayService: CustomOverlayService,
    readonly categoriesService: CategoryService,
  ) {}

  ngOnInit() {
    this.showAddLinkSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.getTimelineData();
        }
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.link?.previousValue !== changes?.link?.currentValue) {
      this.updateQueryModel();
    }
  }

  ngOnDestroy(): void {
    this.showAddLinkSubscription.unsubscribe();
  }

  public updateQueryModel(): void {
    this.queryModel = new Query();
    this.loadFields();
  }

  private loadFields(): void {
    this.categoriesService
      .getUserCategoryFieldsByType(
        UserTagCategoryLabel.STANDARD.toString(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((fields: IFilterField[]) => {
        const field = fields.find(
          (item: IFilterField) =>
            item.label === this.link.type &&
            item.section === FieldSections.FILTER_TO,
        );
        this.queryModel.addRule({
          entity: field.field,
          field: field.id,
          operator: 'in',
          value: [this.link.tagId || this.link.tagName],
        });
        this.getTimelineData();
      });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onDataBound(this.queryModel, this.initialState);
  }

  public onDataBound(query: Query, params: QueryFilters): void {
    this.queryModel = query;
    this.initialState = params;

    this.getTimelineData();
  }

  private getTimelineData(): void {
    if (this.queryModel.hasRules()) {
      this.profilesService.loader$.next(true);
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.timelineData = {
            result:
              this.initialState.page > 1
                ? [...this.timelineData.result, ...response.result]
                : response.result,
            count: response.count,
          };
          this.profilesService.loader$.next(false);
        });
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextSegment): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onToggleSettings(): void {
    this.linkTagsService.showLinkTag$.next(true);
  }
}
