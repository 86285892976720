<form [formGroup]="form" class="flex flex-col px-7 py-4 bg-background w-full">
  <div class="flex w-full items-center justify-between flex-col">
    <div
      class="flex items-center bg-white rounded-md border border-border w-full p-7"
    >
      <div class="flex items-center flex-1">
        <fa-icon class="pr-2" [icon]="['far', 'tag']" [size]="'3x'"></fa-icon>
        <span class="font-bold text-28">Tags</span>
        @if (dataSource?.totalCount) {
          <itq-pill
            class="pl-4"
            [class]="
              'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over h-[36px]'
            "
            [type]="PillType.CUSTOM"
            >{{ dataSource.totalCount | number }}</itq-pill
          >
        }
      </div>
      <div class="flex flex-1">
        This area contains all the tags that have been created for this
        ecosystem. <br />
        Here you can search for the tag you need, create new tags or delete tags
        that are no longer required.
      </div>
    </div>

    <div class="flex justify-between my-7 w-full items-center">
      <div class="flex items-center justify-between">
        <itq-input
          [width]="300"
          [type]="'text'"
          [icon]="'search'"
          formControlName="searchTerm"
          [placeholder]="'Search tags...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
        <div class="flex items-center space-x-4">
          <itq-multiple-dropdown
            class="ml-4 pl-4 border-l border-border w-[200px]"
            [placeholder]="'Pick a tag type...'"
            formControlName="type"
            [dataSource]="userCategoriesDataSource"
            [dataFields]="{ name: 'name', value: 'id' }"
            (changeValue)="onSearch()"
          ></itq-multiple-dropdown>

          <mat-slide-toggle
            (change)="onSearch()"
            [formControlName]="'sharedTag'"
          >
            <span class="pl-2">Shared tags</span>
          </mat-slide-toggle>
          @if (this.keycloakService.isUserInRole('saas-alerts')) {
            <mat-slide-toggle
              (change)="onSearch()"
              [formControlName]="'hasAlerts'"
            >
              <span class="pl-2">Has alerts</span>
            </mat-slide-toggle>
          }
          @if (this.keycloakService.isUserInRole('super-admin')) {
            <mat-slide-toggle
              (change)="onSearch()"
              [formControlName]="'system'"
            >
              <span class="pl-2">System tags</span>
            </mat-slide-toggle>
          }
        </div>
      </div>
      <itq-mat-button
        [materialStyle]="'flat'"
        [style]="'primary'"
        [type]="'button'"
        (clickEvent)="onCreate()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        CREATE TAG
      </itq-mat-button>
    </div>
  </div>
  @if (dataSource) {
    <div class="flex flex-1 overflow-auto">
      <itq-virtual-table
        class="min-w-[1350px]"
        [tableColumns]="tableColumns"
        [initialState]="initialState"
        [dataSource]="dataSource?.items | formatDates"
        [trackBy]="'tagId'"
        [emptyMessage]="'No tags found!'"
        [emptyMessageDescription]="'Please update search term.'"
        (dataBound)="onDataBound()"
      ></itq-virtual-table>
    </div>
  }
</form>

<ng-template #tagTypeTemplate let-row>
  @if (row.userTagCategory) {
    <itq-pill
      [class]="'font-medium rounded-full bg-link  h-[28px]'"
      [item]="{ name: row.userTagCategory }"
      [type]="PillType.CUSTOM"
    >
      <span class="text-white">
        {{ row.userTagCategory }}
      </span>
    </itq-pill>
  }
</ng-template>

<ng-template #sharedTickTemplate let-row>
  <span
    class="rounded-full w-[28px] h-[28px] bg-primary text-white flex items-center justify-center"
  >
    <mat-icon>check</mat-icon>
  </span>
</ng-template>
<ng-template #alertsTemplate let-row>
  @if (row.alertTypeId) {
    <fa-icon [icon]="['far', 'bell']" [size]="'lg'"></fa-icon>
  }
</ng-template>
<ng-template #intorqaTickTemplate let-row>
  @if (row.categoryId) {
    <span
      class="rounded-full w-[28px] h-[28px] bg-primary text-white flex items-center justify-center"
    >
      <mat-icon>check</mat-icon>
    </span>
  }
</ng-template>
<ng-template #editTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [materialStyle]="'stroked'"
      [matTooltip]="
        (row | isTagEnabled)
          ? 'Edit tag'
          : 'You don\'t have permissions to edit this tag'
      "
      [matTooltipPosition]="'above'"
      [disabled]="!(row | isTagEnabled)"
      (clickEvent)="onEdit(row)"
    >
      <fa-icon [icon]="['far', 'pencil']" [size]="'lg'"></fa-icon>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #actionsButtonTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-nav-button
      [openDirection]="{ vertical: 'down', horizontal: 'left' }"
      [openPosition]="'inside'"
      [materialStyle]="'stroked'"
      [template]="actionsTemplate"
      [matTooltip]="'More actions'"
      [matTooltipPosition]="'above'"
    >
      <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
    </itq-nav-button>
  </div>
  <ng-template #actionsTemplate>
    <ng-container *ngIf="row | isTagEnabled as isTagEnabled">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [matTooltip]="
          isTagEnabled
            ? 'Clone tag'
            : 'You don\'t have permissions to clone this tag'
        "
        [disabled]="!isTagEnabled"
        [matTooltipPosition]="'below'"
        (clickEvent)="onClone(row)"
      >
        <div class="flex space-x-1 items-center">
          <fa-icon [icon]="['far', 'clone']" [size]="'lg'"></fa-icon>
          <span class="whitespace-nowrap">Clone tag</span>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [matTooltipPosition]="'below'"
        [disabled]="!isTagEnabled"
        [matTooltip]="
          isTagEnabled
            ? 'Delete tag'
            : 'You don\'t have permissions to delete this tag'
        "
        (clickEvent)="onDelete(row)"
      >
        <div class="flex space-x-1 items-center">
          <fa-icon [icon]="['far', 'trash-alt']" [size]="'lg'"></fa-icon>
          <span class="whitespace-nowrap">Delete tag</span>
        </div>
      </itq-mat-button>
    </ng-container>
    @if (
      this.keycloakService.isUserInRole('super-admin') ||
      this.keycloakService.isUserInRole('admin')
    ) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onViewHierarchy(row)"
      >
        <div class="flex space-x-1 items-center">
          <fa-icon [icon]="['far', 'list-tree']" [size]="'lg'"></fa-icon>
          <span class="whitespace-nowrap">View dependencies</span>
        </div>
      </itq-mat-button>
    }
  </ng-template>
</ng-template>
