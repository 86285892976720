<div class="flex flex-col w-full">
  <span class="px-4 py-2 bg-border font-bold mb-4 rounded-md">TAG TYPE</span>
  <div class="grid grid-cols-2 gap-4">
    @for (type of dataSource; track type.id) {
      <itq-thumbnail
        class="p-4 bg-white"
        [icon]="'tag'"
        [title]="type.name"
        [description]="type.description"
        (add)="onAdd(type.name)"
      ></itq-thumbnail>
    }
  </div>
</div>
