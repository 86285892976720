<ng-container [formGroup]="form">
  <itq-document-item-detail
    class="px-7 py-4"
    [document]="this.config?.data?.document"
  ></itq-document-item-detail>
  <itq-add-notes
    [form]="form"
    [documentId]="this.config?.data?.document?.id"
    [action]="Actions.CREATE"
    [showTitle]="true"
    [note]="note"
    [profile]="config?.data?.profile"
    [navigationItem]="navigationItem"
    [source]="'PostNotesWizardComponent'"
  ></itq-add-notes>
</ng-container>
