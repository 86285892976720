<ng-container [formGroup]="form">
  <div class="notes__toolbar" *ngIf="navigationItem.action !== Actions.CREATE">
    <itq-pill
      [type]="PillType.CUSTOM"
      [class]="'secondary rounded-md h-[36px]'"
      >{{ dataSource?.totalCount | number }}</itq-pill
    >
    <div class="toolbar__wrapper">
      <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="isFilterable = !isFilterable"
      >
        <fa-icon class="mr-1" [icon]="['far', 'filter-list']"></fa-icon>
        <span class="whitespace-nowrap">FILTERS</span>
      </itq-button-toggle>
      <itq-mat-button
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'basic'"
        (clickEvent)="onAddNote()"
      >
        <div class="flex items-center">
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="whitespace-nowrap">ADD NOTE</span>
        </div>
      </itq-mat-button>
    </div>
  </div>
  <div class="flex flex-col">
    <itq-virtual-table
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [showFilters]="isFilterable"
      [dataSource]="dataSource?.items | transformNotes"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any notes yet!'"
      [emptyMessageDescription]="'To add a note click the button below.'"
      (dataBound)="onDataBound($event)"
      (rowClick)="onViewNote($event)"
    ></itq-virtual-table>
    @if (navigationItem.action === Actions.CREATE) {
      <div class="flex border-border border border-t-0 bg-white py-2">
        <itq-mat-button
          class="ml-4"
          [padding]="Sizes.SM"
          [type]="'button'"
          [materialStyle]="'basic'"
          (clickEvent)="onAddNote()"
        >
          <div class="flex items-center">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
            <span class="whitespace-nowrap">ADD NOTE</span>
          </div>
        </itq-mat-button>
      </div>
    }

    <ng-template #valueTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawValue | urlify"
        ></span>
      </div>
    </ng-template>

    <ng-template #notesTemplate let-row>
      <div class="flex items-center">
        <span
          class="mr-2 mt-3 mb-3"
          [innerHtml]="row?.rawTextNote | urlify"
        ></span>
        <fa-icon *ngIf="row.documentId" [icon]="['far', 'file']"></fa-icon>
      </div>
    </ng-template>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex flex-col w-full">
          <itq-mat-button
            [materialStyle]="'basic'"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            (clickEvent)="onViewNote(row)"
          >
            <fa-icon
              [icon]="['far', 'eye']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            View
          </itq-mat-button>
          <itq-mat-button
            [materialStyle]="'basic'"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            (clickEvent)="onEditNote(row)"
          >
            <fa-icon
              [icon]="['far', 'pencil']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Edit
          </itq-mat-button>
          <itq-mat-button
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [materialStyle]="'basic'"
            (clickEvent)="onDeleteNote(row)"
          >
            <fa-icon
              [icon]="['far', 'trash-alt']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Delete
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
  </div>
</ng-container>
