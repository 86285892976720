<itq-mat-button
  class="w-[350px] m-4"
  [size]="Sizes.BLOCK"
  [type]="'button'"
  [materialStyle]="'stroked'"
  (clickEvent)="onLoadNotifications()"
>
  <span class="whitespace-nowrap">SEARCH NOTIFICATIONS</span>
</itq-mat-button>
<itq-documents-feed
  class="flex min-w-[500px] flex-1 border-l border-border overflow-hidden"
  [data]="matchesDataSource"
  [initialState]="initialState"
  [query]="initialState?.query"
  (createProfile)="onCreateProfile($event)"
  (scrollEvent)="onDataBound($event)"
  (shareEmail)="onShareEmail($event)"
  (drilldown)="onDrilldown($event)"
></itq-documents-feed>
