import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  FieldTypes,
  IRule,
  Operator,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { FieldFiltersComponent } from '@portal/tags/components/field-filters/field-filters.component';
import { NgxAngularQueryBuilderModule, Rule } from 'ngx-angular-query-builder';
import { ICustomTag, IFilterField } from '../../../interfaces/tag.interface';
import { TagService } from '../../../services/tag.service';
import { RuleValueComponent } from './components/rule-value/rule-value.component';
import {
  CustomQueryBuilderConfig,
  IOperator,
  IOperatorList,
} from './query-builder.interface';
import { QBMultipleDropdownComponent } from './components/multiple-dropdown/qb-multiple-dropdown.component';

@Component({
  selector: 'itq-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FormsModule,
    MatTooltipModule,
    FAwesomeModule,
    NgxAngularQueryBuilderModule,
    CommonModule,
    MatIconModule,
    MatRadioModule,
    FieldFiltersComponent,
    RuleValueComponent,
    MatRippleModule,
    QBMultipleDropdownComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QueryBuilderComponent,
    },
  ],
})
export class QueryBuilderComponent implements OnInit {
  @Input() queryModel: QueryBuilderModel;
  @Input() fieldsDataSource: Array<IFilterField>;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  @Input() disabled = false;
  @Input() userTagCategory: string;

  @Output() dataBound = new EventEmitter<QueryBuilderModel>();
  @Output() clearFilters = new EventEmitter<QueryBuilderModel>();

  public builderConfig: CustomQueryBuilderConfig;
  public operatorFields: Array<IOperatorList> = [];
  private touched = false;
  private _tagsDataSource: { items: Array<ICustomTag>; totalCount: number };

  public get tagsDataSource(): {
    items: Array<ICustomTag>;
    totalCount: number;
  } {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: {
    items: Array<ICustomTag>;
    totalCount: number;
  }) {
    if (this.initialState.page > 1) {
      this._tagsDataSource = {
        ...this._tagsDataSource,
        items: this._tagsDataSource.items.concat(value.items),
      };
    } else {
      this._tagsDataSource = value;
    }
  }
  public selection: QueryBuilderModel;
  public tagsInitialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly Sizes = Sizes;
  readonly tagCategory = TagCategory;
  readonly PillType = PillType;
  readonly UserTagCategoryLabel = UserTagCategoryLabel;

  constructor(
    public tagService: TagService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayService: CustomOverlayService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.initializeQueryBuilder();
    this.tagsInitialState.where = this.initialState.where;
  }

  onChange = (value: QueryBuilderModel) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(value: QueryBuilderModel): void {
    this.selection = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public addRuleToRuleSet(): void {
    this.queryModel.addRuleToEmptyRuleSet();
  }

  onFieldFilterSelectionChange(selectedValue: IFilterField, rule: Rule): void {
    rule.field = selectedValue.id;
    rule.entity = selectedValue.field;
    rule.value = undefined;
    rule.operator = selectedValue.operators[0];
    this.cdr.detectChanges();
  }

  onFilterValuesSelectionChange(params: {
    values: Array<ICustomTag>;
    rule: Rule;
  }): void {
    params.rule.value = params.values;
    this.cdr.detectChanges();
    this.onChange(this.queryModel);
    this.tagService.updateQueryModel$.next(this.queryModel);
    this.dataBound.emit(this.queryModel);
  }

  public getLabelByFieldId(rule: Rule): string {
    return this.fieldsDataSource?.find(
      (item: IFilterField) => item.id === rule.field,
    )?.label;
  }

  public onLoadFields(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: FieldFiltersComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private getOperatorsList(): Array<IOperatorList> {
    const operatorFields: Array<IOperatorList> = [];
    Object.keys(this.builderConfig.fields).forEach((key: string) => {
      const field = this.builderConfig.fields[key];
      operatorFields.push({
        fieldName: key,
        operators: field.operators.map((operator: string) => {
          return {
            name: Operator[operator],
            value: operator,
          };
        }),
      });
    });
    return operatorFields;
  }

  initializeQueryBuilder(): void {
    this.builderConfig = {
      fields: {
        undefined: {
          entity: 'undefined',
          name: 'undefined',
          type: 'undefined',
          operators: ['undefined'],
          defaultOperator: 'undefined',
        },
      },
    };
    this.fieldsDataSource.forEach((item: IFilterField) => {
      this.builderConfig.fields[item.id] = {
        entity: item.field,
        name: item.label,
        type:
          item.operators.includes('contains') ||
          item.operators.includes('doesnotcontains') ||
          item.operators.includes('regex')
            ? 'textarea'
            : item.preFilter
              ? 'filter'
              : 'tag',
        operators: item.operators,
        value: item.id,
        defaultOperator: item.operators[0],
      };
    });
    this.operatorFields = this.getOperatorsList();
    if (!this.queryModel) {
      this.queryModel = new QueryBuilderModel(
        [
          {
            entity: undefined,
            field: undefined,
            operator: undefined,
            value: undefined,
          },
        ],
        this.userTagCategory === UserTagCategoryLabel.STANDARD.toString()
          ? 'and'
          : 'or',
      );
    }
  }

  public onChangeQuery(rule?: IRule): void {
    if (rule?.entity === 'actorId' || rule?.entity === 'channelId') {
      const field = this.fieldsDataSource.find(
        (item: IFilterField) => item.field === rule.entity,
      );
      rule.query = [field.query.replace('%s%', rule.value)];
    } else {
      rule.query = undefined;
    }
    this.onChange(this.queryModel);
    if (!this.queryModel.hasEmptyRules()) {
      this.dataBound.emit(this.queryModel);
    }
  }

  public onRemoveRuleSet(): void {
    this.onChange(this.queryModel);
    if (!this.queryModel.hasEmptyRules()) {
      this.dataBound.emit(this.queryModel);
    }
  }

  public onChangeOperator(rule: IRule): void {
    rule.value = undefined;
    this.onChangeQuery(rule);
  }

  onChangeDataField(rule: Rule, fields: any): void {
    const field = fields.find((item: any) => item.value === rule.field);
    rule.entity = field?.entity;
    rule.value = undefined;
    rule.operator = field?.operators[0];
    this.cdr.detectChanges();
  }

  public getFieldOperators(field: string): Array<IOperator> {
    const operators = this.operatorFields.find((item: IOperatorList) => {
      return item.fieldName === field;
    });
    return operators?.operators;
  }

  private isLastPage(): boolean {
    return (
      this.tagsDataSource?.items?.length + this.tagsInitialState.pageSize >=
        this.tagsDataSource?.totalCount && this.tagsInitialState.page > 1
    );
  }

  public onGetFieldValues(
    rule: Rule,
    params: {
      initialState: QueryFilters;
      includeEmpty: boolean;
    },
  ): void {
    this.tagsInitialState = params.initialState;
    if (!this.isLastPage()) {
      const field = this.fieldsDataSource.find(
        (item: IFilterField) => item.id === rule.field,
      );
      if (field.type === FieldTypes.FIELD) {
        this.tagService
          .getFieldValues(
            this.tagsInitialState,
            this.queryModel.convertToBackEndQuery(),
            field.field,
            this.userService.userPreferences.defaultEcosystemId,
            this.tagsInitialState.page > 1
              ? this.tagsDataSource.items[this.tagsDataSource.items.length - 1]
                  .name
              : undefined,
            params.includeEmpty,
          )
          .subscribe(
            (response: { items: Array<ICustomTag>; totalCount: number }) => {
              this.tagsDataSource = response;
            },
          );
      } else {
        this.categoryService
          .getCategoryValues(
            this.tagsInitialState.query,
            this.tagsInitialState,
            this.queryModel.convertToBackEndQuery(),
            field.label,
            this.userService.userPreferences.defaultEcosystemId,
            field.type,
            this.tagsInitialState.page > 1
              ? this.tagsDataSource.items[this.tagsDataSource.items.length - 1]
                  .name
              : undefined,
            params.includeEmpty,
          )
          .subscribe(
            (response: { items: Array<ICustomTag>; totalCount: number }) => {
              this.tagsDataSource = response;
            },
          );
      }
    }
  }

  public onChangeValue(): void {
    if (!this.queryModel.hasEmptyRules()) {
      this.onChange(this.queryModel);
      this.tagService.updateQueryModel$.next(this.queryModel);
      this.dataBound.emit(this.queryModel);
    }
  }

  public onClearFilters(): void {
    this.queryModel = new QueryBuilderModel([
      {
        entity: 'undefined',
        field: 'undefined',
        operator: 'undefined',
        value: [],
      },
    ]);
    this.clearFilters.emit(this.queryModel);
  }

  public onRemoveValue(params: { rule: Rule; value: ICustomTag }): void {
    params.rule.value = params.rule.value.filter((item: ICustomTag) => {
      return item.id !== params.value.id;
    });
    this.onChangeValue();
  }
}
