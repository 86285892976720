import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FieldSections,
  OverlayService,
  QueryFilters,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { UserService } from '@portal/shared/services/user.service';
import { cloneDeep } from 'lodash';
import { IFilterField } from '../../interfaces/tag.interface';
import { CategoryService } from '@portal/shared/services/category.service';

@Component({
  selector: 'itq-field-filters',
  templateUrl: './field-filters.component.html',
  styleUrls: ['./field-filters.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FieldFiltersComponent,
    },
  ],
})
export class FieldFiltersComponent implements OnInit {
  @Input() field: string;
  @Input() initialState: QueryFilters;
  @Input() disabled = false;

  @Output() selectionChange = new EventEmitter<IFilterField>();

  public fieldsDataSurce: Array<IFilterField> = [];
  public selectedSection: string;
  public sectionsDataSource: Array<string>;
  public showLoader = true;
  public selection: any;
  private touched = false;
  public fields: Array<IFilterField>;

  constructor(
    readonly userService: UserService,
    readonly overlayService: OverlayService,
    readonly categoryService: CategoryService,
  ) {}

  onChange = (items: string) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.onGetFields();
  }

  private onGetFields(): void {
    this.showLoader = true;
    this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategoryLabel.STANDARD,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response.filter(
          (item: IFilterField) =>
            item.section === FieldSections.SEARCH_WITHIN || item.hasAnyMatches,
        );
        this.sectionsDataSource = [
          ...new Set(this.fields.map((item: IFilterField) => item.section)),
        ];
        if (this.field) {
          this.selectedSection = this.fields.find(
            (item: IFilterField) => item.id === this.field,
          )?.section;
        } else {
          this.selectedSection = this.sectionsDataSource[0];
        }
        this.fieldsDataSurce = cloneDeep(this.fields)?.filter(
          (item: IFilterField) => item.section === this.selectedSection,
        );
        this.showLoader = false;
      });
  }

  onSelectionChange(item: IFilterField): void {
    this.onChange(item.label);
    this.selectionChange.emit(item);
    this.overlayService.closeOverlay();
  }

  public onNavChange(section: string): void {
    this.selectedSection = section;
    this.fieldsDataSurce = cloneDeep(this.fields)?.filter(
      (item: IFilterField) => item.section === this.selectedSection,
    );
  }
}
