<form [formGroup]="form" class="flex flex-col w-full h-full">
  <div
    class="flex items-center bg-white rounded-md border border-border w-full p-7"
  >
    <div class="flex items-center flex-1">
      <itq-svg class="pr-2 w-14 h-14" [icon]="'vendor'"></itq-svg>
      <span class="font-bold text-28">Vendors</span>
      @if (dataSource?.totalCount) {
        <itq-pill
          class="pl-4"
          [class]="
            'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over h-[36px]'
          "
          [type]="PillType.CUSTOM"
          >{{ dataSource.totalCount | number }}</itq-pill
        >
      }
    </div>
    <div class="flex flex-1">
      This area contains all the vendors that have been created for this
      ecosystem. <br />
      Here you can search for the vendor you need, create new vendors or delete
    </div>
  </div>
  <div class="flex justify-between w-full my-7">
    <div class="flex items-center">
      <itq-dropdown
        class="w-[200px]"
        [icon]="'filter-list'"
        [matTooltip]="'Filter by vendor type'"
        [formControlName]="'type'"
        [clearSelection]="false"
        [dataSource]="typesDataSource"
        [dataFields]="{ name: 'name', value: 'id' }"
        (changeValue)="onChangeProfileType()"
      ></itq-dropdown>
      <mat-slide-toggle
        class="ml-4 pl-4 border-l border-border"
        (toggleChange)="showFilters = !showFilters"
      >
        <div class="flex space-x-2">
          <span class="pl-1"
            >{{ showFilters ? 'Hide ' : 'Show ' }} Filters</span
          >
          @if (initialState?.columnFilters?.length - 1 > 0) {
            <span class="ml-1">
              ({{ initialState?.columnFilters?.length - 1 }})</span
            >
          }
        </div>
      </mat-slide-toggle>
    </div>
    <itq-nav-button
      [openDirection]="{ vertical: 'down', horizontal: 'right' }"
      [openPosition]="'inside'"
      [materialStyle]="'flat'"
      [color]="'primary'"
      [size]="Sizes.BLOCK"
      [template]="addTemplate"
    >
      <fa-icon [icon]="['far', 'plus']"></fa-icon> CREATE VENDOR
    </itq-nav-button>
  </div>
  @if (dataSource) {
    <div class="flex flex-1 overflow-auto">
      <itq-virtual-table
        class="flex-1 overflow-auto min-w-[1740px]"
        [ngStyle]="{
          minWidth:
            (this.form?.get('type')?.value | getProfileTypeName) === 'Cheat'
              ? '1920px'
              : undefined
        }"
        [tableColumns]="tableColumns"
        [showFilters]="showFilters"
        [initialState]="initialState"
        [dataSource]="dataSource?.items | transformProfiles"
        [trackBy]="'profileId'"
        [emptyMessage]="'No vendors found!'"
        [emptyMessageDescription]="'Please update search term.'"
        (dataBound)="onDataBound($event)"
        (rowClick)="onView($event.profileId)"
      ></itq-virtual-table>
    </div>
  }
</form>

<ng-template #updatedByTemplate let-row>
  <itq-avatar [tooltip]="row.updatedBy">
    {{ row.updatedBy | getUsernameInitials }}
  </itq-avatar>
</ng-template>
<ng-template #actionsButtonTemplate let-row>
  <itq-nav-button
    [openDirection]="{ vertical: 'down', horizontal: 'right' }"
    [openPosition]="'inside'"
    [materialStyle]="'basic'"
    [template]="actionsTemplate"
  >
    <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
  </itq-nav-button>
  <ng-template #actionsTemplate>
    <div class="flex items-center flex-col w-full">
      <itq-mat-button
        class="w-full"
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onView(row.profileId)"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        View
      </itq-mat-button>
      <itq-mat-button
        class="w-full"
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [padding]="Sizes['X-SM']"
        [materialStyle]="'basic'"
        (clickEvent)="onDelete(row.profileId)"
      >
        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        Delete
      </itq-mat-button>
    </div>
  </ng-template>
</ng-template>
<ng-template #metadataTemplate let-row let-data="data">
  @if (
    row[data]?.name === MetadataFields.CHEAT_FEATURE ||
    row[data]?.name === MetadataFields.RECENT_POSTS
  ) {
    @if (row[data]?.name === MetadataFields.CHEAT_FEATURE) {
      <div class="flex overflow-hidden mr-4" #buttonWrapper>
        <itq-tags-list
          class="overflow-hidden"
          [dataSource]="row[data] | transformMetadataValues"
          [dataFields]="{ name: 'name', value: 'name' }"
          [type]="PillType.CUSTOM"
          [maxWidth]="getMaxWidth()"
        >
        </itq-tags-list>
      </div>
    }
    @if (row[data]?.name === MetadataFields.RECENT_POSTS) {
      <itq-pill
        [disabled]="false"
        [class]="'secondary rounded-md h-[36px] flex'"
        [type]="PillType.CUSTOM"
        [stopPropagation]="true"
      >
        <span>
          {{ row[data] }}
        </span>
      </itq-pill>
    }
  } @else {
    {{ (row | adaptMetadatataValues: data)?.values }}
  }
</ng-template>
<ng-template #activityTemplate let-data>
  <div
    class="activity__container relative justify-start w-full"
    *ngIf="data.activity !== undefined; else updatingTemplate"
  >
    <itq-pill
      [class]="'secondary rounded-md min-w-[60px] h-[36px] flex'"
      [type]="PillType.CUSTOM"
      >{{ (data | pillFormat).name }}</itq-pill
    >
    @if (data.activity > 0 && data?.activityData) {
      <itq-chart
        [settings]="data?.options"
        [type]="ChartType.LINE"
        [data]="data?.activityData"
      ></itq-chart>
    } @else {
      @if (!data?.activityData) {
        <itq-loader [type]="'controls'"></itq-loader>
      }
    }
  </div>
</ng-template>
<ng-template #trendTemplate let-data>
  <div
    class="flex justify-center flex-col items-center w-full"
    *ngIf="data.trend !== undefined; else updatingTemplate"
    [ngStyle]="{ color: data.trend | getTrendColor }"
  >
    <fa-icon
      [size]="'lg'"
      [icon]="['far', data.trend | getTrendIcon]"
    ></fa-icon>
    <span class="trend__label">{{ data.trend }}%</span>
  </div>
</ng-template>
<ng-template #updatingTemplate>
  <div class="flex relative justify-center w-full h-full items-center">
    <fa-icon [icon]="['far', 'dash']"></fa-icon>
  </div>
</ng-template>
<ng-template #addTemplate>
  <div class="flex items-center flex-col w-full">
    @for (item of typesDataSource; track item.id) {
      <itq-mat-button
        class="w-full"
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (clickEvent)="onCreate(item.id)"
      >
        <div class="flex items-center space-x-2">
          <fa-icon [icon]="['far', ProfileTypeIcon[item?.name]]"></fa-icon>
          <span> {{ item.name }}</span>
        </div>
      </itq-mat-button>
    }
  </div>
</ng-template>
