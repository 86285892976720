import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import {
  DateQueryType,
  DateRangeHelper,
  DTOQuery,
  QueryFilters,
} from '@intorqa-ui/core';
import { map, Observable } from 'rxjs';
import {
  ICustomTag,
  IExtTag,
  IFilterField,
  ITagResults,
  IUserCategory,
} from '../../tags/interfaces/tag.interface';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  public fields: Array<IFilterField>;
  public types: Array<IUserCategory>;

  constructor(private apiRequestService: ApiRequestService) {}

  public getCategories(ecosystemId: string): Observable<Array<any>> {
    let queryParams = '';
    if (ecosystemId) {
      queryParams = `?ecosystemId=${ecosystemId}`;
    }

    return this.apiRequestService.get(
      `/tags/categories/system${queryParams}`,
      new DTOTypeConverter<Array<any>>(),
      undefined,
      'v2.0',
    );
  }

  public getCategoryValues(
    query: string,
    initialState: QueryFilters,
    queryModel: DTOQuery,
    userCategoryName: string,
    ecosystemId: string,
    type: string,
    after?: string,
    showAll?: boolean,
  ): Observable<{ items: Array<ICustomTag>; totalCount: number }> {
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&pageSize=${initialState.pageSize}`;
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    if (initialState.page > 1) {
      pageQuery += `&after=${encodeURIComponent(after)}`;
    }
    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    if (showAll) {
      pageQuery += `&showAll=${showAll}`;
    }
    if (query) {
      pageQuery += `&prefix=${query}`;
    }
    if (type) {
      pageQuery += `&type=${type}`;
    }

    return this.apiRequestService
      .post(
        `/tags/categories/${userCategoryName}/values?${pageQuery}`,
        new DTOTypeConverter<any>(),
        JSON.stringify(queryModel),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: ITagResults) => {
          const result = response.items.map((tag: IExtTag) => ({
            name: tag.value,
            description: tag.description,
            id: tag.id || tag.value,
            count: tag.count,
            userTagCategory: tag.userTagCategory,
          }));
          return { items: result, totalCount: response.totalCount };
        }),
      );
  }

  public getUserCategories(
    ecosystemId: string,
  ): Observable<Array<IUserCategory>> {
    return this.apiRequestService
      .get(
        `/tags/categories/user?ecosystemId=${ecosystemId}`,
        new DTOTypeConverter<Array<IUserCategory>>(),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: Array<IUserCategory>) => {
          this.types = response.map((item: IUserCategory) => {
            const fields = item.fields?.map((field: IFilterField) => ({
              ...field,
              id: `${field.field}_${field.section.trim()}`,
            }));
            return {
              ...item,
              fields,
            };
          });
          return this.types;
        }),
      );
  }

  public getUserCategoryFieldsByType(
    userCategoryName: string,
    ecosystemId: string,
    initialState?: QueryFilters,
  ): Observable<Array<IFilterField>> {
    let pageQuery: string = '';
    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    return this.apiRequestService
      .get(
        `/tags/categories/user/${userCategoryName}/fields?ecosystemId=${ecosystemId}${pageQuery}`,
        new DTOTypeConverter<Array<IFilterField>>(),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: Array<IFilterField>) => {
          this.fields = response?.map((field: IFilterField) => ({
            ...field,
            id: `${field.label}_${field.section.trim()}`,
          }));
          return this.fields;
        }),
      );
  }
}
