import { Component, Input, OnInit } from '@angular/core';

import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { CoreModule, DateRangeComponent, IPresetQuery } from '@intorqa-ui/core';
import { WidgetIntervals } from '@portal/boards/const/widget.const';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Widget } from '@portal/boards/models/widgets/widget';
import { DataSourceType } from '@portal/widget-settings/enums/widget-settings.enum';
import { DateInterval } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { AllTagsDatasourceComponent } from '../all-tags-datasource/all-tags-datasource.component';
import { SpecificTagDatasourceComponent } from '../specific-tag-datasource/specific-tag-datasource.component';

@Component({
  selector: 'itq-tag-trends-metrics',
  templateUrl: './tag-trends-metrics.component.html',
  styleUrls: ['./tag-trends-metrics.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule,
    AllTagsDatasourceComponent,
    SpecificTagDatasourceComponent,
    CoreModule,
    DateRangeComponent,
  ],
})
export class TagTrendsMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.timeSeriesWidget = widget as TimeSeries;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public dataPointsDataSource: Array<Tag>;
  public timeSeriesWidget: TimeSeries;
  public intervalDataSource: Array<{ name: string; value: DateInterval }>;

  readonly DataSourceType = DataSourceType;

  constructor(public widgetService: WidgetService) {
    this.intervalDataSource = WidgetIntervals;
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl('typeDataSource');
    this.form.removeControl('searchType');
    this.form.removeControl('interval');
  }

  private createForm(): void {
    this.form.addControl(
      'typeDataSource',
      new FormControl(
        this.timeSeriesWidget.dataSource.length === 0
          ? DataSourceType.ALL
          : DataSourceType.SET,
        [Validators.required],
      ),
    );

    this.form.addControl(
      'interval',
      new FormControl(this.timeSeriesWidget.interval || DateInterval.DAY, [
        Validators.required,
      ]),
    );
  }

  public onChangeDataValues(value: Array<string>): void {
    this.timeSeriesWidget.dataValues = value;
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      reloadData: true,
    });
  }

  public onChangeDataType(value: string): void {
    this.timeSeriesWidget.dataValues = [];
    this.timeSeriesWidget.dataType = value;
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      reloadData: true,
    });
  }

  public onChangeDataSources(value: Array<string>): void {
    this.timeSeriesWidget.dataValues = [];
    this.timeSeriesWidget.dataType = undefined;
    this.timeSeriesWidget.dataSource = value;
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      reloadData: true,
    });
  }

  public onChangeInterval(): void {
    this.timeSeriesWidget.interval = this.form.controls.interval.value;
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      reloadData: true,
    });
  }

  public onChangeDataSourceType(event: MatRadioChange): void {
    if (event.value === DataSourceType.ALL) {
      this.timeSeriesWidget.dataSource = [];
      this.timeSeriesWidget.dataType = undefined;
      this.timeSeriesWidget.dataValues = [];
    } else {
      this.timeSeriesWidget.dataType = undefined;
      this.timeSeriesWidget.dataValues = [];
    }
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.widgetService.updateWidget$.next({
      widget: this.timeSeriesWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}
