<div class="flex justify-between w-full" (click)="onViewOtherMatches()">
  <div class="flex">
    @if (notification.tagEdited) {
      <div class="flex items-center mr-4">
        <fa-icon
          [size]="'2xl'"
          [matTooltip]="'This tag has changed since the alert was flat'"
          [icon]="['far', 'circle-exclamation']"
          [ngStyle]="{ color: '#ffb74d' }"
        ></fa-icon>
      </div>
    }
    <div class="flex items-center">
      <div
        class="rounded-2xl p-2 shadow-none font-semibold h-6 flex items-center"
        [ngStyle]="{
          backgroundColor: PriorityColor[this.notification.priority],
          width: '112px'
        }"
      >
        <fa-icon [icon]="['far', 'bell']"></fa-icon>
        <span class="pl-2 text-xs">{{
          AlertTypesLabel[notification.alertTypeName]
        }}</span>
      </div>
    </div>
    <div class="flex flex-col px-4 text-xs">
      <div class="font-semibold text-sm">
        New match for {{ notification?.tagName }}
      </div>
      @if (notification.matches) {
        <div class="flex flex-col text-metadata">
          <span>
            Raised with {{ notification?.matches }} matches on
            {{
              notification.createdDate
                | convertFromEpochMillis
                | date: 'dd/MM/yyyy - hh:mm'
            }}
          </span>
        </div>
      }
    </div>
  </div>
  <div class="flex">
    <itq-mat-button
      class="mr-1"
      [size]="Sizes.SM"
      [type]="'button'"
      [materialStyle]="'stroked'"
      [padding]="Sizes['X-SM']"
      [matTooltip]="'View matches'"
      (mouseDown)="$event.stopImmediatePropagation(); onViewOtherMatches()"
    >
      <fa-icon [icon]="['far', 'list-check']"></fa-icon>
    </itq-mat-button>
    <itq-mat-button
      class="mr-1"
      [size]="Sizes.SM"
      [type]="'button'"
      [materialStyle]="'stroked'"
      [padding]="Sizes['X-SM']"
      [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
      (clickEvent)="$event.stopImmediatePropagation(); onToggleRead()"
    >
      <fa-icon [icon]="['far', notification.read ? 'check' : 'eye']"></fa-icon>
    </itq-mat-button>
    @if (notification?.document) {
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'stroked'"
        [size]="Sizes.SM"
        [padding]="Sizes['X-SM']"
        [matTooltip]="showDocument ? 'Hide document' : 'Show document'"
        (clickEvent)="
          $event.stopImmediatePropagation(); showDocument = !showDocument
        "
      >
        <fa-icon
          [icon]="['far', showDocument ? 'file-check' : 'file']"
        ></fa-icon>
      </itq-mat-button>
    }
  </div>
</div>

@if (notification?.document && showDocument) {
  <itq-document-item-tile
    class="flex w-full pt-4"
    [document]="notification?.document"
    (createProfile)="onCreateProfile($event)"
    (drilldown)="onDrilldown($event)"
  ></itq-document-item-tile>
}
