<div class="flex flex-col w-full">
  <div class="flex w-full">
    <itq-input
      class="pb-2 w-[250px]"
      [icon]="'search'"
      [placeholder]="'Add search term...'"
      [type]="'text'"
      [(ngModel)]="query"
      (search)="onSearch()"
      (clear)="onSearch()"
    ></itq-input>
  </div>
  <div class="flex w-full border-t border-border pt-[8px]">
    <mat-nav-list
      class="w-[275px] mr-4"
      [style.padding-right]="'8px !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of userCategoriesDataSource"
        [style.background-color]="
          item.name === selectedUserCategory ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item.name)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-semibold': item.name === selectedUserCategory
          }"
        >
          <span>{{ item.name }}</span>
          <mat-icon>{{
            item.name === selectedUserCategory ? 'chevron_right' : 'expand_more'
          }}</mat-icon>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <div
      class="flex w-[400px] min-h-[250px] max-h-[350px] overflow-auto border-l border-border"
    >
      @if (tagsDataSource?.length > 0) {
        <mat-selection-list
          [(ngModel)]="selections"
          class="flex-1"
          [style.padding-top]="'8px !important'"
          [style.padding-top]="'0 !important'"
          [style.padding-left]="'8px !important'"
          (selectionChange)="onSelectionChange()"
        >
          <mat-list-option
            class="hover:bg-background-over rounded-md"
            *ngFor="let item of tagsDataSource"
            [value]="item"
            [disabled]="item.count === 0"
            [selected]="item | isSelected: selections"
          >
            <div class="flex items-center justify-between w-full space-x-2">
              <span
                class="flex-1"
                [innerHTML]="item.name | highlightSearch: query"
              ></span>
              <span class="font-semibold">({{ item.count }})</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      } @else {
        @if (tagsDataSource) {
          <itq-panel-info
            class="p-7"
            [label]="'No values found!'"
            [message]="'Please update your search term of choose a new field.'"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        }
      }
    </div>
  </div>

  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</div>
