<a
  class="flex justify-between items-center w-full px-4 h-full hover:bg-background-over"
  [routerLinkActive]="'active'"
  [routerLink]="[url]"
>
  <div class="flex items-center font-semibold">
    @if (iconType === IconType.SVG) {
      <itq-svg class="w-5 h-5 mr-1 fill-current" [icon]="icon"></itq-svg>
    } @else {
      <fa-icon class="mr-1" [icon]="['far', icon]" [size]="'lg'"></fa-icon>
    }
    {{ title }}
  </div>
  <itq-pill
    [class]="'rounded-full bg-link text-white h-[28px]'"
    [type]="PillType.CUSTOM"
    >{{ totalCount || 0 | number }}</itq-pill
  >
</a>
