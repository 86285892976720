<ng-container [formGroup]="form">
  <div class="flex items-center justify-between">
    <itq-pill
      [type]="PillType.CUSTOM"
      [class]="'secondary rounded-md h-[36px]'"
      >{{ dataSource?.totalCount | number }}</itq-pill
    >
    <div class="flex space-x-2">
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        (clickEvent)="onExit()"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="whitespace-nowrap">VIEW CONTENT</span>
      </itq-mat-button>
      <itq-mat-button
        [style]="'primary'"
        [type]="'button'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'cog']"></fa-icon>
        <span class="whitespace-nowrap">SETTINGS</span>
      </itq-mat-button>
    </div>
    <div class="flex space-x-1">
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onLinkTag()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="whitespace-nowrap">ADD LINK</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="flex flex-col py-4">
    <itq-virtual-table
      [showFilters]="isFilterable"
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t linked any tags yet!'"
      [emptyMessageDescription]="'To link a tag click the button below.'"
      (dataBound)="onDataBound($event)"
    ></itq-virtual-table>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex flex-col w-full">
          <itq-mat-button
            class="w-full"
            [materialStyle]="'basic'"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            (clickEvent)="onEditLink(row)"
          >
            <fa-icon
              [icon]="['far', 'pencil']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Edit
          </itq-mat-button>
          <itq-mat-button
            class="w-full"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [materialStyle]="'basic'"
            [matTooltip]="
              row.isDefault ? 'You can\'t delete default tag' : undefined
            "
            [disabled]="row.isDefault ? true : false"
            (clickEvent)="onUnlinkTag(row)"
          >
            <fa-icon
              [icon]="['far', 'trash-alt']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Delete
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row>
      <span>
        {{ row.updatedDate | date: 'yyyy MMM dd' }}
      </span>
    </ng-template>
    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
    <ng-template #defaultButtonTemplate let-row>
      <fa-icon
        *ngIf="row.isDefault"
        [icon]="['far', 'check-circle']"
        [size]="'lg'"
      ></fa-icon>
    </ng-template>
  </div>
</ng-container>
