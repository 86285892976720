import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FAwesomeModule, HighlightSearchPipe } from '@intorqa-ui/core';
import { DocumentExtensionFieldsComponent } from '../document-extension-fields/document-extension-fields.component';
import {
  DocumentItem,
  IContentLink,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'itq-document-links',
  templateUrl: './document-links.component.html',
  styleUrls: ['./document-links.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    DocumentExtensionFieldsComponent,
    MatTooltipModule,
    CommonModule,
    HighlightSearchPipe,
  ],
})
export class DocumentLinksComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() drilldownSegment: string;
  @Input() query: string;

  @Output() drilldown = new EventEmitter<ISegment>();

  public contentLinks: Array<IContentLink> = [];

  constructor() {}

  ngOnInit() {
    this.contentLinks = this.getContentLinks();
  }

  public getContentLinks(): Array<IContentLink> {
    let contentLinks: any;
    if (this.document?.emitExtensionFields?.length > 0) {
      if (this.document.emitSource === 'Discord') {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField.name === 'contentLinks') {
              return extensionField;
            }
          },
        );
        return contentLinks?.data?.map((item: { url: string }) => ({
          href: item.url,
        }));
      } else {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField['content links']?.length > 0) {
              return extensionField;
            }
          },
        );
        if (contentLinks && contentLinks['content links']) {
          let uniqueHrefs: Array<string> = [];
          return contentLinks['content links'].filter((link: IContentLink) => {
            if (link.href?.length > 0) {
              if (!uniqueHrefs.includes(link.href.trim())) {
                uniqueHrefs.push(link.href);
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      }
    }
    return [];
  }

  public onDrilldownDiscord(extensionField: IExtensionField): void {
    this.drilldown.emit({
      scope: SegmentScope.DISCORD,
      value: extensionField,
    });
  }
}
