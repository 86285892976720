import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  FAwesomeModule,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
} from '@intorqa-ui/core';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TOP_ACTORS_CHART_TYPES } from '@portal/boards/const/top-actors.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { TopActorsMetricsComponent } from '@portal/widgets/components/top-actors-metrics/top-actors-metrics.component';
import { Subscription } from 'rxjs';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-chart-wizard-top-actors.component',
  templateUrl: './chart-wizard-top-actors.component.html',
  styleUrls: ['./chart-wizard-top-actors.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MapLabelPipe,
    CommonModule,
    SelectMetricsLabelPipe,
    TopActorsMetricsComponent,
    ChartComponent,
    TableChartComponent,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTopActorsComponent implements OnInit {
  @Input() widget: TopActor;
  @Input() action = Actions.CREATE;
  @Input() initialState: QueryFilters;

  public form: FormGroup;
  public showLoader = false;
  public chartTypesDataSource = TOP_ACTORS_CHART_TYPES;
  private subscription = new Subscription();
  public count: number;
  public dataSource: IWidgetData;
  public tableColumns: Array<TableColumn>;

  readonly ChartType = ChartType;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  constructor(
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly modalContainerService: ModalContainerService,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initTableColumns(): void {
    if (this.widget.chartType === ChartType.TABLE) {
      this.tableColumns = [
        {
          name: 'Actor',
          dataKey: 'label',
          width: 'fit',
          isSortable: true,
        },
        {
          name: 'Count',
          dataKey: 'count',
          position: 'right',
          width: '100px',
          customRender: true,
          template: this.countTemplate,
          isSortable: true,
        },
      ];
    }
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.initialState = params;
      this.getWidgetData();
    }
  }

  private getWidgetData(): void {
    this.showLoader = true;

    this.widgetService
      .getData(
        {
          widget: this.widget,
          filters: new Query().convertToBackEndQuery(),
        },
        this.initialState,
      )
      .subscribe((response: IWidgetData) => {
        this.dataSource = response;
        this.count = response.totalHits;
        this.initTableColumns();
        this.showLoader = false;
        this.cdr?.detectChanges();
        this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
      });
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TopActor;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.initTableColumns();
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
  }

  public onPrev(): void {
    this.modalContainerService.navigate$.next({
      component: CreateWidgetTypeComponent,
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.widgetService
      .createWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({ refresh: true, widget: response });
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
