import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CoreModule,
  FAwesomeModule,
  HighlightSearchPipe,
  PillType,
  QueryFilters,
} from '@intorqa-ui/core';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'itq-qb-multiple-dropdown-results',
  templateUrl: './qb-multiple-dropdown-results.component.html',
  styleUrls: ['./qb-multiple-dropdown-results.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    MatListModule,
    MatSlideToggleModule,
    FormsModule,
    CommonModule,
    HighlightSearchPipe,
    FAwesomeModule,
  ],
})
export class QBMultipleDropdownResultsComponent implements OnInit, OnChanges {
  @Input() dataSource: Array<any>;
  @Input() selections: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() initialState: QueryFilters;

  @Output() changeValue = new EventEmitter<Array<any>>();
  @Output() dataBound = new EventEmitter<{
    initialState: QueryFilters;
    includeEmpty: boolean;
  }>();

  @ViewChild('inputControl') inputControl: ElementRef;
  @ViewChild('listSearch', { read: ElementRef }) listSearch: ElementRef;

  public showLoader: boolean;
  public query: string;
  public includeEmpty = false;

  readonly PillType = PillType;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue) {
      this.showLoader = false;
      if (this.initialState.page === 1 && this.listSearch) {
        this.listSearch.nativeElement.scrollTop = 0;
      }
    }
  }

  public onChangeValue(): void {
    this.changeValue.emit(this.selections);
  }

  public onToggleShowAll(): void {
    this.initialState.page = 1;
    this.dataBound.emit({
      initialState: this.initialState,
      includeEmpty: this.includeEmpty,
    });
  }

  public onDataBound(): void {
    this.initialState.query = this.query;
    this.showLoader = true;
    this.dataBound.emit({
      initialState: this.initialState,
      includeEmpty: this.includeEmpty,
    });
  }

  public isChecked(item: any): boolean {
    return this.selections?.includes(item[this.dataFields.value])
      ? true
      : false;
  }

  public onScroll(): void {
    this.initialState.page++;
    this.onDataBound();
  }
}
