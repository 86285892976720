import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayService } from '@intorqa-ui/core';
import { Align, Sizes } from '../../enums/shared.enum';

@Component({
  selector: 'itq-mat-button',
  templateUrl: './mat-button.component.html',
  styleUrls: ['./mat-button.component.scss'],
})
export class MatButtonComponent implements OnInit {
  @Input() style: string;
  @Input() size = Sizes.MD;
  @Input() type = 'button';
  @Input() disabled: boolean;
  @Input() rounded = false;
  @Input() align: Align;
  @Input() materialStyle: string;
  @Input() defaultColor: string;
  @Input() padding = Sizes.MD;

  @Output() clickEvent = new EventEmitter<MouseEvent>();
  @Output() mouseDownEvent = new EventEmitter<MouseEvent>();
  @Output() blurEvent = new EventEmitter<FocusEvent>();

  @ViewChild('dropdownButton') dropdownButton: any;

  constructor(readonly overlayService: OverlayService) {}

  ngOnInit(): void {}

  onClick(event: MouseEvent): void {
    this.clickEvent.emit(event);
    this.overlayService.closeOverlay();
  }

  onMouseDown(event: MouseEvent): void {
    this.mouseDownEvent.emit(event);
  }

  onBlur(event: FocusEvent): void {
    this.blurEvent.emit(event);
  }
}
