import { FormGroup } from '@angular/forms';
import { Actions, IconType, QueryFilters } from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { ProfileConnection } from '@portal/profiles/models/profile-connection';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { Rule } from 'ngx-angular-query-builder';
import { IProfilesNavigationItem } from '../interfaces/profiles-navigation-item.interface';
import { ISegment } from '../interfaces/widget-settings.interface';
import { NavigationHistoryItem } from './navigation-history-item.model';

export class ProfilesNavigationItem
  extends NavigationHistoryItem
  implements IProfilesNavigationItem
{
  constructor(
    public id: string,
    public item: Widget,
    public action: Actions,
    public rules: Array<Rule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string,
    public linkTag: LinkTag,
    public connections: Array<ProfileConnection>,
    public notes: Array<ProfileNote>,
    public selectedTabIndex: number,
  ) {
    super(
      id,
      item,
      action,
      rules,
      initialState,
      form,
      icon,
      iconType,
      selectedDisplayType,
      segment,
      boardId,
    );
  }
}
