import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { OverlayService, Utils } from '@intorqa-ui/core';
import { PillType } from '../pill/pill.enum';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'itq-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent implements OnInit {
  @Input() dataSource: Array<any>;
  @Input() type: PillType;
  @Input() maxWidth: number;
  @Input() dataFields: { name: string; value: string };

  @Output() removeValue = new EventEmitter<any>();

  @ViewChild('moreIcon') moreIcon: ElementRef;

  public reachedMaxItems: boolean;
  private overlayRef: OverlayRef;

  readonly PillType = PillType;

  constructor(
    readonly overlayService: OverlayService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dataSource?.previousValue !== changes.dataSource?.currentValue
    ) {
      this.reachedMaxItems = this.hasReachedMax();
    }
  }

  public onRemoveItem(value: any): void {
    if (this.type === PillType.BUTTON) {
      this.removeValue.emit(value);
    }
  }

  onShowTooltip(): void {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.moreIcon)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
    });

    const tooltipPortal = new ComponentPortal(
      TooltipComponent,
      this.viewContainerRef,
    );
    const tooltipRef = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.content = this.getTooltipContent();
  }

  getTooltipContent(): string {
    // Your logic to get the tooltip content
    return this.dataSource
      .map((item) => item[this.dataFields.name] || item)
      .join('<br>');
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  public onHideTooltip(): void {
    this.closeOverlay();
  }

  private hasReachedMax(): boolean {
    if (!this.dataSource) return false;
    if (!this.maxWidth) return false;
    let textWidth = 0;
    let reached = false;
    const containerWrapperWidth = this.maxWidth;
    this.dataSource.forEach((selection: any) => {
      textWidth +=
        Utils.getTextWidthFromElement(
          selection[this.dataFields.name] || selection,
          'Mont',
          '12px',
        ) +
        30 +
        Utils.remToPx(1.25);
    });
    if (textWidth > containerWrapperWidth) {
      reached = true;
    }
    return reached;
  }
}
