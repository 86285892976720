import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Actions,
  Align,
  ComponentType,
  CustomOverlayService,
  CustomOverlayType,
  IDropdownItem,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { DocumentItem } from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import {
  INoteDataSource,
  INotesData,
} from '@portal/profiles/interfaces/profile-note.interface';
import { NoteType } from '@portal/profiles/models/note-type';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddNotesComponent } from '@portal/shared/components/add-notes/add-notes.component';
import { UserService } from '@portal/shared/services/user.service';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-profiles-notes',
  templateUrl: './profiles-notes.component.html',
  styleUrls: ['./profiles-notes.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesNotesComponent,
    },
  ],
})
export class ProfilesNotesComponent implements OnInit, AfterViewInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() action: Actions;
  @Input() showTitle = true;

  public dataSource: INotesData;
  public tableColumns: Array<TableColumn> = [];
  public notesSubscription: Subscription;
  private selection: Array<ProfileNote>;
  private touched = false;
  private disabled = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  private resetSubscription: Subscription;
  public isFilterable = false;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly Align = Align;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('notesTemplate')
  notesTemplate: TemplateRef<unknown>;
  @ViewChild('valueTemplate')
  valueTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;

  constructor(
    private notesService: NotesService,
    readonly documentService: DocumentItemService,
    readonly customOverlayService: CustomOverlayService,
    readonly profilesService: ProfileService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.resetSubscription = this.notesService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    this.notesSubscription = this.notesService.notes$.subscribe(
      (response: INotesData) => {
        this.dataSource = cloneDeep(response);
      },
    );
    this.onLoadTypes();
    if (this.navigationItem.notes?.length > 0) {
      this.notesService.notes = {
        items: this.navigationItem.notes,
        totalCount: this.navigationItem.notes.length,
      };
      this.dataSource = cloneDeep(this.notesService.notes);
    } else {
      if (this.navigationItem.action === Actions.CREATE) {
        this.notesService.notes = {
          items: [],
          totalCount: 0,
        };
        this.dataSource = cloneDeep(this.notesService.notes);
      }
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.notesSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileNote>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onLoadTypes(): void {
    this.notesService.getNoteTypes(this.profile.profileTypeId).subscribe(() => {
      this.getSubTypes();
      this.getNoteTypes();
      if (
        this.navigationItem.action === Actions.EDIT ||
        this.navigationItem.action === Actions.EXPLORE
      ) {
        this.onDataBound(this.initialState, false);
      }
    });
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Type',
        dataKey: 'type',
        searchField: 'typeId',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: undefined,
      },
      {
        name: 'Sub type',
        dataKey: 'subType',
        searchField: 'subType',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: undefined,
        dataBound: () => this.getSubTypes(),
      },
      {
        name: 'Value',
        dataKey: 'rawValue',
        searchField: 'rawValue',
        isSortable: true,
        width: 'fit',
        isFilterable: true,
        customRender: true,
        template: this.valueTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Notes',
        dataKey: 'rawTextNote',
        searchField: 'textNote',
        isSortable: true,
        width: 'fit',
        isFilterable: true,
        customRender: true,
        template: this.notesTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];

    if (
      this.navigationItem.action === Actions.EXPLORE ||
      this.navigationItem.action === Actions.EDIT
    ) {
      this.tableColumns = [
        {
          name: 'Updated date',
          dataKey: 'updatedDate',
          searchField: 'updatedDate',
          isSortable: true,
          width: '150px',
          sticky: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.DATE,
          searchComponent: ComponentType.DATE,
        },
        {
          name: 'Updated by',
          dataKey: 'updatedBy',
          searchField: 'updatedBy',
          isSortable: true,
          position: 'center',
          customRender: true,
          template: this.ownerTemplate,
          width: '120px',
          httpBinding: true,
          sticky: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.TEXT,
          searchComponent: ComponentType.DROPDOWN,
          dataSource: undefined,
          dataBound: () => {
            this.onGetUsers();
          },
        },
        {
          name: 'DocumentId',
          dataKey: 'documentId',
          width: '150px',
          show: false,
        },
        ...this.tableColumns,
      ];
    }
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'updatedBy') {
        this.notesService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  private getNoteTypes(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'typeId') {
        column.dataSource = this.notesService.noteTypes.map(
          (item: NoteType) => ({ name: item.name, value: item.id }),
        );
      }
      return column;
    });
  }

  private getSubTypes(): void {
    let result: Array<IDropdownItem> = [];
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'subType') {
        this.notesService.noteTypes.forEach((item: NoteType) => {
          if (item.subTypes?.length > 0) {
            result = [
              ...result,
              ...item.subTypes.map((subItem: string) => ({
                name: subItem,
                value: subItem,
              })),
            ];
          }
        });
        column.dataSource = result;
      }
      return column;
    });
  }

  public onDeleteNote(note: ProfileNote): void {
    if (
      this.navigationItem.action === Actions.EXPLORE ||
      this.navigationItem.action === Actions.EDIT
    ) {
      this.notesService
        .removeNotes(this.profile.profileId, [note.id])
        .subscribe();
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onAddNote(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            navigationItem: this.navigationItem,
            note: new ProfileNote(
              Utils.generateUUID(),
              this.profile.profileId,
              undefined,
              undefined,
              undefined,
              undefined,
              this.userService.userPreferences.defaultEcosystemId,
            ),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.onDataBound(this.initialState);
      }
    });
  }

  public onEditNote(note: INoteDataSource): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddNotesComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            navigationItem: this.navigationItem,
            note: cloneDeep(note),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.onDataBound(this.initialState);
      }
    });
  }

  public onDataBound(params: QueryFilters, showLoader = true): void {
    this.profilesService.loader$.next(true);
    this.notesService
      .getNotes(this.profile.profileId, params, showLoader)
      .subscribe(() => {
        this.profilesService.loader$.next(false);
      });
  }

  public onViewNote(note: ProfileNote): void {
    this.documentService
      .getDocument(note.documentId)
      .subscribe((response: DocumentItem) => {
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: AddNotesComponent,
              inputs: {
                document: response,
                note: cloneDeep(note),
              },
            },
          },
          closeBtnClass: 'hidden',
          closeBtnStyle: 'basic',
          type: CustomOverlayType['slide-right'],
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      });
  }
}
