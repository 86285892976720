import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  FAwesomeModule,
  IconType,
  OverlayService,
  QueryFilters,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { UserService } from '@portal/shared/services/user.service';
import { TagService } from '@portal/tags/services/tag.service';
import {
  AnalysisTypes,
  AnalysisTypesLabel,
} from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-timeline-widget-actions',
  templateUrl: './timeline-widget-actions.component.html',
  styleUrls: ['./timeline-widget-actions.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    FormsModule,
    ClipboardModule,
    SvgComponent,
  ],
})
export class TimelineWidgetActionsComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() board: Board;

  public showLoader = false;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'asc',
      active: 'name',
    },
    undefined,
  );
  public selectedSection = false;
  public tagShareLink: string;

  public transformTypesDataSource = [
    {
      id: AnalysisTypes.TOP_ACTORS,
      name: AnalysisTypesLabel.TOP_ACTORS,
      icon: 'user',
    },
    {
      id: AnalysisTypes.TOP_CHANNELS,
      name: AnalysisTypesLabel.TOP_CHANNELS,
      icon: 'hashtag',
    },
    {
      id: AnalysisTypes.TOPIC_CLUSTERS,
      name: AnalysisTypesLabel.TOPIC_CLUSTERS,
      icon: 'ripl',
      iconType: IconType.SVG,
    },
  ];

  @Output() delete = new EventEmitter<void>();

  readonly Align = Align;
  readonly Sizes = Sizes;
  readonly IconType = IconType;

  constructor(
    readonly userService: UserService,
    readonly profileService: ProfileService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly linkTagsService: LinkTagsService,
    readonly snackBar: MatSnackBar,
    readonly widgetService: WidgetService,
    readonly boardService: BoardService,
    readonly overlayService: OverlayService,
  ) {}

  ngOnInit() {
    this.tagShareLink = `${window.location.origin}/boards/${this.board.id}?widgetid=${this.widget.widgetId}`;
  }

  public onNavChange(): void {
    this.selectedSection = !this.selectedSection;
  }

  public onCreateWidget(type: AnalysisTypes): void {
    this.overlayService.closeOverlay();
    let widget = WidgetFactory.createWidget({
      name: this.widget.name,
      description: this.widget.description,
      type,
      dataSource: this.widget.dataSource,
      ecosystemId: this.widget.ecosystemId,
    });
    this.widgetService.createWidget(widget).subscribe((response: Widget) => {
      this.boardService
        .addWidgetsToBoard(this.board.id, {
          add: [response.widgetId],
        })
        .subscribe();
    });
  }

  public onShareLink(): void {
    this.snackBar.open('Tag shared successfully!', 'Close', {
      horizontalPosition: 'right',
      duration: 5000,
      verticalPosition: 'top',
    });
    this.overlayService.closeOverlay();
  }

  public onDeleteWidget(): void {
    this.delete.emit();
    this.overlayService.closeOverlay();
  }
}
