import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AlertTypes, CoreModule, UserTagCategoryLabel } from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { BoardService } from '@portal/boards/services/board.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { QueryRendererComponent } from '../query-renderer/query-renderer.component';
import {
  CastAsQueryModelPipe,
  GetDelayLabelPipe,
} from './tag-wizard-save.pipe';
import { GetAlertTypeLabelPipe } from '../tag-wizard-alerts/tag-wizard-alerts.pipe';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { map, Observable, of } from 'rxjs';
import { TagService } from '@portal/tags/services/tag.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'itq-tag-wizard-save',
  templateUrl: './tag-wizard-save.component.html',
  styleUrls: ['./tag-wizard-save.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    CommonModule,
    CastAsQueryModelPipe,
    QueryRendererComponent,
    GetDelayLabelPipe,
    GetAlertTypeLabelPipe,
    MatFormFieldModule,
    MatIconModule,
  ],
})
export class TagWizardSaveComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() boardIds: Array<string>;
  @Input() tag: Tag;

  readonly UserTagCategoryLabel = UserTagCategoryLabel;
  readonly AlertTypes = AlertTypes;

  public boardsDataSource: Array<Board>;
  public categoriesDataSource: Array<any>;

  constructor(
    readonly boardService: BoardService,
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
  ) {}

  ngOnInit() {
    this.addControls();
    this.loadBoards();
    this.onGetSystemCategories();
  }

  private onGetSystemCategories(): void {
    if (this.tag.userTagCategory === UserTagCategoryLabel.SYSTEM) {
      this.categoryService
        .getCategories(this.userService.userPreferences.defaultEcosystemId)
        .subscribe((response: Array<any>) => {
          this.categoriesDataSource = response;
        });
    }
  }

  private addControls(): void {
    this.form.addControl(
      'save',
      new FormGroup({
        name: new FormControl(
          this.tag?.name,
          [Validators.required],
          [this.validateName()],
        ),
        description: new FormControl(this.tag?.description),
        boardIds: new FormControl([]),
      }),
    );
    if (this.tag.userTagCategory === 'System') {
      (this.form.get('save') as FormGroup).addControl(
        'category',
        new FormControl(this.tag?.categoryId, [Validators.required]),
      );
    }
  }

  private validateName(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control?.value) {
        return this.tagService
          .validateName({
            name: control?.value,
            ecosystemId: this.userService.userPreferences.defaultEcosystemId,
            userTagCategory: this.tag.userTagCategory,
            sharedTag: this.form.get('sharedTag').value,
          })
          .pipe(
            map((isValid: boolean) => {
              return isValid ? null : { duplicateName: true };
            }),
          );
      } else {
        return of(null);
      }
    };
  }

  private loadBoards(): void {
    this.boardService
      .getBoards(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<Board>) => {
        this.boardsDataSource = response;
        if (this.boardIds?.length > 0) {
          this.setBoards();
        }
      });
  }

  private setBoards(): void {
    if (this.boardsDataSource?.length > 0) {
      const boards = this.boardIds.map((item: string) =>
        this.boardsDataSource?.find((board: Board) => board.id === item),
      );
      this.form.get('save.boardIds').setValue(boards);
    }
  }
}
