import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  FieldTypes,
  HighlightSearchPipe,
  InfiniteScrollDirective,
  IPresetQuery,
  IsSelectedPipe,
  QueryFilters,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { map, Observable, Subscription, tap } from 'rxjs';
import {
  ICustomTag,
  IFilterField,
  ITagResults,
} from '../../interfaces/tag.interface';
import { TagService } from '../../services/tag.service';

@Component({
  selector: 'itq-value-filters',
  templateUrl: './value-filters.component.html',
  styleUrls: ['./value-filters.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatCheckboxModule,
    FormsModule,
    HighlightSearchPipe,
    IsSelectedPipe,
    MatTooltipModule,
    InfiniteScrollDirective,
    MatSlideToggleModule,
    MatSidenavModule,
    FAwesomeModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValueFiltersComponent,
    },
  ],
})
export class ValueFiltersComponent implements OnInit {
  @Input() field: string;
  @Input() fields: Array<IFilterField>;
  @Input() dates: IPresetQuery;
  @Input() queryModel = new QueryBuilderModel();
  @Input() preFilter: string;

  @Output() selectionChange = new EventEmitter<Array<ICustomTag>>();

  private _subDataSource: { items: Array<ICustomTag>; totalCount: number };

  public get subDataSource(): { items: Array<ICustomTag>; totalCount: number } {
    return this._subDataSource;
  }

  public set subDataSource(value: {
    items: Array<ICustomTag>;
    totalCount: number;
  }) {
    if (this.valuesInitialState.page === 1) {
      this._subDataSource = value;
    } else {
      this._subDataSource.items = this._subDataSource.items.concat(value.items);
    }
  }
  private _dataSource: ITagResults;

  public get dataSource(): ITagResults {
    return this._dataSource;
  }

  public set dataSource(value: ITagResults) {
    if (this.initialState.page === 1) {
      this._dataSource = value;
    } else {
      this._dataSource.items = this._dataSource.items.concat(value.items);
    }
  }
  public selectedIndex = 0;
  public showLoader = true;
  public query: string;
  public showPrefilter = true;
  public selections: Array<ICustomTag> = [];
  private touched = false;
  private disabled = false;
  private subscriptions = new Subscription();
  public showEmpty = false;
  public initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public valuesInitialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  constructor(
    readonly categoryService: CategoryService,
    readonly userService: UserService,
    readonly tagService: TagService,
    readonly virtualScrollService: VirtualScrollService,
  ) {}

  onChange = (items: string) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ICustomTag>): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.bindUpdateQueryModelSubscription();
    this.valuesInitialState.where = this.dates;
    this.initialState.where = this.dates;
    this.onDataBound();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onToggleShowAll(): void {
    this.valuesInitialState.page = 1;
    if (this.showPrefilter) {
      this.initialState.page = 1;
      this.onDataBound();
    } else {
      this.onGetFieldValues();
    }
  }

  private bindUpdateQueryModelSubscription(): void {
    this.subscriptions.add(
      this.tagService.updateQueryModel$.subscribe(
        (response: QueryBuilderModel) => {
          this.queryModel = response.clone();
          this.valuesInitialState.page = 1;
          this.initialState.page = 1;
          this.onDataBound();
        },
      ),
    );
  }

  public onSearch(): void {
    this.valuesInitialState.query = this.query;
    this.onGetFieldValues();
  }

  private isLastPageForPrefilter(): boolean {
    return (
      this.dataSource?.items.length + this.initialState.pageSize >=
        this.dataSource?.totalCount && this.initialState.page > 1
    );
  }

  public onGetPrefilters(): Observable<ITagResults> {
    if (this.preFilter) {
      if (!this.isLastPageForPrefilter()) {
        this.showLoader = true;
        const field = this.fields.find(
          (item: IFilterField) => item.id === this.field,
        );
        return this.tagService.getPrefilterValues(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.preFilter,
          this.userService.userPreferences.defaultEcosystemId,
          field.field,
          this.initialState.page > 1
            ? this.dataSource.items[this.dataSource.items.length - 1].value
            : undefined,
          this.showEmpty,
        );
      }
    }
  }

  public onTogglePrefilter(): void {
    this.showPrefilter = !this.showPrefilter;
    this.valuesInitialState.page = 1;
    if (this.showPrefilter) {
      this.initialState.page = 1;
      this.onDataBound();
    } else {
      this.onGetFieldValues();
    }
  }

  public onScrollPrefilters(): void {
    this.onGetPrefilters().subscribe((response: ITagResults) => {
      this.dataSource = response;
      this.initialState.page++;
    });
  }

  public onDataBound(): void {
    this.onGetPrefilters().subscribe((response: ITagResults) => {
      this.dataSource = response;
      this.initialState.page++;
      if (this.dataSource?.items?.length > 0) {
        this.onGetFieldValues();
      } else {
        this.showLoader = false;
      }
    });
  }

  private isLastPageForValues(): boolean {
    return (
      this.subDataSource?.items.length + this.valuesInitialState.pageSize >=
        this.subDataSource?.totalCount && this.valuesInitialState.page > 1
    );
  }

  public onGetFieldValues(): void {
    if (!this.isLastPageForValues()) {
      this.showLoader = true;
      const field = this.fields.find(
        (item: IFilterField) => item.id === this.field,
      );
      if (this.showPrefilter) {
        if (this.dataSource.items?.length > 0) {
          this.queryModel.addRule({
            entity: field.preFilter,
            field: field.id,
            operator: 'in',
            value: this.dataSource.items[this.selectedIndex].value,
          });
        }
      }
      if (field.type === FieldTypes.FIELD) {
        this.tagService
          .getFieldValues(
            this.valuesInitialState,
            this.queryModel.convertToBackEndQuery(),
            field.field,
            this.userService.userPreferences.defaultEcosystemId,
            this.valuesInitialState.page > 1
              ? this.subDataSource.items[this.subDataSource.items.length - 1]
                  .name
              : undefined,
            this.showEmpty,
          )
          .pipe(
            tap(() => (this.showLoader = false)),
            map(
              (response: { items: Array<ICustomTag>; totalCount: number }) => {
                this.subDataSource = response;
                this.valuesInitialState.page++;
              },
            ),
          )
          .subscribe();
      } else {
        this.categoryService
          .getCategoryValues(
            this.query,
            this.valuesInitialState,
            this.queryModel.convertToBackEndQuery(),
            field.label,
            this.userService.userPreferences.defaultEcosystemId,
            field.type,
            this.valuesInitialState.page > 1
              ? this.subDataSource[this.subDataSource.items.length - 1].name
              : undefined,
            this.showEmpty,
          )
          .pipe(
            tap(() => (this.showLoader = false)),
            map(
              (response: { items: Array<ICustomTag>; totalCount: number }) => {
                this.subDataSource = response;
                this.valuesInitialState.page++;
              },
            ),
          )
          .subscribe();
      }
    }
  }

  public onSelectionChange(): void {
    this.selectionChange.emit(this.selections);
  }

  public onNavChange(index: number): void {
    this.selectedIndex = index;
    this.valuesInitialState.page = 1;
    this.onGetFieldValues();
  }
}
