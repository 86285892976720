import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import {
  Actions,
  Align,
  CurrencyPrefix,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ProfileEditAction } from '@portal/profiles/enums/profile.enum';
import {
  MetadataFields,
  MetadataIcons,
  ProfileMetadataGroups,
} from '@portal/profiles/enums/profiles-metadata.enum';
import {
  ILinksData,
  ILoadLink,
} from '@portal/profiles/interfaces/profile-tags.interface';
import { GroupMetadata } from '@portal/profiles/models/group-metadata';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { NotesService } from '@portal/profiles/services/notes.service';
import { ProfileMetricsService } from '@portal/profiles/services/vendors-metrics.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { IWidgetData } from '@portal/shared/interfaces/widget.interface';
import { BackEndQuery } from '@portal/shared/models/backend-query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagWizardComponent } from '@portal/tags/tag-wizard/tag-wizard.component';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import {
  ChartOrientation,
  ChartType,
  LineMode,
} from '@portal/widgets/enums/chart.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { cloneDeep } from 'lodash';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { GroupMetadataPipe } from './profiles-explore.pipe';

@Component({
  selector: 'itq-profiles-explore',
  templateUrl: './profiles-explore.component.html',
  styleUrls: ['./profiles-explore.component.scss'],
  providers: [ProfileService],
})
export class ProfilesExploreComponent implements OnInit {
  @Input() navigationItem: ProfilesNavigationItem;

  public expandedFilters = true;
  private _profile: Profile;

  public get profile(): Profile {
    return this._profile;
  }

  public set profile(value: Profile) {
    this.profileService.profile = value;
    this._profile = value;
  }
  private showAddConnectionsSubscription: Subscription;
  private showAddNotesSubscription: Subscription;
  public profileTypeName: string;
  public tagsDataSource: ILinksData;
  public selectedTag: LinkTag;
  private getLinkedTagsSubscription: Subscription;
  private showLinkTagsSubscription: Subscription;
  public showLinkTags = false;
  public editAction = ProfileEditAction.DETAILS;
  public closeOnUpdate = true;
  public showConnectionSettings = false;
  private showConnectionsSettingsSubscription: Subscription;
  private metadataSubscription: Subscription;
  public metadata: Array<GroupMetadata>;
  public editMetadataAction: ProfileMetadataGroups;
  private profilesSubscription: Subscription;
  public selectedTabIndex = 0;
  public activityData: IWidgetData;
  public showLoader = true;
  public options = {
    orientation: ChartOrientation.VERTICAL,
    mode: LineMode.LINE,
    tooltip: {
      show: false,
    },
    xAxis: {
      show: false,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: false,
      splitLine: {
        show: false,
      },
    },
    series: {
      showSymbol: false,
      label: {
        show: false,
      },
      lineStyle: {
        width: 2,
      },
    },
  };
  public form: FormGroup;
  private subscription = new Subscription();

  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly Actions = Actions;
  readonly ProfileEditAction = ProfileEditAction;
  readonly TagCategory = TagCategory;
  readonly MetadataFields = MetadataFields;
  readonly MetadataIcons = MetadataIcons;
  readonly CurrencyPrefix = CurrencyPrefix;
  readonly ChartType = ChartType;

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    readonly widgetService: WidgetService,
    readonly tagService: TagService,
    private profileService: ProfileService,
    private notesService: NotesService,
    private connectionsService: ConnectionsService,
    private linkTagsService: LinkTagsService,
    private groupMetadataPipe: GroupMetadataPipe,
    public customOverlayRef: CustomOverlayRef,
    readonly profileMetricsService: ProfileMetricsService,
    readonly userService: UserService,

    readonly cdr: ChangeDetectorRef,
    readonly customOverlayService: CustomOverlayService,
    readonly chartService: ChartService,
  ) {
    this.customOverlayService.setCssClass$.next('min-w-[90%]');
  }

  ngOnInit(): void {
    this.form = new FormGroup({});
    this.bindLoaderSubscription();

    this.profilesSubscription = this.profileService.profiles$.subscribe(() => {
      this.profile = this.profileService.findProfileById(
        this.profile.profileId,
      );
    });

    this.metadataSubscription = this.profileService.metadata$.subscribe(
      (response: Array<ProfileTypeMetadata>) => {
        this.metadata = this.groupMetadataPipe.transform(response);
      },
    );
    this.showAddNotesSubscription = this.notesService.showAddNotes$.subscribe(
      (response: Actions) => {
        if (!response) {
          this.form.removeControl('addNote');
        }
      },
    );
    this.showLinkTagsSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          this.form.removeControl('addTag');
        }
        this.showLinkTags = response;
      },
    );
    this.showConnectionsSettingsSubscription =
      this.connectionsService.showConnectionsSettings$.subscribe(
        (showSettings: boolean) => {
          this.showConnectionSettings = showSettings;
          this.cdr.detectChanges();
          if (!showSettings) {
            this.form.removeControl('addConnections');
            this.chartService.reloadChart$.next(this.profile.profileId);
          }
        },
      );
    this.showAddConnectionsSubscription =
      this.connectionsService.showAddConnections$.subscribe(
        (response: Actions) => {
          if (!response) {
            this.form.removeControl('addConnections');
          }
        },
      );
    this.getLinkedTagsSubscription = this.linkTagsService.links$.subscribe(
      (response: ILoadLink) => {
        this.tabGroup.selectedIndex = 0;
        this.tagsDataSource = response.data;
        if (response.link) {
          this.selectedTag = response.link;
        } else {
          this.selectedTag = this.tagsDataSource.items.find(
            (item: LinkTag) => item.isDefault,
          );
        }
        if (this.selectedTag) {
          this.onChangeTag(this.selectedTag);
        }
        if (!this.selectedTag) {
          this.showLinkTags = true;
        }
      },
    );
    this.selectedTabIndex = this.navigationItem.selectedTabIndex || 0;
    this.profile = cloneDeep(this.navigationItem.item) as Profile;
    this.createForm();
    this.profileService.getMetadata(this.profile.profileTypeId).subscribe();
    this.profileTypeName = this.profileService.getProfileTypeById(
      this.profile.profileTypeId,
    )?.name;
    this.initializeActivityWidget();
    this.getProfileLinks();
  }

  ngOnDestroy(): void {
    this.showLinkTagsSubscription.unsubscribe();
    this.showAddConnectionsSubscription.unsubscribe();
    this.showAddNotesSubscription.unsubscribe();
    this.getLinkedTagsSubscription.unsubscribe();
    this.showLinkTagsSubscription.unsubscribe();
    this.metadataSubscription.unsubscribe();
    this.showConnectionsSettingsSubscription.unsubscribe();
    this.profilesSubscription.unsubscribe();
  }

  private bindLoaderSubscription(): void {
    this.subscription.add(
      this.profileService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  private initializeActivityWidget(): void {
    if (this.profile.activity > 0) {
      this.profileMetricsService
        .getActivityData([this.profile.profileId])
        .subscribe((response: Array<IWidgetData>) => {
          this.activityData = response[0];
        });
    }
  }

  private createForm(): void {
    this.form.addControl('tags', new FormControl(1));
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onEditTag(value: LinkTag): void {
    this.tagService.getTagById(value.tagId).subscribe((response: Tag) => {
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: TagWizardComponent,
            inputs: {
              tag: response,
              action: Actions.EDIT,
              queryModel: new BackEndQuery(
                response.query.operator,
                response.query.conditions,
                response.query.queries,
              ).convertFromBackEndQuery(),
            },
          },
        },
        closeBtnStyle: 'basic',
        closeBtnClass: 'hidden',
        type: CustomOverlayType['almost-full'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    });
  }

  public onChangeTag(value: LinkTag): void {
    this.selectedTag = value;
  }

  public onEdit(
    editAction: ProfileEditAction,
    editMetadataAction: string,
  ): void {
    this.closeOnUpdate = false;
    this.navigationItem.action = Actions.EDIT;
    this.editAction = editAction;
    this.editMetadataAction = editMetadataAction as ProfileMetadataGroups;
  }

  public onToggleSettings(): void {
    this.showLinkTags = !this.showLinkTags;
  }

  public onTabChange(): void {
    this.connectionsService.showAddConnections$.next(undefined);
    this.notesService.showAddNotes$.next(undefined);
  }

  public getProfileLinks(): void {
    this.linkTagsService
      .getLinkedTags(
        this.profile.profileId,
        new QueryFilters(
          1000,
          1,
          undefined,
          undefined,
          {
            direction: 'desc',
            active: 'updatedDate',
          },
          undefined,
        ),
      )
      .subscribe((response: ILinksData) => {
        this.tagsDataSource = response;
        this.selectedTag = this.tagsDataSource?.items?.find(
          (item: LinkTag) => item.isDefault,
        );
      });
  }
}
