<div class="item-detail-container">
  <div class="document-item-detail-container">
    <div class="flex items-center">
      <div class="header_icon rounded-md bg-black flex">
        <img
          [src]="'assets/icons/' + document.emitSource.toLowerCase() + '.svg'"
        />
      </div>
      <span class="text-3xl font-semibold pl-2">
        {{ document.emitSource + ' ' + document.emitType }}
      </span>
    </div>
    <div class="container__when pt-2 italic">
      <span *ngIf="document.emitChannel">
        <span [innerHTML]="document.emitChannel"></span>
      </span>
      <span class="dash" *ngIf="document.emitChannel">-</span>
      <div class="when_date">
        {{ document.emitDocumentDate | date: dateFormats.short }}
      </div>
      <div class="when_ago" *ngIf="document.emitDocumentDate">
        ({{ document.emitDocumentDate | millisToDate | timeAgo }})
      </div>
    </div>
    <div class="container__content">
      <div class="custom-fields__container">
        <dl class="content-keyValue">
          <div class="title__container">
            <div class="title__wrapper">
              <ng-container *ngIf="document.emitHeadline">
                <dt>Title</dt>
                <dd
                  [innerHTML]="
                    translatedTitle && translatedTitle !== ''
                      ? translatedTitle
                      : document.emitHeadline
                  "
                ></dd>
              </ng-container>
              <ng-container *ngIf="!document.emitHeadline">
                <dt>Title</dt>
                <dd>
                  <span [innerHTML]="document.emitType"></span>
                  <span *ngIf="document.emitActor">
                    by

                    <span [innerHTML]="document.emitActor"></span>
                  </span>
                </dd>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="document.emitActor && expanded">
            <dt class="pt-4">Posted by</dt>
            <dd>
              <div>
                <span [innerHTML]="document.emitActor"></span>
              </div>
            </dd>
          </ng-container>
          <ng-container *ngIf="document.emitURL && expanded">
            <dt class="pt-4">Link</dt>
            <dd>
              <a
                class="label-secondary"
                rel="noreferrer"
                [attr.href]="document.emitURL"
                target="_blank"
                [innerHTML]="document.emitURL"
              ></a>
            </dd>
          </ng-container>
        </dl>
      </div>
      <mat-accordion *ngIf="expanded" class="basic" [multi]="true">
        <mat-expansion-panel
          *ngIf="document.emitBodyText"
          [hideToggle]="false"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Content </mat-panel-title>
            <mat-panel-description>
              <button
                type="button"
                class="btn btn-link-secondary btn--translate"
                (click)="$event.stopImmediatePropagation(); onTranslate()"
              >
                Translate to English
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            [innerHTML]="
              translatedContent && translatedContent !== ''
                ? translatedContent
                : (document.emitBodyText | striphtml | detecturl | stripImg)
            "
          ></div>
        </mat-expansion-panel>
        <ng-container *ngIf="document?.emitExtensionFields?.length > 0">
          <ng-container
            *ngIf="document?.emitSource !== 'Discord'; else discordTemplate"
          >
            <mat-expansion-panel
              [hideToggle]="false"
              [expanded]="true"
              *ngIf="extractedData?.length > 0"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Extracted data</mat-panel-title>
              </mat-expansion-panel-header>
              <dl class="content-keyValue">
                <ng-container
                  *ngFor="let extension of document.emitExtensionFields"
                >
                  <ng-container
                    *ngIf="
                      document.emitSource === 'Telegram';
                      else otherSourcesTemplate
                    "
                  >
                    <ng-container
                      *ngIf="extension | getExtensionFieldName as fieldName"
                    >
                      <dt
                        *ngIf="
                          fieldName[0] !== ExtensionDataFieldsText['topic'] &&
                          fieldName[0] !== ExtensionDataFieldsText['urls']
                        "
                        [innerHTML]="fieldName"
                      ></dt>
                      <dd>
                        <div
                          *ngFor="
                            let item of extension | getExtensionFieldValues
                          "
                        >
                          <ng-container
                            *ngIf="isArray(item[1]); else stringTemplate"
                          >
                            <ng-container *ngIf="item[1]?.length > 0">
                              <span class="mb-1 label">{{
                                ExtensionDataFieldsText[item[0]]
                              }}</span
                              >: <span>{{ item[1] }}</span>
                            </ng-container>
                          </ng-container>
                          <ng-template #stringTemplate>
                            <ng-container *ngIf="item[1] && item[1] !== ''">
                              <span class="mb-1 label">{{
                                ExtensionDataFieldsText[item[0]]
                              }}</span
                              >: <span [innerHTML]="item[1]"></span>
                            </ng-container>
                          </ng-template>
                        </div>
                      </dd>
                    </ng-container>
                  </ng-container>
                  <ng-template #otherSourcesTemplate>
                    <ng-container
                      *ngIf="
                        extension.name !== 'Links' &&
                        extension.name !== 'Link' &&
                        extension.name !== 'Number of Post Comments'
                      "
                    >
                      <dt [innerHTML]="extension.name"></dt>
                      <ng-container
                        *ngIf="extension.value; else nonValueTemplate"
                      >
                        <dd [innerHTML]="extension.value"></dd>
                      </ng-container>
                      <ng-template #nonValueTemplate>
                        <ng-container
                          *ngIf="
                            isArray(extension.selectors);
                            else listTemplate
                          "
                        >
                          <dd [innerHTML]="extension.value"></dd>
                        </ng-container>
                        <ng-template #listTemplate>
                          <ul class="extension__container">
                            <li *ngFor="let selector of extension.selectors">
                              <ng-container
                                *ngIf="
                                  selector.scrapedData;
                                  else selectorTemplate
                                "
                              >
                                <ng-container
                                  *ngFor="let item of selector.scrapedData"
                                >
                                  <itq-pill
                                    *ngIf="item.textContent.trim() !== ''"
                                    [class]="'secondary rounded-md  h-[32px]'"
                                    [innerHTML]="item.textContent"
                                  ></itq-pill>
                                </ng-container>
                              </ng-container>
                              <ng-template #selectorTemplate>
                                <itq-pill
                                  *ngIf="selector.trim() !== ''"
                                  [class]="'secondary rounded-md h-[32px]'"
                                  [innerHTML]="selector"
                                ></itq-pill>
                              </ng-template>
                            </li>
                          </ul>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </dl>
            </mat-expansion-panel>
          </ng-container>

          <ng-template #discordTemplate>
            <ng-container
              *ngFor="let extension of document.emitExtensionFields"
            >
              <ng-container *ngIf="extension.name !== 'contentLinks'">
                <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{
                      ExtensionDataFieldsText[extension.name]
                    }}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="extension__data_container">
                    <div
                      class="extension__data_item"
                      *ngFor="
                        let item of extension.data | customSortExtensionFields
                      "
                    >
                      <ng-container *ngFor="let dataItem of item | keyvalue">
                        <ng-container
                          *ngIf="
                            dataItem.key !== ExtensionDataFields.AVATAR_URL &&
                            dataItem.value
                          "
                        >
                          <span class="font-normal">{{
                            ExtensionDataFieldsText[dataItem.key]
                          }}</span
                          >:&nbsp;
                          <ng-container
                            *ngIf="
                              dataItem.key !== ExtensionDataFields.NAME;
                              else drilldownTemplate
                            "
                          >
                            <span>{{
                              dataItem.key === ExtensionDataFields.ISBOT
                                ? 'True'
                                : dataItem.value
                            }}</span>
                          </ng-container>
                          <ng-template #drilldownTemplate>
                            <span>
                              {{ dataItem.value }}
                            </span>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-container>

        <mat-expansion-panel
          [hideToggle]="false"
          [expanded]="true"
          *ngIf="contentLinks?.length > 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <fa-icon
                [matTooltip]="
                  'Links that have been automatically extracted from the text of this post'
                "
                [matTooltipPosition]="'above'"
                [icon]="['far', 'info-circle']"
              ></fa-icon>
              Extracted Links
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let link of contentLinks">
              <a [href]="link.href" target="_blank" [innerHTML]="link.href"></a>
            </li>
          </ul>
        </mat-expansion-panel>
        <ng-container *ngFor="let media of document.emitMedia">
          <ng-container
            *ngIf="media?.media_source?.toLowerCase() === 'youtube'"
          >
            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>Video</mat-panel-title>
              </mat-expansion-panel-header>
              <iframe
                [src]="media.media_link | safe"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
</div>
<itq-loader *ngIf="showLoader"></itq-loader>

<!-- Default template for key-value -->
<ng-template #isNotList let-extension>
  <dd [innerHTML]="extension.value"></dd>
</ng-template>

<!-- List template for key-value -->
<ng-template #isList let-extension>
  <ul class="extension__container">
    <li *ngFor="let selector of extension.selectors">
      <ng-container *ngIf="selector.scrapedData; else selectorTemplate">
        <ng-container *ngFor="let item of selector.scrapedData">
          <itq-pill
            *ngIf="item.textContent.trim() !== ''"
            [class]="'secondary rounded-md h-[32px]'"
            [innerHTML]="item.textContent"
          ></itq-pill>
        </ng-container>
      </ng-container>
      <ng-template #selectorTemplate>
        <itq-pill
          *ngIf="selector.trim() !== ''"
          [class]="'secondary rounded-md h-[32px]'"
          [innerHTML]="selector"
        ></itq-pill>
      </ng-template>
    </li>
  </ul>
</ng-template>
