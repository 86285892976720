<ng-container *ngIf="materialStyle === 'basic'">
  <button
    #dropdownButton
    mat-button
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[
      size ? 'btn-' + size : '',
      align ? 'flex-' + align : '',
      defaultColor ? 'btn-' + defaultColor : '',
      rounded ? 'btn-rounded' : '',
      padding ? 'padding-' + padding : ''
    ]"
    [color]="style"
    (click)="onClick($event)"
    (mousedown)="onMouseDown($event)"
    (blur)="onBlur($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="materialStyle === 'flat'">
  <button
    #dropdownButton
    mat-flat-button
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[
      size ? 'btn-' + size : '',
      align ? 'flex-' + align : '',
      defaultColor ? 'btn-' + defaultColor : '',
      rounded ? 'btn-rounded' : '',
      padding ? 'padding-' + padding : ''
    ]"
    [color]="style"
    (click)="onClick($event)"
    (mousedown)="onMouseDown($event)"
    (blur)="onBlur($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="materialStyle === 'icon'">
  <button
    #dropdownButton
    mat-icon-button
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[
      size ? 'btn-' + size : '',
      align ? 'flex-' + align : '',
      defaultColor ? 'btn-' + defaultColor : '',
      rounded ? 'btn-rounded' : '',
      padding ? 'padding-' + padding : ''
    ]"
    [color]="style"
    (click)="onClick($event)"
    (mousedown)="onMouseDown($event)"
    (blur)="onBlur($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="materialStyle === 'mini-fab'">
  <button
    #dropdownButton
    mat-mini-fab
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[
      size ? 'btn-' + size : '',
      align ? 'flex-' + align : '',
      defaultColor ? 'btn-' + defaultColor : '',
      rounded ? 'btn-rounded' : '',
      padding ? 'padding-' + padding : ''
    ]"
    [color]="style"
    (click)="onClick($event)"
    (mousedown)="onMouseDown($event)"
    (blur)="onBlur($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-container *ngIf="materialStyle === 'stroked'">
  <button
    #dropdownButton
    mat-stroked-button
    [type]="type"
    [disabled]="disabled"
    [ngClass]="[
      size ? 'btn-' + size : '',
      align ? 'flex-' + align : '',
      defaultColor ? 'btn-' + defaultColor : '',
      rounded ? 'btn-rounded' : '',
      padding ? 'padding-' + padding : ''
    ]"
    [color]="style"
    (click)="onClick($event)"
    (mousedown)="onMouseDown($event)"
    (blur)="onBlur($event)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
