import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  IPresetQuery,
  QueryFilters,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { BoardService } from '@portal/boards/services/board.service';
import { Subscription } from 'rxjs';
import { CreateWidgetTypeComponent } from '../create-widget-type/create-widget-type.component';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { EventWizardComponent } from '@portal/events/components/event-wizard/event-wizard.component';
import { UserService } from '@portal/shared/services/user.service';
import { HasEventsEnabledPipe } from '@portal/shared/pipes/events.pipe';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-board-toolbar',
  templateUrl: './board-toolbar.component.html',
  styleUrls: ['./board-toolbar.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    CommonModule,
    FAwesomeModule,
    MatTooltipModule,
    DateRangeComponent,
    HasEventsEnabledPipe,
  ],
})
export class BoardToolbarComponent implements OnInit {
  @Input() board: Board;
  @Input() initialState: QueryFilters;
  @Input() form: FormGroup;

  @Output() search = new EventEmitter<QueryFilters>();

  private subscriptions = new Subscription();

  constructor(
    private boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    readonly widgetService: WidgetService,
    readonly activatedRoute: ActivatedRoute,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.bindLoadBoardSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindLoadBoardSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.params.subscribe((params) => {
        this.form.get('boardToolbar.query').setValue('');
      }),
    );
  }

  public onAddWidget(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: CreateWidgetTypeComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(
      (response: {
        widget: TagAnalysis | TimeSeries | TagComparison | Timeline;
        refresh: boolean;
      }) => {
        if (response?.refresh) {
          this.boardService
            .addWidgetsToBoard(this.board.id, {
              add: [response.widget.widgetId],
            })
            .subscribe();
        }
      },
    );
  }

  private addControls(): void {
    this.form = new FormGroup({
      boardToolbar: new FormGroup({
        query: new FormControl(''),
      }),
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.initialState.where = params;
    this.initialState.query = this.form?.get('boardToolbar.query')?.value;
    this.boardService.board.filter = {
      date: params,
    };
    if (this.initialState.where) {
      this.boardService
        .updateBoard(this.board.id, {
          filter: {
            date: params,
          },
        })
        .subscribe();
    } else {
      this.boardService
        .updateBoard(this.board.id, {
          filter: undefined,
        })
        .subscribe();
    }
  }

  onSearch(): void {
    this.initialState.query = this.form?.get('boardToolbar.query')?.value;
    this.initialState.page = 1;
    this.search.emit(this.initialState);
  }

  public onRefresh(): void {
    this.widgetService.reload$.next({ initialState: this.initialState });
  }

  public onRecordEvent(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
}
