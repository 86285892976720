import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import { DateQueryType, DateRangeHelper, QueryFilters } from '@intorqa-ui/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { AlertTypes } from '../../../../../core/src/lib/enums/alerts.enum';
import {
  IHubNotificationsResults,
  INotification,
  ITagMatchNotification,
  IResearchNotification,
  ITransactionalNotification,
  IThresholdHubNotification,
} from '../interfaces/notification.interface';
import {
  Notification,
  ResearchNotification,
  SlackNotification,
  TagMatchNotification,
  ThresholdNotification,
  TransactionalNotification,
} from '../models/notifications';
import {
  DocumentItem,
  IMessage,
  ISearchResults,
} from '@portal/document/interfaces/document.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public getNotifications$ = new Subject<QueryFilters>();
  public getUnreadCount$ = new Subject<number>();
  public toggleNotification$ = new Subject<string>();
  public openNotifications$ = new Subject<{
    raisedAlertId: string;
    researchAlertId: string;
  }>();
  public markAsRead$ = new Subject<void>();
  public hideNotifications$ = new Subject<void>();
  public loader$ = new Subject<boolean>();

  public createProfile$ = new Subject<{
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }>();
  public loadOtherMatches$ = new Subject<{
    filters?: QueryFilters;
    raisedAlertId?: string;
  }>();
  public loadNotifications$ = new Subject<{
    items: Notification[];
    totalCount: number;
  }>();
  private _notifications: Notification[] = [];

  public get notifications(): Notification[] {
    return this._notifications;
  }

  public set notifications(v: Notification[]) {
    this._notifications = v;
  }
  private _unreadCount: number;

  public get unreadCount(): number {
    return this._unreadCount;
  }

  public set unreadCount(v: number) {
    this._unreadCount = v;
  }

  constructor(readonly apiRequestService: ApiRequestService) {}

  public postSlackMessage(attachment: string): Observable<SlackNotification> {
    return this.apiRequestService
      .post(
        '/notifications/slack/message',
        new DTOTypeConverter<{
          attachment: string;
        }>(),
        { attachments: attachment },
      )
      .pipe(
        map((response: { attachment: string }) => {
          return new SlackNotification(response.attachment);
        }),
      );
  }

  public markAllAsRead(): Observable<void> {
    return this.apiRequestService.put('/notifications/hub/readAll').pipe(
      map(() => {
        this.notifications = this.notifications.map(
          (
            item:
              | TagMatchNotification
              | ThresholdNotification
              | ResearchNotification
              | TransactionalNotification,
          ) => {
            item.read = true;
            return item;
          },
        );
        this.unreadCount = 0;
        this.markAsRead$.next();
        this.getUnreadCount$.next(this.unreadCount);
      }),
    );
  }

  /**
   * Retrieves notifications based on the specified parameters.
   *
   * @param params - The query filters for pagination and search.
   * @param read - Optional. Specifies if the notifications should be read or unread.
   * @param priority - Optional. Specifies if the notifications should have priority.
   * @param alertTypeId - Optional. The ID of the alert type.
   * @returns An Observable containing the notifications and the total count.
   */
  public getNotifications(
    params: QueryFilters,
    read?: boolean,
    priority?: boolean,
    alertTypeId?: string,
  ): Observable<{
    items: Notification[];
    totalCount: number;
  }> {
    const pageQuery = this.buildPageQuery(params, read, priority, alertTypeId);
    return this.apiRequestService
      .get(
        `/notifications/hub?${pageQuery}`,
        new DTOTypeConverter<IHubNotificationsResults>(),
      )
      .pipe(
        map((response: IHubNotificationsResults) => {
          const notifications = this.mapNotifications(response.items);
          this.updateNotifications(params.page, notifications);
          this.loadNotifications$.next({
            items: this.notifications,
            totalCount: response.totalCount,
          });
          return { items: this.notifications, totalCount: response.totalCount };
        }),
      );
  }

  private buildPageQuery(
    params: QueryFilters,
    read?: boolean,
    priority?: boolean,
    alertTypeId?: string,
  ): string {
    let pageQuery = `page=${params.page}&pageSize=${params.pageSize}`;
    if (params.query) {
      pageQuery += `&query=${params.query}`;
    }
    if (read !== undefined) {
      pageQuery += `&read=${read}`;
    }
    if (priority) {
      pageQuery += `&priority=${priority}`;
    }
    if (alertTypeId) {
      pageQuery += `&alertTypeId=${alertTypeId}`;
    }
    if (params.where) {
      if (params.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${params.where?.start}`;
        pageQuery += `&dateTo=${params.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(params.where.label);
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    return pageQuery;
  }

  private mapNotifications(items: INotification[]): Notification[] {
    return items.map((item: INotification) => {
      switch (item.alertTypeName) {
        case AlertTypes.TAG_MATCH:
          const tagMatchNotification = item as ITagMatchNotification;
          return new TagMatchNotification(
            tagMatchNotification.raisedAlertId,
            tagMatchNotification.createdDate,
            tagMatchNotification.alertTypeName,
            tagMatchNotification.tagName,
            tagMatchNotification.priority,
            tagMatchNotification.message,
            tagMatchNotification.read,
            tagMatchNotification.document,
            tagMatchNotification.matches,
            tagMatchNotification.tagEdited,
            tagMatchNotification.ecosystemId,
          );
        case AlertTypes.RESEARCH:
          const researchNotification = item as IResearchNotification;
          return new ResearchNotification(
            researchNotification.raisedAlertId,
            researchNotification.createdDate,
            researchNotification.alertTypeName,
            researchNotification.priority,
            researchNotification.message,
            researchNotification.read,
            researchNotification.headline,
            researchNotification.rawMessage,
            researchNotification.ecosystemId,
          );
        case AlertTypes.SYSTEM:
          const transactionalNotification = item as ITransactionalNotification;
          return new TransactionalNotification(
            transactionalNotification.raisedAlertId,
            transactionalNotification.createdDate,
            transactionalNotification.alertTypeName,
            transactionalNotification.message,
            transactionalNotification.read,
            transactionalNotification.headline,
            transactionalNotification.triggerMetadata,
            transactionalNotification.ecosystemId,
          );
        default:
          const thresholdNotification = item as IThresholdHubNotification;
          return new ThresholdNotification(
            thresholdNotification.raisedAlertId,
            thresholdNotification.createdDate,
            thresholdNotification.alertTypeName,
            thresholdNotification.tagName,
            thresholdNotification.priority,
            thresholdNotification.message,
            thresholdNotification.read,
            thresholdNotification.period,
            thresholdNotification.condition,
            thresholdNotification.count,
            thresholdNotification.tagId,
            thresholdNotification.tagEdited,
            thresholdNotification.ecosystemId,
          );
      }
    });
  }

  private updateNotifications(
    page: number,
    notifications: Notification[],
  ): void {
    if (page === 1) {
      this.notifications = notifications;
    } else {
      this.notifications = [...this.notifications, ...notifications];
    }
  }

  public getNotificationById(id: string): Observable<INotification> {
    return this.apiRequestService.get(
      `/notifications/${id}`,
      new DTOTypeConverter<INotification>(),
    );
  }

  public getUnreadNotificationsCount(
    params: QueryFilters,
    priority: boolean,
    alertTypeId: string,
  ): Observable<number> {
    let pageQuery = '';
    if (params?.query) {
      pageQuery += `query=${params.query}`;
    }
    if (priority) {
      pageQuery += (pageQuery ? '&' : '') + `priority=${priority}`;
    }
    if (alertTypeId) {
      pageQuery += (pageQuery ? '&' : '') + `alertTypeId=${alertTypeId}`;
    }
    if (params?.where) {
      if (params.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${params.where?.start}`;
        pageQuery += `&dateTo=${params.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(params.where.label);
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    return this.apiRequestService
      .get(
        `/notifications/hub/unreadcount?${pageQuery}`,
        new DTOTypeConverter<number>(),
      )
      .pipe(
        map((response: number) => {
          this.unreadCount = response;
          this.getUnreadCount$.next(response);
          return response;
        }),
      );
  }

  public getUnread(): Observable<boolean> {
    return this.apiRequestService.get(
      '/notifications/unread',
      new DTOTypeConverter<boolean>(),
    );
  }

  public getMatches(
    params: QueryFilters,
    raisedAlertId: string,
  ): Observable<{ items: Array<DocumentItem>; totalCount: number }> {
    let pageQuery = `page=${params.page}`;
    pageQuery += `&pageSize=${params.pageSize}`;
    return this.apiRequestService.get(
      `/notifications/hub/matches/${raisedAlertId}?${pageQuery}`,
      new DTOTypeConverter<{
        items: Array<DocumentItem>;
        totalCount: number;
      }>(),
    );
  }

  public toggleReadState(
    raisedAlertId: string,
    state: boolean,
  ): Observable<Notification> {
    return this.apiRequestService
      .put(`/notifications/hub/${raisedAlertId}/read`, {
        read: state,
      })
      .pipe(
        map(() => {
          const notification = this.notifications?.find(
            (item: Notification) => item.raisedAlertId === raisedAlertId,
          );
          notification.read = state;
          notification.read ? (this.unreadCount -= 1) : (this.unreadCount += 1);
          this.getUnreadCount$.next(this.unreadCount);
          return notification;
        }),
      );
  }

  public findNotificationById(raisedAlertId: string): Notification {
    return this.notifications.find(
      (item: Notification) => item.raisedAlertId === raisedAlertId,
    );
  }

  public shareEmail(message: IMessage): Observable<any> {
    return this.apiRequestService.post(
      '/notifications/email',
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(message),
    );
  }
}
