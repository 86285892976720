<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'metadata'">
    <itq-form-row *ngIf="profileMetadata?.component === ComponentType.DROPDOWN">
      <itq-form-element>
        <span
          [ngClass]="{ 'mb-1': true, required: profileMetadata?.required }"
          >{{ profileMetadata?.name }}</span
        >
        <itq-dropdown
          [invalid]="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.invalid
          "
          [formControlName]="formControlName"
          [dataFields]="{ name: 'name', value: 'value' }"
          [dataSource]="dataSource"
          (changeValue)="onMetadataChange($event)"
          (clear)="onMetadataChange(undefined)"
        ></itq-dropdown>
        <mat-error
          *ngIf="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.errors
              ?.required
          "
        >
          {{ profileMetadata?.name }} is required
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row *ngIf="profileMetadata?.component === ComponentType.DATE">
      <itq-form-element>
        <span
          [ngClass]="{ 'mb-1': true, required: profileMetadata?.required }"
          >{{ profileMetadata?.name }}</span
        >
        <itq-date-picker [formControlName]="formControlName"></itq-date-picker>
        <mat-error
          *ngIf="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.errors
              ?.required
          "
        >
          {{ profileMetadata?.name }} is required
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row *ngIf="profileMetadata?.component === ComponentType.INPUT">
      <itq-form-element>
        <span
          [ngClass]="{ 'mb-1': true, required: profileMetadata?.required }"
          >{{ profileMetadata?.name }}</span
        >
        <itq-input
          [formControlName]="formControlName"
          [type]="
            this.profileMetadata.name === MetadataFields.PRICE_PER_MONTH
              ? this.profileService.pricingModel === PricingModel.FREE
                ? 'text'
                : 'number'
              : 'text'
          "
        ></itq-input>
        <mat-error
          *ngIf="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.errors
              ?.required
          "
        >
          {{ profileMetadata?.name }} is required
        </mat-error>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row
      *ngIf="profileMetadata?.component === ComponentType.MULTIPLE_DROPDOWN"
    >
      <itq-form-element>
        <span [ngClass]="{ 'mr-2 ': true, required: profileMetadata?.required }"
          >{{ profileMetadata?.name }}:
        </span>
        <itq-multiple-dropdown
          [invalid]="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.invalid
          "
          [dataSource]="profileMetadata?.values | formatMetadataValues"
          [materialStyle]="'stroked'"
          [padding]="Sizes.SM"
          [formControlName]="formControlName"
          [dataFields]="{ name: 'name', value: 'value' }"
        >
        </itq-multiple-dropdown>
        <mat-error
          *ngIf="
            form.controls?.metadata['controls'][formControlName]?.touched &&
            form.controls?.metadata['controls'][formControlName]?.errors
              ?.required
          "
        >
          {{ profileMetadata?.name }} is required
        </mat-error>
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>
