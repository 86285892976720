import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ComponentType,
  CoreModule,
  IDropdownItem,
  QueryFilters,
  TableColumn,
} from '@intorqa-ui/core';

@Component({
  selector: 'itq-virtual-table-filters',
  templateUrl: './virtual-table-filters.component.html',
  styleUrls: ['./virtual-table-filters.component.scss'],
  standalone: true,
  imports: [CoreModule, FormsModule, CommonModule],
})
export class VirtualTableFiltersComponent implements OnInit {
  @Input() tableColumns: Array<TableColumn>;
  @Input() item: TableColumn;

  @Output() dataBound = new EventEmitter<TableColumn>();
  @Output() clearSearch = new EventEmitter<TableColumn>();
  @Output() search = new EventEmitter<TableColumn>();
  @Output() multipleDropdownChange = new EventEmitter<TableColumn>();
  @Output() dropdownChange = new EventEmitter<{
    column: TableColumn;
    value: IDropdownItem | string;
  }>();

  readonly ComponentType = ComponentType;

  public multipleDropdownInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public dropdownInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  constructor() {}

  ngOnInit() {}

  public onDataBound(column: TableColumn): void {
    this.dataBound.emit(column);
  }

  public onApplySearch(column: TableColumn): void {
    this.search.emit(column);
  }

  public onMultipleDropdownChange(params: TableColumn): void {
    this.multipleDropdownChange.emit(params);
  }

  public onChangeValue(
    params: TableColumn,
    value: IDropdownItem | string,
  ): void {
    this.dropdownChange.emit({ column: params, value });
  }

  public onClearSearch(column: TableColumn): void {
    this.clearSearch.emit(column);
  }
}
