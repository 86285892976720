import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import {
  IDisplayType,
  IWidget,
} from '@portal/shared/interfaces/widget.interface';

export abstract class Widget implements IWidget {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
  ) {}

  public abstract getDisplayType(): IDisplayType;
}
