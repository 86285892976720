<div
  class="flex flex-col w-full h-full overflow-auto space-y-4 py-4"
  #documentContainer
  infiniteScroll
  (scrolledToEnd)="onScroll()"
>
  @if (data?.count > 0) {
    @for (item of data?.result; track item.id) {
      <div class="flex flex-col relative">
        @if (parentDocument?.id === item.emitReplyToId) {
          <div class="flex relative w-full">
            <itq-document-item-tile
              [ngClass]="{
                'border border-border p-4 mx-7 my-0 rounded-md w-full hover:bg-background': true,
                'bg-white': item.id !== activeDocumentId,
                'bg-primary-over': item.id === activeDocumentId
              }"
              [document]="parentDocument"
              [allowDrilldownProfiles]="allowDrilldownProfiles"
              [parentDocument]="item.emitReplyToId"
              [isParent]="true"
              [query]="query"
              [allowCreateConnections]="allowCreateConnections"
              [drilldownSegment]="drilldownSegment"
              [isParentExpanded]="parentDocument?.id === item.emitReplyToId"
              (drilldown)="onDrilldown($event)"
              (createProfile)="onCreateProfile($event)"
              (showParent)="onShowParent($event)"
              (hideParent)="onHideParent()"
            ></itq-document-item-tile>
          </div>
        }

        <div
          [ngClass]="{
            'pl-10': parentDocument?.id === item.emitReplyToId,
            flex: true
          }"
        >
          <div
            [ngClass]="{
              flex: parentDocument?.id === item.emitReplyToId,
              hidden: parentDocument?.id !== item.emitReplyToId,
              'border-l-2 border-b-2 border-metadata h-[50%] w-[1rem]': true
            }"
          ></div>
          <div class="flex w-full">
            <itq-document-item-tile
              [ngClass]="{
                'border border-border p-4 mx-7 my-0 rounded-md w-full hover:bg-background': true,
                'before:w-[10px]': true,
                'bg-white': item.id !== activeDocumentId,
                'bg-primary-over': item.id === activeDocumentId,
                'ml-0 mt-4': parentDocument?.id === item.emitReplyToId
              }"
              [document]="item"
              [allowDrilldownProfiles]="allowDrilldownProfiles"
              [query]="query"
              [isParent]="false"
              [allowCreateConnections]="allowCreateConnections"
              [drilldownSegment]="drilldownSegment"
              [parentDocument]="item.emitReplyToId"
              [isParentExpanded]="parentDocument?.id === item.emitReplyToId"
              (drilldown)="onDrilldown($event)"
              (createProfile)="onCreateProfile($event)"
              (showParent)="onShowParent($event)"
              (shareEmail)="onShareEmail($event)"
            ></itq-document-item-tile>
          </div>
        </div>
      </div>
    }
  } @else {
    @if (data) {
      <div class="flex no-results-container w-full">
        <itq-panel-info
          class="flex p-7 bg-white rounded-md w-full"
          [label]="'No results found!'"
          [message]="'Please update your query and try again...'"
          [icon]="'exclamation-circle'"
        ></itq-panel-info>
      </div>
    }
  }
</div>
