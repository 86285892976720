<ng-container [formGroup]="form">
  @if (!showLinkTags) {
    <div class="flex items-center justify-between px-7">
      <itq-pill
        [type]="PillType.CUSTOM"
        [class]="'secondary rounded-md h-[36px]'"
        >{{ timelineData?.count || 0 | number }}</itq-pill
      >
      <div class="flex space-x-2">
        <itq-mat-button
          [type]="'button'"
          [style]="'primary'"
          [materialStyle]="'flat'"
          [padding]="Sizes.SM"
        >
          <fa-icon [icon]="['far', 'eye']"></fa-icon>
          <span class="whitespace-nowrap">VIEW CONTENT</span>
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [padding]="Sizes.SM"
          [materialStyle]="'basic'"
          (clickEvent)="onToggleSettings()"
        >
          <fa-icon class="mr-2" [icon]="['far', 'cog']"></fa-icon>
          <span class="whitespace-nowrap">SETTINGS</span>
        </itq-mat-button>
      </div>
      <div class="flex space-x-2">
        <itq-mat-button
          [type]="'button'"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          (clickEvent)="onDataBound(this.queryModel, this.initialState)"
        >
          <fa-icon [icon]="['far', 'refresh']"></fa-icon>
          <span class="whitespace-nowrap">REFRESH</span>
        </itq-mat-button>

        <itq-date-range
          [dates]="this.initialState?.where"
          (search)="onChangeDate($event)"
        ></itq-date-range>
      </div>
    </div>
    @if (this.queryModel?.hasRules()) {
      <itq-documents-feed
        class="h-full w-full flex flex-col overflow-hidden"
        [data]="timelineData"
        [initialState]="initialState"
        [query]="initialState?.query"
        [allowCreateConnections]="true"
        (shareEmail)="onShareEmail($event)"
        (scrollEvent)="onDataBound(this.queryModel, this.initialState)"
        (createProfile)="onCreateProfile($event)"
        (drilldown)="onDrilldown($event)"
      ></itq-documents-feed>
    } @else {
      @if (!link) {
        <itq-panel-action
          class="p-7"
          [label]="'No linked tags!'"
          [description]="
            'Please link some tags by clicking in the plus icon above.'
          "
        ></itq-panel-action>
      }
    }
  }
  @if (showLinkTags) {
    <itq-profiles-links
      class="px-7"
      [profile]="profile"
      [navigationItem]="navigationItem"
      [form]="form"
    ></itq-profiles-links>
  }
</ng-container>
