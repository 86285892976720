import { FormGroup } from '@angular/forms';
import { Actions, IconType, QueryFilters } from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { Rule } from 'ngx-angular-query-builder';
import { INavigationHistoryItem } from '../interfaces/navigation-history-item.interface';
import { ISegment } from '../interfaces/widget-settings.interface';

export class NavigationHistoryItem implements INavigationHistoryItem {
  constructor(
    public id: string,
    public item: Widget,
    public action: Actions,
    public rules: Array<Rule>,
    public initialState: QueryFilters,
    public form: FormGroup,
    public icon: string,
    public iconType: IconType,
    public selectedDisplayType: IDisplayType,
    public segment: ISegment,
    public boardId: string,
  ) {}
}
