import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  HighlightSearchPipe,
  IconType,
  IPresetQuery,
  OverlayService,
  QueryFilters,
  SearchFieldType,
  Sizes,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import {
  ProfileDrildownScope,
  ProfileTypeIcon,
} from '@portal/profiles/enums/profile.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { BackEndQuery } from '@portal/shared/models/backend-query-model';
import { UserService } from '@portal/shared/services/user.service';
import { TaggingStatus } from '@portal/tags/enums/tag.enum';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { TagWizardComponent } from '@portal/tags/tag-wizard/tag-wizard.component';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-timeline-tag-actions',
  templateUrl: './timeline-tag-actions.component.html',
  styleUrls: ['./timeline-tag-actions.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    FormsModule,
    FormsModule,
    HighlightSearchPipe,
  ],
})
export class TimelineTagActionsComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() taggingStatus: TaggingStatus;
  @Input() count: number;
  @Input() dates: IPresetQuery;

  private _vendorsDataSource: IProfileData;
  public get vendorsDataSource(): IProfileData {
    return this._vendorsDataSource;
  }

  public set vendorsDataSource(value: IProfileData) {
    if (this.vendorsInitialState.page === 1) {
      this._vendorsDataSource = value;
    } else if (this.vendorsInitialState.page > 1) {
      this._vendorsDataSource.items = this._vendorsDataSource.items.concat(
        value.items,
      );
    }
  }
  public selections: Array<Profile> = [];
  public tagLinks: Array<string>;

  public vendorTypesDataSurce: Array<ProfileType> = [];
  public selectedSection: { action: string; name: string };
  public sectionsDataSource: Array<{ action: string; name: string }>;
  public showLoader = false;
  private initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private vendorsInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );

  public query: string;

  readonly ProfileTypeIcon = ProfileTypeIcon;
  readonly Align = Align;
  readonly Sizes = Sizes;
  readonly ProfileDrildownScope = ProfileDrildownScope;
  readonly TaggingStatus = TaggingStatus;

  constructor(
    readonly userService: UserService,
    readonly profileService: ProfileService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly linkTagsService: LinkTagsService,
    readonly snackBar: MatSnackBar,
    readonly widgetService: WidgetService,
    readonly overlayService: OverlayService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initialState.where = this.dates;
    this.sectionsDataSource = [
      {
        action: 'update',
        name: 'Add to existing vendor',
      },
      { action: 'create', name: 'Add to new vendor' },
    ];
  }

  private getLinks(vendors: IProfileData): void {
    this.linkTagsService
      .getTargetLinks(this.widget.dataSource[0])
      .subscribe((response: Array<string>) => {
        this.vendorsDataSource = vendors;
        this.tagLinks = response;
        this.selections = response?.map((id: string) => {
          return this.vendorsDataSource.items.find(
            (profile: Profile) => profile.profileId === id,
          );
        });
        this.showLoader = false;
        this.cdr.detectChanges();
      });
  }

  public onCreateProfile(profileType: ProfileType): void {
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((tag: Tag) => {
        const profileDrilldown = new ProfileDrilldown(
          ProfileDrildownScope.TAG,
          profileType,
          this.widget.dataSource[0],
        );
        const profile = new Profile(
          undefined,
          undefined,
          AnalysisTypes.PROFILE,
          this.widget.name,
          undefined,
          undefined,
          this.userService.userPreferences.defaultEcosystemId,
          profileDrilldown.profileType.id,
          profileDrilldown.profileType.name,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        );
        const navigationItem = new ProfilesNavigationItem(
          `${Actions.CREATE}_profiles`,
          profile,
          Actions.CREATE,
          undefined,
          new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
          new FormGroup({}),
          'plus',
          IconType.FONT_AWESOME,
          undefined,
          undefined,
          undefined,
          new LinkTag(
            undefined,
            undefined,
            tag.userTagCategory,
            true,
            this.widget.name,
            undefined,
            undefined,
            profileDrilldown.value,
          ),
          undefined,
          undefined,
          0,
        );
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: ProfilesWizardComponent,
              inputs: {
                navigationItem,
              },
            },
          },
          closeBtnStyle: 'basic',
          closeBtnClass: 'hidden',
          type: CustomOverlayType['almost-full'],
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      });
  }

  public onAddProfile(): void {
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((tag: Tag) => {
        const profileToLink = this.selections.find(
          (profile) => !this.tagLinks.includes(profile.profileId),
        );
        const link = new LinkTag(
          undefined,
          undefined,
          tag.userTagCategory,
          true,
          this.widget.name,
          undefined,
          undefined,
          this.widget.dataSource[0],
        );
        this.linkTagsService
          .linkTags(profileToLink.profileId, [link])
          .subscribe(() => {
            this.tagLinks.push(profileToLink.profileId);
            this.snackBar.open(
              `${this.widget.name} has been linked to profile ${profileToLink.name}!`,
              'Close',
              {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              },
            );
          });
      });
  }

  public onExplore(): void {
    this.overlayService.closeOverlay();
    this.widgetService.explore$.next(this.widget);
  }

  public onGetVendors(): void {
    this.showLoader = true;
    this.profileService
      .getProfiles(
        this.vendorsInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IProfileData) => {
        if (!this.tagLinks) {
          this.getLinks(response);
        } else {
          this.vendorsDataSource = response;
          this.showLoader = false;
          this.cdr.detectChanges();
        }
      });
  }

  public onSearch(): void {
    if (this.query) {
      this.vendorsInitialState.addQueryColumn({
        searchValues: [this.query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.vendorsInitialState.removeQueryColumn('name');
    }
    this.vendorsInitialState.resetPagination();
    this.onGetVendors();
  }

  public onGetProfileTypes(): void {
    this.showLoader = true;
    this.profileService
      .getProfileTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.vendorTypesDataSurce = response;
        this.showLoader = false;
      });
  }

  public onNavChange(section: { action: string; name: string }): void {
    this.selectedSection = section;

    if (this.selectedSection.action === 'update') {
      this.onGetVendors();
    } else {
      this.onGetProfileTypes();
    }
  }

  public onEdit(): void {
    this.overlayService.closeOverlay();
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((response: Tag) => {
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: TagWizardComponent,
              inputs: {
                tag: response,
                widget: this.widget,
                action: Actions.EDIT,
                dates: this.initialState.where,
                queryModel: new BackEndQuery(
                  this.widget.query.operator,
                  this.widget.query.conditions,
                  this.widget.query.queries,
                ).convertFromBackEndQuery(),
              },
            },
          },
          closeBtnStyle: 'basic',
          closeBtnClass: 'hidden',
          type: CustomOverlayType['almost-full'],
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      });
  }

  public onClone(): void {
    this.overlayService.closeOverlay();
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((response: Tag) => {
        response.name = `Clone ${this.widget.name}`;
        this.customOverlayService.open({
          data: {
            componentConfig: {
              component: TagWizardComponent,
              inputs: {
                tag: response,
                action: Actions.CLONE,
                dates: this.initialState.where,
                queryModel: new BackEndQuery(
                  this.widget.query.operator,
                  this.widget.query.conditions,
                  this.widget.query.queries,
                ).convertFromBackEndQuery(),
              },
            },
          },
          closeBtnStyle: 'basic',
          closeBtnClass: 'hidden',
          type: CustomOverlayType['almost-full'],
          component: ModalContainerComponent,
          disposeOnNavigation: true,
        });
      });
  }
}
