import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOMessage,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiSettingsService {
  constructor(private apiRequestService: ApiRequestService) {}

  generateKey(tagId: string): Observable<any> {
    return this.apiRequestService.put('/external/key/' + tagId, tagId);
  }
}
