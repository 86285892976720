<p class="title pb-4 whitespace-nowrap">{{ title }}</p>
<ng-template dynamicPlaceholder></ng-template>
@if (!component) {
  <p class="whitespace-nowrap" [innerHTML]="message"></p>
}
<itq-mat-button
  class="absolute top-[20px] right-[1rem]"
  [type]="'button'"
  [materialStyle]="'basic'"
  [matTooltip]="'Close'"
  (clickEvent)="onClose()"
>
  <fa-icon class="text-white" [size]="'2x'" [icon]="['far', 'times']"></fa-icon>
</itq-mat-button>
