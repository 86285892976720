<itq-headline-simple
  class="mb-7"
  [iconName]="'note'"
  [iconSize]="'2x'"
  [iconType]="IconType.FONT_AWESOME"
  [title]="note | getNoteValue"
></itq-headline-simple>
<div class="view_note__container">
  <ng-template dynamicPlaceholder></ng-template>
</div>
<div class="view_note__footer">
  <itq-mat-button [materialStyle]="'basic'" (clickEvent)="onClose()">
    <span class="whitespace-nowrap">Close</span>
  </itq-mat-button>
</div>
