import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  DateRangeComponent,
  DateRangeHelper,
  Event,
  EventTeams,
  FAwesomeModule,
  IEventData,
  IEventType,
  IPresetQuery,
  PillType,
  QueryFilters,
  SearchFieldType,
  TimelineOrientation,
} from '@intorqa-ui/core';
import { EVENT_TIMELINE_CHART_TYPES } from '@portal/boards/const/event-timeline.const';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { EventsTimelineComponent } from '@portal/events/components/events-timeline/events-timeline.component';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { SortDescendingPipe } from '@portal/shared/pipes/events.pipe';
import { ChartService } from '@portal/shared/services/chart.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import {
  BarMode,
  ChartOrientation,
  ChartType,
} from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { EChartsOption } from 'echarts';
import moment from 'moment';
import { combineLatest, delay, map, Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'itq-chart-explore-event-timeline',
  templateUrl: './chart-explore-event-timeline.component.html',
  styleUrls: ['./chart-explore-event-timeline.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ChartComponent,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    MapLabelPipe,
    EventsTimelineComponent,
    ChartComponent,
    SortDescendingPipe,
    MatSlideToggleModule,
  ],
  providers: [EventsService],
})
export class ChartExploreEventTimelineComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() segment: any;
  @Input() widget: EventTimeline;
  @Input() initialState: QueryFilters;

  public chartTypesDataSource = EVENT_TIMELINE_CHART_TYPES;
  public trendDataSource: IWidgetData;
  public form: FormGroup;
  public trendWidget: TimeSeries;
  public dataSource: IEventData;
  public count = 0;
  private subscription = new Subscription();
  public cheaterTeamDataSource: Array<IEventType>;
  public securityTeamDataSource: Array<IEventType>;
  public showLoader = false;
  public cheaterTeamInitialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public securityTeamInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public selectedUserCategory: string;

  readonly PillType = PillType;
  readonly ChartType = ChartType;
  readonly TimelineOrientation = TimelineOrientation;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly chartService: ChartService,
    readonly eventsService: EventsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.createForm();
    this.getTagById();
    this.bindLoaderSubscription();
    this.bindDrilldownEventSubscription();
    this.bindRemoveSegmentSubscription();
    this.onGetSecurityEvents(this.securityTeamInitialState);
    this.onGetCheaterEvents(this.cheaterTeamInitialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getTagById(): void {
    if (this.widget?.dataCorrelation?.length > 0) {
      this.widgetService.loader$.next(true);
      this.tagService
        .getTagById(this.widget.dataCorrelation[0])
        .subscribe((tag: Tag) => {
          this.selectedUserCategory = tag.userTagCategory;
          this.loadData();
        });
    } else {
      this.loadData();
    }
  }

  private bindLoaderSubscription(): void {
    this.subscription.add(
      this.widgetService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  public onGetCheaterEvents(params: QueryFilters): void {
    this.cheaterTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.cheaterTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.CHEATER_TEAM;
        });
        if (this.widget.widgetId) {
          const widgetTypeIds = this.cheaterTeamDataSource.filter(
            (event: IEventType) => {
              return this.widget.dataSource.includes(event.id);
            },
          );
          if (widgetTypeIds.length === this.cheaterTeamDataSource.length) {
            this.form.get('selectAllCheater').setValue(true);
          }
          this.form.get('cheaterTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onGetSecurityEvents(params: QueryFilters): void {
    this.securityTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.securityTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.SECURITY_TEAM;
        });
        if (this.widget.widgetId) {
          const widgetTypeIds = this.securityTeamDataSource.filter(
            (event: IEventType) => {
              return this.widget.dataSource.includes(event.id);
            },
          );
          if (widgetTypeIds.length === this.securityTeamDataSource.length) {
            this.form.get('selectAllSecurity').setValue(true);
          }
          this.form.get('securityTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onToggleSecurity(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.securityTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('securityTeam').setValue(eventTypeIds);
    this.onChangeEvents();
  }

  public onToggleCheater(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.cheaterTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('cheaterTeam').setValue(eventTypeIds);
    this.onChangeEvents();
  }

  public onChangeEvents(): void {
    const securityTypeIds =
      this.form
        .get('securityTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const cheaterTypeIds =
      this.form
        .get('cheaterTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const eventTypeIds = [...securityTypeIds, ...cheaterTypeIds];
    this.widget.dataSource = eventTypeIds;
    this.onDataBound(this.initialState);
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.segment = undefined;
        this.cdr.detectChanges();
        this.chartService.reloadChart$.next(this.navigationItem?.id);
      }),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.navigationItem?.id);
  }

  private createForm(): void {
    this.form = new FormGroup({});
    this.form.addControl('securityTeam', new FormControl([]));
    this.form.addControl('cheaterTeam', new FormControl([]));
    this.form.addControl('selectAllSecurity', new FormControl(false));
    this.form.addControl('selectAllCheater', new FormControl(false));
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private bindDrilldownEventSubscription(): void {
    this.subscription.add(
      this.eventsService.drilldown$.subscribe((event: Event) => {
        if (this.widget.dataCorrelation?.length > 0) {
          this.segment = {
            name: moment(
              DateRangeHelper.convertFromEpochSeconds(event.date),
            ).format('DD/MM/YYYY'),
            seriesIndex: 0,
          };
          this.cdr.detectChanges();
          this.chartService.reloadChart$.next(this.navigationItem?.id);
        }
      }),
    );
  }

  private loadData(): void {
    this.widgetService.loader$.next(true);
    combineLatest([this.getData(), this.getTrendData()]).subscribe(() => {
      this.cdr.detectChanges();
      this.chartService.reloadChart$.next(this.navigationItem?.id);
      this.widgetService.loader$.next(false);
    });
  }

  public getTrendData(): Observable<void> {
    if (this.widget.dataCorrelation?.length === 0)
      return of(undefined).pipe(delay(0));

    const queryModel = new Query();
    this.trendWidget = WidgetFactory.createWidget({
      type: AnalysisTypes.TIME_SERIES,
      ecosystemId: this.userService.userPreferences.defaultEcosystemId,
      dataValues: this.widget.dataCorrelation,
      chartType: ChartType.BAR,
      dataType: this.selectedUserCategory,
      options: this.getOptions(),
    }) as TimeSeries;
    return this.widgetService
      .getData(
        {
          widget: this.trendWidget,
          filters: queryModel.convertToBackEndQuery(),
        },
        this.initialState,
      )
      .pipe(
        map((response: IWidgetData) => {
          this.trendDataSource = response;
        }),
      );
  }
  private getOptions(): EChartsOption {
    return {
      orientation: ChartOrientation.VERTICAL,
      mode: BarMode.STACK,
    };
  }

  private getData(): Observable<IEventData> {
    this.initialState.addQueryColumn({
      searchField: 'typeId',
      searchValues: this.widget.dataSource?.map(
        (eventTypeId: string) => eventTypeId,
      ),
      searchFieldType: SearchFieldType.ID,
    });
    return this.eventsService
      .getEvents(
        this.initialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IEventData) => {
          this.count = response.totalCount;
          this.dataSource = response;
          return this.dataSource;
        }),
      );
  }

  public onDataBound(params: QueryFilters): void {
    this.widgetService.loader$.next(true);
    this.initialState = params;
    this.loadData();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onSearch(): void {
    this.widgetService.loader$.next(true);
    this.segment = undefined;
    this.loadData();
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
