import { Pipe, PipeTransform } from '@angular/core';
import { CategoryService } from '@portal/shared/services/category.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { Rule, RuleSet } from 'ngx-angular-query-builder';

@Pipe({
  name: 'castAsQueryModel',
  standalone: true,
})
export class CastAsQueryModelPipe implements PipeTransform {
  constructor() {}

  transform(rule: Rule | RuleSet): Rule {
    return rule as Rule;
  }
}

@Pipe({
  name: 'getFieldById',
  standalone: true,
})
export class GetFieldByIdPipe implements PipeTransform {
  constructor(readonly categoryService: CategoryService) {}

  transform(id: string): IFilterField {
    return this.categoryService.fields?.find(
      (item: IFilterField) => item.id === id,
    );
  }
}
