import { Injectable } from '@angular/core';
import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { QueryFilters, SharedService, TagCategory } from '@intorqa-ui/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ILoadLink,
  ILinksData,
  ILinkTag,
} from '../interfaces/profile-tags.interface';
import { LinkTag } from '../models/profile-tags';

@Injectable({
  providedIn: 'root',
})
export class LinkTagsService {
  public reset$ = new Subject<void>();
  public showLinkTag$ = new Subject<boolean>();
  public links$ = new Subject<ILoadLink>();
  private _links: ILinksData = { items: [], totalCount: undefined };

  public get links(): ILinksData {
    return this._links;
  }

  public set links(v: ILinksData) {
    this._links = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getLinkedTags(
    id: string,
    params: QueryFilters,
  ): Observable<ILinksData> {
    let pageQuery = `page=${params?.page}`;
    pageQuery += `&size=${params?.pageSize}`;
    pageQuery += `&sortField=${params?.sort?.active}`;
    pageQuery += `&sortOrder=${params?.sort?.direction}`;
    return this.apiRequestService
      .post(
        `/profiles/${id}/tags/list?${pageQuery}`,
        new DTOTypeConverter<ILinksData>(),
        params?.query,
      )
      .pipe(
        map((response: ILinksData) => {
          this.links = {
            items: response?.items?.map(
              (link: LinkTag) =>
                new LinkTag(
                  link.id,
                  link.description,
                  link.type,
                  link.isDefault,
                  link.tagName,
                  link.updatedDate,
                  link.updatedBy,
                  link.tagId,
                ),
            ),
            totalCount: response.totalCount,
          };
          this.links$.next({ link: undefined, data: this.links });
          return this.links;
        }),
      );
  }

  public resetLinks(): void {
    this.links = {
      items: [],
      totalCount: 0,
    };
  }

  public linkTags(
    profileId: string,
    tags: Array<LinkTag>,
  ): Observable<ILinksData> {
    tags.map((item: LinkTag) => {
      delete item.id;
    });
    return this.apiRequestService
      .post(
        `/profiles/${profileId}/tags`,
        new DTOTypeConverter<ILinksData>(),
        tags,
      )
      .pipe(
        map((response: ILinksData) => {
          this.links = {
            items: response?.items?.map(
              (link: ILinkTag) =>
                new LinkTag(
                  link.id,
                  link.description,
                  link.type,
                  link.isDefault,
                  link.tagName,
                  link.updatedDate,
                  link.updatedBy,
                  link.tagId,
                ),
            ),
            totalCount: response.totalCount,
          };
          return this.links;
        }),
      );
  }

  public unLinkTags(
    profileId: string,
    links: Array<LinkTag>,
  ): Observable<ILinksData> {
    return this.apiRequestService
      .delete(`/profiles/${profileId}/tags`, links)
      .pipe(
        map((response: ILinksData) => {
          this.links = {
            items: response?.items?.map(
              (link: ILinkTag) =>
                new LinkTag(
                  link.id,
                  link.description,
                  link.type,
                  link.isDefault,
                  link.tagName,
                  link.updatedDate,
                  link.updatedBy,
                  link.tagId,
                ),
            ),
            totalCount: response.totalCount,
          };
          this.links$.next({ link: undefined, data: this.links });
          return this.links;
        }),
      );
  }

  public getUsers(profileId: string): Observable<Array<string>> {
    return this.apiRequestService.get(
      `/profiles/${profileId}/tags/users`,
      new DTOTypeConverter<Array<string>>(),
      undefined,
    );
  }

  public getDefaultTag(): LinkTag {
    return this.links?.items?.find((item: LinkTag) => item.isDefault);
  }

  public validateLinks(): AsyncValidatorFn {
    return (): Observable<ValidationErrors | null> => {
      const valid = this.getDefaultTag();
      if (this.links?.items?.length === 0) return of(null);
      return valid ? of(null) : of({ invalidDefaultTab: true });
    };
  }

  public getTagsByType(type: TagCategory): Array<LinkTag> {
    if (!type) return [];
    return this.links?.items?.filter((item: LinkTag) => item.type === type);
  }

  public updateLink(profileId: string, link: LinkTag): Observable<ILinksData> {
    return this.apiRequestService
      .put(`/profiles/${profileId}/tags/${link.id}`, {
        type: link.type,
        tagId: link.tagId,
        description: link.description,
        tagName: link.tagName,
        isDefault: link.isDefault,
      })
      .pipe(
        map((response: LinkTag) => {
          this.links = {
            items: this.links?.items?.map((item: LinkTag) => {
              if (response.id === item.id) {
                return new LinkTag(
                  response.id,
                  response.description,
                  response.type,
                  response.isDefault,
                  response.tagName,
                  response.updatedDate,
                  response.updatedBy,
                  response.tagId,
                );
              } else {
                return new LinkTag(
                  item.id,
                  item.description,
                  item.type,
                  response.isDefault ? false : item.isDefault,
                  item.tagName,
                  item.updatedDate,
                  item.updatedBy,
                  item.tagId,
                );
              }
            }),
            totalCount: this.links.totalCount,
          };
          this.links$.next({ link: undefined, data: this.links });
          return this.links;
        }),
      );
  }

  public getTargetLinks(
    tagId?: string,
    tagName?: string,
    tagType?: string,
  ): Observable<Array<string>> {
    let payload = {
      tagId: undefined,
      tagName: undefined,
      tagType: undefined,
    };
    if (tagId) {
      payload.tagId = tagId;
    } else {
      payload.tagName = tagName;
      payload.tagType = tagType;
    }
    return this.apiRequestService.post(
      '/profiles/tags/links',
      new DTOTypeConverter<Array<string>>(),
      payload,
    );
  }
}
