import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FieldTypes,
  HighlightSearchPipe,
  IPresetQuery,
  OverlayService,
  QueryFilters,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
  IUserCategory,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
@Component({
  selector: 'itq-link-tags-type',
  templateUrl: './link-tags-type.component.html',
  styleUrls: ['./link-tags-type.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FormsModule,
    HighlightSearchPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LinkTagsTypeComponent,
    },
  ],
})
export class LinkTagsTypeComponent implements OnInit {
  @Input() field: string;
  @Input() fields: Array<IFilterField>;
  @Input() selectedLinkType: string;
  @Input() disabled = false;
  @Input() dates: IPresetQuery;

  @Output() selectionChange = new EventEmitter<ICustomTag>();

  private _tagsDataSource: Array<ICustomTag>;

  public get tagsDataSource(): Array<ICustomTag> {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: Array<ICustomTag>) {
    if (this.initialState.page === 1) {
      this._tagsDataSource = value;
    } else {
      this._tagsDataSource = this._tagsDataSource.concat(value);
    }
  }

  public linkTypesDataSource: Array<string>;
  public showLoader = true;
  public selection: any;
  private touched = false;
  public query: string;
  public initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  constructor(
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly overlayService: OverlayService,
    readonly tagService: TagService,
  ) {}

  onChange = (items: ICustomTag) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.initialState.where = this.dates;
    this.getUserCategories();
  }

  onSelectionChange(item: ICustomTag): void {
    this.onChange(item);
    this.selectionChange.emit(item);
    this.overlayService.closeOverlay();
  }

  public onSearch(): void {
    this.initialState.query = this.query;
    this.onGetTags();
  }

  public getUserCategories(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IUserCategory>) => {
        this.linkTypesDataSource = response?.map(
          (item: IUserCategory) => item.name,
        );
        this.linkTypesDataSource = [
          ...this.linkTypesDataSource,
          'Channel',
          'Actor',
        ];
        this.selectedLinkType =
          this.selectedLinkType || this.linkTypesDataSource[0];
        this.onGetTags();
      });
  }

  public onNavChange(selectedLinkType: string): void {
    this.selectedLinkType = selectedLinkType;
    this.onGetTags();
  }

  private onGetTags(): void {
    this.showLoader = true;
    if (
      this.selectedLinkType === 'Channel' ||
      this.selectedLinkType === 'Actor'
    ) {
      this.tagService
        .getFieldValues(
          this.initialState,
          new Query().convertToBackEndQuery(),
          this.selectedLinkType.toLowerCase(),
          this.userService.userPreferences.defaultEcosystemId,
          this.initialState.page > 1
            ? this.tagsDataSource[this.tagsDataSource.length - 1].name
            : undefined,
        )
        .subscribe(
          (response: { items: Array<ICustomTag>; totalCount: number }) => {
            const index = response.items.findIndex(
              (item) => item.id === this.field,
            );
            if (index > -1) {
              const [item] = response.items.splice(index, 1);
              response.items.unshift(item);
            }
            this.tagsDataSource = response.items;
            this.showLoader = false;
          },
        );
    } else {
      this.categoryService
        .getCategoryValues(
          this.query,
          this.initialState,
          new Query().convertToBackEndQuery(),
          this.selectedLinkType,
          this.userService.userPreferences.defaultEcosystemId,
          FieldTypes.USER_TAG_CATEGORY,
          this.initialState.page > 1
            ? this.tagsDataSource[this.tagsDataSource.length - 1].name
            : undefined,
        )
        .subscribe(
          (response: { items: Array<ICustomTag>; totalCount: number }) => {
            const index = response.items.findIndex(
              (item) => item.id === this.field,
            );
            if (index > -1) {
              const [item] = response.items.splice(index, 1);
              response.items.unshift(item);
            }
            this.tagsDataSource = response.items;
            this.showLoader = false;
          },
        );
    }
  }
}
