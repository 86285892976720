import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import {
  Align,
  CoreModule,
  DateQueryLabel,
  DateQueryType,
  DateRangeHelper,
  DateRangeService,
  IPreset,
  Sizes,
} from '@intorqa-ui/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'itq-date-range-presets',
  templateUrl: './date-range-presets.component.html',
  styleUrls: ['./date-range-presets.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
  ],
})
export class DateRangePresetsComponent implements OnInit {
  @Input() dates: IPreset;

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly DateQueryLabel = DateQueryLabel;
  readonly DateQueryType = DateQueryType;

  public dateRange: DateRange<moment.Moment>;
  public customPresets: Array<IPreset>;

  constructor(
    readonly dateRangeService: DateRangeService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
  ) {
    this.customPresets = DateRangeHelper.getDatePresets();
  }

  ngOnInit(): void {
    this.dateRange = new DateRange<Moment>(this.dates?.start, this.dates?.end);
  }

  public onRangeClick(preset: IPreset, event: MouseEvent): void {
    if (preset.label === DateQueryType.Custom) {
      event.stopImmediatePropagation();
    }
  }

  public rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection,
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.dateRange = new DateRange<Moment>(
      moment(newSelection.start),
      moment(newSelection.end),
    );
  }

  public selectRange(preset: IPreset): void {
    this.dateRange = new DateRange<Moment>(preset.start, preset.end);
    this.onSetDates(preset.label, preset.start, preset.end, true);
  }

  public onSelectCustomRange(preset: IPreset): void {
    event.stopImmediatePropagation();
    this.onSetDates(
      preset.label,
      this.dateRange.start,
      this.dateRange.end,
      false,
    );
  }

  private onSetDates(
    label: DateQueryType,
    start: Moment,
    end: Moment,
    refresh: boolean,
  ): void {
    this.dateRangeService.changeDates$.next({
      refresh: refresh,
      preset: {
        label: label,
        start: start?.isValid()
          ? DateRangeHelper.convertToEpochSec(start.toDate())
          : undefined,
        end: end?.isValid()
          ? DateRangeHelper.convertToEpochSec(end.toDate())
          : undefined,
      },
    });
  }

  public onCancel(): void {
    this.closeCalendar();
  }

  public onApply(): void {
    this.closeCalendar();
    this.onSetDates(
      this.dates.label,
      this.dateRange.start,
      this.dateRange.end,
      true,
    );
  }

  private closeCalendar(): void {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(clickEvent);
  }
}
