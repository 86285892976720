<form
  class="flex flex-col h-full"
  [formGroup]="form"
  (ngSubmit)="form.valid && onSubmit()"
>
  <div class="flex flex-col flex-1">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <fa-icon [size]="'2x'" [icon]="['far', 'envelope']"></fa-icon>
          <h1 class="text-28 font-bold">Share by email</h1>
        </div>
        <div class="text-xl justify-start w-full">
          Please add recipient details and custom message to share your items
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
    <div class="flex flex-col p-7 bg-background flex-1">
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">EMAIL</span>
        <itq-input
          formControlName="email"
          [placeholder]="'Please enter your email address'"
        ></itq-input>
        @if (
          form.controls['email'].touched &&
          form.controls['email'].hasError('required')
        ) {
          <mat-error> Email address is <strong>required</strong> </mat-error>
        }
        @if (
          form.controls['email'].touched &&
          form.controls['email'].hasError('email')
        ) {
          <mat-error> Email address is <strong>invalid</strong> </mat-error>
        }
      </div>
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">MESSAGE</span>
        <quill-editor
          #Editor
          [styles]="{ height: '200px' }"
          formControlName="content"
        ></quill-editor>
      </div>
      @if (document) {
        <div class="flex flex-col mb-4">
          <span class="mb-1 text-13 font-medium">DOCUMENT</span>
          <itq-document-item-tile
            class="border border-border rounded-md p-4 bg-white"
            [document]="document"
            [shareByEmail]="false"
            [allowDrilldownProfiles]="false"
            (drilldown)="onDrilldown($event)"
          ></itq-document-item-tile>
        </div>
      }
    </div>
  </div>
  <footer class="flex justify-end px-7 py-4 bg-white border-t border-border">
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [disabled]="!form.valid"
    >
      SEND
    </itq-mat-button>
  </footer>
</form>
@if (showLoader) {
  <itq-loader></itq-loader>
}
