import { Pipe, PipeTransform } from '@angular/core';
import { Delays_DataSource } from '@portal/notifications/const/alerts.const';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';

@Pipe({
  name: 'castAsQueryModel',
  standalone: true,
})
export class CastAsQueryModelPipe implements PipeTransform {
  constructor() {}

  transform(query: string): QueryBuilderModel {
    return query as unknown as QueryBuilderModel;
  }
}

@Pipe({
  name: 'getDelayLabel',
  standalone: true,
})
export class GetDelayLabelPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (!value) return '';
    const delay = Delays_DataSource.find(
      (item: { name: string; value: number }) => {
        return item.value === value;
      },
    );
    return delay.name.toLowerCase();
  }
}
