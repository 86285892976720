import { ISerie, IDataPoint } from '@portal/shared/interfaces/widget.interface';
import { defaultTheme } from './echarts.theme';

export class ChartUtils {
  static buildTooltip(
    item: ISerie,
    value: any,
    index: number,
    dataPointValue: IDataPoint,
  ): string {
    let tooltip = '';
    const overedSeries = item.name === value.seriesName;

    if (overedSeries) {
      tooltip +=
        "<span class='display: block;  width: 150px; height: 30px; padding-top: 5px;border: 1px solid black;color: black;'>";
      tooltip +=
        "<span style='padding-left: 5px;padding-right: 5px;display: inline-block;width: 10px; height: 10px; font-size: 0.75rem; background-color:" +
        defaultTheme.theme.color[index] +
        "; border-radius: 100%;'></span>";
      tooltip +=
        "<div style='display: block;background-color: #8f8f90; border-radius: 0.25rem; justify-content: space-between; padding-left: 5px;font-weight: 500; display: inline-block; color: " +
        defaultTheme.theme.color[index] +
        "'><span>" +
        item.name +
        "</span> - <span style='color: black; padding-right: 5px;'>" +
        dataPointValue.count +
        '</span></div>';
      tooltip += '</span>';
    } else {
      tooltip +=
        "<div class='display: flex; justify-content: space-between; width: 150px; height: 30px; padding-top: 5px;color: black; font-size: 0.75rem'>";
      tooltip +=
        "<span style='display: inline-block;width: 10px; height: 10px; background-color: " +
        defaultTheme.theme.color[index] +
        "; border-radius: 100%;'></span>";
      tooltip +=
        "<span style='padding-left: 5px; display: inline-block; color: " +
        defaultTheme.theme.color[index] +
        "'>" +
        item.name +
        "</span> - <span style='font-weight: 500; color: black;'>" +
        dataPointValue.count +
        '</span>';
      tooltip += '</div>';
    }
    return tooltip;
  }

  static getTextStyle(): any {
    return {
      fontFamily: 'Mont',
      color: 'black',
    };
  }

  static getDataPoint = (
    item: IDataPoint,
    segment: any,
    tagId: string,
    seriesIndex: number,
  ) => {
    let dataPoint = {
      name: item?.label,
      value: item?.count,
      tagId: item?.tagId || tagId,
      field: item?.field,
    };
    if (item?.label === segment?.name && segment?.seriesIndex === seriesIndex) {
      dataPoint = {
        ...dataPoint,
        ...{
          itemStyle: { color: '#1de62a' },
        },
      };
    }
    return dataPoint;
  };

  static getGrid(hasLegend: boolean, containLabel: boolean): any {
    return {
      left: 0,
      top: hasLegend ? 40 : 5,
      right: 0,
      bottom: 0,
      containLabel,
    };
  }

  static getLegend(hasLegend: boolean): any {
    return {
      show: hasLegend,
      type: 'scroll',
      icon: 'circle',
      top: 'top',
      orient: 'horizontal',
    };
  }
}
