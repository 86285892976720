<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <div class="flex flex-col mb-4">
      <span class="label mb-1">ALIAS TYPE</span>
      <itq-dropdown
        [formControlName]="'provider'"
        [clearSelection]="false"
        [dataSource]="subTypesDataSource"
        [dataFields]="{ name: 'name', value: 'name' }"
        (changeValue)="onChangeSubType()"
      ></itq-dropdown>
      <mat-error
        *ngIf="
          form.controls?.provider?.touched && form.controls?.provider?.invalid
        "
      >
        Alias type is required
      </mat-error>
    </div>
    <div class="flex flex-col mb-4">
      <span class="label mb-1">ALIAS</span>
      <itq-input
        formControlName="value"
        (search)="onValueChanged()"
        (clear)="onValueChanged()"
      ></itq-input>
      <mat-error
        *ngIf="form.controls?.value?.touched && form.controls?.value?.invalid"
      >
        Alias is required
      </mat-error>
    </div>
    <div class="flex flex-col mb-4">
      <span class="label mb-1">TEXT NOTES</span>
      <quill-editor
        [styles]="{
          height: '100%',
          minHeight: '400px',
          width: '100%',
          maxHeight: '400px'
        }"
        [formControlName]="'description'"
        [modules]="editorModules"
        (onContentChanged)="onContentChanged()"
      ></quill-editor>
    </div>
  </ng-container>
</ng-container>
