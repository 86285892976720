import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  AvatarComponent,
  ComponentType,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  GetUsernameInitialsPipe,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
  TagCategory,
  Utils,
  VirtualTableComponent,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import {
  ILinksData,
  ILoadLink,
} from '@portal/profiles/interfaces/profile-tags.interface';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { LinkTagsComponent } from '../link-tags/link-tags.component';

@Component({
  selector: 'itq-profiles-links',
  templateUrl: './profiles-links.component.html',
  styleUrls: ['./profiles-links.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesLinksComponent,
    },
  ],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    VirtualTableComponent,
    MatTooltipModule,
    CommonModule,
    ReactiveFormsModule,
    AvatarComponent,
    GetUsernameInitialsPipe,
  ],
})
export class ProfilesLinksComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;

  public dataSource: ILinksData = { items: [], totalCount: undefined };
  public tableColumns: Array<TableColumn> = [];
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  private linksSubscription: Subscription;
  private resetSubscription: Subscription;
  private selection: Array<LinkTag>;
  private touched = false;
  private disabled = false;
  public isFilterable = false;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<unknown>;
  @ViewChild('defaultButtonTemplate')
  defaultButtonTemplate: TemplateRef<unknown>;

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly Actions = Actions;
  readonly PillType = PillType;

  constructor(
    private linkTagsService: LinkTagsService,
    private customOverlayService: CustomOverlayService,
    readonly profilesService: ProfileService,
  ) {}

  ngOnInit() {
    this.dataSource = this.linkTagsService.links;
    this.linksSubscription = this.linkTagsService.links$.subscribe(
      (response: ILoadLink) => {
        this.form.controls.links?.setValue(response.data);
        this.dataSource = cloneDeep(response.data);
      },
    );
    this.resetSubscription = this.linkTagsService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    if (this.navigationItem.action === Actions.EDIT) {
      this.onDataBound(this.initialState);
    }
    if (this.navigationItem.linkTag) {
      this.linkTagsService.links = {
        items: [this.navigationItem.linkTag],
        totalCount: 1,
      };
      this.dataSource = cloneDeep(this.linkTagsService.links);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.linksSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<LinkTag>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Updated date',
        dataKey: 'updatedDate',
        searchField: 'updatedDate',
        isSortable: true,
        width: '180px',
        customRender: true,
        template: this.dateTemplate,
        // sticky: true,
        // isFilterable: true,
        // searchFieldType: SearchFieldType.DATE,
        // searchComponent: ComponentType.DATE,
      },
      {
        name: 'Updated by',
        dataKey: 'updatedBy',
        searchField: 'updatedBy',
        isSortable: true,
        position: 'center',
        customRender: true,
        template: this.ownerTemplate,
        width: '120px',
        // sticky: true,
        // httpBinding: true,
        // isFilterable: true,
        // searchFieldType: SearchFieldType.TEXT,
        // searchComponent: ComponentType.DROPDOWN,
        // dataSource: undefined,
        // dataBound: () => {
        //   this.onGetUsers();
        // },
      },
      {
        name: 'Tag type',
        dataKey: 'type',
        searchField: 'type',
        width: '200px',
        // isFilterable: false,
        isSortable: true,
        // searchFieldType: SearchFieldType.ID,
        // searchComponent: ComponentType.DROPDOWN,
        // dataSource: [
        // { name: TagCategory.MY_TAGS, value: TagCategory.MY_TAGS },
        // { name: TagCategory.ACTOR, value: TagCategory.ACTOR },
        // { name: TagCategory.CHANNEL, value: TagCategory.CHANNEL },
        // ],
      },
      {
        name: 'Tag',
        dataKey: 'tagName',
        searchField: 'tagName',
        width: '200px',
        // isFilterable: true,
        isSortable: true,
        // searchFieldType: SearchFieldType.TEXT,
        // searchComponent: ComponentType.INPUT,
        // dataSource: undefined,
      },
      {
        name: 'Description',
        dataKey: 'description',
        searchField: 'description',
        width: 'fit',
        // isFilterable: true,
        isSortable: true,
        // searchFieldType: SearchFieldType.TEXT,
        // dataSource: undefined,
        // searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Default',
        dataKey: 'isDefault',
        searchField: 'isDefault',
        width: '100px',
        position: 'center',
        // isFilterable: false,
        isSortable: true,
        customRender: true,
        template: this.defaultButtonTemplate,
        // searchFieldType: SearchFieldType.TEXT,
        // dataSource: undefined,
        // searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'updatedBy') {
        this.linkTagsService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  public onEditLink(link: LinkTag): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: LinkTagsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.EDIT,
            navigationItem: this.navigationItem,
            link: cloneDeep(link),
            totalCount: this.dataSource.totalCount,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onUnlinkTag(link: LinkTag): void {
    if (link.isDefault) {
      this.customOverlayService.openCustom(
        {
          title: 'Oooops!',
          message: "You can't delete the default tag!",
          icon: ['far', 'exclamation-circle'],
          size: '4x',
          dialog: {
            type: DialogTypes.ALERT,
          },
        },
        DialogComponent,
      );
    } else {
      this.linkTagsService
        .unLinkTags(this.profile.profileId, [link])
        .subscribe(() => {
          this.form.controls.links.updateValueAndValidity();
        });
    }

    this.form.controls.links?.markAsTouched();
  }

  public onLinkTag(): void {
    const defaultTag = this.linkTagsService.getDefaultTag();
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: LinkTagsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            navigationItem: this.navigationItem,
            link: new LinkTag(
              Utils.generateUUID(),
              undefined,
              undefined,
              !defaultTag ? true : false,
              undefined,
              undefined,
              undefined,
              undefined,
            ),
            totalCount: this.dataSource.totalCount,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.onDataBound(this.initialState);
      }
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.profilesService.loader$.next(true);
    this.linkTagsService
      .getLinkedTags(this.profile.profileId, params)
      .subscribe(() => {
        this.profilesService.loader$.next(false);
      });
  }

  public onExit(): void {
    this.linkTagsService.showLinkTag$.next(false);
  }
}
