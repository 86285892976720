<div class="flex m-7 mt-4">
  <div
    class="flex items-center bg-white rounded-md border border-border w-full p-7"
  >
    <div class="flex items-center flex-1">
      <fa-icon class="pr-2" [icon]="['far', 'tag']" [size]="'3x'"></fa-icon>
      <span class="font-bold text-28">Library</span>
    </div>
    <div class="flex flex-1">
      <span class="max-w-[75%]">Your library is a central repository.</span>
    </div>
  </div>
</div>
<div class="flex justify-center w-full flex-1 mb-7">
  <div class="flex w-full justify-center mx-7 gap-7">
    <itq-library-thumbnail
      class="flex flex-col border border-border rounded-md flex-1 bg-white"
      [dataSource]="tagsDataSource"
      [title]="ThumbnailTitle.TAGS"
      [icon]="'tag'"
      [totalCount]="tagsTotalCount"
      [iconType]="IconType.FONT_AWESOME"
      [dateLabel]="'CREATED DATE'"
      (action)="onLoadTags()"
      (create)="onCreateTag()"
      (dataBound)="onDataBoundTags($event)"
    ></itq-library-thumbnail>
    <itq-library-thumbnail
      class="flex flex-col border border-border rounded-md flex-1 bg-white"
      [dataSource]="vendorsDataSource"
      [icon]="'vendor'"
      [iconType]="IconType.SVG"
      [totalCount]="vendorsTotalCount"
      [title]="ThumbnailTitle.VENDORS"
      [dateLabel]="'CREATED DATE'"
      (action)="onLoadVendors()"
      (create)="onCreateVendor($event)"
      (dataBound)="onDataBoundVendors($event)"
    ></itq-library-thumbnail>
    @if (userService.userPreferences.defaultEcosystemId | hasEventsEnabled) {
      <itq-library-thumbnail
        class="flex flex-col border border-border rounded-md flex-1 bg-white"
        [dataSource]="eventsDataSource"
        [title]="ThumbnailTitle.EVENTS"
        [totalCount]="eventsTotalCount"
        [icon]="'calendar-plus'"
        [iconType]="IconType.FONT_AWESOME"
        [dateLabel]="'EVENT DATE'"
        (action)="onLoadEvents()"
        (create)="onCreateEvent()"
        (dataBound)="onDataBoundEvents($event)"
      ></itq-library-thumbnail>
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
