<div class="flex flex-col w-full">
  <div class="flex justify-between w-full items-center space-x-4">
    <itq-input
      class="pb-2 w-[250px]"
      [icon]="'search'"
      [placeholder]="'Add search term...'"
      [type]="'text'"
      [(ngModel)]="query"
      (search)="onSearch()"
      (clear)="onSearch()"
    ></itq-input>
    <mat-slide-toggle [(ngModel)]="showEmpty" (change)="onToggleShowAll()">
      <span class="pl-2">Include empty results</span>
    </mat-slide-toggle>
  </div>
  <div
    class="flex border-t border-border pt-[8px] min-h-[250px] max-h-[350px] below-560h:max-h-[200px] below-560h:min-h-[200px]"
  >
    @if (dataSource?.items?.length === 0) {
      <div class="flex w-[650px]">
        <itq-panel-info
          class="flex-1 p-7"
          [label]="'No values found!'"
          [message]="'Please update your search term of choose a new field.'"
          [icon]="'exclamation-circle'"
        ></itq-panel-info>
      </div>
    } @else {
      @if (showPrefilter) {
        <div
          [ngClass]="{
            'min-h-[250px]  flex min-w-[250px]': true,
            hidden: !showPrefilter
          }"
          infiniteScroll
          (scrolledToEnd)="onScrollPrefilters()"
        >
          @if (dataSource?.items?.length > 0) {
            <mat-nav-list
              class="overflow-auto flex flex-cold w-full"
              [style.padding-right]="'8px !important'"
            >
              <mat-list-item
                *ngFor="let item of dataSource.items; let i = index"
                [style.background-color]="
                  i === selectedIndex ? '#f5f5f5 !important' : ''
                "
                [ngClass]="{
                  'opacity-60': item.count === 0,
                  'rounded-md': true
                }"
                (click)="onNavChange(i)"
              >
                <div
                  [ngClass]="{
                    'flex justify-between items-center w-full space-x-2': true,
                    'font-semibold': i === selectedIndex
                  }"
                >
                  <div
                    class="flex items-center justify-between flex-1 overflow-hidden"
                  >
                    <span
                      class="overflow-hidden text-ellipsis"
                      [matTooltip]="item.value"
                      >{{ item.value }}</span
                    >
                    @if (item.count) {
                      <span class="font-semibold">({{ item.count }})</span>
                    }
                  </div>
                  <mat-icon>{{
                    i === selectedIndex ? 'chevron_right' : 'expand_more'
                  }}</mat-icon>
                </div>
              </mat-list-item>
            </mat-nav-list>
          } @else {
            @if (dataSource) {
              <itq-panel-info
                class="flex-1 p-7 w-[250px]"
                [label]="'No values found!'"
                [message]="
                  'Please update your search term of choose a new field.'
                "
                [icon]="'exclamation-circle'"
              ></itq-panel-info>
            }
          }
        </div>
      }

      <div
        [ngClass]="{
          'flex relative w-full': true,
          'border-l border-border pl-[8px]': showPrefilter
        }"
      >
        <div
          class="flex min-w-[350px] w-full overflow-auto"
          infiniteScroll
          (scrolledToEnd)="onGetFieldValues()"
        >
          @if (subDataSource?.items?.length > 0) {
            <mat-selection-list
              [(ngModel)]="selections"
              (selectionChange)="onSelectionChange()"
            >
              <mat-list-option
                class="hover:bg-background-over rounded-md"
                *ngFor="let item of subDataSource.items"
                [value]="item"
                [selected]="item | isSelected: selections"
              >
                <div class="flex items-center justify-between w-full space-x-2">
                  <span
                    class="flex-1"
                    [innerHTML]="item.name | highlightSearch: query"
                  ></span>
                  @if (item.count) {
                    <span class="font-semibold">({{ item.count }})</span>
                  }
                </div>
              </mat-list-option>
            </mat-selection-list>
          } @else {
            @if (subDataSource) {
              <itq-panel-info
                class="flex-1 p-7"
                [label]="'No values found!'"
                [message]="
                  'Please update your search term of choose a new field.'
                "
                [icon]="'exclamation-circle'"
              ></itq-panel-info>
            }
          }
        </div>

        <button
          mat-flat-button
          class="l-0 z-10 top-[calc(50%-13px)]"
          [style.position]="'absolute !important'"
          [style.padding]="'0px !important'"
          [style.height]="'32px !important'"
          [style.width]="'32px !important'"
          [style.left]="showPrefilter ? '-16px !important' : '0'"
          [style.min-width]="'32px !important'"
          [type]="'button'"
          [color]="'accent'"
          [matTooltip]="showPrefilter ? 'Hide prefilters' : 'Showprefilters'"
          (click)="onTogglePrefilter()"
        >
          <fa-icon
            [icon]="
              showPrefilter ? ['far', 'chevron-left'] : ['far', 'chevron-right']
            "
          ></fa-icon>
        </button>
      </div>
    }
  </div>
</div>
@if (showLoader) {
  <itq-loader></itq-loader>
}
