import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  FieldSections,
  FieldTypes,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagPickerComponent } from '@portal/tags/components/tag-picker/tag-picker.component';
import {
  ICustomTag,
  IFilterField,
  ITagMetadata,
} from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'itq-specific-tag-datasource',
  templateUrl: './specific-tag-datasource.component.html',
  styleUrls: ['./specific-tag-datasource.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    CommonModule,
    TagPickerComponent,
    MatTooltipModule,
  ],
})
export class SpecificTagDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;
  @Input() dates: IPresetQuery;

  @Output() changeDataValue = new EventEmitter<Array<string>>();
  @Output() changeDataSource = new EventEmitter<Array<string>>();
  @Output() changeDataType = new EventEmitter<TagCategory>();

  public dataSlicerDataSource: Array<IFilterField>;
  public tag: Timeline;
  private _dataPointsDataSource: Array<ICustomTag>;
  public selectedUserCategory: string;

  public get dataPointsDataSource(): Array<ICustomTag> {
    return this._dataPointsDataSource;
  }

  public set dataPointsDataSource(value: Array<ICustomTag>) {
    this._dataPointsDataSource = value;
    this._dataPointsDataSource =
      this.initialState.page > 1
        ? [...this._dataPointsDataSource, ...value]
        : value;
  }

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  private subscriptions = new Subscription();

  readonly TagCategory = TagCategory;
  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    private categoryService: CategoryService,
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly tagService: TagService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.bindReloadWidgetDatesSubscription();
    this.createForm();
    if (this.widget.widgetId) {
      this.getTagById();
      this.getDataSlices();
      this.loadMetadata();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('category');
    this.form.removeControl('dataPoints');
    this.subscriptions.unsubscribe();
  }

  public getTagById(): void {
    this.tagService
      .getTagById(this.widget.dataSource[0])
      .subscribe((tag: Tag) => {
        this.selectedUserCategory = tag.userTagCategory;
        this.form.patchValue({
          dataSource: {
            name: tag.name,
            id: tag.tagId,
          },
        });
      });
  }

  private bindReloadWidgetDatesSubscription(): void {
    this.subscriptions.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagComparison | TimeSeries;
          dates?: IPresetQuery;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
        },
      ),
    );
  }

  private loadMetadata(): void {
    if (this.widget.dataValues?.length > 0) {
      this.categoryService
        .getUserCategoryFieldsByType(
          UserTagCategoryLabel.STANDARD.toString(),
          this.userService.userPreferences.defaultEcosystemId,
          this.initialState,
        )

        .subscribe((response: Array<IFilterField>) => {
          const field = response.find((item: IFilterField) => {
            return item.label === this.widget.dataType;
          });
          if (field.field === 'tag') {
            this.tagService
              .getTagsMetadata(this.widget.dataValues)
              .subscribe((response: Array<ITagMetadata>) => {
                const dataValues = response.map((item: ITagMetadata) => ({
                  name: item.tagName,
                  id: item.tagId,
                }));
                if (dataValues) {
                  this.form.controls.dataPoints.setValue(dataValues);
                }
              });
          } else {
            this.form.get('dataPoints').enable();
            const dataValues = this.widget.dataValues.map((item: string) => ({
              name: item,
              id: item,
            }));
            this.form.get('dataPoints').setValue(dataValues);
          }
        });
    }
  }

  private getDataSlices(): void {
    this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategoryLabel.STANDARD.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .pipe(
        map((response: Array<IFilterField>) =>
          response.filter((item: IFilterField) => {
            return item.section !== FieldSections.SEARCH_WITHIN;
          }),
        ),
      )
      .subscribe((response: Array<IFilterField>) => {
        this.dataSlicerDataSource = response;
        this.form.controls.category.setValue(this.widget.dataType);
      });
  }

  private createForm(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, Validators.required),
    );
    this.form.addControl(
      'category',
      new FormControl(
        { value: undefined, disabled: !this.widget.dataType },
        Validators.required,
      ),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl(
        {
          value: undefined,
          disabled: this.widget.dataValues?.length === 0,
        },
        Validators.required,
      ),
    );
  }

  public getField(): string {
    if (this.widget instanceof TagComparison)
      return (this.widget as TagComparison).dataSource[0];
    if (this.widget instanceof TimeSeries)
      return (this.widget as TimeSeries).dataSource[0];
  }

  public onChangeDataSources(tag: ICustomTag): void {
    this.selectedUserCategory = tag.userTagCategory;
    this.widget.dataSource = [this.form.controls.dataSource.value?.id];
    this.widget.dataValues = [];
    this.widget.dataType = undefined;
    this.form.get('category').setValue(undefined);
    this.form.get('dataPoints').setValue(undefined);
    this.form.get('category').enable();
    this.form.get('dataPoints').disable();
    this.getDataSlices();
    this.changeDataSource.emit(this.widget.dataSource);
  }

  public onChangeDataValues(): void {
    this.widget.dataValues = this.form.controls.dataPoints.value?.map(
      (item: ICustomTag) => item.id,
    );
    this.changeDataValue.emit(this.widget.dataValues);
  }

  public onChangeDataSlicer(): void {
    const dataType = this.form.controls.category.value as TagCategory;
    this.form.get('dataPoints').enable();
    this.form.get('dataPoints').setValue(undefined);
    this.widget.dataValues = [];
    this.initialState.resetPagination();
    this.changeDataType.emit(dataType);
    this.widget.dataType = dataType;
  }

  public onDataBoundDataValues(queryFilters: QueryFilters): void {
    this.initialState = queryFilters;
    const field = this.dataSlicerDataSource.find(
      (item: IFilterField) => item.label === this.form.controls.category.value,
    );
    const queryModel = new QueryBuilderModel();
    queryModel.addRule({
      entity: 'tag',
      field: 'tag',
      operator: 'in',
      value: this.form.controls.dataSource.value?.id,
    });
    if (field.type === FieldTypes.FIELD) {
      this.tagService
        .getFieldValues(
          this.initialState,
          queryModel.convertToBackEndQuery(),
          field.field,
          this.userService.userPreferences.defaultEcosystemId,
          this.initialState.page > 1
            ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1]
                .name
            : undefined,
        )
        .subscribe(
          (response: { items: Array<ICustomTag>; totalCount: number }) => {
            this.dataPointsDataSource = response?.items;
          },
        );
    } else {
      this.categoryService
        .getCategoryValues(
          this.initialState.query,
          this.initialState,
          queryModel.convertToBackEndQuery(),
          field.label,
          this.userService.userPreferences.defaultEcosystemId,
          field.type,
          this.initialState.page > 1
            ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1]
                .name
            : undefined,
        )
        .subscribe(
          (response: { items: Array<ICustomTag>; totalCount: number }) => {
            this.dataPointsDataSource = response?.items;
          },
        );
    }
  }
}
