import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CustomOverlayRef,
  CustomOverlayService,
  IconType,
  PillType,
  Sizes,
} from '@intorqa-ui/core';
import { DateFormats } from '@portal/document/enums/document.enum';
import {
  DocumentItem,
  IContentLink,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { Subscription } from 'rxjs';
import {
  ExtensionDataFields,
  ExtensionDataFieldsText,
} from './document-item-detail.enum';

@Component({
  selector: 'itq-document-item-detail',
  templateUrl: './document-item-detail.component.html',
  styleUrls: ['./document-item-detail.component.scss'],
})
export class DocumentItemDetailComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() expanded = true;

  public customOverlay: CustomOverlayRef;
  public showLoader = false;
  public translatedContent: string;
  public translatedTitle: string;
  public code: string;
  public contentLinks: Array<IContentLink> = [];
  public extractedData: Array<any> = [];
  private queryParamsSubscription: Subscription;

  readonly IconType = IconType;
  readonly Sizes = Sizes;
  readonly SegmentScope = SegmentScope;
  readonly PillType = PillType;
  readonly dateFormats = DateFormats;
  readonly ExtensionDataFieldsText = ExtensionDataFieldsText;
  readonly ExtensionDataFields = ExtensionDataFields;

  constructor(
    public customOverlayService: CustomOverlayService,
    private docService: DocumentItemService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.code = params.code;
      },
    );
    this.extractedData = this.getExtractedData();
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public onTranslate(): void {
    this.showLoader = true;
    this.docService
      .detectLanguage(this.document.emitBodyText)
      .then((response) => response.json())
      .then((result: any) => {
        if (result?.data?.detections?.length > 0) {
          this.translate(
            result.data.detections[0][0].language,
            this.document.emitBodyText,
          )
            .then((text: string) => {
              this.translatedContent = text;
              this.showLoader = false;
            })
            .catch(() => {
              this.showLoader = false;
            });
          this.translate(
            result.data.detections[0][0].language,
            this.document.emitHeadline,
          )
            .then((text: string) => {
              this.translatedTitle = text;
              this.showLoader = false;
            })
            .catch(() => {
              this.showLoader = false;
            });
        }
      })
      .catch((error) => console.log('error', error));
  }

  private translate(lang: string, text: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.docService
        .translate(text, lang)
        .then((response) => response.json())
        .then((response: any) => {
          resolve(response.data.translations[0].translatedText);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getContentLinks(): Array<IContentLink> {
    let contentLinks: any;
    if (this.document?.emitExtensionFields?.length > 0) {
      if (this.document.emitSource === 'Discord') {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField.name === 'contentLinks') {
              return extensionField;
            }
          },
        );
        return contentLinks?.data?.map((item: { url: string }) => ({
          href: item.url,
        }));
      } else {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField['content links']?.length > 0) {
              return extensionField;
            }
          },
        );
        if (contentLinks && contentLinks['content links']) {
          let uniqueHrefs: Array<string> = [];
          return contentLinks['content links'].filter((link: IContentLink) => {
            if (link.href?.length > 0) {
              if (!uniqueHrefs.includes(link.href.trim())) {
                uniqueHrefs.push(link.href);
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      }
    }
    return [];
  }

  private getExtractedData(): Array<any> {
    if (this.document?.emitExtensionFields?.length > 0) {
      return this.document?.emitExtensionFields.filter(
        (item: any) =>
          item.name !== 'contentLinks' && item.name !== 'content links',
      );
    }
  }

  getDocumentDate(dateItem: any): Date {
    if (typeof dateItem === 'number') {
      return new Date(dateItem);
    } else if (typeof dateItem === 'string') {
      return new Date(dateItem);
    } else if (dateItem) {
      return dateItem;
    } else {
      return null;
    }
  }
}
