export enum NoteTypes {
  SocialChannels = 'Social Channels',
  PaymentDetails = 'Payment Details',
  TextNote = 'Text Note',
  CodeRepoLink = 'Code Repo Link',
  DomainDetails = 'Domain Details (Whois)',
  ContactDetails = 'Contact Details',
  FileHash = 'File Hash',
  Screenshot = 'Screenshot',
  CodeSnippet = 'Code Snippet',
  VideoLink = 'Video Link',
  BioLink = 'Bio Link',
  Domains = 'Domains',
  Alias = 'Alias',
  ForumProfile = 'Forum Profile',
  Storefront = 'Storefront',
  Gamertag = 'Gamertag',
  ProfileLink = 'Forum Profile',
}

export enum ProfileTypeIcon {
  Cheat = 'gamepad-modern',
  Actor = 'user-secret',
  'Cheat Provider' = 'cart-shopping',
}

export enum ProfileEditAction {
  DETAILS = 'DETAILS',
  METADATA = 'METADATA',
}

export enum ProfileDrildownScope {
  ACTOR = 'Actor',
  CHANNEL = 'Channel',
  TAG = 'Tag',
}

export enum ProfilePathSvg {
  Actor = 'M378.8 339.7l35.26-82.28c3.188-7.422 2.406-15.94-2.031-22.67S400.1 224 392 224h-44.54c2.664-10.29 4.534-20.88 4.534-32c0-9.492-1.19-18.68-3.151-27.58C389.9 155.6 416 142.6 416 128c0-16.08-31.75-30.28-80.31-38.99C323.8 45.15 304.9 0 277.4 0c-10.38 0-19.62 4.5-27.38 10.5c-15.25 11.88-36.75 11.88-52 0C190.3 4.5 181.1 0 170.7 0C143.2 0 124.4 45.16 112.5 88.98C63.83 97.68 32 111.9 32 128c0 14.58 26.1 27.62 67.15 36.42C97.19 173.3 96 182.6 96 192.1C96 203.2 97.86 213.7 100.5 224H56C47.94 224 40.41 228 35.97 234.8S30.75 250 33.94 257.5l35.26 82.28C27.41 371.3 0 420.9 0 477.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 420.9 420.6 371.3 378.8 339.7zM224 272c-34.41 0-63.56-21.96-74.83-52.52C154.4 222.1 160.1 224 166.4 224h12.33c16.46 0 30.29-10.39 35.63-24.99C215.9 194.9 219.6 192 224 192s8.105 2.887 9.615 7.008C238.1 213.6 252.8 224 269.3 224h12.33c6.299 0 11.1-1.857 17.25-4.516C287.6 250 258.4 272 224 272zM48.71 464c4.765-44.31 35.35-77.19 40.66-77.19l16.56 38.64C109.8 434.6 118.7 440 128 440c3.156 0 6.375-.625 9.469-1.938c12.16-5.219 17.81-19.33 12.59-31.52L92.41 272h32.41C139.5 290.2 158.9 304.2 181.5 312.2L208 352l-32 112H48.71zM272 464l-32-112l26.53-39.79C289.1 304.2 308.5 290.2 323.2 272h32.41l-57.66 134.5c-5.219 12.19 .4375 26.3 12.59 31.52C313.6 439.4 316.8 440 320 440c9.312 0 18.16-5.453 22.06-14.55l16.56-38.64c5.776 0 35.94 33.25 40.66 77.19H272z',
  'Cheat Provider' = 'M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM475.6 240L520.6 80H131.1L161.6 240H475.6zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z',
  'Cheat' = 'M639.1 357.2c0-6.198-.4631-12.49-1.421-18.83l-27.5-181.3C603.5 107.4 581.8 32 319.1 32C57.05 32 36.44 106.9 28.83 157.1l-27.37 181.3C.4734 344.8 0 351.1 0 357.4C0 423.9 53.35 480 121.2 480c46.12-.125 88.37-26.5 108.6-68L243.6 384h152.7l13.75 28c20.25 41.5 62.25 67.88 108.4 68C586.5 480 639.1 423.8 639.1 357.2zM591.1 357.2c0 17.99-6.413 35.55-18.26 49.46c-13.66 16.14-33.73 25.38-54.82 25.38c-.1417 0-.2834-.0004-.4252-.0013c-27.75 0-52.75-15.75-65.25-41.13l-18.32-37.05C429.5 342.9 418.4 336 406.2 336H233.7c-12.17 0-23.29 6.907-28.69 17.82l-18.32 37.05c-4.671 9.482-23.54 41.13-65.92 41.13c-21.09 0-41.04-9.357-54.58-25.38c-11.85-13.91-18.26-31.47-18.26-49.46c0-3.839 .292-7.697 .8855-11.54l27.5-181.3C81.2 132.5 104.1 107.5 134.8 100.6C195.8 86.88 257.9 80 319.1 80s124.2 6.875 185.1 20.62c30.75 6.875 53.62 31.88 58.5 63.75l27.5 181.3C591.7 349.5 591.1 353.3 591.1 357.2zM399.1 240c-17.62 0-31.1 14.38-31.1 32s14.37 32 31.1 32s31.1-14.38 31.1-32S417.6 240 399.1 240zM247.1 200l-31.96-.011L215.1 168c0-13.2-10.78-24-23.98-24C178.8 144 167.1 154.8 167.1 168l.0367 31.99L135.1 200c-13.2 0-23.98 10.8-23.98 24c0 13.2 10.77 24 23.98 24l32.04-.0098L167.1 280c0 13.2 10.82 24 24.02 24c13.2 0 23.98-10.8 23.98-24l.0368-32.01L247.1 248c13.2 0 24.02-10.8 24.02-24C271.1 210.8 261.2 200 247.1 200zM463.1 144c-17.62 0-31.1 14.38-31.1 32s14.37 32 31.1 32c17.62 0 31.1-14.38 31.1-32S481.6 144 463.1 144z',
}

export enum ViewMode {
  DASHBOARD = 'DASHBOARD',
  RIPL = 'RIPL',
  RELATIONSHIPS = 'RELATIONSHIPS',
  INTEL = 'INTEL',
  TABLE = 'TABLE',
  RELATIONSHIPS_GRAPH = 'RELATIONSHIPS_GRAPH',
}
