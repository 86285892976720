import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  AvatarComponent,
  ComponentType,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  Event,
  EventTeams,
  FAwesomeModule,
  GetUsernameInitialsPipe,
  IEventData,
  IEventType,
  PillType,
  QueryFilters,
  SearchFieldType,
  SharedService,
  Sizes,
  TableColumn,
  VirtualScrollService,
  VirtualTableComponent,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import {
  FormatEventsPipe,
  HasEventsEnabledPipe,
} from '@portal/shared/pipes/events.pipe';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { forkJoin, Subscription } from 'rxjs';
import { EventWizardComponent } from '../event-wizard/event-wizard.component';
import { Router } from '@angular/router';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'itq-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    FormatEventsPipe,
    MatTooltipModule,
    AvatarComponent,
    GetUsernameInitialsPipe,
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    VirtualTableComponent,
  ],
  providers: [HasEventsEnabledPipe],
})
export class EventsComponent implements OnInit {
  private _dataSource: IEventData;

  get dataSource(): IEventData {
    return this._dataSource;
  }

  set dataSource(value: IEventData) {
    if (this.initialState.page === 1) {
      this._dataSource = value;
    } else {
      this._dataSource.items = [...this._dataSource.items, ...value.items];
    }
  }
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  public tableColumns: TableColumn[];
  public typesDataSource: Array<IEventType>;
  private users: Array<{ name: string; value: string }>;
  private subscriptions = new Subscription();
  public showFilters = false;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly EventTeams = EventTeams;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;

  @ViewChild('teamTemplate') teamTemplate: TemplateRef<unknown>;
  @ViewChild('updatedByTemplate') updatedByTemplate: TemplateRef<unknown>;

  constructor(
    readonly customOverlayService: CustomOverlayService,
    readonly sharedService: SharedService,
    readonly virtualScrollService: VirtualScrollService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    readonly snackBar: MatSnackBar,
    private readonly hasEventsEnabledPipe: HasEventsEnabledPipe,
    private readonly router: Router,
    readonly ecosystemService: EcosystemsService,
  ) {}

  ngOnInit() {
    this.onDataBound();
    this.bindChangeEcosystemSubscription();
  }

  ngAfterViewInit(): void {
    forkJoin([
      this.eventsService.getUsers(),
      this.eventsService.getEventTypes(
        this.userService.userPreferences.defaultEcosystemId,
      ),
    ]).subscribe((response: [Array<string>, Array<IEventType>]) => {
      this.users = response[0].map((item: string) => ({
        name: item,
        value: item,
      }));
      this.typesDataSource = response[1];
      this.initColumns();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }),
    );
  }

  public initColumns(): void {
    this.tableColumns = [
      {
        name: 'Created',
        dataKey: 'createdDate',
        isSortable: true,
        width: '100px',
        isFilterable: true,
        searchField: 'createdDate',
        searchFieldType: SearchFieldType.DATE,
        searchComponent: ComponentType.DATE,
      },
      {
        name: 'By',
        dataKey: 'updatedBy',
        isSortable: true,
        position: 'center',
        width: '180px',
        customRender: true,
        searchField: 'updatedBy',
        isFilterable: true,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.DROPDOWN,
        template: this.updatedByTemplate,
        dataSource: this.users,
      },
      {
        name: 'Name',
        dataKey: 'name',
        isSortable: true,
        width: 'fit',
        isFilterable: true,
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Team',
        dataKey: 'team',
        isSortable: true,
        position: 'center',
        width: '200px',
        customRender: true,
        template: this.teamTemplate,
        searchField: 'team',
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.DROPDOWN,
        isFilterable: true,
        dataSource: [
          { value: EventTeams.CHEATER_TEAM, name: 'Cheater Team' },
          { value: EventTeams.SECURITY_TEAM, name: 'Security Team' },
        ],
        dataBound: () => {
          this.onSearch();
        },
      },
      {
        name: 'Type',
        dataKey: 'typeName',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.DROPDOWN,
        searchField: 'typeId',
        dataSource: this.typesDataSource?.map((type: IEventType) => ({
          value: type.id,
          name: type.name,
        })),
        dataBound: () => {
          this.onSearch();
        },
      },
      {
        name: 'Date',
        dataKey: 'date',
        isSortable: true,
        width: '150px',
        isFilterable: true,
        searchField: 'date',
        searchFieldType: SearchFieldType.DATE,
        searchComponent: ComponentType.DATE,
      },
      {
        name: 'Details',
        dataKey: 'description',
        isSortable: true,
        isFilterable: true,
        searchField: 'description',
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: '',
        dataKey: 'settings',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '47px',
      },
    ];
  }

  public onSearch(): void {
    this.initialState.resetPagination();
    this.onDataBound();
  }

  public onDataBound(): void {
    const isEventsEnabled = this.hasEventsEnabledPipe.transform(
      this.userService.userPreferences.defaultEcosystemId,
    );
    if (isEventsEnabled) {
      this.sharedService.loader$.next(true);
      this.eventsService
        .getEvents(
          this.initialState,
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IEventData) => {
          this.dataSource = response;
          this.sharedService.loader$.next(false);
        });
    } else {
      this.router.navigate(['/boards']);
    }
  }

  public onEdit(item: Event): void {
    const event = this.eventsService.findEventById(item.id);
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
          inputs: {
            event,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.snackBar.open('Your event has been updated!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.initialState.resetPagination();
        this.onDataBound();
      }
    });
  }

  public onDelete(item: Event): void {
    this.eventsService.delete(item.id).subscribe(() => {
      this.snackBar.open('Your event has been deleted!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.initialState.resetPagination();
      this.onDataBound();
    });
  }

  public onCreateEvent(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.snackBar.open('Your event has been created!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.initialState.resetPagination();
        this.onDataBound();
      }
    });
  }
}
