<form class="flex flex-col w-full" [formGroup]="form">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'bell']" [size]="'2x'"></fa-icon>
      <h1 class="text-28 font-bold pl-4">Alerts</h1>
    </div>
    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <fa-icon [icon]="['far', 'refresh']"></fa-icon>
        <span class="whitespace-nowrap">REFRESH</span>
      </itq-mat-button>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <itq-sidebar class="flex-none!" [width]="300">
      <div
        class="flex justify-between px-7 py-4 border-border border-b items-center h-[69px] bg-white"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ notificationsDataSource?.totalCount | number }}</itq-pill
        >
      </div>
      <div class="flex flex-col px-7 py-4">
        <div class="mb-7 flex flex-col">
          <h2 class="flex font-bold pb-2 text-xl">Search</h2>
          <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
          <itq-input
            [formControlName]="'query'"
            [type]="'text'"
            [placeholder]="'Search alerts...'"
            (search)="onSearch()"
            (clear)="onSearch()"
          ></itq-input>
        </div>
        <h2 class="flex font-bold pb-4 text-xl">Filters</h2>
        <div class="flex mb-4 flex-col">
          <span class="mb-1 text-13 font-medium">TYPE</span>
          <itq-dropdown
            [dataSource]="alertTypesDataSource"
            formControlName="alertType"
            [dataFields]="{ name: 'label', value: 'id' }"
            (changeValue)="onSearch()"
            (clear)="onSearch()"
          ></itq-dropdown>
        </div>
        <div class="flex mb-4 flex-col">
          <span class="mb-1 text-13 font-medium">PRIORITY</span>
          <itq-dropdown
            [dataSource]="priorityDataSource"
            formControlName="priority"
            [dataFields]="{ name: 'name', value: 'value' }"
            (changeValue)="onSearch()"
            (clear)="onSearch()"
          ></itq-dropdown>
        </div>
        <div class="flex mb-4 flex-col">
          <span class="mb-1 text-13 font-medium">DATE</span>

          <itq-date-range
            class="w-full"
            [dates]="this.initialState?.where"
            (search)="onChangeDates($event)"
          ></itq-date-range>
        </div>
      </div>
    </itq-sidebar>
    <div class="flex w-full relative">
      <div class="flex flex-col flex-1 pt-4 min-w-[500px]">
        <div class="flex mx-7 mb-4 space-x-7">
          <itq-button-thumbnail
            [formControlName]="'showAll'"
            [dataSource]="buttonDataSource"
            (change)="onSearch()"
          ></itq-button-thumbnail>
          @if (unreadCount > 0) {
            <itq-mat-button
              [type]="'button'"
              [materialStyle]="'stroked'"
              [padding]="Sizes.SM"
              (clickEvent)="onMarkAllRead()"
            >
              <div class="flex items-center">
                <fa-icon [icon]="['far', 'check']"></fa-icon>MARK ALL AS READ
              </div>
            </itq-mat-button>
          }
        </div>
        <div class="flex overflow-hidden bg-background border-t border-border">
          <itq-notifications-timeline
            class="flex-1 min-w-[500px]"
            [raisedAlertId]="raisedAlertId"
            [initialState]="initialState"
            [dataSource]="notificationsDataSource"
            (drilldown)="onDrilldown($event)"
            (dataBound)="onDataBound($event)"
          ></itq-notifications-timeline>
        </div>
      </div>

      @if (raisedAlertId && matchesDataSource?.result) {
        <div class="flex relative flex-1 border-border border-l min-w-[500px]">
          <itq-documents-feed
            class="h-full flex flex-col overflow-hidden"
            [data]="matchesDataSource"
            [initialState]="initialState"
            [query]="initialState?.query"
            (shareEmail)="onShareEmail($event)"
            (createProfile)="onCreateProfile($event)"
            (scrollEvent)="onDataBoundOtherMatches($event)"
            (drilldown)="onDrilldown($event)"
          ></itq-documents-feed>
        </div>
      }
    </div>
  </div>
</form>
<ng-template #unreadTemplate> UNREAD ({{ unreadCount || 0 }}) </ng-template>
@if (showLoader) {
  <itq-loader></itq-loader>
}
