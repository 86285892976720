<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon
        [icon]="[
          'far',
          action === Actions.EDIT
            ? 'pencil'
            : action === Actions.CLONE
              ? 'clone'
              : 'plus'
        ]"
        [size]="'2x'"
      ></fa-icon>
      <h1 class="text-28 font-bold pl-4">
        {{
          action === Actions.EDIT
            ? 'Edit tag'
            : action === Actions.CLONE
              ? 'Clone tag'
              : 'Create tag'
        }}
      </h1>
      <itq-pill
        class="ml-7"
        [class]="
          'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over uppercase h-[36px]'
        "
        [type]="PillType.CUSTOM"
        >{{ tag.userTagCategory }}
      </itq-pill>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
    ></itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex w-full overflow-auto bg-background rounded-md h-full">
      <div class="flex flex-col border-r border-border">
        <itq-tag-wizard-navigation
          class="flex w-[250px] min-w-[250px] flex-1"
          [active]="active"
          [completed]="completed"
          [form]="form"
          (change)="onNavigationChange($event)"
        ></itq-tag-wizard-navigation>
        <div class="flex justify-center">
          <mat-slide-toggle
            [formControlName]="'sharedTag'"
            class="px-4 py-2 rounded-full bg-primary-over border border-primary mb-4"
            [checked]="tag?.sharedTag"
          >
            <span class="pl-2">Shared tag</span>
          </mat-slide-toggle>
        </div>
      </div>
      <div class="flex flex-col flex-1">
        <div
          [ngClass]="{
            'flex-col flex-1 h-full': true,
            flex: active === TagWizardNavItem.FILTERS,
            hidden: active !== TagWizardNavItem.FILTERS
          }"
        >
          @if (fields?.length > 0) {
            @if (this.query) {
              <itq-tag-wizard-filters
                class="flex-1 flex h-full"
                [form]="form"
                [initialState]="initialState"
                [fields]="fields"
                [queryModel]="query"
                [tag]="tag"
              ></itq-tag-wizard-filters>
            }
          }
          <div
            [ngClass]="{
              'flex bg-white p-4 border-t border-border': true,
              'justify-between': action === Actions.CREATE && !this.queryModel,
              'justify-end':
                action !== Actions.CREATE ||
                (action === Actions.CREATE && this.queryModel)
            }"
          >
            @if (this.action === Actions.CREATE && !this.queryModel) {
              <itq-mat-button
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onLoadTypeSelection()"
              >
                <mat-icon>chevron_left</mat-icon>
                PREV
              </itq-mat-button>
            }
            <itq-mat-button
              [materialStyle]="'flat'"
              [style]="'primary'"
              [type]="'button'"
              (clickEvent)="onNavigationNotifications()"
            >
              NEXT
              <mat-icon>chevron_right</mat-icon>
            </itq-mat-button>
          </div>
        </div>
        @if (this.keycloakService.isUserInRole('saas-alerts')) {
          <div
            [ngClass]="{
              'flex-col flex-1 h-full': true,
              flex: active === TagWizardNavItem.NOTIFICATIONS,
              hidden: active !== TagWizardNavItem.NOTIFICATIONS
            }"
          >
            <itq-tag-wizard-alerts
              class="flex-1 flex"
              [action]="action"
              [form]="form"
              [tag]="tag"
            ></itq-tag-wizard-alerts>
            <div
              class="flex bg-white p-4 justify-between border-t border-border"
            >
              <itq-mat-button
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onNavigationFilters()"
              >
                <mat-icon>chevron_left</mat-icon>
                PREV
              </itq-mat-button>
              <itq-mat-button
                [disabled]="
                  !form?.get('filters')?.valid || !form?.get('alerts')?.valid
                "
                [materialStyle]="'flat'"
                [style]="'primary'"
                [type]="'button'"
                (clickEvent)="onNavigationSave()"
              >
                NEXT
                <mat-icon>chevron_right</mat-icon>
              </itq-mat-button>
            </div>
          </div>
        }
        <div
          [ngClass]="{
            'flex-col flex-1 h-full': true,
            flex: active === TagWizardNavItem.SAVE,
            hidden: active !== TagWizardNavItem.SAVE
          }"
        >
          <itq-tag-wizard-save
            class="flex-1 flex"
            [form]="form"
            [tag]="tag"
            [boardIds]="boardIds"
          ></itq-tag-wizard-save>
          <div class="flex bg-white p-4 justify-between border-t border-border">
            <itq-mat-button
              [materialStyle]="'flat'"
              [style]="'primary'"
              [type]="'button'"
              (clickEvent)="onNavigationNotifications()"
            >
              <mat-icon>chevron_left</mat-icon>
              PREV
            </itq-mat-button>
            <itq-mat-button
              [disabled]="!form.valid"
              [materialStyle]="'flat'"
              [style]="'primary'"
              [type]="'button'"
              (clickEvent)="onSubmit()"
            >
              {{
                this.action === Actions.EDIT
                  ? 'UPDATE'
                  : this.action === Actions.CLONE
                    ? 'CLONE'
                    : 'FINISH'
              }}
            </itq-mat-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader> </itq-loader>
}
