import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { QueryFilters } from '../../../models/query-filters';
import { PillType } from '../../pill/pill.enum';

@Component({
  selector: 'itq-multiple-dropdown-results',
  templateUrl: './multiple-dropdown-results.component.html',
  styleUrls: ['./multiple-dropdown-results.component.scss'],
})
export class MultipleDropdownResultsComponent implements OnInit, OnChanges {
  @Input() dataSource: Array<any>;
  @Input() selections: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() query: string;
  @Input() initialState: QueryFilters;

  @Output() changeValue = new EventEmitter<Array<any>>();
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @ViewChild('listSearch', { read: ElementRef }) listSearch: ElementRef;

  public results: Array<any>;
  public showLoader: boolean;

  readonly PillType = PillType;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue) {
      this.results = cloneDeep(this.dataSource);
      this.showLoader = false;
      if (this.initialState?.page === 1 && this.listSearch) {
        this.listSearch.nativeElement.scrollTop = 0;
      }
    }
  }

  public onChangeValue(): void {
    this.changeValue.emit(this.selections);
  }

  public onDataBound(): void {
    this.initialState.query = this.query;
    if (this.initialState) {
      this.showLoader = true;
      this.dataBound.emit(this.initialState);
    } else {
      const value = this.query?.toLowerCase().trim();
      this.results = this.results.filter((elem: any) => {
        return elem[this.dataFields.name].toLowerCase().trim().includes(value);
      });
    }
  }

  public isChecked(item: any): boolean {
    return this.selections?.includes(item[this.dataFields.value])
      ? true
      : false;
  }

  public onScroll(): void {
    this.initialState.page++;
    this.onDataBound();
  }
}
