import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Sizes } from '../../enums/shared.enum';
import { QueryFilters } from '../../models/query-filters';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { IOpenDirection, OverlayService } from '@intorqa-ui/core';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'itq-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent,
    },
  ],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @ViewChild('button', { static: true }) button: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('templateBody') templateBody: TemplateRef<unknown>;

  @Input() disabled = false;
  @Input() width: string;
  @Input() emptyDataSourceMessage = 'No results found';
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() placeholder = 'Please select an item...';
  @Input() icon: string;
  @Input() color: string;
  @Input() materialStyle = 'stroked';
  @Input() padding = Sizes['X-SM'];
  @Input() invalid: boolean;
  @Input() clearSelection = true;
  @Input() initialState: QueryFilters;

  @Output() changeValue = new EventEmitter<any>();
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() open = new EventEmitter<QueryFilters>();
  @Output() clear = new EventEmitter<void>();

  private overlayRef: OverlayRef;
  private touched = false;
  public showLoader = false;
  public isOpen = false;
  public selection: any;

  readonly Sizes = Sizes;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (
      this.isOpen &&
      !this.button.nativeElement.contains(event.target) &&
      !this.menu.nativeElement.contains(event.target)
    ) {
      this.closeDropdown();
    }
  }

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    readonly cdr: ChangeDetectorRef,
  ) {}

  onChange = (items: string) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'resize', () => {
      if (this.isOpen) {
        this.adjustDropdownPosition();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
      if (this.initialState && this.isOpen && !this.overlayRef) {
        this.openDropdown();
      }
    }
  }

  public onOpen(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      if (this.initialState) {
        this.showLoader = true;
        this.dataBound.emit(this.initialState);
      } else {
        this.openDropdown();
      }
    } else {
      this.closeDropdown();
    }
  }

  openDropdown(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      return;
    }

    const buttonRect = this.button.nativeElement.getBoundingClientRect();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.button)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(this.templateBody, this.viewContainerRef);
    this.overlayRef.attach(portal);
    this.cdr.detectChanges();
    this.renderer.setStyle(
      this.menu.nativeElement,
      'minWidth',
      `${buttonRect.width}px`,
    );
  }

  closeDropdown(): void {
    if (this.overlayRef) {
      this.isOpen = false;
      this.overlayRef.detach();
    }
  }

  adjustDropdownPosition(): void {
    if (this.overlayRef) {
      const buttonRect = this.button.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.menu.nativeElement,
        'minWidth',
        `${buttonRect.width}px`,
      );
      this.overlayRef.updatePosition();
    }
  }

  public onDataBound(params: QueryFilters): void {
    if (this.initialState) {
      this.initialState = params;
      this.dataBound.emit(this.initialState);
    }
  }

  public onChangeValue(value: any): void {
    this.isOpen = false;
    this.selection = value;
    this.onChange(this.selection);
    this.changeValue.emit(value);
  }

  public onClear(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.selection = undefined;
    this.onChange(this.selection);
    this.initialState = new QueryFilters(
      30,
      1,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    this.clear.emit();
    this.onTouched(true);
  }
}
