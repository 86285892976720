<ng-container *ngIf="type === PillType.CUSTOM">
  <div
    class="content whitespace-nowrap"
    [ngClass]="[class]"
    [matTooltip]="item?.tooltip || undefined"
  >
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </div>
</ng-container>

<ng-container *ngIf="type === PillType.BUTTON">
  <itq-mat-button
    [ngClass]="[class]"
    [padding]="padding"
    [disabled]="disabled"
    [materialStyle]="'flat'"
    [style]="color"
    [size]="size"
    (clickEvent)="onClick($event)"
  >
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </itq-mat-button>
</ng-container>
<ng-template #contentTpl><ng-content></ng-content></ng-template>
