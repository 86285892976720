import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getHiddenSelections',
})
export class GetHiddenSelectionsPipe implements PipeTransform {
  constructor() {}

  transform(
    selections: Array<any>,
    dataFields: { name: string; value: string },
  ): string {
    let htmlString = "<div class='flex flex-col'>";
    for (let i = 0; i < selections.length; i++) {
      htmlString += `<div>${
        selections[i][dataFields.name] || selections[i]
      }</div>`;
    }
    htmlString += '</div>';
    return htmlString;
  }
}
