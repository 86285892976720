import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  Event,
  EventTeams,
  FAwesomeModule,
  IEventType,
  Sizes,
  TextAreaComponent,
} from '@intorqa-ui/core';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-event-wizard',
  templateUrl: './event-wizard.component.html',
  styleUrls: ['./event-wizard.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FAwesomeModule,
    MatFormFieldModule,
    CommonModule,
    TextAreaComponent,
  ],
})
export class EventWizardComponent implements OnInit {
  @Input() event: Event;

  public form: FormGroup;
  public typeDataSource: Array<IEventType>;
  public showLoader = false;
  public subscription = new Subscription();
  public teamsDataSource = [
    { id: EventTeams.CHEATER_TEAM, name: 'Cheater Team' },
    { id: EventTeams.SECURITY_TEAM, name: 'Security Team' },
  ];

  readonly Sizes = Sizes;

  constructor(
    readonly customOverlayRef: CustomOverlayRef,
    readonly eventsService: EventsService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.event?.id) {
      this.onGetEventTypes();
    }
  }

  public onGetEventTypes(): void {
    this.eventsService
      .getEventTypes(
        this.userService.userPreferences.defaultEcosystemId,
        this.form.get('team').value,
      )
      .subscribe((response: Array<IEventType>) => {
        this.form.get('type').setValue(this.event?.typeId);
        this.typeDataSource = response;
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      team: new FormControl(this.event?.team, [Validators.required]),
      type: new FormControl(
        { value: this.event?.typeId, disabled: !this.event?.team },
        [Validators.required],
      ),
      name: new FormControl(this.event?.name, [Validators.required]),
      date: new FormControl(this.event?.date, [Validators.required]),
      description: new FormControl(this.event?.description),
    });
  }
  public onTeamChange(): void {
    this.onGetEventTypes();
    this.form.get('type').enable();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.event?.id) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onSave(): void {
    this.showLoader = true;
    const payload = {
      name: this.form.get('name').value,
      typeId: this.form.get('type').value,
      date: this.form.get('date').value,
      description: this.form.get('description').value,
      team: this.form.get('team').value,
      ecosystemId: this.userService.userPreferences.defaultEcosystemId,
    };
    this.eventsService.create(payload).subscribe(() => {
      this.showLoader = false;
      this.customOverlayRef.close({ refresh: true });
    });
  }

  private onEdit(): void {
    this.showLoader = true;
    const payload = this.getChangedProperties();
    this.eventsService.update(payload, this.event.id).subscribe(() => {
      this.showLoader = false;
      this.customOverlayRef.close({ refresh: true });
    });
  }

  private getChangedProperties(): { [key: string]: any } {
    let changedProperties = {};

    Object.keys(this.form.controls).forEach((name: string) => {
      const currentControl = this.form.controls[name];

      if (!currentControl.pristine) {
        changedProperties = {
          ...changedProperties,
          [name]: currentControl.value,
        };
      }
    });
    return changedProperties;
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
