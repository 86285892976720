<div class="flex flex-col" (click)="onToggle($event)">
  <div class="flex justify-between w-full items-center">
    <div class="flex">
      <div class="flex items-center">
        <div
          class="rounded-2xl p-2 shadow-none font-semibold h-6 flex items-center"
          [ngStyle]="{
            backgroundColor: PriorityColor[this.notification.priority]
          }"
        >
          <itq-svg class="w-4 h-4 fill-primary" [icon]="'logo'"></itq-svg>
          <span class="pl-2 text-xs">{{
            AlertTypesLabel[notification.alertTypeName]
          }}</span>
        </div>
      </div>
      <div class="flex flex-col px-4 text-xs flex-1">
        <div class="font-semibold text-sm">{{ notification.headline }}</div>
        <div class="flex flex-col text-metadata">
          <span>
            Raised on {{ notification.createdDate | date: 'dd/MM/YYYY hh:mm' }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex">
      @if (isTextTruncated) {
        <itq-mat-button
          [type]="'button'"
          [size]="Sizes.SM"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          [matTooltip]="
            showDocument ? 'Open notification' : 'Close notification'
          "
          (mousedown)="
            $event.stopImmediatePropagation(); showDocument = !showDocument
          "
        >
          <fa-icon
            [icon]="['far', showDocument ? 'file-check' : 'file']"
          ></fa-icon>
        </itq-mat-button>
      }
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [size]="Sizes.SM"
        [padding]="Sizes['X-SM']"
        [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'"
        (clickEvent)="onToggleRead()"
        (click)="$event.stopImmediatePropagation()"
      >
        <fa-icon
          [icon]="['far', notification.read ? 'check' : 'eye']"
        ></fa-icon>
      </itq-mat-button>
    </div>
  </div>

  @if (notification?.rawMessage) {
    <div
      #textContainer
      [ngClass]="{
        'line-clamp-3': !showDocument,
        'text-xs pt-4 body__message': true
      }"
      [innerHTML]="
        showDocument ? notification?.message : notification.rawMessage
      "
    ></div>
  }
</div>
