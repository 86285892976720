import { NgModule } from '@angular/core';
import {
  AngularModule,
  AvatarComponent,
  ConvertFromEpochMillisPipe,
  CoreModule,
  DateRangeComponent,
  FAwesomeModule,
  GetUsernameInitialsPipe,
  HeadLineSimpleComponent,
  MaterialModule,
  VirtualTableComponent,
} from '@intorqa-ui/core';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '../../../shared/shared.module';

import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { LinkTagsComponent } from './link-tags/link-tags.component';
import { FilterTagsPipe } from './link-tags/link-tags.pipe';
import { ProfilesConnectionsComponent } from './profiles-connections/profiles-connections.component';
import { GetDistinctTypesCountPipe } from './profiles-connections/profiles-connections.pipe';
import { ConnectionsNetworkComponent } from './profiles-explore/components/connections-network/connections-network.component';
import { ProfilesExploreComponent } from './profiles-explore/profiles-explore.component';
import {
  GetMetadataItemByGroupNamePipe,
  GetProfileTypeLabelPipe,
  GroupMetadataPipe,
  ParseMetadataLabelPipe,
} from './profiles-explore/profiles-explore.pipe';
import { ProfilesLinksComponent } from './profiles-links/profiles-links.component';
import { ProfilesNotesComponent } from './profiles-notes/profiles-notes.component';
import { ProfilesTagsComponent } from './profiles-tags/profiles-tags.component';
import { ProfileWizardDetailsComponent } from './profiles-wizard/components/profile-wizard-details/profile-wizard-details.component';
import { MetadataContainerComponent } from './profiles-wizard/components/profiles-wizard-metadata/metadata-container/metadata-container/metadata-container.component';
import {
  FormatMetadataValuesPipe,
  GetMetadataComponentDisabledPipe,
} from './profiles-wizard/components/profiles-wizard-metadata/metadata-container/metadata-container/metadata-container.pipe';
import { ProfilesWizardMetadataComponent } from './profiles-wizard/components/profiles-wizard-metadata/profiles-wizard-metadata.component';
import { IsOptionalPipe } from './profiles-wizard/components/profiles-wizard-metadata/profiles-wizard-metadata.pipe';
import { ProfilesWizardComponent } from './profiles-wizard/profiles-wizard.component';
import { ExploreMetadataComponent } from './profiles-explore/components/explore-metadata/explore-metadata.component';
import { GetNetworkGraphDataPipe } from './profiles-explore/components/connections-network/connections-network.pipe';
import { AddConnectionsComponent } from '../../../shared/components/add-connections/add-connections.component';
import {
  FilterProfilesPipe,
  FilterDuplicatesPipe,
} from '../../../shared/components/add-connections/add-connections.pipe';
import { TypeAliasComponent } from '../../../shared/components/add-notes/type-alias/type-alias.component';
import { TypeBioLinkComponent } from '../../../shared/components/add-notes/type-bio-link/type-bio-link.component';
import { TypeCodeSnippetComponent } from '../../../shared/components/add-notes/type-code-snippet/type-code-snippet.component';
import { TypeContactDetailsComponent } from '../../../shared/components/add-notes/type-contact-details/type-contact-details.component';
import { TypeDomainDetailsComponent } from '../../../shared/components/add-notes/type-domain-details/type-domain-details.component';
import { TypeFileHashComponent } from '../../../shared/components/add-notes/type-file-hash/type-file-hash.component';
import { TypeForumProfileComponent } from '../../../shared/components/add-notes/type-forum-profile/type-forum-profile.component';
import { TypeGamerTagComponent } from '../../../shared/components/add-notes/type-gamer-tag/type-gamer-tag.component';
import { TypePaymentDetailsComponent } from '../../../shared/components/add-notes/type-payment-details/type-payment-details.component';
import { TypeRepoLinkComponent } from '../../../shared/components/add-notes/type-repo-link/type-repo-link.component';
import { TypeScreenshotComponent } from '../../../shared/components/add-notes/type-screenshot/type-screenshot.component';
import { TypeSocialChannelsComponent } from '../../../shared/components/add-notes/type-social-channels/type-social-channels.component';
import { TypeStoreFrontComponent } from '../../../shared/components/add-notes/type-store-front/type-store-front.component';
import { TypeTextNoteComponent } from '../../../shared/components/add-notes/type-text-note/type-text-note.component';
import { TypeVideoLinkComponent } from '../../../shared/components/add-notes/type-video-link/type-video-link.component';
import { ViewNotesComponent } from './view-notes/view-notes.component';
import { ViewTextNoteComponent } from './view-notes/components/view-text-note/view-text-note.component';
import { GetNoteValuePipe } from './view-notes/view-notes.pipe';
import { PostNotesComponent } from './post-notes/post-notes.component';
import { ViewGamerTagComponent } from './view-notes/components/view-gamer-tag/view-gamer-tag.component';
import { ViewSocialChannelsComponent } from './view-notes/components/view-social-channels/view-social-channels.component';
import { ViewRepoLinkComponent } from './view-notes/components/view-repo-link/view-repo-link.component';
import { ViewContactDetailsComponent } from './view-notes/components/view-contact-details/view-contact-details.component';
import { ViewDomainDetailsComponent } from './view-notes/components/view-domain-details/view-domain-details.component';
import { ViewCodeSnippetComponent } from './view-notes/components/view-code-snippet/view-code-snippet.component';
import { ViewFileHashComponent } from './view-notes/components/view-file-hash/view-file-hash.component';
import { ViewScreenshotComponent } from './view-notes/components/view-screenshot/view-screenshot.component';
import { ViewVideoLinkComponent } from './view-notes/components/view-video-link/view-video-link.component';
import { ViewBioLinkComponent } from './view-notes/components/view-bio-link/view-bio-link.component';
import { ViewForumProfileComponent } from './view-notes/components/view-forum-profile/view-forum-profile.component';
import { ViewStoreFrontComponent } from './view-notes/components/view-store-front/view-store-front.component';
import { ViewPaymentDetailsComponent } from './view-notes/components/view-payment-details/view-payment-details.component';
import { ViewAliasComponent } from './view-notes/components/view-alias/view-alias.component';
import { RenderSingleMetadataPipe } from './profiles-explore/components/explore-metadata/explore-metadata.pipe';
import { TransformNotesPipe } from './profiles-notes/profiles-notes.pipe';
import {
  GetMetadataFieldPipe,
  GetTrendIconColor,
  GetTrendIconPipe,
  TransformMetadataValuesPipe,
} from '@portal/profiles/pipes/vendors.pipe';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ProfilesLinksWizardComponent } from './profiles-links-wizard/profiles-links-wizard.component';

@NgModule({
  imports: [
    FAwesomeModule,
    MaterialModule,
    AngularModule,
    CoreModule,
    SharedModule,
    HeadLineSimpleComponent,
    DateRangeComponent,
    GetUsernameInitialsPipe,
    ConvertFromEpochMillisPipe,
    QuillModule.forRoot({
      customOptions: [
        {
          import: 'formats/font',
          whitelist: [
            'mirza',
            'roboto',
            'aref',
            'serif',
            'sansserif',
            'monospace',
          ],
        },
      ],
    }),
    TransformNotesPipe,
    AvatarComponent,
    ChartComponent,
    GetTrendIconColor,
    GetTrendIconPipe,
    TransformMetadataValuesPipe,
    DocumentsFeedComponent,
    VirtualTableComponent,
    ProfilesLinksWizardComponent,
    ProfilesLinksComponent,
  ],
  declarations: [
    ProfilesWizardComponent,
    ProfilesConnectionsComponent,
    ProfileWizardDetailsComponent,
    ProfilesWizardMetadataComponent,
    MetadataContainerComponent,
    ProfilesExploreComponent,
    ProfilesNotesComponent,
    ConnectionsNetworkComponent,
    FormatMetadataValuesPipe,
    GetDistinctTypesCountPipe,
    GetProfileTypeLabelPipe,
    AddConnectionsComponent,
    TypeTextNoteComponent,
    ProfilesTagsComponent,
    FilterProfilesPipe,
    EditProfileComponent,
    FilterDuplicatesPipe,
    IsOptionalPipe,
    TypePaymentDetailsComponent,
    TypeSocialChannelsComponent,
    TypeGamerTagComponent,
    TypeRepoLinkComponent,
    TypeContactDetailsComponent,
    TypeDomainDetailsComponent,
    TypeCodeSnippetComponent,
    TypeFileHashComponent,
    TypeScreenshotComponent,
    TypeVideoLinkComponent,
    TypeBioLinkComponent,
    TypeForumProfileComponent,
    TypeAliasComponent,
    TypeStoreFrontComponent,
    GetMetadataFieldPipe,
    GroupMetadataPipe,
    GetMetadataComponentDisabledPipe,
    GetMetadataItemByGroupNamePipe,
    ParseMetadataLabelPipe,
    ExploreMetadataComponent,
    GetNetworkGraphDataPipe,
    ViewNotesComponent,
    ViewTextNoteComponent,
    GetNoteValuePipe,
    PostNotesComponent,
    ViewGamerTagComponent,
    ViewSocialChannelsComponent,
    ViewRepoLinkComponent,
    ViewContactDetailsComponent,
    ViewDomainDetailsComponent,
    ViewCodeSnippetComponent,
    ViewFileHashComponent,
    ViewScreenshotComponent,
    ViewVideoLinkComponent,
    ViewBioLinkComponent,
    ViewForumProfileComponent,
    ViewStoreFrontComponent,
    ViewPaymentDetailsComponent,
    ViewAliasComponent,
    RenderSingleMetadataPipe,
  ],
  exports: [],
  providers: [GroupMetadataPipe, GetMetadataFieldPipe, GetUsernameInitialsPipe],
})
export class WidgetSettingsProfilesModule {}
