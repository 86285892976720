import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Pipe({
  name: 'dataPropertyGetter',
  standalone: true,
})
export class DataPropertyGetterPipe implements PipeTransform {
  transform(object: any, keyName: string): unknown {
    return object[keyName];
  }
}

@Pipe({
  name: 'filterByQuery',
  standalone: true,
})
export class FilterByQueryPipe implements PipeTransform {
  transform(
    tableDataSource: MatTableDataSource<any>,
    query: string,
    activeColumn: string,
  ): MatTableDataSource<any> {
    tableDataSource.filterPredicate = (data: any, filter: string) => {
      return data[activeColumn]
        ?.trim()
        .toLowerCase()
        .toString()
        .includes(filter);
    };
    tableDataSource.filter = query?.trim().toLowerCase();
    return tableDataSource;
  }
}
