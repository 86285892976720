import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { ValueFiltersComponent } from '@portal/tags/components/value-filters/value-filters.component';
import {
  ICustomTag,
  IFilterField,
} from '@portal/tags/interfaces/tag.interface';

@Component({
  selector: 'itq-value-picker',
  templateUrl: './value-picker.component.html',
  styleUrl: './value-picker.component.scss',
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    ValueFiltersComponent,
    FormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ValuePickerComponent,
    },
  ],
})
export class ValuePickerComponent {
  @Input() dates: IPresetQuery;
  @Input() field: string;
  @Input() queryModel = new QueryBuilderModel();

  @Output() valueChange = new EventEmitter<void>();

  private touched = false;
  private disabled = false;
  public selections: Array<ICustomTag> = [];
  public fieldDataSource: Array<IFilterField>;
  public preFilter: string;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor(
    readonly categoryService: CategoryService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.initialState.where = this.dates;
    this.onGetFields();
  }

  onChange = (items: Array<ICustomTag>) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ICustomTag>): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onGetPreFilter(): void {
    const preFilterField = this.fieldDataSource.find(
      (item: IFilterField) => item.id === this.field,
    );
    this.preFilter = preFilterField ? preFilterField.preFilter : undefined;
  }

  private onGetFields(): void {
    this.categoryService
      .getUserCategoryFieldsByType(
        UserTagCategoryLabel.STANDARD.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fieldDataSource = response;
        this.onGetPreFilter();
      });
  }

  public onRemoveValue(tag: ICustomTag): void {
    this.selections = this.selections.filter(
      (item: ICustomTag) => item.id !== tag.id,
    );
    this.onChange(this.selections);
  }

  public onFilterValuesSelectionChange(values: Array<ICustomTag>): void {
    this.onChange(values);
  }
}
