import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  FAwesomeModule,
} from '@intorqa-ui/core';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { UserService } from '@portal/shared/services/user.service';
import { ModalContainerService } from '../modal-container/modal-container.service';
import { WidgetTypeThumbnailListComponent } from '../widget-type-thumbnail-list/widget-type-thumbnail-list.component';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import { BoardService } from '@portal/boards/services/board.service';

@Component({
  selector: 'itq-create-widget-type',
  templateUrl: './create-widget-type.component.html',
  styleUrls: ['./create-widget-type.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    WidgetTypeThumbnailListComponent,
    MatTooltipModule,
    FAwesomeModule,
  ],
})
export class CreateWidgetTypeComponent implements OnInit {
  readonly AnalysisTypes = AnalysisTypes;

  constructor(
    readonly modalContainerService: ModalContainerService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit() {}

  public onAdd(type: AnalysisTypes): void {
    this.modalContainerService.navigate$.next({
      component: ChartWizardComponent,
      inputs: {
        widget: WidgetFactory.createWidgetByType(
          type,
          this.userService.userPreferences.defaultEcosystemId,
        ),
        action: Actions.CREATE,
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
