<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div
    class="flex items-center p-7 border-b border-border justify-between w-full"
  >
    <div class="flex justify-between w-full items-center">
      <div class="flex items-center">
        <fa-icon [icon]="['far', 'search']" [size]="'2x'"></fa-icon>
        <h1 class="text-28 font-bold pl-4">Search</h1>
      </div>
      <div class="flex space-x-2">
        <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
          <fa-icon [icon]="['far', 'refresh']"></fa-icon>
          <span class="whitespace-nowrap">REFRESH</span>
        </itq-mat-button>
        <itq-mat-button
          [disabled]="query?.hasEmptyRules() || !query?.hasRules()"
          [materialStyle]="'stroked'"
          (clickEvent)="onCreateTag()"
        >
          <mat-icon>add</mat-icon>
          <span class="whitespace-nowrap">CREATE TAG</span>
        </itq-mat-button>
        <itq-mat-button
          [matTooltip]="'Close'"
          [materialStyle]="'basic'"
          [type]="'button'"
          (clickEvent)="onClose()"
        >
          <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
        ></itq-mat-button>
      </div>
    </div>
  </div>
  <div class="flex w-full bg-background rounded-md flex-1 h-full overflow-auto">
    <div class="flex flex-col flex-1 min-w-[900px]">
      <div
        class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
      >
        <h2 class="flex font-semibold text-xl">Build your query:</h2>
        <itq-date-range
          class="flex"
          [dates]="this.initialState?.where"
          (search)="onChangeDate($event)"
        ></itq-date-range>
      </div>

      <div
        class="flex justify-between flex-col border-border border-b relative overflow-hidden flex-1"
      >
        @if (fields?.length > 0) {
          <itq-query-builder
            class="px-7 py-4 overflow-auto"
            [initialState]="initialState"
            [userTagCategory]="userTagCategory"
            [fieldsDataSource]="fields"
            [formControlName]="'query'"
            (dataBound)="onDataBound($event)"
          ></itq-query-builder>
        }
      </div>
    </div>
    <div
      class="flex flex-col overflow-hidden border-l border-border min-w-[600px]"
    >
      <div
        class="flex justify-between py-4 px-7 bg-white items-center border-border border-b"
      >
        <span class="font-medium pr-2">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ (dataSource?.count | number) || 0 }}</itq-pill
        >
      </div>
      <div class="flex flex-1 overflow-hidden">
        @if (query?.hasRules()) {
          <itq-documents-feed
            class="h-full flex flex-col overflow-hidden w-full"
            [data]="dataSource"
            [initialState]="initialState"
            (shareEmail)="onShareEmail($event)"
            (scrollEvent)="onScroll($event)"
            (drilldown)="onDrilldown($event)"
            (createProfile)="onCreateProfile($event)"
          ></itq-documents-feed>
        } @else {
          <div class="flex w-full justify-center items-center">
            <itq-panel-action
              class="my-7 flex w-full px-7"
              [label]="'No filters selected!'"
              [description]="
                'Type in the search box and add some filters for targeted results...'
              "
            ></itq-panel-action>
          </div>
        }
      </div>
    </div>
  </div>
</form>
@if (showLoader) {
  <itq-loader></itq-loader>
}
