<ng-container [formGroup]="form">
  <div class="flex flex-col w-full" [formGroupName]="'alerts'">
    <div class="flex pt-7 space-x-4">
      @if (action !== Actions.CREATE && action !== Actions.CLONE) {
        <div class="flex">
          <div class="font-semibold mr-2">Active</div>
          <mat-slide-toggle
            [color]="'primary'"
            [formControlName]="'active'"
          ></mat-slide-toggle>
        </div>
      }
      <div class="flex">
        <div class="font-semibold mr-2">Share to slack?</div>
        <mat-slide-toggle [formControlName]="'shareSlack'"> </mat-slide-toggle>
      </div>
    </div>
    <div class="space-x-4 pt-7 flex">
      <div class="flex flex-col flex-1">
        <div class="font-semibold pb-2">Check every</div>
        <itq-dropdown
          [formControlName]="'delay'"
          [clearSelection]="false"
          [dataSource]="delayDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
        @if (
          form?.controls['delay']?.touched &&
          form?.controls['delay']?.hasError('required')
        ) {
          <mat-error> Delay is <strong>required</strong> </mat-error>
        }
      </div>

      <div class="flex flex-col flex-1">
        <div class="font-semibold pb-2">Priority</div>
        <div class="flex items-center w-full">
          <itq-dropdown
            class="w-full"
            [placeholder]="'Please select a priority'"
            [formControlName]="'priority'"
            [dataSource]="priorityDataSource"
            [clearSelection]="false"
            [dataFields]="{ name: 'name', value: 'value' }"
          ></itq-dropdown>
        </div>
        @if (
          form?.controls['priority']?.touched &&
          form?.controls['priority']?.hasError('required')
        ) {
          <mat-error> Priority is <strong>required</strong> </mat-error>
        }
      </div>
    </div>
  </div>
</ng-container>
