import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { ApiSettingsComponent } from '@portal/widgets/components/api-settings/api-settings.component';

@Component({
  selector: 'itq-chart-explore-timeline',
  templateUrl: './chart-explore-timeline.component.html',
  styleUrls: ['./chart-explore-timeline.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,
    MapLabelPipe,
    MatIconModule,
    MatSlideToggleModule,
  ],
})
export class ChartExploreTimelineComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() widget: Timeline;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public form: FormGroup;
  public _dataSource: IData;
  public showLoader = false;
  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource = {
        result: [...this._dataSource.result, ...value.result],
        count: value.count,
      };
    } else {
      this._dataSource = value;
    }
  }
  private subscription = new Subscription();
  public count = 0;

  readonly PillType = PillType;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly chartService: ChartService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.bindRemoveSegmentSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.document = undefined;
      }),
    );
  }

  public onShowApi(): void {
    this.customOverlayService.open({
      data: {
        timeline: this.widget,
      },
      type: CustomOverlayType['slide-right'],
      component: ApiSettingsComponent,
      closeBtnClass: 'hidden',
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {}

  private drilldownDiscord(segment: ISegment): void {}

  private drilldownChannel(segment: ISegment): void {}

  private drilldownActor(segment: ISegment): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: segment.value.emitActor,
          },
        },
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(this.initialState.query),
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onRefresh(): void {
    this.onDataBound(this.initialState);
  }

  private addQueryRules(): Query {
    let queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    if (this.form?.get('query')?.value) {
      queryModel.addRule({
        entity: 'content',
        field: 'tag',
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    } else {
      queryModel.removeRuleByField('content');
    }
    return queryModel;
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      const queryModel = this.addQueryRules();
      if (queryModel.hasRules()) {
        this.tagService
          .execute(
            this.initialState,
            queryModel.convertToBackEndQuery(),
            this.userService.userPreferences.defaultEcosystemId,
          )
          .subscribe((response: IData) => {
            this.dataSource = response;
            this.count = this.dataSource.count;
            this.showLoader = false;
          });
      }
    }
  }

  public onClearDate(): void {
    this.initialState.where = undefined;
    this.onSearch();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.showLoader = true;
    this.document = undefined;
    this.onDataBound(this.initialState);
  }
}
