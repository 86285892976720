import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Alert,
  AlertTypes,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  DateQueryType,
  DynamicPlaceholderDirective,
  FAwesomeModule,
  ICreateTagMatchAlertDTO,
  ICreateTagThresholdAlertDTO,
  PillType,
  QueryFilters,
  Sizes,
  TagMatchAlert,
  TagThresholdAlert,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { BoardService } from '@portal/boards/services/board.service';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { KeycloakService } from 'keycloak-angular';
import moment from 'moment';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { combineLatest, concat, Observable, of, Subscription } from 'rxjs';
import { CreateTagTypeComponent } from '../components/create-tag-type/create-tag-type.component';
import { IFilterField } from '../interfaces/tag.interface';
import { QueryBuilderModel } from './../../shared/models/qb-query-model';
import { TagWizardAlertsComponent } from './components/tag-wizard-alerts/tag-wizard-alerts.component';
import { TagWizardFiltersComponent } from './components/tag-wizard-filters/tag-wizard-filters.component';
import { TagWizardNavigationComponent } from './components/tag-wizard-navigation/tag-wizard-navigation.component';
import { TagWizardNavItem } from './components/tag-wizard-navigation/tag-wizard-navigation.enum';
import { TagWizardSaveComponent } from './components/tag-wizard-save/tag-wizard-save.component';

@Component({
  selector: 'itq-tag-wizard',
  templateUrl: './tag-wizard.component.html',
  styleUrls: ['./tag-wizard.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    CommonModule,
    MatIconModule,
    TagWizardFiltersComponent,
    TagWizardNavigationComponent,
    TagWizardAlertsComponent,
    TagWizardSaveComponent,
    MatSlideToggleModule,
  ],
})
export class TagWizardComponent implements OnInit {
  @Input() queryModel: QueryBuilderModel;
  @Input() widget: Timeline;
  @Input() tag: Tag;
  @Input() action = Actions.CREATE;
  @Input() dates = {
    label: DateQueryType.LastMonth,
    start: moment().subtract(1, 'month').valueOf(),
    end: moment().valueOf(),
  };

  @Input() boardIds: Array<string>;

  public showLoader = false;
  public query: QueryBuilderModel;
  private subscriptions = new Subscription();
  public form: FormGroup;
  public active: TagWizardNavItem = TagWizardNavItem.FILTERS;
  public alert: Alert;
  public completed: Array<TagWizardNavItem> = [];
  public fields: Array<IFilterField>;
  public initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  readonly Sizes = Sizes;
  readonly Actions = Actions;
  readonly UserTagCategoryLabel = UserTagCategoryLabel;
  readonly PillType = PillType;
  readonly TagWizardNavItem = TagWizardNavItem;

  constructor(
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly tagService: TagService,
    readonly categoryService: CategoryService,
    readonly modalContainerService: ModalContainerService,
    readonly alertService: AlertsService,
    readonly boardService: BoardService,
    readonly keycloakService: KeycloakService,
    readonly alertsService: AlertsService,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.customOverlayService.setCssClass$.next('');
  }

  ngOnInit() {
    this.initialState.where = this.dates;
    this.createForm();
    this.bindLoaderSubscription();
    this.getBoardsForTag();
    this.onGetFields();
    this.bindLoadAlertSsubscription();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private bindLoadAlertSsubscription(): void {
    this.subscriptions.add(
      this.alertsService.loadAlert$.subscribe((response: Alert) => {
        this.alert = response;
      }),
    );
  }

  private onGetFields(): void {
    this.showLoader = true;
    this.categoryService
      .getUserCategoryFieldsByType(
        this.tag.userTagCategory,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.getTagIds();
      });
  }

  private getBoardsForTag(): void {
    if (this.action === Actions.EDIT || this.action === Actions.CLONE) {
      this.boardService
        .getTags(this.tag.tagId)
        .subscribe((response: Array<string>) => {
          this.boardIds = response;
        });
    }
  }

  private getTagIds(): void {
    if (this.action === Actions.EDIT || this.action === Actions.CLONE) {
      this.showLoader = true;
      const tagIds = this.queryModel?.getTagIds();
      if (tagIds?.length > 0) {
        combineLatest([
          this.tagService.getTagsMetadata(tagIds),
          this.categoryService.getUserCategories(
            this.userService.userPreferences.defaultEcosystemId,
          ),
        ]).subscribe((response) => {
          this.categoryService
            .getUserCategoryFieldsByType(
              this.tag.userTagCategory,
              this.userService.userPreferences.defaultEcosystemId,
              this.initialState,
            )
            .subscribe((fields: Array<IFilterField>) => {
              this.query = this.queryModel.convertToQueryBuilder(
                this.queryModel,
                response[0],
                fields,
              );
              this.showLoader = false;
            });
        });
      } else {
        this.categoryService
          .getUserCategoryFieldsByType(
            this.tag.userTagCategory,
            this.userService.userPreferences.defaultEcosystemId,
            this.initialState,
          )
          .subscribe((response: Array<IFilterField>) => {
            this.query = this.queryModel.convertToQueryBuilder(
              this.queryModel,
              undefined,
              response,
            );
            this.showLoader = false;
          });
      }
    } else {
      if (this.queryModel) {
        this.query = this.queryModel;
      } else {
        this.query = new QueryBuilderModel(
          [
            {
              entity: undefined,
              field: undefined,
              operator: undefined,
              value: undefined,
            },
          ],
          this.tag.userTagCategory === UserTagCategoryLabel.STANDARD.toString()
            ? 'and'
            : 'or',
        );
      }
      this.showLoader = false;
    }
  }

  private bindLoaderSubscription(): void {
    this.subscriptions.add(
      this.tagService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  public onNavigationChange(active: TagWizardNavItem): void {
    this.addCompletedItem();
    this.active = active;
  }

  public onNavigationFilters(): void {
    this.addCompletedItem();
    this.active = TagWizardNavItem.FILTERS;
  }

  public onNavigationNotifications(): void {
    this.addCompletedItem();
    this.active = TagWizardNavItem.NOTIFICATIONS;
  }

  public onNavigationSave(): void {
    this.addCompletedItem();
    this.active = TagWizardNavItem.SAVE;
  }

  private addCompletedItem(): void {
    if (!this.completed.includes(this.active)) {
      this.completed = [...this.completed, this.active];
    }
    if (!this.completed.includes(TagWizardNavItem.FILTERS)) {
      this.completed = [...this.completed, TagWizardNavItem.FILTERS];
    }
  }

  public onLoadTypeSelection(): void {
    this.modalContainerService.navigate$.next({
      component: CreateTagTypeComponent,
    });
  }

  private createForm(): void {
    this.form = new FormGroup({
      sharedTag: new FormControl(this.tag.sharedTag || false),
    });
  }

  public onPrev(): void {
    this.modalContainerService.navigate$.next({
      component: CreateWidgetTypeComponent,
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private getChangedAlertsProperties(): { [key: string]: any } {
    let changedProperties = {};
    Object.keys((this.form.get('alerts') as FormGroup).controls).forEach(
      (name: string) => {
        const currentControl = (this.form.get('alerts') as FormGroup).controls[
          name
        ];

        if (!currentControl.pristine) {
          changedProperties = {
            ...changedProperties,
            [name]: currentControl.value,
          };
        }
      },
    );

    if (this.keycloakService.isUserInRole('saas-alerts')) {
      const currentControl = this.form.get['alerts.alertTypeId'];

      if (currentControl && !currentControl.pristine) {
        changedProperties = {
          ...changedProperties,
          typeId: currentControl.value,
        };
      }
    }
    return changedProperties;
  }

  private getChangedTagProperties(): { [key: string]: any } {
    let changedProperties = {};

    const currentControl = this.form.get(`sharedTag`);
    if (!currentControl?.pristine) {
      const queryModel = new QueryBuilderModel(
        currentControl.value.rules,
        currentControl.value?.condition,
      );
      changedProperties = {
        ...changedProperties,
        sharedTag: currentControl.value,
      };
    }
    Object.keys((this.form.get('filters') as FormGroup).controls).forEach(
      (name: string) => {
        const currentControl = this.form.get(`filters.${name}`);

        if (!currentControl?.pristine) {
          const queryModel = new QueryBuilderModel(
            currentControl.value.rules,
            currentControl.value?.condition,
          );
          changedProperties = {
            ...changedProperties,
            [name]: queryModel.convertToBackEndQuery(),
          };
        }
      },
    );

    Object.keys((this.form.get('save') as FormGroup).controls).forEach(
      (name: string) => {
        const currentControl = this.form.get(`save.${name}`);

        if (currentControl && !currentControl.pristine) {
          if (name !== 'boardIds') {
            changedProperties = {
              ...changedProperties,
              [name]: currentControl.value,
            };
          }
        }
      },
    );
    return changedProperties;
  }

  private getUpdateTagObservable(tagId: string): Observable<Tag> {
    const changedProperties = this.getChangedTagProperties();
    if (Object.keys(changedProperties)?.length > 0) {
      return this.tagService.update(changedProperties, tagId);
    }
    return undefined;
  }

  private onEdit(): void {
    let observables: Array<Observable<any>> = [];
    const updateTagObservable = this.getUpdateTagObservable(this.tag.tagId);
    const updateBoardsObservable = this.getUpdateBoardsObservable();
    const updateAlertsObservable = this.getUpdateAlertsObservable();
    if (updateTagObservable) {
      observables.push(updateTagObservable);
    }
    if (updateBoardsObservable) {
      observables = [...observables, ...updateBoardsObservable];
    }
    if (updateAlertsObservable) {
      observables.push(updateAlertsObservable);
    }
    if (observables.length > 0) {
      combineLatest(observables).subscribe((values: Array<any>) => {
        this.snackBar.open('Your tag has been updated!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.customOverlayRef.close({ refresh: true });
      });
    }
  }

  private getUpdateAlertsObservable(): Observable<any> {
    if (this.keycloakService.isUserInRole('saas-alerts')) {
      const changedAlertsProperties = this.getChangedAlertsProperties();
      const hasAlertTypeChanged = Object.keys(changedAlertsProperties).includes(
        'alertTypeId',
      );
      if (
        hasAlertTypeChanged &&
        changedAlertsProperties.alertTypeId === undefined
      ) {
        return this.alertService.delete(this.alert.id);
      } else {
        if (Object.keys(changedAlertsProperties)?.length > 0) {
          if (this.alert?.id) {
            if (hasAlertTypeChanged) {
              return concat(
                this.alertService.delete(this.alert.id),
                this.createAlert(this.tag.tagId),
              );
            } else {
              return this.alertService.update(
                this.alert.id,
                changedAlertsProperties,
              );
            }
          } else {
            return this.createAlert(this.tag.tagId);
          }
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  }

  private getBoardIdsPayload(): { add: string[]; delete: string[] } {
    const payload = { add: [], delete: [] };
    const boardIds = this.form
      .get('save.boardIds')
      .value?.map((item: Board) => item.id);
    this.boardIds.forEach((boardId: string) => {
      if (!boardIds?.includes(boardId)) {
        payload.delete.push(boardId);
      }
    });

    boardIds?.forEach((boardId: string) => {
      if (!this.boardIds.includes(boardId)) {
        payload.add.push(boardId);
      }
    });
    return payload;
  }

  /**
   * Updates the boards by adding or deleting the widget's tag ID.
   * @returns An array of observables representing the update operations.
   */
  private getUpdateBoardsObservable(): Array<Observable<IBoard>> {
    let updateWidgetsPromises: Array<Observable<IBoard>> = [];
    const payload = this.getBoardIdsPayload();
    payload.add?.forEach((boardId: string) => {
      this.widgetService
        .createWidget({
          dataSource: [this.tag.tagId],
          description: this.form.get('save.description').value,
          ecosystemId: this.userService.userPreferences.defaultEcosystemId,
          name: this.form.get('save.name').value,
          type: AnalysisTypes.TIMELINE,
          chartType: ChartType.TIMELINE,
          width: 30,
          x: 0,
          height: 12,
          y: 0,
        })
        .subscribe((widget: Timeline) => {
          this.boardService
            .addWidgetsToBoard(boardId, {
              add: [widget.widgetId],
            })
            .subscribe();
        });
    });

    payload.delete?.forEach((boardId: string) => {
      if (this.boardService.board?.id === boardId) {
        this.boardService.removeTimelines$.next(this.widget);
      } else {
        updateWidgetsPromises.push(
          this.boardService.addWidgetsToBoard(boardId, {
            delete: [this.widget.widgetId],
          }),
        );
      }
    });
    return updateWidgetsPromises?.length > 0
      ? updateWidgetsPromises
      : undefined;
  }

  private onSave(): void {
    this.tagService
      .save({
        name: this.form.get('save.name').value,
        description: this.form.get('save.description').value,
        categoryId: this.form.get('save.category')?.value,
        ecosystemId: this.userService.userPreferences.defaultEcosystemId,
        query: this.query.convertToBackEndQuery(),
        sharedTag: false,
        userTagCategory: this.tag.userTagCategory,
      })
      .subscribe({
        next: (tag: Tag) => {
          let subscriptions: Array<Observable<any>> = [];
          const boardIds = this.form
            .get('save.boardIds')
            .value?.map((item: Board) => item.id);
          if (boardIds?.length > 0) {
            subscriptions = [...this.createWidgets(boardIds, tag.tagId)];
          }
          const alertTypeId = this.form.get('alerts.create')?.value;
          if (alertTypeId === 'yes') {
            subscriptions.push(this.createAlert(tag?.tagId));
          }
          if (subscriptions.length === 0) {
            this.snackBar.open(
              'Your tag has been created, and is available for immediate use!',
              'Close',
              {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              },
            );
            this.showLoader = false;
            this.customOverlayRef.close({ refresh: true });
          } else {
            combineLatest(subscriptions).subscribe((response: Array<any>) => {
              this.appendwidgetsToBoards(response, boardIds);
              this.snackBar.open(
                'Your tag has been created, and is available for immediate use!',
                'Close',
                {
                  horizontalPosition: 'right',
                  duration: 5000,
                  verticalPosition: 'top',
                },
              );
              this.showLoader = false;
              this.customOverlayRef.close({ refresh: true });
            });
          }
        },
        error: (error) => {
          this.snackBar.open(
            'An error occurred while creating the tag. Please try again.',
            'Close',
            {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            },
          );
          this.showLoader = false;
        },
      });
  }

  private appendwidgetsToBoards(
    observables: Array<any>,
    boardIds: Array<string>,
  ): void {
    const widgetIds = observables.map((item: any) => item?.widgetId);
    boardIds?.forEach((boardId: string, index: number) => {
      this.boardService
        .addWidgetsToBoard(boardId, {
          add: [widgetIds[index]],
        })
        .subscribe();
    });
  }

  private createAlert(
    tagId: string,
  ): Observable<TagThresholdAlert | TagMatchAlert> {
    const alertTypeName = this.alertService.getTypeById(
      this.form.get('alerts.alertTypeId')?.value,
    )?.label;
    let alertPayload: ICreateTagThresholdAlertDTO | ICreateTagMatchAlertDTO;
    if (alertTypeName === AlertTypes.THRESHOLD) {
      alertPayload = {
        tagId,
        typeId: this.form.get('alerts.alertTypeId')?.value,
        priority: this.form?.get('alerts.priority')?.value,
        message: this.form?.get('alerts.message')?.value,
        postSlack: this.form?.get('alerts.postSlack')?.value || false,
        emailMe: false,
        condition: this.form?.get('alerts.condition')?.value,
        period: this.form?.get('alerts.period')?.value,
        count: this.form?.get('alerts.count')?.value,
      } as ICreateTagThresholdAlertDTO;
    } else {
      alertPayload = {
        tagId,
        typeId: this.form.get('alerts.alertTypeId')?.value,
        delay: this.form?.get('alerts.delay')?.value,
        priority: this.form?.get('alerts.priority')?.value,
        message: this.form?.get('alerts.message')?.value,
        emailMe: false,
        postSlack: this.form?.get('alerts.postSlack')?.value || false,
      } as ICreateTagMatchAlertDTO;
    }
    return this.alertService.create(alertPayload);
  }

  private createWidgets(
    boardIds: Array<string>,
    tagId: string,
  ): Array<Observable<any>> {
    if (boardIds?.length > 0) {
      let observables = [];
      boardIds.forEach(() => {
        observables.push(
          this.widgetService.createWidget({
            dataSource: [tagId],
            description: this.form.get('save.description').value,
            ecosystemId: this.userService.userPreferences.defaultEcosystemId,
            name: this.form.get('save.name').value,
            type: AnalysisTypes.TIMELINE,
            chartType: ChartType.TIMELINE,
            width: 30,
            x: 0,
            height: 12,
            y: 0,
          }),
        );
      });
      return observables;
    } else {
      return [of()];
    }
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
