import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FieldTypes,
  HighlightSearchPipe,
  IPresetQuery,
  OverlayService,
  QueryFilters,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
  IUserCategory,
} from '../../interfaces/tag.interface';

@Component({
  selector: 'itq-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FormsModule,
    HighlightSearchPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TagPickerComponent,
    },
  ],
})
export class TagPickerComponent implements OnInit {
  @Input() field: string;
  @Input() fields: Array<IFilterField>;
  @Input() selectedUserCategory: string;
  @Input() disabled = false;
  @Input() dates: IPresetQuery;

  @Output() selectionChange = new EventEmitter<ICustomTag>();

  private _tagsDataSource: Array<ICustomTag>;

  public get tagsDataSource(): Array<ICustomTag> {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: Array<ICustomTag>) {
    if (this.initialState.page === 1) {
      this._tagsDataSource = value;
    } else {
      this._tagsDataSource = this._tagsDataSource.concat(value);
    }
  }

  public userCategoriesDataSource: Array<IUserCategory>;
  public showLoader = true;
  public selection: any;
  private touched = false;
  public query: string;
  public initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  constructor(
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly overlayService: OverlayService,
  ) {}

  onChange = (items: ICustomTag) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: string): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.initialState.where = this.dates;
    this.getUserCategories();
  }

  onSelectionChange(item: ICustomTag): void {
    this.onChange(item);
    this.selectionChange.emit(item);
    this.overlayService.closeOverlay();
  }

  public onSearch(): void {
    this.initialState.query = this.query;
    this.onGetTags();
  }

  public getUserCategories(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IUserCategory>) => {
        this.userCategoriesDataSource = response;
        this.selectedUserCategory =
          this.selectedUserCategory || this.userCategoriesDataSource[0].name;
        this.onGetTags();
      });
  }

  public onNavChange(userTagCategory: string): void {
    this.selectedUserCategory = userTagCategory;
    this.onGetTags();
  }

  private onGetTags(): void {
    this.categoryService
      .getCategoryValues(
        this.query,
        this.initialState,
        new Query().convertToBackEndQuery(),
        this.selectedUserCategory,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.initialState.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          const index = response.items.findIndex(
            (item) => item.id === this.field,
          );
          if (index > -1) {
            const [item] = response.items.splice(index, 1);
            response.items.unshift(item);
          }
          this.tagsDataSource = response.items;
          this.showLoader = false;
        },
      );
  }
}
