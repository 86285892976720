import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICustomTag } from 'projects/portal/src/app/tags/interfaces/tag.interface';

@Component({
  selector: 'itq-tags-include-selection',
  templateUrl: './tags-include-selection.component.html',
  styleUrls: ['./tags-include-selection.component.scss'],
})
export class TagsIncludeSelectionComponent implements OnInit {
  @Input() item: ICustomTag;
  @Input() checked: boolean;

  @Output() changeValue = new EventEmitter<ICustomTag>();

  constructor() {}

  ngOnInit() {}

  public onToggle(item: ICustomTag): void {
    this.changeValue.emit(item);
  }
}
