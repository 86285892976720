import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import {
  ICustomTag,
  IExtTag,
  ITag,
  ITagResults,
  ITagTreeNode,
} from '../interfaces/tag.interface';

import {
  DateQueryType,
  DateRangeHelper,
  DTOQuery,
  QueryFilters,
} from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  IContext,
  IData,
  ISearchResults,
} from '../../document/interfaces/document.interface';
import { Query } from '../../shared/models/query-model';
import { ITagMetadata } from '../interfaces/tag.interface';
import { Tag } from '../models/tag';

interface ISaveTagDTO {
  name: string;
  description: string;
  categoryId: string;
  ecosystemId: string;
  query: DTOQuery;
  sharedTag: boolean;
  userTagCategory: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagService {
  public loader$ = new Subject<boolean>();
  public updateQueryModel$ = new Subject<QueryBuilderModel>();
  public getFilters$ = new Subject<void>();
  public reloadFilters$ = new Subject<{
    queryFilters: QueryFilters;
    query: Query;
  }>();
  public resetQuery$ = new Subject<void>();
  public reloadTags$ = new Subject<void>();
  public toggleSharedTag$ = new Subject<boolean>();
  public tags$ = new Subject<{
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  }>();
  public changeAlertType$ = new Subject<string>();
  public queryModel: Query;
  public getSearchResults$ = new Subject<void>();

  constructor(private apiRequestService: ApiRequestService) {}

  save(tag: ISaveTagDTO): Observable<Tag> {
    return this.apiRequestService
      .post('/tags', new DTOTypeConverter<ITag>(), tag, undefined, 'v2.0')
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        map((response: ITag) => {
          this.reloadTags$.next();
          return new Tag(
            response.tagId,
            response.description,
            response.createdDate,
            response.updatedDate,
            response.name,
            response.query,
            response.sharedTag,
            response.username,
            response.categoryId,
            response.userTagCategory,
            response.lastTaggingTime,
            response.alertTypeId,
            response.ecosystemId,
          );
        }),
      );
  }

  update(attributes: { [key: string]: any }, tagId: string): Observable<Tag> {
    return this.apiRequestService.put(
      `/tags/${tagId}`,
      attributes,
      undefined,
      'v2.0',
    );
  }

  getTags(
    initialState: QueryFilters,
    query: string,
    ecosystemId: string,
    userTagCategory?: string,
    systemTag?: boolean,
    sharedTag?: boolean,
    hasAlerts?: boolean,
  ): Observable<{
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  }> {
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&size=${initialState.pageSize}`;
    if (query) {
      pageQuery += `&search=${encodeURIComponent(query)}`;
    }
    pageQuery += `&sortField=${initialState.sort?.active}`;
    pageQuery += `&sortOrder=${initialState.sort?.direction}`;
    pageQuery += `&ecosystemId=${ecosystemId}`;
    if (userTagCategory) {
      pageQuery += `&userTagCategory=${userTagCategory}`;
    }
    if (systemTag) {
      pageQuery += `&systemTag=${systemTag}`;
    }
    if (sharedTag) {
      pageQuery += `&sharedTag=${sharedTag}`;
    }
    if (hasAlerts) {
      pageQuery += `&hasAlerts=${hasAlerts}`;
    }

    return this.apiRequestService
      .get(
        `/tags?${pageQuery}`,
        new DTOTypeConverter<{
          items: Array<Tag>;
          page: number;
          pageSize: number;
        }>(),
        undefined,
        'v2.0',
      )
      .pipe(
        map(
          (response: {
            items: Array<ITag>;
            page: number;
            pageSize: number;
            totalCount: number;
          }) => {
            const result = {
              items: response?.items.map((item: ITag) => {
                return new Tag(
                  item.tagId,
                  item.description,
                  item.createdDate,
                  item.updatedDate,
                  item.name,
                  item.query,
                  item.sharedTag,
                  item.username,
                  item.categoryId,
                  item.userTagCategory,
                  item.lastTaggingTime,
                  item.alertTypeId,
                  item.ecosystemId,
                );
              }),
              page: response.page,
              pageSize: response.pageSize,
              totalCount: response.totalCount,
            };
            return result;
          },
        ),
      );
  }

  public getTagById(id: string): Observable<Tag> {
    return this.apiRequestService
      .get(`/tags/${id}`, new DTOTypeConverter<ITag>(), undefined, 'v2.0')
      .pipe(
        map((response: Tag) => {
          return new Tag(
            response.tagId,
            response.description,
            response.createdDate,
            response.updatedDate,
            response.name,
            response.query,
            response.sharedTag,
            response.username,
            response.categoryId,
            response.userTagCategory,
            response.lastTaggingTime,
            response.alertTypeId,
            response.ecosystemId,
          );
        }),
      );
  }

  delete(id: string): Observable<{ message: string }> {
    return this.apiRequestService.delete(`/tags/${id}`);
  }

  public getTagsMetadata(ids: Array<string>): Observable<Array<ITagMetadata>> {
    return this.apiRequestService.post(
      '/tags/metadata',
      new DTOTypeConverter<Array<ITagMetadata>>(),
      {
        tagIds: ids,
      },
      undefined,
      'v1.0',
    );
  }

  public getApiKeyPrefix(id: string): Observable<string> {
    return this.apiRequestService.get(
      `/tags/${id}/apiKeyPrefix`,
      new DTOTypeConverter<string>(),
      undefined,
      'v2.0',
    );
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.get(
      `/tags/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.get(
      `/tags/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.put(
      `/tags/${unlinkId}/unlink/${dependencyId}`,
    );
  }
  public execute(
    state: QueryFilters,
    queryModel: DTOQuery,
    ecosystemId: string,
    context?: IContext,
  ): Observable<IData> {
    const action = state?.page || 1;
    let pageQuery = `page=${action}`;

    if (state?.pageSize) {
      pageQuery += `&size=${state.pageSize}`;
    }
    if (state?.where) {
      if (state.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        pageQuery += `&dateTo=${state.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(state.where.label);
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    if (context) {
      pageQuery += `&context=${context.document.id}`;
      if (context.before || context.after) {
        pageQuery += `&page=${state.page}`;
      }
      if (context.before) {
        pageQuery += `&before=${context.before}`;
      }
      if (context.after) {
        pageQuery += `&after=${context.after}`;
      }
    }
    return this.apiRequestService
      .post(
        `/tags/search?${pageQuery}`,
        new DTOTypeConverter<ISearchResults>(),
        JSON.stringify(queryModel),
      )
      .pipe(
        map((response: ISearchResults) => {
          return {
            result: response.items,
            count: response.totalHits,
          } as IData;
        }),
      );
  }

  public getTotalCount(ecosystemId: string): Observable<number> {
    return this.apiRequestService.get(
      `/tags/count?ecosystemId=${ecosystemId}`,
      new DTOTypeConverter<number>(),
      undefined,
      'v2.0',
    );
  }

  public getFieldValues(
    initialState: QueryFilters,
    queryModel: DTOQuery,
    field: string,
    ecosystemId: string,
    after?: string,
    showAll?: boolean,
  ): Observable<{ items: Array<ICustomTag>; totalCount: number }> {
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&pageSize=${initialState.pageSize}`;
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }

    if (initialState.page > 1) {
      pageQuery += `&after=${encodeURIComponent(after)}`;
    }
    if (showAll) {
      pageQuery += `&showAll=${showAll}`;
    }
    if (initialState?.query) {
      pageQuery += `&prefix=${initialState.query}`;
    }
    return this.apiRequestService
      .post(
        `/tags/fields/${field}/values?${pageQuery}`,
        new DTOTypeConverter<any>(),
        JSON.stringify(queryModel),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: ITagResults) => {
          const result = response.items.map((tag: IExtTag) => ({
            name: tag.value,
            description: tag.description,
            id: tag.id || tag.value,
            count: tag.count,
          }));
          return { items: result, totalCount: response.totalCount };
        }),
      );
  }

  public getPrefilterValues(
    initialState: QueryFilters,
    queryModel: DTOQuery,
    field: string,
    ecosystemId: string,
    child: string,
    after?: string,
    showAll?: boolean,
  ): Observable<ITagResults> {
    let pageQuery = `ecosystemId=${ecosystemId}`;
    pageQuery += `&child=${child}`;
    pageQuery += `&page=${initialState.page}`;
    pageQuery += `&pageSize=${initialState.pageSize}`;
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }

    if (initialState.page > 1) {
      pageQuery += `&after=${encodeURIComponent(after)}`;
    }
    if (showAll) {
      pageQuery += `&showAll=${showAll}`;
    }

    return this.apiRequestService.post(
      `/tags/fields/${field}/prefilter/values?${pageQuery}`,
      new DTOTypeConverter<ITagResults>(),
      JSON.stringify(queryModel),
      undefined,
      'v2.0',
    );
  }

  public validateName(payload: {
    name: string;
    ecosystemId: string;
    userTagCategory: string;
    sharedTag: boolean;
  }): Observable<boolean> {
    return this.apiRequestService.post(
      '/tags/validate',
      new DTOTypeConverter<boolean>(),
      payload,
    );
  }
}
