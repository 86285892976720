import { ChartUtils } from '@intorqa-ui/core';
import { PieMode } from '@portal/widgets/enums/chart.enum';
import { IDataPoint, ISerie } from '@portal/shared/interfaces/widget.interface';
import { EChartsOption } from 'echarts';
import { Chart } from './chart';

export class PieChart extends Chart {
  constructor() {
    super();
  }
  public getOptions(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): EChartsOption {
    return {
      tooltip: this.getToolip(),
      grid: ChartUtils.getGrid(true, true),
      textStyle: ChartUtils.getTextStyle(),
      legend: ChartUtils.getLegend(true),
      series: this.getSeries(data, settings, segment),
    };
  }

  private getSeries(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): { [key: string]: any } {
    return data.map((serie: ISerie, index: number) => {
      return {
        name: data[0].name,
        type: 'pie',
        label: {
          position: 'inner',
          formatter: (params: any) => {
            return params.value === 0 ? '' : params.value;
          },
        },
        data: serie.data.map((item: IDataPoint) => {
          return ChartUtils.getDataPoint(item, segment, serie.tagId, index);
        }),
        animation: false,
        radius: settings.mode === PieMode.PIE ? '75%' : ['75%', '50%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: true,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      };
    });
  }

  private getToolip(): { [key: string]: any } {
    return {
      trigger: 'item',
      extraCssText: 'overflow: auto; max-height: 250px; border: 0;',
      enterable: true,
    };
  }
}
