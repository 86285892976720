<form [formGroup]="form" class="flex flex-col h-full">
  <ng-container [formGroupName]="'addNote'">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <fa-icon
            [size]="'2x'"
            [icon]="['far', action === Actions.CREATE ? 'plus' : 'pencil']"
          ></fa-icon>
          <h1 class="text-28 font-bold">
            {{ action === Actions.CREATE ? 'Add note' : 'Edit note' }}
          </h1>
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
    <div class="bg-background p-7 flex-1">
      <ng-container
        *ngIf="
          navigationItem?.action === Actions.EXPLORE ||
          navigationItem?.action === Actions.EDIT
        "
      >
        <div class="flex flex-col mb-4">
          <span class="mb-1 text-13 font-medium">VENDOR TYPE</span>
          <itq-dropdown
            [formControlName]="'profileType'"
            [dataFields]="{ name: 'name', value: 'id' }"
            [clearSelection]="false"
            [dataSource]="profilesTypesDataSource"
            (changeValue)="onChangeProfileType($event)"
          ></itq-dropdown>
          <mat-error
            *ngIf="
              form?.get('addNote.profileType')?.touched &&
              form?.get('addNote.profileType')?.invalid
            "
          >
            Vendor type is required
          </mat-error>
        </div>
        <div
          class="flex flex-col mb-4"
          *ngIf="
            form?.get('addNote.profileType')?.value ||
            navigationItem?.action === Actions.CREATE
          "
        >
          <span class="mb-1 text-13 font-medium">VENDOR</span>
          <itq-dropdown
            [formControlName]="'profile'"
            [clearSelection]="false"
            [dataSource]="profilesDataSource"
            [dataFields]="{ name: 'name', value: 'profileId' }"
            (dataBound)="onDataBoundProfiles($event)"
            (changeValue)="onChangeProfile()"
          ></itq-dropdown>
          <mat-error
            *ngIf="
              form?.get('addNote.profile')?.touched &&
              form?.get('addNote.profile').invalid
            "
          >
            Vendor is required
          </mat-error>
        </div>
      </ng-container>
      <div
        class="flex flex-col mb-4"
        *ngIf="
          form?.get('addNote.profileType')?.value ||
          navigationItem?.action === Actions.CREATE
        "
      >
        <span class="mb-1 text-13 font-medium">NOTE TYPE</span>
        <itq-dropdown
          [formControlName]="'type'"
          [clearSelection]="false"
          [dataSource]="typesDataSource"
          [dataFields]="{ name: 'name', value: 'id' }"
          (changeValue)="onChangeType($event)"
        ></itq-dropdown>
        <mat-error
          *ngIf="
            form?.get('addNote.type')?.touched &&
            form?.get('addNote.type')?.invalid
          "
        >
          Note type is required
        </mat-error>
      </div>
      <ng-template dynamicPlaceholder></ng-template>
    </div>
    <footer class="flex justify-end px-7 py-4 bg-white border-t border-border">
      <ng-container *ngIf="action === Actions.CREATE; else updateTemplate">
        <itq-nav-button
          [materialStyle]="'flat'"
          [openDirection]="{
            vertical: 'down',
            horizontal: 'left'
          }"
          [color]="'primary'"
          [padding]="Sizes.MD"
          [width]="200"
          [openPosition]="'inside'"
          [disabled]="!this.form?.get('addNote')?.valid"
          [template]="saveTemplate"
          >SAVE</itq-nav-button
        >
        <ng-template #saveTemplate>
          <div class="flex flex-col w-full">
            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.BLOCK"
              [align]="Align.START"
              (clickEvent)="onCreate()"
            >
              <fa-icon [icon]="['far', 'plus']"></fa-icon>
              <span class="whitespace-nowrap">Add another note</span>
            </itq-mat-button>

            <itq-mat-button
              [materialStyle]="'basic'"
              [padding]="Sizes['X-SM']"
              [size]="Sizes.BLOCK"
              [align]="Align.START"
              (clickEvent)="onAdd()"
            >
              <fa-icon [icon]="['far', 'plus']"></fa-icon>
              <span class="whitespace-nowrap">Add to profile</span>
            </itq-mat-button>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #updateTemplate>
        <itq-mat-button
          [disabled]="!this.form?.get('addNote')?.valid"
          [materialStyle]="'flat'"
          [style]="'primary'"
          (clickEvent)="onUpdate()"
        >
          <span class="whitespace-nowrap">Update</span>
        </itq-mat-button>
      </ng-template>
    </footer>
  </ng-container>
</form>
