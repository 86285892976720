import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  FAwesomeModule,
  QueryFilters,
} from '@intorqa-ui/core';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import {
  DocumentItem,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { QuillModule } from 'ngx-quill';
import { DocumentItemTileComponent } from '../document-item-tile/document-item-tile.component';

@Component({
  selector: 'itq-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    QuillModule,
    CommonModule,
    MatTooltipModule,
    DocumentItemTileComponent,
  ],
})
export class ShareEmailComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() initialState: QueryFilters;

  public form: FormGroup;
  public showLoader = false;

  @ViewChild('Editor', { static: false })
  editorElementRef: any;

  constructor(
    private customOverlayRef: CustomOverlayRef,
    private snackBar: MatSnackBar,
    private notificationService: NotificationsService,
    readonly modalContainerService: ModalContainerService,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      content: new FormControl(''),
    });
  }

  public onSubmit(): void {
    this.showLoader = true;
    const message = {
      itemId: this.document.id,
      destination: this.form.controls.email.value,
      message: this.form.controls.content.value,
    };
    this.notificationService.shareEmail(message).subscribe(() => {
      this.snackBar.open('Document has been shared succssefully', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextSegment): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        initialState: this.initialState,
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState,
      },
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState,
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        initialState: this.initialState,
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        initialState: this.initialState,
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
