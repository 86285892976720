import { ITag } from '../../tags/interfaces/tag.interface';
import { Tag } from '../../tags/models/tag';

export class TagFactory {
  static createTag(tag: ITag): Tag {
    return new Tag(
      tag.tagId,
      tag.description,
      tag.createdDate,
      tag.updatedDate,
      tag.name,
      tag.query,
      tag.sharedTag,
      tag.username,
      tag.categoryId,
      tag.userTagCategory,
      tag.lastTaggingTime,
      tag.alertTypeId,
      tag.ecosystemId,
    );
  }

  static createTagByType(type: string): Tag {
    return new Tag(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      type,
      undefined,
      undefined,
      undefined,
    );
  }
}
