import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  FoamtreeComponent,
  FoamtreeService,
  IconType,
  IPresetQuery,
  IRiplResults,
  PillType,
  QueryFilters,
  SvgComponent,
  TagCategory,
  Utils,
  VirtualScrollService,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TOPIC_CLUSTERS_CHART_TYPES } from '@portal/boards/const/topic-clusters.const';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
  ISearchResults,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import {
  IDisplayType,
  IWidgetType,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import html2canvas from 'html2canvas';
import { delay, map, Observable, of, Subscription } from 'rxjs';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';

@Component({
  selector: 'itq-chart-explore-topic-clusters',
  templateUrl: './chart-explore-topic-clusters.component.html',
  styleUrls: ['./chart-explore-topic-clusters.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FoamtreeComponent,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    MapLabelPipe,
    DocumentsFeedComponent,
    SvgComponent,
  ],
})
export class ChartExploreTopicClustersComponent
  implements OnInit, AfterViewInit
{
  @Input() segment: any;
  @Input() widget: TopicClustering;
  @Input() initialState: QueryFilters;

  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  public form: FormGroup;
  public showLoader = false;
  public widgetType: IWidgetType;
  public chartTypesDataSource = TOPIC_CLUSTERS_CHART_TYPES;
  public get documentsData(): IData {
    return this._documentsData;
  }

  public set documentsData(value: IData) {
    if (this.initialState.page > 1) {
      this._documentsData.result = [
        ...this.documentsData.result,
        ...value.result,
      ];
    } else {
      this._documentsData = value;
    }
  }
  public dataSource: IRiplResults;
  private subscription = new Subscription();
  private _documentsData: IData;
  public count = 0;

  readonly PillType = PillType;

  constructor(
    public customOverlayRef: CustomOverlayRef,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly chartService: ChartService,
    readonly snackBar: MatSnackBar,
    readonly foamtreeService: FoamtreeService,
    readonly virtualScrollService: VirtualScrollService,
    readonly widgetService: WidgetService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.createForm();
    this.bindRemoveSegmentSubscription();
    this.bindLoaderSubscription();
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindLoaderSubscription(): void {
    this.subscription.add(
      this.widgetService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownContext(context: IContextSegment): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.segment = undefined;
        this.cdr.detectChanges();
        this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
      }),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = { ...this.widget.options, ...params.options };
    this.widget.chartType = params.type;
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
  }

  private createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(this.initialState.query),
      chartType: new FormControl(this.widget.getDisplayType()?.id, [
        Validators.required,
      ]),
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private loadData(): void {
    this.widgetService.loader$.next(true);
    this.getData().subscribe(() => {
      this.getDocuments().subscribe(() => {
        this.chartService.reloadChart$.next(`${this.widget?.widgetId}_explore`);
        this.widgetService.loader$.next(false);
      });
    });
  }

  private getDocuments(): Observable<any> {
    if (!this.segment) return of(undefined).pipe(delay(0));
    const documentIds = this.foamtreeService.getClusterDocumentIds(
      [this.segment.data.value],
      this.dataSource,
    );
    return this.getClusterDocuments(documentIds);
  }

  private getClusterDocuments(documentIds: any): Observable<IData> {
    return this.foamtreeService.filterCluster({ documentIds }).pipe(
      map((response: ISearchResults) => {
        this.documentsData = {
          result: response.items,
          count: response.totalHits,
        };
        return this.documentsData;
      }),
    );
  }

  public onExpandCluster(cluster: any): void {
    this.widgetService.loader$.next(true);
    this.segment = {
      data: {
        value: cluster,
      },
    };
    this.initialState.resetPagination();
    this.getDocuments().subscribe(() => {
      this.widgetService.loader$.next(false);
    });
  }

  private addQueryRules(): Query {
    let queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    if (this.form?.get('query')?.value) {
      queryModel.addRule({
        entity: 'content',
        field: 'tag',
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    } else {
      queryModel.removeRuleByField('content');
    }
    return queryModel;
  }

  private getData(): Observable<IRiplResults> {
    const queryModel = this.addQueryRules();
    return this.foamtreeService
      .getData(
        this.initialState,
        queryModel.convertToBackEndQuery(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IRiplResults) => {
          this.dataSource = response;
          this.cdr.detectChanges();
          this.chartService.reloadChart$.next(
            `${this.widget?.widgetId}_explore`,
          );
          this.count = response.source_documents.length;
          return this.dataSource;
        }),
      );
  }

  public onDataBound(params: QueryFilters): void {
    this.initialState = params;
    this.loadData();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.segment = undefined;
    this.loadData();
  }

  public onExport(): void {
    this.widgetService.loader$.next(true);
    setTimeout(() => {
      html2canvas(this.chartContainer.nativeElement).then((canvas: any) => {
        Utils.exportImage(canvas.toDataURL('image/png'), this.widget.name);
        this.snackBar.open('Chart has been exported successfully!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.widgetService.loader$.next(false);
      });
    }, 100);
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
