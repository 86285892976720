import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOCreation,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import {
  DateQueryType,
  DateRangeHelper,
  DTOQuery,
  IPresetQuery,
  QueryFilters,
} from '@intorqa-ui/core';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { TopChannel } from '@portal/boards/models/widgets/top-channel';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import {
  IWidget,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { ITagTreeNode } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  public updateSegmentObservable = new Subject<ISegment | undefined>();
  public addWidget$ = new Subject<Widget>();
  public loader$ = new Subject<boolean>();
  public updateLayoutMinWidth$ = new Subject<EventTimeline>();
  public drilldownObservable = new Subject<NavigationHistoryItem>();
  public explore$ = new Subject<Widget>();
  public reload$ = new Subject<{
    widget?: Widget;
    initialState: QueryFilters;
  }>();
  public updateWidget$ = new Subject<{
    widget: Widget;
    dates?: IPresetQuery;
    reloadData?: boolean;
  }>();
  public updateCount$ = new Subject<{ widgetId: string; count: number }>();
  public updateApiKeyObservable = new Subject<string>();
  public drilldown$ = new Subject<NavigationHistoryItem>();
  public chartResizeObservable = new Subject();
  public delete$ = new Subject<Widget>();

  constructor(private apiRequestService: ApiRequestService) {}

  public getData(
    payload: {
      widget: TagComparison | TagAnalysis | TimeSeries | TopActor | TopChannel;
      filters: DTOQuery;
    },
    initialState: QueryFilters,
  ): Observable<IWidgetData> {
    let pageQuery = '';

    if (initialState?.where) {
      if (initialState.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${initialState.where?.start}`;
        pageQuery += `&dateTo=${initialState.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(
          initialState.where.label,
        );
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }

    return this.apiRequestService
      .post(
        `/widgets/data${pageQuery !== '' ? `?${pageQuery}` : ''}`,
        new DTOTypeConverter<IWidgetData>(),
        JSON.stringify(payload),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidgetData) => {
          if (response?.series) {
            response.series = payload.widget.transformData(response.series);
          }
          return response;
        }),
      );
  }

  public getFoamtreeDocuments(
    state: QueryFilters,
    documents: { ids: Array<string> },
  ): Observable<any> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    addQueryParams('pageSize', state?.pageSize);
    addQueryParams('page', state?.page);
    addQueryParams('dateFrom', state?.where?.start);
    addQueryParams('dateTo', state?.where?.end);
    return this.apiRequestService.post(
      `/widgets/docs${pageQuery !== '' ? `?${pageQuery}` : ''}`,
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(documents),
      undefined,
      'v2.0',
    );
  }

  public getWidgetDocuments(
    payload: {
      widget: Widget;
      filters: DTOQuery;
    },
    state: any,
  ): Observable<any> {
    let pageQuery = '';
    const addQueryParams = (paramName: string, paramValue: any) => {
      if (paramValue && paramName) {
        if (pageQuery) {
          pageQuery += '&';
        }
        pageQuery += `${paramName}=${paramValue}`;
      }
    };

    addQueryParams('pageSize', state?.pageSize);
    addQueryParams('page', state?.page);
    addQueryParams('dateFrom', state?.where?.start);
    addQueryParams('dateTo', state?.where?.end);
    if (state?.dataValue) {
      addQueryParams('dataValue', encodeURIComponent(state?.dataValue));
    }
    return this.apiRequestService.post(
      `/widgets/docs${pageQuery !== '' ? `?${pageQuery}` : ''}`,
      new DTOTypeConverter<DTOCreation>(),
      JSON.stringify(payload),
      undefined,
      'v2.0',
    );
  }

  public createWidget(
    widget: any,
  ): Observable<
    | TimeSeries
    | TagComparison
    | TagAnalysis
    | Timeline
    | EventTimeline
    | TopicClustering
    | TopActor
    | TopChannel
  > {
    return this.apiRequestService
      .post(
        '/widgets',
        new DTOTypeConverter<any>(),
        JSON.stringify({
          ...widget,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidget) => {
          return WidgetFactory.createWidget(response);
        }),
      );
  }
  public deleteWidget(widgetId: string): Observable<void> {
    return this.apiRequestService.delete(
      '/widgets/' + widgetId,
      undefined,
      'v2.0',
    );
  }

  public updateWidget(widget: Widget): Observable<Widget> {
    return this.apiRequestService
      .put(
        '/widgets/' + widget.widgetId,
        JSON.stringify({
          widget,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IWidget) => {
          return WidgetFactory.createWidget(response);
        }),
      );
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.get(
      `/widgets/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.get(
      `/widgets/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.put(
      `/widgets/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public mergeTagIntoTimeline(tag: Tag, timeline?: Timeline): Timeline {
    return new Timeline(
      tag.tagId,
      tag.username,
      AnalysisTypes.TIMELINE,
      tag.name,
      tag.description,
      ChartType.TIMELINE,
      tag.ecosystemId,
      timeline?.width,
      timeline?.height,
      timeline?.x,
      timeline?.y,
      [tag.tagId],
      tag.createdDate,
      tag.sharedTag,
      tag.query,
      tag.categoryId,
      tag.lastTaggingTime,
      tag.updatedDate,
      tag.alertTypeId,
      timeline?.options,
      timeline?.dataPoints,
    );
  }
}
