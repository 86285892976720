import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FAwesomeModule } from '@intorqa-ui/core';

@Component({
  selector: 'itq-tag-wizard-nav-item',
  templateUrl: './tag-wizard-nav-item.component.html',
  styleUrls: ['./tag-wizard-nav-item.component.scss'],
  standalone: true,
  imports: [
    MatRippleModule,
    MatIconModule,
    CommonModule,
    FAwesomeModule,
    MatTooltipModule,
  ],
})
export class TagWizardNavItemComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() completed: boolean;
  @Input() active: boolean;
  @Input() errors: boolean;

  constructor() {}

  ngOnInit() {}
}
