@if (builderConfig && userTagCategory) {
  <query-builder
    class="border border-border rounded-md p-4 bg-white"
    [(ngModel)]="queryModel"
    [config]="builderConfig"
    [allowRuleset]="userTagCategory === UserTagCategoryLabel.STANDARD"
    [allowCollapse]="true"
    emptyMessage="A group cannot be empty. Please add a rule or remove it all together."
  >
    <ng-container
      *queryButtonGroup="
        let ruleset;
        let addRule = addRule;
        let addRuleSet = addRuleSet;
        let removeRuleSet = removeRuleSet
      "
    >
      <div class="flex items-center space-x-2" role="group">
        <itq-mat-button
          [disabled]="queryModel.hasEmptyRules()"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          (clickEvent)="addRule()"
        >
          <fa-icon [icon]="['far', 'plus']" [size]="'lg'"></fa-icon>
          ADD RULE
        </itq-mat-button>
        @if (userTagCategory === UserTagCategoryLabel.STANDARD) {
          @if (addRuleSet) {
            <itq-mat-button
              [disabled]="
                queryModel.hasEmptyRules() ||
                queryModel.maxLevelNestingReached()
              "
              [padding]="Sizes.SM"
              [matTooltip]="
                queryModel.maxLevelNestingReached()
                  ? 'Can\'t add more than three levels of nested queries'
                  : ''
              "
              [materialStyle]="'stroked'"
              (clickEvent)="addRuleSet(); addRuleToRuleSet()"
            >
              <fa-icon [icon]="['far', 'plus']" [size]="'lg'"></fa-icon>
              ADD GROUP
            </itq-mat-button>
          }

          @if (removeRuleSet) {
            <itq-mat-button
              [padding]="Sizes.SM"
              [materialStyle]="'stroked'"
              (clickEvent)="removeRuleSet(); onRemoveRuleSet()"
            >
              <fa-icon [icon]="['far', 'trash-alt']" [size]="'lg'"></fa-icon>
              DELETE GROUP
            </itq-mat-button>
          }
        }
      </div>
    </ng-container>

    <ng-container *querySwitchGroup="let ruleset; let onChange = onChange">
      @if (ruleset) {
        <mat-radio-group
          [disabled]="userTagCategory !== UserTagCategoryLabel.STANDARD"
          [(ngModel)]="ruleset.condition"
          (ngModelChange)="onChange($event); onChangeValue()"
        >
          <mat-radio-button value="and">And</mat-radio-button>
          <mat-radio-button value="or">Or</mat-radio-button>
        </mat-radio-group>
      }
    </ng-container>
    <ng-container *queryField="let rule; let fields = fields">
      @if (userTagCategory === UserTagCategoryLabel.STANDARD) {
        <itq-nav-button
          #fieldButton
          class="mr-2"
          [materialStyle]="'stroked'"
          [size]="Sizes.BLOCK"
          [openDirection]="{ vertical: 'down', horizontal: 'left' }"
          [openPosition]="'inside'"
          [template]="fieldsTemplate"
        >
          <div class="flex items-center justify-between w-full">
            @if (rule.field && rule.field !== 'undefined') {
              <div
                class="font-normal flex items-center justify-between w-full whitespace-nowrap"
              >
                {{ getLabelByFieldId(rule) }}
                <fa-icon
                  class="border-l border-border pl-1 ml-2"
                  [icon]="
                    fieldButton.isOpen
                      ? ['far', 'chevron-up']
                      : ['far', 'chevron-down']
                  "
                ></fa-icon>
              </div>
            } @else {
              <div
                class="text-gray-400 font-normal justify-between flex items-center w-full whitespace-nowrap"
              >
                Eg. content<fa-icon
                  class="border-l border-border pl-1 ml-2 text"
                  [icon]="
                    fieldButton.isOpen
                      ? ['far', 'chevron-up']
                      : ['far', 'chevron-down']
                  "
                ></fa-icon>
              </div>
            }
          </div>
        </itq-nav-button>
        <ng-template #fieldsTemplate>
          <itq-field-filters
            class="flex"
            [field]="rule.field !== 'undefined' ? rule.field : null"
            (selectionChange)="onFieldFilterSelectionChange($event, rule)"
          ></itq-field-filters>
        </ng-template>
      } @else {
        <itq-dropdown
          class="mr-2"
          [(ngModel)]="rule.field"
          [placeholder]="'Eg. content'"
          [clearSelection]="false"
          [dataSource]="fieldsDataSource"
          [dataFields]="{ name: 'label', value: 'id' }"
          (changeValue)="onChangeDataField(rule, fields)"
        ></itq-dropdown>
      }
    </ng-container>
    <ng-container *queryOperator="let rule; let fields = fields">
      @if (rule.field) {
        <div class="mr-2">
          <itq-dropdown
            [(ngModel)]="rule.operator"
            [placeholder]="'Operator...'"
            [clearSelection]="false"
            [dataSource]="getFieldOperators(rule.field)"
            [dataFields]="{ name: 'name', value: 'value' }"
            (changeValue)="onChangeOperator(rule)"
          ></itq-dropdown>
        </div>
      } @else {
        <div
          class="flex border whitespace-nowrap border-border h-[36px] items-center pl-4 rounded-md mr-2 font-normal bg-white text-gray-400 opacity-50 justify-between space-x-2"
        >
          <span>Eg. Contains</span>
          <fa-icon
            class="border-l border-border px-2 flex items-center h-[24px]"
            [icon]="['far', 'chevron-down']"
          ></fa-icon>
        </div>
      }
    </ng-container>
    <ng-container *queryInput="let rule; type: 'undefined'">
      <div
        class="flex flex-1 border whitespace-nowrap border-border h-[36px] pl-4 rounded-md bg-white text-gray-400 font-normal justify-between items-center w-full opacity-50"
      >
        <span>Eg. Cheat</span
        ><fa-icon
          class="border-l border-border px-2 flex items-center h-[24px]"
          [icon]="['far', 'chevron-down']"
        ></fa-icon>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'textarea'">
      <div class="flex-1">
        <itq-input
          [icon]="'search'"
          [(ngModel)]="rule.value"
          [placeholder]="'Add search term...'"
          [type]="'text'"
          (search)="onChangeQuery(rule)"
          (clear)="onChangeQuery(rule)"
        ></itq-input>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'tag'">
      <div class="flex-1">
        <itq-qb-multiple-dropdown
          [(ngModel)]="rule.value"
          [dataFields]="{ name: 'name', value: 'id' }"
          [placeholder]="'Choose an option...'"
          [dataSource]="tagsDataSource?.items"
          [initialState]="tagsInitialState"
          (changeValue)="onChangeValue()"
          (dataBound)="onGetFieldValues(rule, $event)"
        >
        </itq-qb-multiple-dropdown>
      </div>
    </ng-container>
    <ng-container *queryInput="let rule; type: 'filter'">
      <itq-rule-value
        class="flex-1 flex overflow-hidden"
        [rule]="rule"
        [dataSource]="this.fieldsDataSource"
        [initialState]="this.initialState"
        [queryModel]="this.queryModel"
        (removeValue)="onRemoveValue($event)"
        (valuesChange)="onFilterValuesSelectionChange($event)"
      ></itq-rule-value>
    </ng-container>
    <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
      @if (queryModel.getRulesCount() > 1) {
        <div
          class="delete__rule rounded-full ml-2 hover:bg-border w-[30px] h-[30px] items-center justify-center"
          matRipple
          [matTooltip]="'Delete'"
          (click)="
            $event.stopPropagation(); removeRule(rule); onChangeQuery(rule)
          "
        >
          <fa-icon [icon]="['far', 'circle-xmark']"></fa-icon>
        </div>
      }
    </ng-container>
  </query-builder>
}
