import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import {
  CoreModule,
  DateRangeComponent,
  FAwesomeModule,
  IPresetQuery,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { TagPickerComponent } from '@portal/tags/components/tag-picker/tag-picker.component';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ICustomTag } from 'projects/portal/src/app/tags/interfaces/tag.interface';
import { Tag } from 'projects/portal/src/app/tags/models/tag';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';

@Component({
  selector: 'itq-topic-clusters-metrics',
  templateUrl: './topic-clusters-metrics.component.html',
  styleUrls: ['./topic-clusters-metrics.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSliderModule,
    MatRadioModule,
    DateRangeComponent,
    FAwesomeModule,
    TagPickerComponent,
  ],
})
export class TopicClustersMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.topicClusteringWidget = widget as TopicClustering;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public tagsDataSource: {
    items: Array<ICustomTag>;
    totalCount: number;
  };
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public selectedUserCategory: string;
  public topicClusteringWidget: TopicClustering;

  readonly Sizes = Sizes;

  constructor(
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    this.getTagById();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('theme');
  }

  public onSearch(params: QueryFilters): void {
    this.initialState.query = params.query;
  }

  public getTagById(): void {
    if (this.topicClusteringWidget?.dataSource?.length > 0) {
      this.tagService
        .getTagById(this.topicClusteringWidget.dataSource[0])
        .subscribe((tag: Tag) => {
          this.selectedUserCategory = tag.userTagCategory;
          this.form.patchValue({
            dataSource: {
              name: tag.name,
              id: tag.tagId,
            },
          });
        });
    }
  }

  public onClear(): void {
    this.initialState.query = undefined;
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(this.topicClusteringWidget.dataSource, [
        Validators.required,
      ]),
    );
    this.form.addControl(
      'theme',
      new FormControl(this.topicClusteringWidget.options?.theme),
    );
  }

  public onChangeDataSources(tag: ICustomTag): void {
    this.topicClusteringWidget.dataSource = [tag.id];
    this.widgetService.updateWidget$.next({
      widget: this.topicClusteringWidget,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.widgetService.loader$.next(true);
    this.widgetService.updateWidget$.next({
      widget: this.topicClusteringWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  public onChangeTheme(params: MatRadioChange): void {
    this.topicClusteringWidget.options = {
      ...this.topicClusteringWidget.options,
      ...{ theme: params.value },
    };
    this.widgetService.updateWidget$.next({
      widget: this.topicClusteringWidget,
      reloadData: false,
    });
  }
}
