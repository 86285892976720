export enum TagNodeType {
  TAG = 'TAG',
  SYSTEM_TAG = 'SYSTEM_TAG',
  WIDGET = 'WIDGET',
  BOARD = 'BOARD',
}

export enum TaggingStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_RESULTS = 'NO_RESULTS',
}
