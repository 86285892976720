import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  FAwesomeModule,
  FoamtreeComponent,
  FoamtreeService,
  IPresetQuery,
  IRiplResults,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TOPIC_CLUSTERS_CHART_TYPES } from '@portal/boards/const/topic-clusters.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TopicClustering } from '@portal/boards/models/widgets/topic-clustering';
import {
  AnalysisTypes,
  WidgetViewMode,
} from '@portal/widgets/enums/widget.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { Subscription } from 'rxjs';
import { TopicClustersMetricsComponent } from '../../../topic-clusters-metrics/topic-clusters-metrics.component';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';
import { WidgetService } from '@portal/widgets/services/widget.service';

@Component({
  selector: 'itq-chart-wizard-topic-cluster.component',
  templateUrl: './chart-wizard-topic-cluster.component.html',
  styleUrls: ['./chart-wizard-topic-cluster.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    SelectMetricsLabelPipe,
    CommonModule,
    TopicClustersMetricsComponent,
    FoamtreeComponent,
    MapLabelPipe,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTopicClusterComponent implements OnInit {
  @Input() widget: TopicClustering;
  @Input() initialState: QueryFilters;
  @Input() action = Actions.CREATE;

  public form: FormGroup;
  public showLoader = false;
  public chartTypesDataSource = TOPIC_CLUSTERS_CHART_TYPES;
  private subscription = new Subscription();
  public count: number;
  public dataSource: IRiplResults;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly WidgetViewMode = WidgetViewMode;

  constructor(
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly foamtreeService: FoamtreeService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.getFoamtreeData();
    }
  }

  private getFoamtreeData(): void {
    const queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    this.foamtreeService
      .getData(
        this.initialState,
        queryModel.convertToBackEndQuery(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IRiplResults) => {
        this.dataSource = response;
        this.cdr.detectChanges();
        this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
        this.count = response.source_documents.length;
        this.showLoader = false;
      });
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TopicClustering;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = { ...this.widget.options, ...params.options };
    this.widget.chartType = params.type;
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
  }

  public onPrev(): void {
    this.modalContainerService.navigate$.next({
      component: CreateWidgetTypeComponent,
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.widgetService
      .createWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({ refresh: true, widget: response });
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
