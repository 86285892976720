import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Align, Sizes } from '../../enums/shared.enum';
import { OverlayService } from '../../services/overlay.service';
import { IOpenDirection } from './nav-button.interface';

@Component({
  selector: 'itq-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent implements OnInit {
  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('templateBody') templateBody: TemplateRef<unknown>;
  @ViewChild('container', { static: false }) container: ElementRef;

  @Input() size = Sizes.MD;
  @Input() template: TemplateRef<unknown>;
  @Input() templateData: any;
  @Input() disabled = false;
  @Input() color = '';
  @Input() openDirection: IOpenDirection;
  @Input() openPosition: 'inside' | 'outside';
  @Input() materialStyle = 'basic';
  @Input() align = Align.START;
  @Input() padding = Sizes['X-SM'];

  @Output() opened = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>();

  private overlayRef: OverlayRef;
  public isOpen = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private cdr: ChangeDetectorRef,
    readonly overlayService: OverlayService,
  ) {}

  ngOnInit(): void {}

  onToggle(): void {
    this.isOpen = true;
    this.opened.emit();
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      return;
    }
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
    });

    this.overlayService.setOverlayRef(this.overlayRef);

    // Create a portal to attach the template
    const templatePortal = new TemplatePortal(
      this.templateBody,
      this.viewContainerRef,
    );

    // Attach the portal to the overlay
    this.overlayRef.attach(templatePortal);

    // Handle backdrop click
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
    this.cdr.detectChanges();
    this.checkBoundaries();
    this.updatePosition();
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.isOpen = false;
      this.overlayRef.dispose();
      this.close.emit();
      this.overlayRef = null;
    }
  }

  private updatePosition(): void {
    const position = this.getContainerPosition();
    this.overlayRef.updatePositionStrategy(
      this.overlay
        .position()
        .global()
        .left(`${position.left}px`)
        .top(`${position.top}px`),
    );
  }

  private checkBoundaries(): void {
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();
    if (
      dropdownBoundingRect.left + this.container?.nativeElement?.clientWidth >
      window.innerWidth
    ) {
      this.openDirection.horizontal = 'right';
      this.openPosition = 'inside';
      if (
        dropdownBoundingRect.top - this.container.nativeElement.clientWidth <
        0
      ) {
        this.openDirection.vertical = 'down';
      }
    }
    if (
      dropdownBoundingRect.top + this.container?.nativeElement?.clientHeight >
      window.innerHeight
    ) {
      this.openDirection.vertical = 'up';
    }
  }

  private getContainerPosition(): {
    left: number;
    top: number;
  } {
    const dropdownBoundingRect =
      this.dropdownButton.nativeElement.getBoundingClientRect();

    const margin = 5;
    if (this.openDirection.horizontal === 'right') {
      if (this.openPosition === 'outside') {
        return {
          left: dropdownBoundingRect.right + margin,
          top:
            this.openDirection.vertical === 'down'
              ? dropdownBoundingRect.top
              : dropdownBoundingRect.bottom + margin,
        };
      } else {
        return {
          left:
            dropdownBoundingRect.right -
            this.container.nativeElement.clientWidth,
          top:
            this.openDirection.vertical === 'down'
              ? dropdownBoundingRect.top + dropdownBoundingRect.height + margin
              : dropdownBoundingRect.top -
                this.container.nativeElement.clientHeight,
        };
      }
    }
    if (this.openDirection.horizontal === 'left') {
      if (this.openPosition === 'outside') {
        return {
          left: dropdownBoundingRect.left,
          top:
            this.openDirection.vertical === 'up'
              ? dropdownBoundingRect.top + 90
              : dropdownBoundingRect.bottom,
        };
      } else {
        return {
          left: dropdownBoundingRect.left,
          top:
            this.openDirection.vertical === 'up'
              ? dropdownBoundingRect.top -
                this.container.nativeElement.clientHeight -
                margin
              : dropdownBoundingRect.bottom + margin,
        };
      }
    }
  }
}
