import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import {
  DocumentItem,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SlackService } from '@portal/notifications/services/slack.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddConnectionsComponent } from '@portal/shared/components/add-connections/add-connections.component';
import { PostNotesWizardComponent } from '@portal/shared/components/post-notes-wizard/post-notes-wizard.component';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'itq-document-item-actions',
  templateUrl: './document-item-actions.component.html',
  styleUrls: ['./document-item-actions.component.scss'],
  standalone: true,
  imports: [CoreModule, FAwesomeModule, MatTooltipModule, SvgComponent],
})
export class DocumentItemActionsComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() allowDrilldown = true;
  @Input() shareByEmail = true;
  @Input() allowCreateConnections = false;
  @Input() hasExpandedActions = false;

  @Output() openAction = new EventEmitter<void>();
  @Output() closeAction = new EventEmitter<void>();
  @Output() translate = new EventEmitter<void>();
  @Output() drilldown = new EventEmitter<ISegment>();
  @Output() shareEmail = new EventEmitter<DocumentItem>();

  readonly Sizes = Sizes;
  readonly Align = Align;

  constructor(
    public customOverlayService: CustomOverlayService,
    readonly slackService: SlackService,
    readonly notificationService: NotificationsService,
    readonly _snackBar: MatSnackBar,
    public keycloakService: KeycloakService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit() {}

  public onOpen(): void {
    this.openAction.emit();
  }

  public onClose(): void {
    this.closeAction.emit();
  }

  public onTranslate(): void {
    this.translate.emit();
  }

  public onDrilldownContext(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: SegmentScope.CONTEXT,
      value: extensionField,
      context: { document: this.document },
    });
  }

  public onShareSlack(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.openCustom(
      {
        title: 'Share to slack?',
        message: `Are you sure you want to share the document to slack?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          window.localStorage.setItem(
            'document',
            JSON.stringify(this.document),
          );
          const attachment = this.slackService.generateAttachment(
            this.document,
          );
          this.notificationService
            .postSlackMessage(attachment)
            .subscribe(() => {
              this._snackBar.open('Document shared succssefully!', 'Close', {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              });
            });
        }
      },
    );
  }

  public onAddNote(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.open({
      data: {
        document: this.document,
        profile: this.profileService.profile,
      },
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddConnection(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.open({
      data: {
        profile: this.profileService.profile,
        action: Actions.CREATE,
        form: new FormGroup({}),
      },
      type: CustomOverlayType['slide-right'],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onShareEmail(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.shareEmail.emit(this.document);
  }
}
