@if (document?.emitSource !== 'Discord') {
  @if (extractedData?.length > 0 && document.emitExtensionFields) {
    <div class="flex flex-col">
      <ng-container *ngFor="let extension of document.emitExtensionFields">
        @if (document.emitSource === 'Telegram') {
          <ng-container *ngIf="extension | getExtensionFieldName as fieldName">
            @if (
              fieldName[0] !== ExtensionDataFieldsText['topic'] &&
              fieldName[0] !== ExtensionDataFieldsText['urls']
            ) {
              <div
                class="mb-1 text-13 font-semibold uppercase pt-4"
                [innerHTML]="fieldName | highlightSearch: query"
              ></div>
            }
            <div
              class="flex mb-1"
              *ngFor="let item of extension | getExtensionFieldValues"
            >
              @if (isArray(item[1])) {
                @if (item[1]?.length > 0) {
                  <span class="text-xs">{{
                    ExtensionDataFieldsText[item[0]]
                  }}</span
                  >: <span>{{ item[1] }}</span>
                }
              } @else {
                @if (item[1] && item[1] !== '') {
                  <span class="text-xs">{{
                    ExtensionDataFieldsText[item[0]]
                  }}</span
                  >: <span class="text-xs" [innerHTML]="item[1]"></span>
                }
              }
            </div>
          </ng-container>
        } @else {
          @if (
            extension.name !== 'Links' &&
            extension.name !== 'Link' &&
            extension.name !== 'Number of Post Comments'
          ) {
            <div
              class="mb-1 text-13 font-semibold uppercase pt-4"
              [innerHTML]="extension.name | highlightSearch: query"
            ></div>
            @if (extension.value) {
              <span
                class="text-xs"
                [innerHTML]="extension.value | highlightSearch: query"
              ></span>
            } @else {
              @if (isArray(extension.selectors)) {
                <span
                  [innerHTML]="extension.value | highlightSearch: query"
                ></span>
              } @else {
                <div class="flex">
                  <ng-container *ngFor="let selector of extension.selectors">
                    @if (selector.scrapedData) {
                      <ng-container *ngFor="let item of selector.scrapedData">
                        @if (item.textContent.trim() !== '') {
                          <itq-pill
                            [class]="
                              'text-link hover:underline  h-[32px] rounded-md'
                            "
                            [innerHTML]="
                              item.textContent | highlightSearch: query
                            "
                          ></itq-pill>
                        }
                      </ng-container>
                    } @else {
                      @if (selector.trim() !== '') {
                        <itq-pill
                          [class]="
                            'text-link  h-[32px] hover:underline rounded-md'
                          "
                          [innerHTML]="selector | highlightSearch: query"
                        ></itq-pill>
                      }
                    }
                  </ng-container>
                </div>
              }
            }
          }
        }
      </ng-container>
    </div>
  }
} @else {
  <ng-container *ngFor="let extension of document.emitExtensionFields">
    @if (extension.name !== 'contentLinks') {
      <div class="flex flex-col">
        <div class="mb-1 text-13 font-semibold uppercase pt-4">
          {{ ExtensionDataFieldsText[extension.name] | highlightSearch: query }}
        </div>
        <div
          class="flex items-center"
          *ngFor="let item of extension.data | customSortExtensionFields"
        >
          <ng-container *ngFor="let dataItem of item | keyvalue">
            @if (
              dataItem.key !== ExtensionDataFields.AVATAR_URL && dataItem.value
            ) {
              <div class="flex items-center">
                <span class="text-xs font-medium pr-2"
                  >{{ ExtensionDataFieldsText[dataItem.key] }}:</span
                >
                @if (dataItem.key !== ExtensionDataFields.NAME) {
                  <span class="text-xs">{{
                    dataItem.key === ExtensionDataFields.ISBOT
                      ? 'True'
                      : dataItem.value
                  }}</span>
                } @else {
                  @if (drilldownSegment !== dataItem.value) {
                    <span
                      class="text-xs text-link hover:underline font-semibold"
                      (click)="$event.stopPropagation(); onDrilldown(extension)"
                    >
                      {{ dataItem.value }}
                    </span>
                  } @else {
                    <span class="text-xs">
                      {{ dataItem.value }}
                    </span>
                  }
                }
              </div>
            }
          </ng-container>
        </div>
      </div>
    }
  </ng-container>
}
