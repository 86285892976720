<itq-nav-button
  [openDirection]="{ vertical: 'down', horizontal: 'right' }"
  [openPosition]="'inside'"
  [template]="userTemplate"
  [materialStyle]="'basic'"
  [matTooltip]="'User settings'"
  [matTooltipPosition]="'below'"
>
  <fa-icon
    [icon]="['far', 'user-circle']"
    [size]="'lg'"
    class="text-white"
  ></fa-icon>
</itq-nav-button>
<ng-template #userTemplate>
  <ul class="user-menu-content flex flex-col rounded-md text-right">
    <li class="user bg-background py-2 rounded-md" *ngIf="userProfile">
      <span class="name block"
        >{{ userProfile.firstName }} {{ userProfile.lastName }}</span
      >
      <span class="email block">{{ userProfile.email }}</span>
      <span class="type label-secondary"
        >{{ role
        }}<span class="type-super-admin" *ngIf="isSuperAdmin">
          (Intorqa Admin)</span
        ></span
      >
    </li>
    <li>
      <button type="button" class="btn btn-link" (click)="accountManagement()">
        My account
      </button>
    </li>
    <li>
      <button class="btn btn-link" type="button" (click)="logOut()">
        Sign out
      </button>
    </li>
  </ul>
</ng-template>
