import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreModule, FAwesomeModule, IconType, Sizes } from '@intorqa-ui/core';
import { LibrarySidebarItemComponent } from '../library-sidebar-item/library-sidebar-item.component';
import { forkJoin, Subscription } from 'rxjs';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { EventsService } from '@portal/shared/services/events.service';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { HasEventsEnabledPipe } from '@portal/shared/pipes/events.pipe';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';

@Component({
  selector: 'itq-library-sidebar',
  templateUrl: './library-sidebar.component.html',
  styleUrls: ['./library-sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CoreModule,
    LibrarySidebarItemComponent,
    FAwesomeModule,
    HasEventsEnabledPipe,
  ],
})
export class LibrarySidebarComponent implements OnInit {
  public vendorsDataSource: Array<any> = [];
  public tagsDataSource: Array<any> = [];
  public eventsDataSource: Array<any> = [];
  public vendorsTotalCount: number;
  public tagsTotalCount: number;
  public eventsTotalCount: number;
  private subscriptions = new Subscription();

  readonly Sizes = Sizes;
  readonly IconType = IconType;

  constructor(
    readonly router: Router,
    readonly tagService: TagService,
    readonly profileService: ProfileService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    readonly ecosystemService: EcosystemsService,
  ) {}

  ngOnInit() {
    this.onGetTotalCounts();
    this.bindChangeEcosystemSubscription();
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.onGetTotalCounts();
      }),
    );
  }

  public onNavigate(): void {
    this.router.navigateByUrl('/library');
  }

  private onGetTotalCounts(): void {
    forkJoin([
      this.tagService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.profileService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.eventsService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
    ]).subscribe((response: [number, number, number]) => {
      this.vendorsTotalCount = response[1];
      this.tagsTotalCount = response[0];
      this.eventsTotalCount = response[2];
    });
  }
}
