import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  FAwesomeModule,
  HighlightSearchPipe,
  OverlayService,
  QueryFilters,
  SearchFieldType,
  Sizes,
} from '@intorqa-ui/core';
import {
  ProfileDrildownScope,
  ProfileTypeIcon,
} from '@portal/profiles/enums/profile.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-field-vendors-links',
  templateUrl: './field-vendors-links.component.html',
  styleUrls: ['./field-vendors-links.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    FormsModule,
    HighlightSearchPipe,
  ],
})
export class FieldVendorsLinksComponent implements OnInit {
  @Input() field: string;
  @Input() fieldValue: string;

  @Output() createProfile = new EventEmitter<ProfileType>();
  @Output() addProfile = new EventEmitter<Profile>();
  @Output() explore = new EventEmitter<void>();

  public selections: Array<Profile> = [];
  public tagLinks: Array<string>;
  private _vendorsDataSource: IProfileData;
  public get vendorsDataSource(): IProfileData {
    return this._vendorsDataSource;
  }

  public set vendorsDataSource(value: IProfileData) {
    if (this.initialState.page === 1) {
      this._vendorsDataSource = value;
    } else if (this.initialState.page > 1) {
      this._vendorsDataSource.items = this._vendorsDataSource.items.concat(
        value.items,
      );
    }
  }
  public vendorTypesDataSurce: Array<ProfileType> = [];
  public selectedSection: { action: string; name: string };
  public sectionsDataSource: Array<{ action: string; name: string }>;
  public showLoader = false;
  public initialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    {
      direction: 'asc',
      active: 'name',
    },
    undefined,
  );
  public query: string;

  readonly ProfileTypeIcon = ProfileTypeIcon;
  readonly Align = Align;
  readonly Sizes = Sizes;
  readonly ProfileDrildownScope = ProfileDrildownScope;

  constructor(
    readonly userService: UserService,
    readonly profileService: ProfileService,
    readonly overlayService: OverlayService,
    readonly cdr: ChangeDetectorRef,
    readonly linkTagsService: LinkTagsService,
  ) {}

  ngOnInit() {
    this.sectionsDataSource = [
      {
        action: 'update',
        name: 'Add to existing vendor',
      },
      { action: 'create', name: 'Add to new vendor' },
    ];
    this.selectedSection = this.sectionsDataSource[0];
    this.onNavChange(this.selectedSection);
  }

  private getLinks(vendors: IProfileData): void {
    this.linkTagsService
      .getTargetLinks(undefined, this.fieldValue, this.field)
      .subscribe((response: Array<string>) => {
        this.vendorsDataSource = vendors;
        this.tagLinks = response;
        this.selections = response?.map((id: string) => {
          return this.vendorsDataSource.items.find(
            (profile: Profile) => profile.profileId === id,
          );
        });
        this.showLoader = false;
        this.cdr.detectChanges();
      });
  }

  onCreateProfile(item: ProfileType): void {
    this.createProfile.emit(item);
  }

  onAddProfile(): void {
    const profileToLink = this.selections.find(
      (profile) => !this.tagLinks.includes(profile.profileId),
    );
    this.addProfile.emit(profileToLink);
    this.overlayService.closeOverlay();
  }

  public onExplore(): void {
    this.explore.emit();
    this.overlayService.closeOverlay();
  }

  public onGetVendors(): void {
    this.showLoader = true;
    this.profileService
      .getProfiles(
        this.initialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IProfileData) => {
        if (!this.tagLinks) {
          this.getLinks(response);
        } else {
          this.vendorsDataSource = response;
          this.showLoader = false;
          this.cdr.detectChanges();
        }
      });
  }

  public onSearch(): void {
    if (this.query) {
      this.initialState.addQueryColumn({
        searchValues: [this.query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.initialState.removeQueryColumn('name');
    }
    this.initialState.resetPagination();
    this.onGetVendors();
  }

  public onGetProfileTypes(): void {
    this.showLoader = true;
    this.profileService
      .getProfileTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.vendorTypesDataSurce = response;
        this.showLoader = false;
        this.cdr.detectChanges();
      });
  }

  public onNavChange(section: { action: string; name: string }): void {
    this.selectedSection = section;
    if (this.selectedSection.action === 'update') {
      this.onGetVendors();
    } else {
      this.onGetProfileTypes();
    }
  }
}
