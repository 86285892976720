import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreModule, IconType, ThumbnailComponent } from '@intorqa-ui/core';
import { UserService } from '@portal/shared/services/user.service';
import { IUserCategory } from '@portal/tags/interfaces/tag.interface';

@Component({
  selector: 'itq-tag-type-thumbnail-list',
  templateUrl: './tag-type-thumbnail-list.component.html',
  styleUrls: ['./tag-type-thumbnail-list.component.scss'],
  standalone: true,
  imports: [CoreModule, ThumbnailComponent, CommonModule],
})
export class TagTypeThumbnailListComponent implements OnInit {
  @Output() add = new EventEmitter<string>();

  @Input() dataSource: Array<IUserCategory>;
  @Input() disabled: boolean;

  readonly IconType = IconType;

  constructor(public readonly userService: UserService) {}

  ngOnInit() {}

  public onAdd(type: string): void {
    this.add.emit(type);
  }
}
