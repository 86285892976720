<div class="flex flex-col w-full">
  <itq-tag-wizard-nav-item
    [errors]="!form?.get('filters')?.valid"
    [label]="'Search & filters'"
    [icon]="'tune'"
    [active]="active === TagWizardNavItem.FILTERS"
    [completed]="completed?.includes(TagWizardNavItem.FILTERS)"
    (click)="onLoadFilters()"
  ></itq-tag-wizard-nav-item>
  @if (this.keycloakService.isUserInRole('saas-alerts')) {
    <itq-tag-wizard-nav-item
      [errors]="form?.get('alerts')?.errors?.length > 0"
      [label]="'Alerts'"
      [icon]="'notifications'"
      [active]="active === TagWizardNavItem.NOTIFICATIONS"
      [completed]="completed?.includes(TagWizardNavItem.NOTIFICATIONS)"
      (click)="onLoadNotifications()"
    ></itq-tag-wizard-nav-item>
  }
  <itq-tag-wizard-nav-item
    [ngClass]="{
      'opacity-50': !form?.get('filters')?.valid || !form?.get('alerts')?.valid
    }"
    [label]="'Review & save'"
    [icon]="'task_alt'"
    [active]="active === TagWizardNavItem.SAVE"
    (click)="onLoadSave()"
  ></itq-tag-wizard-nav-item>
</div>
