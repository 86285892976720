<table cellpadding="0" cellspacing="0" #grid>
  <thead>
    <tr>
      <ng-container *ngFor="let item of tableColumns; let i = index">
        @if (item.show !== false) {
          <th
            [ngClass]="{
              'text-metadata':
                initialState?.sort?.active !==
                (item.searchField || item.dataKey),
              'font-semibold!':
                initialState?.sort?.active ===
                (item.searchField || item.dataKey),
              'text-right': item.position === 'right' ? true : false,
              'text-center': item.position === 'center' ? true : false,
              'text-left': item.position === 'left' ? true : false
            }"
            [ngStyle]="{
              width:
                item.width && item.width !== 'fit' ? item.width : undefined,
              flex: item.width && item.width === 'fit' ? 1 : undefined,
              padding: item.padding,
              position: item.sticky ? 'sticky' : undefined
            }"
            (click)="onSort(item)"
          >
            <div
              class="mat-sort-header-content cell__container"
              [ngStyle]="{
                'justify-content':
                  item.position !== 'center' ? item.position : 'center'
              }"
            >
              @if (item.name) {
                <span
                  [ngClass]="{
                    'header__container w-full': true,
                    'pl-7': i === 0 ? true : false
                  }"
                >
                  {{ item.name }}
                </span>
              }

              <fa-icon
                [ngClass]="{
                  'px-4 sort__icon': true,
                  flex:
                    initialState?.sort?.active ===
                    (item.searchField || item.dataKey),
                  hidden:
                    initialState?.sort?.active !==
                    (item.searchField || item.dataKey)
                }"
                [icon]="[
                  'far',
                  initialState?.sort?.direction === 'asc'
                    ? 'arrow-up'
                    : 'arrow-down'
                ]"
              ></fa-icon>
            </div>
          </th>
        }
      </ng-container>
    </tr>
    @if (showFilters) {
      <tr class="border-t border-border">
        <ng-container *ngFor="let item of tableColumns">
          @if (item.show !== false) {
            <td
              [ngStyle]="{
                width:
                  item.width && item.width !== 'fit' ? item.width : undefined,
                flex: item.width && item.width === 'fit' ? 1 : undefined,
                padding: item.padding || '0 0 0 1rem',
                position: item.sticky ? 'sticky' : undefined
              }"
            >
              <itq-virtual-table-filters
                [item]="item"
                [tableColumns]="tableColumns"
                (dataBound)="onDataBound($event)"
                (clearSearch)="onClearSearch($event)"
                (search)="onApplySearch($event)"
                (multipleDropdownChange)="onMultipleDropdownChange($event)"
                (dropdownChange)="onChangeValue($event)"
              ></itq-virtual-table-filters>
            </td>
          }
        </ng-container>
      </tr>
    }
  </thead>
  <tbody
    #tbody
    [ngClass]="{
      'no-results': dataSource?.length === 0 || !dataSource,
      'block overflow-auto': true
    }"
    infiniteScroll
    (scrolledToEnd)="onScroll()"
  >
    @for (item of dataSource; track item[trackBy]) {
      <tr
        [ngClass]="{
          table_row: true,
          success: item.success,
          error: item.error,
          active: active && active === trackBy
        }"
        (click)="onRowClick(item)"
      >
        <ng-container *ngFor="let column of tableColumns; let i = index">
          @if (column.show !== false) {
            <td
              [class.text-right]="column.position === 'right'"
              [class.text-center]="column.position === 'center'"
              [class.text-left]="column.position === 'left'"
              [ngClass]="[
                initialState?.sort?.active ===
                (column.searchField || column.dataKey)
                  ? 'font-semibold!'
                  : ''
              ]"
              [ngStyle]="{
                'justify-content':
                  column.position !== 'center' ? column.position : 'center',
                width:
                  column.width && column.width !== 'fit'
                    ? column.width
                    : undefined,
                flex: column.width && column.width === 'fit' ? 1 : undefined,
                padding: column.padding,
                position: column.sticky ? 'sticky' : undefined
              }"
            >
              <div
                [ngClass]="{
                  'flex h-full items-center w-full border-b border-border body_column': true,
                  'ml-7': i === 0 ? true : false,
                  'justify-center': column.position === 'center' ? true : false,
                  'justify-left': column.position === 'left' ? true : false,
                  'justify-right': column.position === 'right' ? true : false,
                  'last-column': i === tableColumns.length - 1 ? true : false
                }"
              >
                @if (column.customRender) {
                  <ng-container
                    *ngTemplateOutlet="
                      column.template;
                      context: {
                        $implicit: item,
                        index: i,
                        data: column.templateData
                      }
                    "
                  ></ng-container>
                } @else {
                  <span>{{ item | dataPropertyGetter: column.dataKey }}</span>
                }
              </div>
            </td>
          }
        </ng-container>
      </tr>
    }
    @if (dataSource && dataSource?.length === 0) {
      <tr>
        <td>
          <itq-panel-info
            class="p-7 bg-white rounded-md"
            [label]="emptyMessage"
            [message]="emptyMessageDescription"
            [icon]="'exclamation-circle'"
          ></itq-panel-info>
        </td>
      </tr>
    }
  </tbody>
</table>
