<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex justify-between w-full flex-col space-y-2">
      <div class="flex items-center">
        <fa-icon [icon]="['far', 'user']" [size]="'2x'"></fa-icon>

        <h1 class="text-28 font-bold pl-4">
          {{ widget?.name }}
        </h1>
        <itq-pill
          [class]="
            'rounded-2xl px-4 py-2 ml-7 shadow-none font-semibold bg-primary-over uppercase h-[36px]'
          "
          [type]="PillType.CUSTOM"
          >{{ widget.type | mapLabel }}</itq-pill
        >
      </div>
      @if (widget?.description) {
        <span class="text-base"> {{ widget.description }}</span>
      }
    </div>
    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <fa-icon [icon]="['far', 'refresh']"></fa-icon>
        <span class="whitespace-nowrap">REFRESH</span>
      </itq-mat-button>
      <itq-mat-button
        [disabled]="count === 0"
        [materialStyle]="'stroked'"
        (clickEvent)="onExport()"
      >
        <fa-icon [icon]="['far', 'download']"></fa-icon>
        <span class="whitespace-nowrap">EXPORT</span>
      </itq-mat-button>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ count | number }}</itq-pill
        >
      </div>

      <div class="flex flex-col px-7 py-4">
        <h2 class="flex font-bold pb-2 text-xl">Search</h2>
        <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
        <itq-input
          class="w-full pb-4"
          [icon]="'search'"
          [placeholder]="'Search...'"
          formControlName="query"
          (search)="onChangeSearch()"
          (clear)="onChangeSearch()"
        ></itq-input>
        <h2 class="flex font-bold pb-4 text-xl">Filters</h2>
        <div class="flex 2 flex-col overflow-hidden">
          <span class="mb-1 text-13 font-medium flex"> DATE RANGE </span>

          <itq-date-range
            class="w-full"
            [dates]="this.initialState?.where"
            (search)="onChangeDate($event)"
          ></itq-date-range>
        </div>
      </div>
    </div>
    <div class="flex flex-1 border-r border-border flex-col">
      <div
        class="flex items-center px-7 py-3 justify-start w-full border-b border-border"
      >
        <span class="font-semibold mr-2">Display:</span>
        <itq-button-thumbnail
          [dataSource]="chartTypesDataSource"
          formControlName="chartType"
          (change)="onChangeChartType($event)"
        ></itq-button-thumbnail>
      </div>
      <div class="flex flex-1 overflow-hidden">
        <div class="flex flex-col bg-background border-r border-border flex-1">
          <div
            class="flex flex-col p-7 py-4 flex-1 h-full min-w-[500px]"
            #chartContainer
          >
            @if (widget?.chartType === ChartType.TABLE) {
              <itq-table-chart
                class="overflow-hidden"
                [dataSource]="dataSource"
                [tableColumns]="tableColumns"
                [trackBy]="'label'"
                [segment]="segment"
              ></itq-table-chart>
            } @else {
              <itq-chart
                class="h-full"
                [id]="widget?.widgetId + '_explore'"
                [data]="dataSource"
                [settings]="widget?.options"
                [segment]="segment"
                [type]="widget?.chartType"
                (drilldown)="onDrilldownChart($event)"
                (updateRef)="onUpdateRef($event)"
              ></itq-chart>
            }
          </div>
        </div>
        @if (segment?.data?.value) {
          <div class="flex flex-col flex-1 min-w-[500px]">
            <div class="flex flex-1 overflow-hidden">
              @if (documentsData) {
                <itq-documents-feed
                  class="h-full flex flex-col overflow-hidden"
                  [data]="documentsData"
                  [initialState]="initialState"
                  (scrollEvent)="onDataBound($event)"
                  (shareEmail)="onShareEmail($event)"
                  (drilldown)="onDrilldown($event)"
                  (createProfile)="onCreateProfile($event)"
                ></itq-documents-feed>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</form>

<ng-template #actorTemplate let-row>
  @if (segment?.data?.name !== row.label) {
    <button
      type="button"
      class="btn btn-link-secondary"
      (click)="onDrilldownActor(row)"
    >
      <span class="normal-case"> {{ row.label }}</span>
    </button>
  } @else {
    <span class="min-w-[32px] py-2 font-semibold">{{ row.label }}</span>
  }
</ng-template>

<ng-template #countTemplate let-row>
  @if (segment?.data?.value !== row.count) {
    <button
      type="button"
      class="btn btn-link-secondary flex w-full justify-center"
      (click)="onLoadCount(row)"
    >
      {{ row.count }}
    </button>
  } @else {
    <span class="min-w-[32px] p-2 font-semibold flex justify-center">{{
      row.count | number
    }}</span>
  }
</ng-template>

@if (showLoader) {
  <itq-loader></itq-loader>
}
