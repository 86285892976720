<div
  class="flex items-center p-2 w-full justify-between space-x-4"
  (mousedown)="$event.stopImmediatePropagation()"
>
  <itq-input
    class="flex-1"
    [(ngModel)]="query"
    [placeholder]="'Search...'"
    (search)="onDataBound()"
    (clear)="onDataBound()"
  ></itq-input>
  <mat-slide-toggle [(ngModel)]="includeEmpty" (change)="onToggleShowAll()">
    <span class="pl-2">Include empty results</span>
  </mat-slide-toggle>
</div>
<div class="dropdown-container">
  @if (dataSource?.length > 0) {
    <div
      class="flex flex-col overflow-auto"
      #listSearch
      infiniteScroll
      (scrolledToEnd)="onScroll()"
    >
      <mat-selection-list
        [(ngModel)]="selections"
        class="max-h-[336px] overflow-auto"
        (selectionChange)="onChangeValue()"
      >
        <mat-list-option
          class="border-border border-t"
          *ngFor="let item of dataSource"
          [value]="item"
          [selected]="isChecked(item)"
        >
          <div class="flex justify-between w-full items-center">
            <span
              class="flex-1 text-ellipsis overflow-hidden"
              [innerHTML]="item[dataFields?.name] | highlightSearch: query"
            >
              {{ item[dataFields?.name] }}
            </span>
            @if (item.count) {
              <strong>({{ item.count }})</strong>
            }
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  } @else {
    @if (dataSource?.length === 0) {
      <span
        class="italic text-sm text-gray-700 m-2 flex items-center space-x-1"
      >
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
        <span>No results found!</span>
      </span>
    } @else {
      <itq-loader class="p-4"></itq-loader>
    }
  }
</div>
@if (showLoader) {
  <itq-loader></itq-loader>
}
