<div class="flex w-full max-h-[350px] overflow-hidden">
  <div class="flex flex-col">
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item (click)="onDeleteWidget()">
        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        <span class="whitespace-nowrap"> Remove widget </span>
      </mat-list-item>
      <mat-list-item
        [cdkCopyToClipboard]="tagShareLink"
        (click)="onShareLink()"
      >
        <fa-icon [icon]="['far', 'link']"></fa-icon>
        <span class="whitespace-nowrap"> Share link </span>
      </mat-list-item>

      <mat-list-item
        [style.background-color]="selectedSection ? '#f5f5f5 !important' : ''"
        (click)="onNavChange()"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-semibold': selectedSection
          }"
        >
          <div class="flex items-center space-x-2">
            <span class="whitespace-nowrap"> Create new widget </span>
          </div>
          <mat-icon>{{
            selectedSection ? 'chevron_right' : 'expand_more'
          }}</mat-icon>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>
  @if (selectedSection) {
    <mat-nav-list
      class="flex-1 border-l w-[200px] border-border overflow-auto"
      [style.padding-left]="'8px !important'"
      [style.margin-left]="'8px !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      @for (item of transformTypesDataSource; track item.id) {
        <mat-list-item
          class="hover:bg-background-over"
          (click)="onCreateWidget(item.id)"
        >
          <div class="flex items-center space-x-2 w-full">
            @if (item.iconType === IconType.SVG) {
              <itq-svg [icon]="item.icon" class="w-3 h-3"></itq-svg>
            } @else {
              <fa-icon [icon]="['far', item.icon]"></fa-icon>
            }
            <span class="capitalize">
              {{ item.name }}
            </span>
          </div>
        </mat-list-item>
      }
    </mat-nav-list>
  }
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
