import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from '@portal/notifications/models/alert-type';

@Pipe({
  name: 'getAlertTypeLabel',
  standalone: true,
})
export class GetAlertTypeLabelPipe implements PipeTransform {
  transform(value: string, data: Array<AlertType>): string {
    const alertType = data?.find((item: AlertType) => item.id === value);
    return alertType?.label;
  }
}
