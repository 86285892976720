<ng-container [formGroup]="form">
  <div class="flex flex-col w-full" [formGroupName]="'alerts'">
    <div class="px-7 py-4 flex-1 w-full">
      <h2 class="flex font-bold pb-2 text-xl">Alerts</h2>
      <p class="pb-4">So you want to set up an alert for this tag?</p>
      <mat-radio-group
        formControlName="create"
        class="flex"
        (change)="onChangeCreate()"
      >
        <mat-radio-button
          [ngClass]="{
            'flex-1 p-4 rounded-tl-md rounded-bl-md border border-primary bg-primary-over': true,
            'border-primary bg-primary-over':
              form?.get('alerts.create')?.value === 'yes',
            'border-border bg-background-over border-r-0':
              form?.get('alerts.create')?.value === 'no'
          }"
          [value]="'yes'"
          >Yes, create an alert</mat-radio-button
        >
        <mat-radio-button
          [ngClass]="{
            'flex-1 p-4 rounded-tr-md rounded-br-md border': true,
            'border-border bg-background-over border-l-0':
              form?.get('alerts.create')?.value === 'yes',
            'border-primary bg-primary-over':
              form?.get('alerts.create')?.value === 'no'
          }"
          [value]="'no'"
          >No</mat-radio-button
        >
      </mat-radio-group>

      @if (form?.get('alerts.create')?.value === 'yes') {
        <div class="w-full flex flex-col">
          <div class="pb-4 pt-7 font-semibold">Alert type</div>
          <mat-radio-group
            formControlName="alertTypeId"
            class="flex"
            (change)="onChangeAlertType()"
          >
            <mat-radio-button
              *ngFor="let item of alertTypesDataSource; let i = index"
              [ngClass]="{
                'flex-1 p-4 border': true,
                'rounded-tl-md rounded-bl-md': i === 0,
                'rounded-tr-md rounded-br-md': i === 1,
                'border-r-0': i === 0,
                'border-l-primary': i === 1,
                'border-primary bg-primary-over':
                  form?.get('alerts.alertTypeId')?.value === item.id,
                'border-border bg-background-over':
                  form?.get('alerts.alertTypeId')?.value !== item.id
              }"
              [value]="item.id"
              >{{ item.label }}</mat-radio-button
            >
          </mat-radio-group>
        </div>

        @if (
          (form?.get('alerts.alertTypeId')?.value
            | getAlertTypeLabel: this.alertTypesDataSource) ===
          AlertTypes.TAG_MATCH
        ) {
          <itq-tag-match-alert
            class="flex w-full"
            [action]="action"
            [form]="form"
            [tag]="tag"
          ></itq-tag-match-alert>
        } @else {
          <itq-tag-threshold-alert
            class="flex w-full"
            [form]="form"
            [tag]="tag"
            [action]="action"
            [query]="form?.get('alerts.query')?.value"
          ></itq-tag-threshold-alert>
        }
        <div class="flex w-full">
          <div class="flex flex-col w-full">
            <div class="pb-2 pt-7 font-semibold">Message</div>
            <itq-input
              [formControlName]="'message'"
              [placeholder]="'Message...'"
            ></itq-input>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
