import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { KeycloakService } from 'keycloak-angular';

@Pipe({
  name: 'formatDates',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(data: Array<any>): Array<any> {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          createdDate: this.datePipe.transform(item.createdDate, 'yyyy dd MMM'),
          updatedDate: this.datePipe.transform(item.updatedDate, 'yyyy dd MMM'),
        },
      };
    });
  }
}

@Pipe({
  name: 'isTagEnabled',
  pure: false,
})
export class IsTagEnabledPipe implements PipeTransform {
  constructor(private keycloakService: KeycloakService) {}

  transform(tag: Timeline): boolean {
    const isSuperAdmin = this.keycloakService.isUserInRole('super-admin');
    if (tag.categoryId && isSuperAdmin) {
      return true;
    }
    if (
      tag.username ===
        this.keycloakService.getKeycloakInstance().profile.username ||
      isSuperAdmin
    ) {
      return true;
    }
    return false;
  }
}
