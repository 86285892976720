import { Actions, DTOQuery, QueryFilters, TagCategory } from '@intorqa-ui/core';
import { TAG_COMPARISON_CHART_TYPES } from '@portal/boards/const/tag-comparison.const';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
  ITagComparison,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import {
  ChartOption,
  ChartOrientation,
  ChartType,
} from '@portal/widgets/enums/chart.enum';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { Widget } from './widget';

export class TagComparison extends Widget implements ITagComparison {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: { [key: string]: any },
    public dataSource: Array<string>,
    public dataValues: Array<string>,
    public dataType: TagCategory,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public getDisplayType(): IDisplayType {
    if (this.chartType === ChartType.TABLE) {
      return TAG_COMPARISON_CHART_TYPES.find((displayType: IDisplayType) => {
        return displayType.type === this.chartType;
      });
    } else {
      if (this.options.orientation || this.options.mode) {
        return TAG_COMPARISON_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            ((this.options.orientation &&
              this.options.orientation === displayType.options?.orientation) ||
              (this.options.mode &&
                this.options.mode === displayType.options?.mode))
          );
        });
      }
    }
  }

  public hasMetrics(): boolean {
    return this.dataValues?.length > 0 ? true : false;
  }

  public resetMetrics(): void {
    this.dataValues = undefined;
    this.dataSource = undefined;
    this.dataType = undefined;
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
      dataValue: params.dataValue,
    };
  }

  public canLoadDocuments(action: Actions, segment: any): boolean {
    return action === Actions.EXPLORE && segment;
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    const filteredSeries = series.filter((item: ISerie) => {
      return item.data.length > 0;
    });
    return filteredSeries.map((serie: ISerie) => {
      return {
        ...serie,
        data: serie.data.sort((a: IDataPoint, b: IDataPoint) => {
          if (this.options.orientation === ChartOrientation.VERTICAL) {
            return a.count > b.count ? -1 : 1;
          } else {
            return a.count > b.count ? 1 : -1;
          }
        }),
      };
    });
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TAG_COMPARISON_CHART_TYPES;
  }

  public getRuleValue(): Array<string> {
    return [];
  }

  public getTagId(index: number): string {
    return this.dataValues[index];
  }

  public getOption(option: ChartOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: ChartOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): TagComparison {
    return new TagComparison(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.dataValues,
      this.dataType,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
    );
  }
}
