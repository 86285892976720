<aside #sidebar id="default-sidebar" class="sidebar-container">
  <div class="sidebar__header flex flex-1 mr-7">
    <a
      class="logo ml-4 items-center flex justify-center font-semibold font-mont"
      [routerLink]="['/']"
    >
      <itq-svg class="w-9 h-9 mr-1 fill-primary" [icon]="'logo'"></itq-svg>
      <span class="text-white ml-1 text-base hidden lg:flex">Intorqa</span>
    </a>
  </div>
  <div class="flex items-center">
    <div class="flex items-center">
      <span class="text-white pr-2 font-semibold hidden lg:flex"
        >ECOSYSTEM:</span
      >
      <itq-dropdown
        [(ngModel)]="ecosystemId"
        [dataSource]="ecosystemDataSource"
        [width]="'250'"
        [clearSelection]="false"
        [dataFields]="{ name: 'name', value: 'id' }"
        (changeValue)="onChangeEcosystem()"
      ></itq-dropdown>
    </div>
    <nav class="sidebar__body flex-1">
      <ul class="justify-center text-15">
        <li class="flex justify-center">
          <a
            class="font-semibold sidebar__icon items-center"
            [routerLinkActive]="'active'"
            [routerLink]="['/boards']"
          >
            <itq-svg
              class="w-6 h-7 mr-1 fill-current"
              [icon]="'board-square'"
            ></itq-svg>
            BOARDS
          </a>
        </li>
        <li class="flex justify-center">
          <a
            class="font-semibold sidebar__icon items-center"
            [routerLinkActive]="'active'"
            [routerLink]="['/library']"
          >
            <itq-svg
              class="w-6 h-6 mr-1 fill-current"
              [icon]="'vendor'"
            ></itq-svg>
            LIBRARY
          </a>
        </li>
        @if (this.keycloakService.isUserInRole('saas-profiles')) {
          <li class="flex justify-center">
            <a
              class="font-semibold sidebar__icon items-center"
              [routerLinkActive]="'active'"
              [routerLink]="['/manager/vendors']"
            >
              <itq-svg
                class="w-6 h-6 mr-1 fill-current"
                [icon]="'vendor'"
              ></itq-svg>

              VENDORS
            </a>
          </li>
        }
      </ul>
    </nav>
  </div>
  <div class="sidebar__footer flex-1 justify-end">
    <itq-mat-button
      class="mr-2"
      [type]="'button'"
      [style]="'secondary'"
      [materialStyle]="'flat'"
      (mouseDownEvent)="onCreateSearch()"
    >
      <fa-icon
        class="font-semibold"
        [icon]="['far', 'magnifying-glass']"
      ></fa-icon>
      <span class="text-wrapper font-semibold">SEARCH</span>
    </itq-mat-button>
    @if (this.keycloakService.isUserInRole('saas-alerts')) {
      <div class="item__notifications_hub relative">
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [type]="'button'"
          [materialStyle]="'basic'"
          [matTooltip]="'Notifications'"
          [matTooltipPosition]="'below'"
          (clickEvent)="$event.stopImmediatePropagation(); toggleNotiications()"
        >
          <fa-icon class="text-white" [icon]="['far', 'bell']"></fa-icon>
        </itq-mat-button>
        @if (unreadStatus) {
          <div class="circle"></div>
        }
      </div>
    }

    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [materialStyle]="'basic'"
      [matTooltip]="'Help'"
      [matTooltipPosition]="'below'"
      (clickEvent)="onLoadHelp()"
    >
      <fa-icon
        [icon]="['far', 'question-circle']"
        class="text-white"
        [size]="'lg'"
      ></fa-icon>
    </itq-mat-button>
    <itq-user-account></itq-user-account>
  </div>
</aside>
