<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex justify-between w-full flex-col space-y-2">
      <div class="flex items-center">
        <fa-icon [icon]="['far', 'timeline']" [size]="'2x'"></fa-icon>

        <h1 class="text-28 font-bold pl-4">
          {{ widget?.name || 'Search' }}
        </h1>
        <itq-pill
          [class]="
            'rounded-2xl px-4 py-2 ml-7 shadow-none font-semibold bg-primary-over uppercase h-[36px]'
          "
          [type]="PillType.CUSTOM"
          >{{ widget.type | mapLabel }}</itq-pill
        >
      </div>
      @if (widget?.description) {
        <span class="text-base"> {{ widget.description }}</span>
      }
    </div>
    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <fa-icon [icon]="['far', 'refresh']"></fa-icon>
        <span class="whitespace-nowrap">REFRESH</span>
      </itq-mat-button>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ count | number }}</itq-pill
        >
      </div>

      <div class="flex flex-col px-7 py-4">
        <h2 class="flex font-bold pb-4 text-xl">Filters</h2>
        <div class="flex flex-col flex-1 mb-4">
          <div
            class="mb-1 text-13 font-medium justify-between items-center flex"
          >
            SECURITY TEAM
            <mat-slide-toggle
              [formControlName]="'selectAllSecurity'"
              (change)="onToggleSecurity($event)"
            >
              <span class="font-medium">SelectAll</span>
            </mat-slide-toggle>
          </div>
          <itq-multiple-dropdown
            [dataSource]="securityTeamDataSource"
            [placeholder]="'Event type...'"
            formControlName="securityTeam"
            [dataFields]="{ name: 'name', value: 'id' }"
            [initialState]="securityTeamInitialState"
            (changeValue)="onChangeEvents()"
          >
          </itq-multiple-dropdown>
        </div>
        <div class="flex flex-col flex-1 mb-4">
          <div
            class="mb-1 text-13 font-medium justify-between items-center flex"
          >
            CHEATER TEAM
            <mat-slide-toggle
              [formControlName]="'selectAllCheater'"
              (change)="onToggleCheater($event)"
            >
              <span class="font-medium">SelectAll</span>
            </mat-slide-toggle>
          </div>
          <itq-multiple-dropdown
            [dataSource]="cheaterTeamDataSource"
            formControlName="cheaterTeam"
            [placeholder]="'Event type...'"
            [initialState]="cheaterTeamInitialState"
            [dataFields]="{ name: 'name', value: 'id' }"
            (changeValue)="onChangeEvents()"
          >
          </itq-multiple-dropdown>
        </div>
        <div class="flex 2 flex-col overflow-hidden">
          <span class="mb-1 text-13 font-medium flex"> DATE RANGE </span>
          <itq-date-range
            class="w-full"
            [dates]="this.initialState?.where"
            [openPosition]="'outside'"
            (search)="onChangeDate($event)"
          ></itq-date-range>
        </div>
      </div>
    </div>
    <div
      class="flex flex-1 border-r border-border flex-col overflow-hidden min-w-[1024px]"
    >
      <div
        class="flex items-center px-7 py-3 justify-start w-full border-b border-border"
      >
        <span class="font-semibold mr-2">Display:</span>
        <itq-button-thumbnail
          [dataSource]="chartTypesDataSource"
          formControlName="chartType"
          (change)="onChangeChartType($event)"
        ></itq-button-thumbnail>
      </div>
      <div
        [ngClass]="{
          'flex flex-1 overflow-hidden': true,
          'flex-col':
            this.widget?.options?.orientation === TimelineOrientation.HORIZONTAL
        }"
      >
        <div
          [ngClass]="{
            'flex bg-background border-border overflow-auto': true,
            'border-r':
              this.widget?.options?.orientation ===
              TimelineOrientation.VERTICAL,
            'border-b':
              this.widget?.options?.orientation ===
              TimelineOrientation.HORIZONTAL
          }"
          [ngStyle]="{ flex: 2 }"
        >
          <div
            [ngClass]="{
              'flex flex-col flex-1 h-full': true,
              'min-w-[915px]':
                this.widget?.options?.orientation ===
                TimelineOrientation.VERTICAL
            }"
          >
            <itq-events-timeline
              class="flex w-full h-full p-7 pt-4"
              [orientation]="this.widget?.options?.orientation"
              [dataSource]="dataSource | sortDescending"
              [widget]="widget"
              [segment]="
                widget?.dataCorrelation?.length > 0 ? segment : undefined
              "
            ></itq-events-timeline>
          </div>
        </div>
        @if (trendDataSource) {
          <div
            [ngClass]="{
              'flex flex-col flex-1': true,
              'min-w-[300px]':
                this.widget?.options?.orientation ===
                TimelineOrientation.VERTICAL
            }"
          >
            <div class="flex flex-1 m-2 overflow-hidden">
              <itq-chart
                class="h-full min-h-[150px]"
                [data]="trendDataSource"
                [id]="navigationItem?.id"
                [type]="ChartType.BAR"
                [settings]="trendWidget?.options"
                [segment]="segment"
              ></itq-chart>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</form>

@if (showLoader) {
  <itq-loader></itq-loader>
}
