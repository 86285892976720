import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  Actions,
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
} from '@intorqa-ui/core';
import { DocumentItem } from '@portal/document/interfaces/document.interface';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { ConnectionsService } from '@portal/profiles/services/connections.service';

@Component({
  selector: 'itq-post-notes',
  templateUrl: './post-notes.component.html',
  styleUrls: ['./post-notes.component.scss'],
})
export class PostNotesComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() note: ProfileNote;

  readonly Actions = Actions;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly connectionsService: ConnectionsService,
    readonly customOverlayRef: CustomOverlayRef,
  ) {}

  ngOnInit() {}
}
