<div
  class="p-4 pb-2 rounded-md border bg-background border-border flex w-full flex-col"
>
  <div class="font-semibold mb-2">{{ query?.condition.toUpperCase() }}</div>
  <div class="ml-7">
    <ng-container *ngFor="let rule of query?.rules">
      <ng-container *ngIf="isRuleSet(rule); else singleRule">
        <itq-query-renderer [query]="query"></itq-query-renderer>
      </ng-container>
      <ng-template #singleRule>
        <div class="flex items-center mb-2 space-x-2">
          <span class="font-semibold">{{
            ((rule | castAsQueryModel).field | getFieldById)?.label
          }}</span>
          <span>{{ (rule | castAsQueryModel).operator }}</span>
          <div class="flex space-x-2 items-center">
            @if (
              ((rule | castAsQueryModel)?.value | isArray) &&
              (rule | castAsQueryModel)?.value?.length > 0
            ) {
              <ng-container
                *ngFor="
                  let value of (rule | castAsQueryModel).value;
                  let i = index
                "
              >
                <itq-pill
                  [type]="PillType.CUSTOM"
                  [class]="'secondary h-[28px] rounded-md'"
                  >{{ value?.name }}</itq-pill
                >
                @if (i < (rule | castAsQueryModel).value.length - 1) {
                  <span class="font-semibold">or</span>
                }
              </ng-container>
            } @else {
              <itq-pill
                [type]="PillType.CUSTOM"
                [class]="'secondary h-[28px] rounded-md'"
                >{{
                  (rule | castAsQueryModel)?.value?.name ||
                    (rule | castAsQueryModel)?.value
                }}</itq-pill
              >
            }
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
