import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  Actions,
  Alert,
  AlertTypes,
  CoreModule,
  CustomOverlayService,
  Priority,
} from '@intorqa-ui/core';
import { Priority_DataSource } from '@portal/notifications/const/alerts.const';
import { AlertType } from '@portal/notifications/models/alert-type';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { TagMatchAlertComponent } from '../tag-match-alert/tag-match-alert.component';
import { TagThresholdAlertComponent } from '../tag-threshold-alert/tag-threshold-alert.component';
import { GetAlertTypeLabelPipe } from './tag-wizard-alerts.pipe';
import { Tag } from '@portal/tags/models/tag';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-tag-wizard-alerts',
  templateUrl: './tag-wizard-alerts.component.html',
  styleUrls: ['./tag-wizard-alerts.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    CommonModule,
    MatSlideToggleModule,
    CoreModule,
    ReactiveFormsModule,
    TagMatchAlertComponent,
    GetAlertTypeLabelPipe,
    TagThresholdAlertComponent,
  ],
})
export class TagWizardAlertsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() tag: Tag;
  @Input() action: Actions;

  public priorityDataSource = Priority_DataSource;
  public alertTypesDataSource: Array<AlertType>;
  private subscriptions = new Subscription();

  readonly AlertTypes = AlertTypes;

  constructor(
    readonly alertsService: AlertsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit() {
    this.bindLoadAlertSsubscription();
    this.addControls();
    this.getAlertTypes();
    if (this.tag.alertTypeId) {
      this.onChangeCreate();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.form.removeControl('alerts');
  }

  private bindLoadAlertSsubscription(): void {
    this.subscriptions.add(
      this.alertsService.loadAlert$.subscribe((response: Alert) => {
        this.form.get('alerts.message').setValue(response.message);
      }),
    );
  }

  private getAlertTypes(): void {
    this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
      this.alertTypesDataSource = response.filter(
        (item: AlertType) =>
          item.label !== AlertTypes.RESEARCH &&
          item.label !== AlertTypes.SYSTEM,
      );
    });
  }

  private addControls(): void {
    this.form.addControl(
      'alerts',
      new FormGroup({
        create: new FormControl(this.tag?.alertTypeId ? 'yes' : 'no', [
          Validators.required,
        ]),
      }),
    );
  }

  public onChangeCreate(): void {
    const alertsGroup = this.form.get('alerts') as FormGroup;
    if (this.form.get('alerts.create').value === 'yes') {
      this.addAlertControls(alertsGroup);
    } else {
      this.removeAlertControls(alertsGroup);
    }
  }

  public onChangeAlertType(): void {
    this.form.get('alerts.message').setValue('');
    this.form.get('alerts.message').markAsPristine();
    this.form.get('alerts.message').markAsUntouched();
  }

  private addAlertControls(alertsGroup: FormGroup): void {
    alertsGroup.addControl(
      'alertTypeId',
      new FormControl(
        this.tag?.alertTypeId || this.alertTypesDataSource[0].id,
        [Validators.required],
      ),
    );
    alertsGroup.addControl(
      'priority',
      new FormControl(Priority.HIGH, [Validators.required]),
    );
    alertsGroup.addControl(
      'message',
      new FormControl('', [Validators.required]),
    );
  }

  private removeAlertControls(alertsGroup: FormGroup): void {
    alertsGroup.removeControl('alerts.alertTypeId');
    alertsGroup.removeControl('alerts.priority');
    alertsGroup.removeControl('alerts.message');
  }
}
