<div #dropdownButton>
  <itq-mat-button
    [ngClass]="{ isOpen: isOpen }"
    [disabled]="disabled"
    [style]="color"
    [size]="size"
    [type]="'button'"
    [align]="align"
    [padding]="padding"
    [materialStyle]="materialStyle"
    (mouseDownEvent)="onToggle()"
  >
    <ng-content></ng-content>
  </itq-mat-button>
</div>
<ng-template #templateBody>
  <div
    #container
    class="navigation__container flex absolute min-w-[150px] bg-white rounded-md p-2 shadow-[0px_2px_8px_0px_rgba(0,0,0,0.2)]"
  >
    <ng-container
      *ngTemplateOutlet="template; context: { $implicit: templateData }"
    ></ng-container>
  </div>
</ng-template>
