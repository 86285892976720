import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CoreModule,
  FAwesomeModule,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { QBMultipleDropdownResultsComponent } from './qb-multiple-dropdown-results/qb-multiple-dropdown-results.component';
@Component({
  selector: 'itq-qb-multiple-dropdown',
  templateUrl: './qb-multiple-dropdown.component.html',
  styleUrls: ['./qb-multiple-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QBMultipleDropdownComponent,
    },
  ],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    CommonModule,
    QBMultipleDropdownResultsComponent,
  ],
})
export class QBMultipleDropdownComponent
  implements OnInit, ControlValueAccessor
{
  @ViewChild('templateBody') templateBody: TemplateRef<unknown>;
  @ViewChild('button', { static: true }) button: ElementRef;
  @ViewChild('buttonWrapper') buttonWrapper: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  @Input() disabled = false;
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() placeholder = 'Please select an item...';
  @Input() materialStyle = 'stroked';
  @Input() padding = Sizes['X-SM'];
  @Input() color: string;
  @Input() invalid: boolean;
  @Input() includeEmpty = false;
  @Input() initialState: QueryFilters;

  @Output() addOnEmpty = new EventEmitter<string>();
  @Output() changeValue = new EventEmitter<void>();
  @Output() dataBound = new EventEmitter<{
    initialState: QueryFilters;
    includeEmpty: boolean;
  }>();

  private touched = false;
  public open = false;
  public selections: Array<any> = [];
  public showLoader: boolean;
  private overlayRef: OverlayRef;

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (
      this.open &&
      !this.button.nativeElement.contains(event.target) &&
      !this.menu.nativeElement.contains(event.target)
    ) {
      this.closeDropdown();
    }
  }

  constructor(
    private renderer: Renderer2,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    readonly cdr: ChangeDetectorRef,
  ) {}

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: Array<any> | undefined): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderer.listen('window', 'resize', () => {
      if (this.open) {
        this.adjustDropdownPosition();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
      if (this.open && !this.overlayRef) {
        this.openDropdown();
      }
    }
  }

  adjustDropdownPosition(): void {
    if (this.overlayRef) {
      const buttonRect = this.button.nativeElement.getBoundingClientRect();
      this.renderer.setStyle(
        this.menu.nativeElement,
        'minWidth',
        `${buttonRect.width}px`,
      );
      this.overlayRef.updatePosition();
    }
  }

  public onOpen(): void {
    this.open = !this.open;
    if (this.open) {
      this.showLoader = true;
      this.onDataBound({
        initialState: this.initialState,
        includeEmpty: this.includeEmpty,
      });
    } else {
      this.closeDropdown();
    }
  }

  openDropdown(): void {
    const buttonRect = this.button.nativeElement.getBoundingClientRect();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.button)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(this.templateBody, this.viewContainerRef);
    this.overlayRef.attach(portal);
    this.cdr.detectChanges();
    this.renderer.setStyle(
      this.menu.nativeElement,
      'minWidth',
      `${buttonRect.width}px`,
    );
  }

  closeDropdown(): void {
    if (this.overlayRef) {
      this.open = false;
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  public getMaxWidth(): number {
    return this.buttonWrapper?.nativeElement?.offsetWidth;
  }

  public onDataBound(params: {
    initialState: QueryFilters;
    includeEmpty: boolean;
  }): void {
    if (params.initialState) {
      this.dataBound.emit(params);
    }
  }

  public onRemoveItem(value: any): void {
    this.selections = this.selections.filter((item: any) => {
      return item[this.dataFields.value] !== value[this.dataFields.value];
    });
    this.onChange(this.selections?.length > 0 ? this.selections : undefined);
    this.changeValue.emit();
  }

  public onChangeValue(selections: any): void {
    this.selections = selections;
    this.onChange(this.selections?.length > 0 ? this.selections : undefined);
    this.changeValue.emit();
  }

  public getSelection(): any {
    if (this.selections) {
      const selection = this.dataSource?.find((item: any) => {
        return item[this.dataFields.value] === this.selections;
      });
      if (selection) {
        return selection[this.dataFields.name];
      }
      return undefined;
    }
    return undefined;
  }
}
