import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { QueryFilters } from '../../../models/query-filters';

@Component({
  selector: 'itq-dropdown-results',
  templateUrl: './dropdown-results.component.html',
  styleUrls: ['./dropdown-results.component.scss'],
})
export class DropdownResultsComponent implements OnInit, OnDestroy {
  @Input() emptyDataSourceMessage = 'No results found';
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() selection: any;
  @Input() initialState: QueryFilters;
  @Input() showLoader: boolean;

  @Output() changeValue = new EventEmitter<any>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  private keyupSubscription: Subscription;
  public disabled = false;
  private touched = false;
  public query: string;
  public filteredDataSource: any[] = [];

  @ViewChild('listSearch', { read: ElementRef }) listSearch: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.filteredDataSource = this.dataSource;
  }

  ngOnDestroy(): void {
    this.keyupSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      if (this.initialState?.page === 1 && this.listSearch) {
        this.listSearch.nativeElement.scrollTop = 0;
      }
      this.filteredDataSource = this.dataSource;
      this.showLoader = false;
    }
  }

  public onChangeValue(item: any): void {
    this.selection = item;
    this.changeValue.emit(item);
  }

  public onDataBound(): void {
    if (this.initialState) {
      this.showLoader = true;
      this.initialState.page = 1;
      this.initialState.query = this.query;
      this.dataBound.emit(this.initialState);
    } else {
      if (this.query) {
        this.filteredDataSource = this.dataSource.filter((item) => {
          const itemName = item[this.dataFields.name].toLowerCase();
          const query = this.query.toLowerCase();
          return itemName.includes(query);
        });
      } else {
        this.filteredDataSource = this.dataSource;
      }
    }
  }

  public onScroll(): void {
    if (this.initialState) {
      this.showLoader = true;
      this.initialState.page++;
      this.dataBound.emit(this.initialState);
    }
  }

  public onClick(event: MouseEvent, item: any): void {
    event.stopPropagation();
    if (this.initialState) {
      this.changeValue.emit(item);
    } else {
      this.changeValue.emit(item[this.dataFields.value] || item);
    }
  }
}
