<div class="flex flex-col h-full" [formGroup]="form">
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Data source</h2>
  <div class="flex flex-col overflow-hidden">
    <span class="mb-1 text-13 font-medium">TAG</span>
    <itq-nav-button
      #dataSourceButton
      class="w-full"
      [size]="Sizes.BLOCK"
      [materialStyle]="'stroked'"
      [openDirection]="{ vertical: 'down', horizontal: 'left' }"
      [openPosition]="'inside'"
      [template]="dataSourceTemplate"
    >
      <span class="font-normal flex items-center w-full justify-between">
        @if (form?.get('dataSource')?.value) {
          <span>{{ form?.get('dataSource')?.value.name }}</span>
        } @else {
          <span class="text-gray-400">Pick a tag...</span>
        }
        <fa-icon
          class="border-l border-border pl-1 ml-2"
          [icon]="
            dataSourceButton.isOpen
              ? ['far', 'chevron-up']
              : ['far', 'chevron-down']
          "
        ></fa-icon>
      </span>
    </itq-nav-button>
    <ng-template #dataSourceTemplate>
      <itq-tag-picker
        class="flex"
        [selectedUserCategory]="selectedUserCategory"
        formControlName="dataSource"
        [dates]="initialState.where"
        [field]="
          this.timelineWidget?.dataSource && this.timelineWidget?.dataSource[0]
        "
        (selectionChange)="onChangeDataSources($event)"
      ></itq-tag-picker>
    </ng-template>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
    }
  </div>
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      class="w-full"
      [dates]="this.dates"
      [openDirection]="{
        vertical: 'down',
        horizontal: 'right'
      }"
      [openPosition]="'inside'"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>
