<ng-container [formGroup]="form">
  <div
    class="connections__toolbar"
    *ngIf="navigationItem.action !== Actions.CREATE"
  >
    <itq-pill
      [type]="PillType.CUSTOM"
      [class]="'secondary rounded-md h-[36px]'"
      >{{ dataSource?.totalCount || 0 | number }}</itq-pill
    >
    <div
      class="flex space-x-2"
      *ngIf="navigationItem.action === Actions.EXPLORE"
    >
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        (clickEvent)="onExit()"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="whitespace-nowrap">VIEW CONTENT</span>
      </itq-mat-button>
      <itq-mat-button
        [style]="'primary'"
        [type]="'button'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'cog']"></fa-icon>
        <span class="whitespace-nowrap">SETTINGS</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__wrapper">
      <!-- <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="isFilterable = !isFilterable"
      >
        <fa-icon class="mr-1" [icon]="['far', 'filter-list']"></fa-icon>
        <span class="whitespace-nowrap">FILTERS</span>
      </itq-button-toggle> -->
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onAddConnection()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="whitespace-nowrap">ADD CONNECTION</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="flex flex-col">
    <itq-virtual-table
      [showFilters]="isFilterable"
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t added any connections yet!'"
      [emptyMessageDescription]="'To add a connection click the button below.'"
      (dataBound)="onDataBound($event)"
    ></itq-virtual-table>
    @if (navigationItem.action === Actions.CREATE) {
      <div class="flex border-border border border-t-0 bg-white py-2">
        <itq-mat-button
          class="ml-4"
          [type]="'button'"
          [padding]="Sizes.SM"
          [materialStyle]="'basic'"
          (clickEvent)="onAddConnection()"
        >
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="whitespace-nowrap">ADD CONNECTION</span>
        </itq-mat-button>
      </div>
    }
    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <div class="flex items-center flex-col">
          <itq-mat-button
            class="w-full"
            [materialStyle]="'basic'"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            (clickEvent)="onEditConnection(row)"
          >
            <fa-icon
              [icon]="['far', 'pencil']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Edit
          </itq-mat-button>
          <itq-mat-button
            class="w-full"
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [materialStyle]="'basic'"
            (clickEvent)="onDeleteConnection(row)"
          >
            <fa-icon
              [icon]="['far', 'trash-alt']"
              class="mr-2"
              [size]="'lg'"
            ></fa-icon>
            Delete
          </itq-mat-button>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row>
      <span [matTooltip]="row.updatedBy">
        {{ row.updatedDate | date: 'yyyy MMM dd' }}
      </span>
    </ng-template>
    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
  </div>
</ng-container>
