import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { BoardService } from '@portal/boards/services/board.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IFilterField } from '@portal/tags/interfaces/tag.interface';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { QueryBuilderComponent } from '../../../tags/tag-wizard/components/query-builder/query-builder.component';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';

@Component({
  selector: 'itq-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    QueryBuilderComponent,
    CoreModule,
    DocumentsFeedComponent,
    CommonModule,
    DateRangeComponent,
    MatIconModule,
    ReactiveFormsModule,
    FAwesomeModule,
    MatTooltipModule,
    MatSlideToggleModule,
  ],
})
export class SearchComponent implements OnInit {
  @Input() initialState: QueryFilters;

  public form: FormGroup;
  public query = new QueryBuilderModel();
  private _dataSource: IData;
  private subscriptions = new Subscription();
  public showLoader = false;
  public userTagCategory = UserTagCategoryLabel.STANDARD.toString();
  public fields: Array<IFilterField>;

  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource = {
        result: [...this._dataSource.result, ...value.result],
        count: value.count,
      };
    } else {
      this._dataSource = value;
    }
  }

  readonly PillType = PillType;

  constructor(
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly customOverlayService: CustomOverlayService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly modalContainerService: ModalContainerService,
    readonly boardService: BoardService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.bindLoaderSubscription();
    this.onGetFields();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onGetFields(): void {
    this.showLoader = true;
    this.categoryService
      .getUserCategoryFieldsByType(
        this.userTagCategory,
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe((response: Array<IFilterField>) => {
        this.fields = response;
        this.showLoader = false;
      });
  }

  private bindLoaderSubscription(): void {
    this.subscriptions.add(
      this.tagService.loader$.subscribe((response: boolean) => {
        this.showLoader = response;
      }),
    );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(undefined),
    });
  }

  public onCreateTag(): void {
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: TagFactory.createTagByType(
          UserTagCategoryLabel.STANDARD.toString(),
        ),
        queryModel: this.query,
        dates: this.initialState.where,
        action: Actions.CREATE,
        boardIds: this.boardService.board?.id
          ? [this.boardService.board?.id]
          : undefined,
      },
    });
  }

  public onRefresh(): void {
    this.onDataBound(this.query);
  }

  public onDataBound(query: QueryBuilderModel): void {
    this.query = query;
    if (this.query?.hasRules()) {
      this.showLoader = true;
      this.tagService
        .execute(
          this.initialState,
          this.query.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.dataSource = response;
          this.showLoader = false;
        });
    }
  }

  public onScroll(params: QueryFilters): void {
    this.initialState = params;
    this.onDataBound(this.query);
  }

  public onClearQuery(): void {}

  public onChangeDate(params: IPresetQuery): void {
    this.initialState.where = params;
    this.onDataBound(this.query);
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      default:
        break;
    }
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextSegment): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }
}
