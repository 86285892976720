import { DTOQuery } from '@intorqa-ui/core';

import { Rule } from 'ngx-angular-query-builder';
import { BackEndQuery } from './backend-query-model';
import { QueryBuilderModel } from './qb-query-model';

export class Query {
  constructor(
    public rules: Array<Rule> = [],
    public condition = 'and',
  ) {}

  public hasRules(): boolean {
    for (const rule of this.rules) {
      if (rule.value !== undefined && rule.value !== '') {
        return true;
      }
    }
    return false;
  }

  public addRule(rule: Rule): void {
    this.rules.push(rule);
  }

  public removeRuleByField(field: string): void {
    this.rules = this.rules.filter((item: Rule) => item.field !== field);
  }

  public convertToBackEndQuery(): DTOQuery {
    if (!this) {
      console.error('Model cannot/should not be null');
      return null;
    }
    const result = new BackEndQuery();
    result.operator = this.condition.toLowerCase();
    if (this.rules) {
      this.rules.forEach((rule: any) => {
        const queryRule = {
          field: rule['entity'],
          operator: rule['operator'],
          value: rule['value'],
        };
        result.conditions.push(queryRule);
      });
    }

    return result;
  }

  public hasEmptyRules(): boolean {
    for (const rule of this.rules) {
      if (
        rule.value === undefined ||
        rule.value === '' ||
        rule?.value?.length === 0
      ) {
        return true;
      }
    }
    return false;
  }

  public convertToQueryBuilderModel(): QueryBuilderModel {
    const queryBuilderModel = new QueryBuilderModel();
    queryBuilderModel.rules = this.rules.map((rule: Rule) => {
      return {
        entity: rule.entity,
        field: rule.field,
        operator: rule.operator,
        value:
          rule.entity === 'content'
            ? rule['value']
            : rule.value?.map((value: string) => {
                return {
                  id: value,
                  name: value,
                };
              }),
      };
    });
    queryBuilderModel.condition = this.condition;
    return queryBuilderModel;
  }
}
