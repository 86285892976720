import { IDisplayType } from '../../shared/interfaces/widget.interface';
import { ChartType, FoamtreeMode } from '@portal/widgets/enums/chart.enum';

export const TIMELINE_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'Timeline',
    type: ChartType.TIMELINE,
    svgIcon: 'board',
    tooltip: 'Posts',
  },
  {
    id: 'Polygonal',
    type: ChartType.FOAMTREE,
    options: {
      layout: FoamtreeMode.POLYGONAL,
    },
    svgIcon: 'ripl',
    tooltip: 'Polygonal',
  },
];
