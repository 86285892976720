import { PortalModule } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';

import { ApiModule, HttpCancelService } from '@intorqa-ui/api';
import {
  CastIconNamePipe,
  CastIconSizePipe,
  CoreModule,
  ManageHttpInterceptor,
} from '@intorqa-ui/core';

import { environment } from '../environments/environment';
import { APP_ROUTES } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  KeycloakAngularModule,
  KeycloakOptions,
  KeycloakService,
} from 'keycloak-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { forkJoin, switchMap } from 'rxjs';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileService } from './profiles/services/vendors.service';
import { DefaultNavigationComponent } from './shared/components/default-navigation/default-navigation.component';
import { ErrorCatchingInterceptor } from './shared/interceptors/error-catching.interceptor';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { EcosystemsService } from './shared/services/ecosystems.service';
import { UserService } from './shared/services/user.service';
import { CacheRouteReuseStrategy } from './strategies/cache-route-reuse.strategy';
import { WidgetSettingsProfilesModule } from './widget-settings/modules/widget-settings-profiles/widget-settings-profiles.module';

function initializeKeycloak(
  keycloakService: KeycloakService,
  userService: UserService,
  ecosystemsService: EcosystemsService,
  profileService: ProfileService,
) {
  const config = environment.authConfig as KeycloakOptions;
  return () =>
    keycloakService.init(config).then(() => {
      return forkJoin([
        ecosystemsService.getEcosystems(),
        userService.getUserPreferences().pipe(
          switchMap((userPreferences) => {
            return profileService.getProfileTypes(
              userPreferences.defaultEcosystemId,
            );
          }),
        ),
      ]).toPromise();
    });
}
@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    KeycloakAngularModule,
    ApiModule.forRoot(environment),
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
    PortalModule,
    CoreModule,
    NotificationsComponent,
    CastIconNamePipe,
    CastIconSizePipe,
    DefaultNavigationComponent,
    WidgetSettingsProfilesModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  declarations: [AppComponent],
  providers: [
    DatePipe,
    HttpCancelService,
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, UserService, EcosystemsService, ProfileService],
    },
    AuthGuardService,
    // ...(environment.errorTracking
    //   ? [{ provide: ErrorHandler, useClass: GlobalErrorHandler }]
    //   : []),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
