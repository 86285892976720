import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  FAwesomeModule,
} from '@intorqa-ui/core';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { BoardService } from '@portal/boards/services/board.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { IUserCategory } from '@portal/tags/interfaces/tag.interface';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { Tag } from '../../models/tag';
import { TagWizardComponent } from '../../tag-wizard/tag-wizard.component';
import { TagTypeThumbnailListComponent } from '../tag-type-thumbnail-list/tag-type-thumbnail-list.component';

@Component({
  selector: 'itq-create-tag-type',
  templateUrl: './create-tag-type.component.html',
  styleUrls: ['./create-tag-type.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    TagTypeThumbnailListComponent,
    MatTooltipModule,
    FAwesomeModule,
  ],
})
export class CreateTagTypeComponent implements OnInit {
  public dataSource: Array<IUserCategory> = [];
  public showLoader = true;

  readonly AnalysisTypes = AnalysisTypes;

  constructor(
    readonly modalContainerService: ModalContainerService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly boardService: BoardService,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit() {
    this.onGetTagTypes();
  }

  private onGetTagTypes(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: IUserCategory[]) => {
        this.dataSource = response;
        this.showLoader = false;
      });
  }

  public onAdd(type: string): void {
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: new Tag(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          type,
          undefined,
          undefined,
          undefined,
        ),
        action: Actions.CREATE,
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
