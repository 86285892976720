import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  Ecosystem,
  FAwesomeModule,
  HelpComponent,
  IconType,
  QueryFilters,
  SharedService,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { BoardService } from '@portal/boards/services/board.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { SearchComponent } from '../../../search/components/search/search.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationsComponent } from '@portal/notifications/notifications.component';

@Component({
  selector: 'itq-default-navigation',
  templateUrl: './default-navigation.component.html',
  styleUrls: ['./default-navigation.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    CommonModule,
    RouterModule,
    SvgComponent,
    FormsModule,
  ],
})
export class DefaultNavigationComponent implements OnInit {
  readonly Sizes = Sizes;
  readonly IconType = IconType;

  public unreadStatus: boolean;
  private subscriptions = new Subscription();
  public ecosystemDataSource: Array<Ecosystem> = [];
  public ecosystemId: string;
  private interval: NodeJS.Timeout;
  private overlayRef: OverlayRef;
  public isOpen = false;

  // @HostListener('document:click', ['$event'])
  // onClick(): void {
  //   this.isOpen = false;
  //   this.overlayRef?.dispose();
  // }

  constructor(
    public sharedService: SharedService,
    private customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    readonly keycloakService: KeycloakService,
    readonly ecosystemsService: EcosystemsService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly router: Router,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
  ) {}

  ngOnInit(): void {
    this.getUnreadStatus();
    this.bindUnreadCountSubscription();
    this.bindNotificationsSubscription();
    this.ecosystemDataSource = this.ecosystemsService.ecosystems;
    this.ecosystemId = this.userService.userPreferences.defaultEcosystemId;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions.unsubscribe();
  }

  private bindNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.openNotifications$.subscribe(
        (response: { raisedAlertId: string; researchAlertId: string }) => {
          this.onLoadNotifications(
            response.raisedAlertId,
            response.researchAlertId,
          );
        },
      ),
    );
  }

  private bindUnreadCountSubscription(): void {
    this.interval = setInterval(() => {
      this.getUnreadStatus();
    }, 30000);
  }

  private getUnreadStatus(): void {
    this.notificationsService.getUnread().subscribe((response: boolean) => {
      this.unreadStatus = response;
    });
  }

  public onLoadHelp(): void {
    this.customOverlayService.open({
      closeBtnStyle: 'basic',
      closeBtnClass: 'help_close__btn',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: HelpComponent,
      disposeOnNavigation: true,
    });
  }

  public isMobileDevice(): boolean {
    return this.sharedService.mobile || this.sharedService.tablet;
  }

  public toggleNotiications(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.onLoadNotifications();
    } else {
      this.overlayRef?.dispose();
    }
  }

  onLoadNotifications(raisedAlertId?: string, researchAlertId?: string): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .right('0px')
      .top('68px');
    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'h-[100%]',
    });

    const tooltipPortal = new ComponentPortal(
      NotificationsComponent,
      this.viewContainerRef,
    );
    const notificationsRef = this.overlayRef.attach(tooltipPortal);
    notificationsRef.instance.raisedAlertId = raisedAlertId;
    notificationsRef.instance.researchAlertId = researchAlertId;
    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
  }

  closeOverlay(): void {
    if (this.overlayRef) {
      this.isOpen = false;
      this.overlayRef.dispose();
    }
  }

  public onCreateSearch(): void {
    const initialState = new QueryFilters(
      30,
      1,
      this.boardService.board?.filter?.date || {
        label: DateQueryType.LastMonth,
      },
      undefined,
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: SearchComponent,
          inputs: {
            initialState: initialState,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  removeRouteParam() {
    // Get the current route
    let currentRoute = this.router.url.split('/');
    let lastSegment = currentRoute[currentRoute.length - 1];

    // Check if the last segment is a UUID
    let uuidRegex =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(lastSegment)) {
      currentRoute.pop(); // Remove the last segment (id)

      // Navigate to the same route without the id
      this.router.navigate(currentRoute, { replaceUrl: true });
    }
  }

  public onChangeEcosystem(): void {
    this.userService.setDefaultEcosystem(this.ecosystemId).subscribe(() => {
      this.removeRouteParam();
      this.ecosystemsService.changeEcosystem$.next();
    });
  }
}
