import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
} from '@intorqa-ui/core';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { TimelineMetricsComponent } from '@portal/widgets/components/timeline-metrics/timeline-metrics.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';

@Component({
  selector: 'itq-chart-wizard-timeline.component',
  templateUrl: './chart-wizard-timeline.component.html',
  styleUrls: ['./chart-wizard-timeline.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MapLabelPipe,
    CommonModule,
    SelectMetricsLabelPipe,
    DocumentsFeedComponent,
    TimelineMetricsComponent,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTimelineComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() initialState: QueryFilters;
  @Input() action = Actions.CREATE;

  public form: FormGroup;
  public showLoader = false;
  private subscription = new Subscription();
  public count: number;
  public dataSource: IData;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;

  constructor(
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly tagService: TagService,
    readonly modalContainerService: ModalContainerService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.getTimelineData();
    }
  }

  private getTimelineData(): void {
    const queryModel = new Query([
      {
        entity: 'tag',
        field: 'tag',
        operator: 'in',
        value: this.widget.dataSource,
      },
    ]);
    this.tagService
      .execute(
        this.initialState,
        queryModel.convertToBackEndQuery(),
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IData) => {
        this.dataSource = response;
        this.count = response.count;
        this.showLoader = false;
      });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownActor(actorName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownActorComponent,
          inputs: {
            actor: actorName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownContext(context: IContextSegment): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownContextComponent,
          inputs: {
            channel: context?.value,
            contextDocument: context.context.document,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownRepliesComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownDiscordComponent,
          inputs: {
            channel: extensionField,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(channelName: string): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: DrilldownChannelComponent,
          inputs: {
            channel: channelName,
            initialState: this.initialState.cloneDeep(),
            allowCreateTag: false,
            allowDrilldownProfiles: false,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: Timeline;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
  }

  public onPrev(): void {
    this.modalContainerService.navigate$.next({
      component: CreateWidgetTypeComponent,
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === Actions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.widgetService
      .createWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({ refresh: true, widget: response });
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
