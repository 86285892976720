import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ExtensionDataFields,
  ExtensionDataFieldsText,
} from '../document-item-detail/document-item-detail.enum';
import { SharedModule } from '@portal/shared/shared.module';
import {
  CoreModule,
  HighlightSearchPipe,
  IQueryColumn,
} from '@intorqa-ui/core';
import { CommonModule } from '@angular/common';
import {
  DocumentItem,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';

@Component({
  selector: 'itq-document-extension-fields',
  templateUrl: './document-extension-fields.component.html',
  styleUrls: ['./document-extension-fields.component.scss'],
  standalone: true,
  imports: [SharedModule, CommonModule, CoreModule, HighlightSearchPipe],
})
export class DocumentExtensionFieldsComponent implements OnInit {
  readonly ExtensionDataFieldsText = ExtensionDataFieldsText;
  readonly SegmentScope = SegmentScope;
  readonly ExtensionDataFields = ExtensionDataFields;

  @Input() query: string;
  @Input() document: DocumentItem;
  @Input() drilldownSegment: string;

  @Output() drilldown = new EventEmitter<IExtensionField>();

  public extractedData: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.extractedData = this.getExtractedData();
  }

  private getExtractedData(): Array<any> {
    if (this.document?.emitExtensionFields?.length > 0) {
      return this.document.emitExtensionFields.filter(
        (item: any) =>
          item.name !== 'contentLinks' && item.name !== 'content links',
      );
    }
  }

  onDrilldown(extensionField: IExtensionField): void {
    this.drilldown.emit(extensionField);
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}
