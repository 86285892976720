import { TimelineOrientation } from '@intorqa-ui/core';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const EVENT_TIMELINE_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'VerticalTimeline',
    type: ChartType.EVENT_TIMELINE,
    options: {
      orientation: TimelineOrientation.VERTICAL,
    },
    svgIcon: 'vertical-timeline',
    tooltip: 'Vertical',
  },
  {
    id: 'HorizontalTimeline',
    type: ChartType.EVENT_TIMELINE,
    options: {
      orientation: TimelineOrientation.HORIZONTAL,
    },
    svgIcon: 'horizontal-timeline',
    tooltip: 'Horizontal',
  },
];
