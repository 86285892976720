import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { UserService } from '@portal/shared/services/user.service';
import { MultipleTagPickerComponent } from '@portal/tags/components/multiple-tag-picker/multiple-tag-picker.component';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/tags/interfaces/tag.interface';
import { TagService } from '@portal/tags/services/tag.service';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-all-tags-datasource',
  templateUrl: './all-tags-datasource.component.html',
  styleUrls: ['./all-tags-datasource.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    FAwesomeModule,
    MultipleTagPickerComponent,
    MatTooltipModule,
  ],
})
export class AllTagsDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;
  @Input() dates: IPresetQuery;

  @Output() changeDataValue = new EventEmitter<Array<string>>();

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private subscriptions = new Subscription();

  readonly Sizes = Sizes;
  readonly PillType = PillType;

  constructor(
    public widgetService: WidgetService,
    readonly userService: UserService,
    readonly tagService: TagService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.createForm();
    this.loadMetadata();
    this.bindReloadWidgetDatesSubscription();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataPoints');
    this.subscriptions.unsubscribe();
  }

  public onRemoveTag(tag: ICustomTag): void {
    const currentValues = this.form.get('dataPoints').value || [];
    const updatedValues = currentValues.filter(
      (item: ICustomTag) => item.id !== tag.id,
    );
    this.form.get('dataPoints').setValue(updatedValues);
  }

  public getField(): string {
    if (this.widget instanceof TagComparison)
      return (this.widget as TagComparison).dataValues[0];
    if (this.widget instanceof TimeSeries)
      return (this.widget as TimeSeries).dataValues[0];
  }

  private bindReloadWidgetDatesSubscription(): void {
    this.subscriptions.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagComparison | TimeSeries;
          dates?: IPresetQuery;
        }) => {
          this.widget = params.widget;
          if (params.dates) {
            this.initialState.where = params.dates;
          }
        },
      ),
    );
  }

  private loadMetadata(): void {
    if (this.widget.dataValues?.length > 0) {
      this.tagService
        .getTagsMetadata(this.widget.dataValues)
        .subscribe((response: Array<ITagMetadata>) => {
          const dataValues = response.map((item: ITagMetadata) => ({
            name: item.tagName,
            id: item.tagId,
          }));
          if (dataValues) {
            this.form.controls.dataPoints.setValue(dataValues);
          }
        });
    }
  }

  public createForm(): void {
    this.form.addControl(
      'dataPoints',
      new FormControl(undefined, Validators.required),
    );
  }

  private updateFormStatus(): void {
    if (this.form.get('dataPoints').value) {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
  }

  public onChangeDataSource(selections: Array<ICustomTag>): void {
    this.onDataBound();
  }

  public onDataBound(): void {
    this.updateFormStatus();
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value?.map((item: ICustomTag) => item.id) ||
        [],
    );
  }
}
