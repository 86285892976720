import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayRef,
  FAwesomeModule,
  IDropdownItem,
  IconType,
  Sizes,
  TagCategory,
} from '@intorqa-ui/core';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ICustomTag } from 'projects/portal/src/app/tags/interfaces/tag.interface';
import { LinkTagsTypeComponent } from '../link-tags-type/link-tags-type.component';

@Component({
  selector: 'itq-link-tags-wizard',
  templateUrl: './link-tags-wizard.component.html',
  styleUrls: ['./link-tags-wizard.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    LinkTagsTypeComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonModule,
    MatTooltipModule,
  ],
})
export class LinkTagsWizardComponent implements OnInit {
  @Input() totalCount: number;
  @Input() profile: Profile;
  @Input() action = Actions.EDIT;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() link: LinkTag;

  public tagsDataSource: Array<ICustomTag>;
  public form: FormGroup;

  readonly Actions = Actions;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly IconType = IconType;

  constructor(
    private linkTagsService: LinkTagsService,
    readonly userService: UserService,
    readonly customOverlayRef: CustomOverlayRef,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl('addTags');
  }

  private createForm(): void {
    this.form = new FormGroup({});
    const tag = {
      id: this.link?.tagId,
      name: this.link?.tagName,
      userTagCategory: this.link?.type,
    };
    this.form.addControl(
      'addTags',
      new FormGroup({
        linkType: new FormControl(
          this.action === Actions.SETTINGS ? tag : undefined,
          [Validators.required],
        ),
        description: new FormControl(this.link?.description),
      }),
    );
    if (this.canUpdateDefault()) {
      (this.form.get('addTags') as FormGroup).addControl(
        'default',
        new FormControl(this.link?.isDefault),
      );
    }
  }

  public canUpdateDefault(): boolean {
    if (this.action === Actions.EDIT && !this.link?.isDefault) {
      return true;
    } else {
      this.totalCount > 0 ? true : false;
    }
  }

  public onChangeLinkType(): void {
    this.form.get('addTags.tag').enable();
    this.form.get('addTags.tag').setValue(undefined);
    this.form.get('addTags.description').setValue(undefined);
    this.link.type = this.form.get('addTags.linkType').value;
  }

  public onChangeDescription(): void {
    this.link.description = this.form.get('addTags.description').value;
  }

  public onChangeLink(tag: ICustomTag): void {
    this.link.tagId = tag.id;
    this.link.tagName = tag.name;
    this.link.type = tag.userTagCategory;
  }

  public onCancel(): void {
    this.customOverlayRef.close();
  }

  public onAdd(): void {
    this.createLink();
    this.customOverlayRef.close({ refresh: true });
  }

  public onUpdate() {
    if (this.link.isDefault) {
      this.linkTagsService.links.items = this.linkTagsService.links.items.map(
        (item: LinkTag) => {
          return {
            ...item,
            ...{ isDefault: false },
          };
        },
      );
    }
    this.linkTagsService.links.items.push(this.link);
    this.linkTagsService.links$.next({
      link: undefined,
      data: this.linkTagsService.links,
    });
    this.customOverlayRef.close({ refresh: true });
  }

  private createLink(): void {
    if (this.link.isDefault) {
      this.linkTagsService.links.items = this.linkTagsService.links.items.map(
        (item: LinkTag) => {
          return {
            ...item,
            ...{ isDefault: false },
          };
        },
      );
    }
    this.linkTagsService.links.items.push(this.link);
    this.linkTagsService.links$.next({
      link: undefined,
      data: this.linkTagsService.links,
    });
  }

  public onCreate(): void {
    this.createLink();
    this.form.get('addTags').reset();
    this.form.get('addTags.default').enable();
  }

  public onToggleChange(): void {
    this.link.isDefault = this.form.get('addTags.default').value;
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}
