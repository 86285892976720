@if (cluster) {
  <div class="flex items-center pb-2">
    <span class="font-semibold mr-2">Segment:</span>
    <mat-chip-set>
      <mat-chip>
        <div class="flex items-center justify-between">
          <span class="font-medium">{{ cluster.label }}</span>
          <button matChipRemove (click)="onRemoveSegment()">
            <fa-icon
              class="text-black flex items-center"
              [icon]="['far', 'circle-xmark']"
            ></fa-icon>
          </button>
        </div>
      </mat-chip>
    </mat-chip-set>
  </div>
}
<div class="w-full flex h-full" #foamtreeElement></div>
@if (dataSource?.source_documents?.length <= MIN_NUMBER_ITEMS) {
  @if (dataSource) {
    <div class="absolute top-0 left-0 w-full">
      <itq-panel-info
        class="p-7 h-[220px] bg-white"
        [label]="'We don\'t have anything!'"
        [description]="'There are not enough documents to cluster'"
        [message]="'Change your search to get results'"
        [icon]="'hands-helping'"
      ></itq-panel-info>
    </div>
  }
}
