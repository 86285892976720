import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, CoreModule } from '@intorqa-ui/core';
import { DocumentItem } from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { Subscription } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'itq-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  standalone: true,
  imports: [CoreModule, SharedModule],
})
export class DocumentComponent implements OnInit, OnDestroy {
  public document: DocumentItem;
  private subscriptions = new Subscription();

  readonly Actions = Actions;

  constructor(
    private docService: DocumentItemService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.docService
          .getDocument(params.id)
          .subscribe((response: DocumentItem) => {
            this.document = response;
          });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
