@if (parentDocument) {
  @if (!isParent) {
    @if (!isParentExpanded) {
      <div
        class="flex absolute top-[-28px] left-[-10px] z-10 pt-4 items-center space-x-1"
      >
        <itq-mat-button
          [size]="Sizes['X-SM']"
          [materialStyle]="'flat'"
          [padding]="Sizes.NONE"
          [style]="'accent'"
          [matTooltip]="'Show parent'"
          [type]="'button'"
          (clickEvent)="onShowParent()"
        >
          <fa-icon [icon]="['far', 'chevron-up']"></fa-icon>
        </itq-mat-button>
        @if (showLoader) {
          <mat-spinner [diameter]="15"></mat-spinner>
        }
      </div>
    }
  } @else {
    <itq-mat-button
      class="flex absolute top-[-28px] right-[-14px] z-10 pt-4"
      [size]="Sizes['X-SM']"
      [materialStyle]="'flat'"
      [padding]="Sizes.NONE"
      [style]="'accent'"
      [matTooltip]="'Hide parent'"
      [type]="'button'"
      (clickEvent)="onHideParent()"
    >
      <fa-icon [icon]="['far', 'times']"></fa-icon>
    </itq-mat-button>
  }
}
<div class="item-detail-container cursor-pointer w-full" (click)="onExpand()">
  <itq-document-item-actions
    [hasExpandedActions]="hasExpandedActions"
    [ngClass]="{ hidden: !showActions }"
    [document]="document"
    [shareByEmail]="shareByEmail"
    [allowCreateConnections]="allowCreateConnections"
    (translate)="onTranslate()"
    (drilldown)="onDrilldown($event)"
    (shareEmail)="onShareEmail($event)"
    (openAction)="hasExpandedActions = true"
    (closeAction)="hasExpandedActions = false"
  ></itq-document-item-actions>

  <div class="flex pb-4 overflow-hidden items-center">
    <div class="header_icon rounded-md bg-black">
      <img
        [src]="'assets/icons/' + document?.emitSource?.toLowerCase() + '.svg'"
      />
    </div>
    <div class="ml-2 overflow-hidden">
      <div class="items-center flex overflow-hidden">
        @if (document.emitChannel) {
          @if (drilldownSegment !== document.emitChannel) {
            <span
              class="text-link hover:underline font-semibold cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis"
              (click)="$event.stopPropagation(); onDrilldownChannel()"
            >
              <span
                [innerHTML]="document.emitChannel | highlightSearch: query"
              ></span>
            </span>
          } @else {
            <span
              class="font-semibold"
              [innerHTML]="document.emitChannel | highlightSearch: query"
            ></span>
          }
          @if (
            keycloakService.isUserInRole('saas-profiles') &&
            allowDrilldownProfiles
          ) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'left' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.CHANNEL"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        }
      </div>
      <div class="flex space-x-1 text-xs items-center">
        <span [innerHTML]="document.emitSource | highlightSearch: query"></span>
        <span [innerHTML]="document.emitType | highlightSearch: query"></span>
        @if (repliesCount === 0) {
          <div
            class="flex items-center text-link hover:underline font-semibold cursor-pointer"
          >
            <span>(</span>
            <span
              [matTooltip]="'Show replies'"
              (mousedown)="
                $event.stopImmediatePropagation(); onDrilldownReplies()
              "
            >
              {{ repliesCount }} replies
            </span>
            <span>)</span>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="container__content p-2 flex-col rounded-md">
    <div class="container__content_wrapper">
      <div
        class="content-headline font-semibold"
        [innerHTML]="
          translatedTitle && translatedTitle !== ''
            ? (translatedTitle | highlightSearch: query)
            : (document.emitHeadline | highlightSearch: query)
        "
      ></div>
      <div class="flex items-center text-xs py-1 whitespace-nowrap flex-wrap">
        <div class="flex">
          {{ document.emitDocumentDate | date: DateFormats.short }}
        </div>
        @if (document.emitActor) {
          &nbsp;({{
            getDocumentDate(document.emitDocumentDate) | timeAgo
          }})<span>&nbsp; by&nbsp;</span>
          @if (drilldownSegment !== document.emitActor) {
            <div
              class="text-link hover:underline font-semibold cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis block max-w-[150px]"
              (mousedown)="
                $event.stopImmediatePropagation(); onDrilldownActor()
              "
            >
              <span
                [innerHTML]="document.emitActor | highlightSearch: query"
              ></span>
            </div>
          } @else {
            <span
              class="font-semibold"
              [innerHTML]="document.emitActor | highlightSearch: query"
            ></span>
          }
          @if (
            keycloakService.isUserInRole('saas-profiles') &&
            allowDrilldownProfiles
          ) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'left' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.ACTOR"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        }
      </div>
      <p
        [ngClass]="{
          'text-xs content-body break-all': true,
          'line-clamp-3': !isExpanded
        }"
        [innerHTML]="
          translatedContent && translatedContent !== ''
            ? (translatedContent | highlightSearch: query)
            : (document.emitBodyText
              | striphtml
              | stripImg
              | highlightSearch: query)
        "
      ></p>
    </div>

    @if (isExpanded) {
      <itq-document-links
        class="flex flex-col"
        [query]="query"
        [drilldownSegment]="drilldownSegment"
        [document]="document"
        (drilldown)="onDrilldown($event)"
      ></itq-document-links>
      <ng-container *ngFor="let media of document.emitMedia">
        @if (media?.media_source?.toLowerCase() === 'youtube') {
          <div class="flex my-4 flex-col">
            <span class="mb-1 text-13 font-medium">VIDEO</span>
            <iframe
              [src]="media.media_link | safe"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        }
      </ng-container>
    }
  </div>
</div>
<ng-template #profileTemplate let-data>
  <itq-field-vendors-links
    [field]="data"
    [fieldValue]="
      data === ProfileDrildownScope.CHANNEL
        ? document.emitChannel
        : document.emitActor
    "
    (explore)="
      data === ProfileDrildownScope.CHANNEL
        ? onDrilldownChannel()
        : onDrilldownActor()
    "
    (addProfile)="
      onLinkProfile(
        $event,
        data === ProfileDrildownScope.ACTOR
          ? ProfileDrildownScope.ACTOR
          : ProfileDrildownScope.CHANNEL
      )
    "
    (createProfile)="
      onCreateProfile(
        data === ProfileDrildownScope.ACTOR
          ? ProfileDrildownScope.ACTOR
          : ProfileDrildownScope.CHANNEL,
        $event
      )
    "
  ></itq-field-vendors-links>
</ng-template>
