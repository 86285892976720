<div class="flex flex-col" [formGroup]="form">
  <div class="flex mb-4 flex-col">
    <span class="mb-1 text-13 font-medium">TAG</span>

    <itq-nav-button
      #dataSourceButton
      class="w-full"
      [size]="Sizes.BLOCK"
      [disabled]="form.controls['dataSource'].disabled"
      [materialStyle]="'stroked'"
      [openDirection]="{ vertical: 'down', horizontal: 'left' }"
      [openPosition]="'inside'"
      [template]="dataSourceTemplate"
    >
      <span class="font-normal flex items-center w-full justify-between">
        @if (form?.get('dataSource')?.value) {
          <span>{{ form?.get('dataSource')?.value?.name }}</span>
        } @else {
          <span class="text-gray-400">Pick a tag...</span>
        }
        <fa-icon
          class="border-l border-border pl-1 ml-2"
          [icon]="
            dataSourceButton.isOpen
              ? ['far', 'chevron-up']
              : ['far', 'chevron-down']
          "
        ></fa-icon>
      </span>
    </itq-nav-button>
    <ng-template #dataSourceTemplate>
      <itq-tag-picker
        class="flex"
        [selectedUserCategory]="selectedUserCategory"
        [dates]="initialState.where"
        formControlName="dataSource"
        [field]="getField()"
        (selectionChange)="onChangeDataSources($event)"
      ></itq-tag-picker>
    </ng-template>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data source is required </mat-error>
    }
  </div>
  <div class="flex mb-4 flex-col">
    <span class="mb-1 text-13 font-medium">DATA SLICER</span>
    <itq-dropdown
      formControlName="category"
      [clearSelection]="false"
      [dataSource]="dataSlicerDataSource"
      [dataFields]="{ name: 'label', value: 'label' }"
      [placeholder]="'Pick a data slicer...'"
      (changeValue)="onChangeDataSlicer()"
    ></itq-dropdown>

    @if (
      form.controls['category']?.touched &&
      form.controls['category']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data slice is required </mat-error>
    }
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATA POINTS</span>
    <itq-multiple-dropdown
      formControlName="dataPoints"
      [initialState]="initialState"
      [dataFields]="{ name: 'name', value: 'id' }"
      [dataSource]="dataPointsDataSource"
      [placeholder]="'Pick some data points...'"
      (dataBound)="onDataBoundDataValues($event)"
      (changeValue)="onChangeDataValues()"
    >
    </itq-multiple-dropdown>

    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data points is required </mat-error>
    }
  </div>
</div>
