import { Pipe, PipeTransform } from '@angular/core';
import { DynamicFlatNode } from './tag-dependencies.model';
import { TagNodeType } from 'projects/portal/src/app/tags/enums/tag.enum';

@Pipe({
  name: 'tooltipNode',
  standalone: true,
})
export class TooltipNodePipe implements PipeTransform {
  constructor() {}

  transform(node: DynamicFlatNode): string | undefined {
    let result = '';
    if (node.organisation && node.organisation !== 'Intorqa') {
      result += node.organisation;
    }
    if (node.type === TagNodeType.SYSTEM_TAG) {
      if (result !== '') {
        result += ' - System tag';
      } else {
        result += 'System tag';
      }
    }

    if (result !== '') {
      return result;
    } else {
      return undefined;
    }
  }
}
