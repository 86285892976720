import { Component, Input } from '@angular/core';

@Component({
  selector: 'itq-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
})
export class TooltipComponent {
  @Input() content: string;

  constructor() {}

  ngOnInit() {}
}
