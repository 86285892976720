<div class="flex flex-col w-full">
  <div class="flex w-full">
    <itq-input
      class="pb-2 w-[250px]"
      [icon]="'search'"
      [placeholder]="'Add search term...'"
      [type]="'text'"
      [(ngModel)]="query"
      (search)="onSearch()"
      (clear)="onSearch()"
    ></itq-input>
  </div>
  <div class="flex w-full border-t border-border pt-[8px] max-h-[400px]">
    <mat-nav-list
      class="w-[275px] mr-4"
      [style.padding-right]="'8px !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of userCategoriesDataSource"
        [style.background-color]="
          item.name === selectedUserCategory ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item.name)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-semibold': item.name === selectedUserCategory
          }"
        >
          <span>{{ item.name }}</span>
          <mat-icon>{{
            item.name === selectedUserCategory ? 'chevron_right' : 'expand_more'
          }}</mat-icon>
        </div>
      </mat-list-item>
    </mat-nav-list>
    @if (tagsDataSource?.length > 0) {
      <mat-nav-list
        class="flex-1 border-l w-[350px] border-border overflow-auto"
        [style.padding-right]="'8px !important'"
        [style.padding-left]="'8px !important'"
        [style.padding-bottom]="'0 !important'"
        [style.padding-top]="'0 !important'"
      >
        <mat-list-item
          class="hover:bg-background-over"
          *ngFor="let item of tagsDataSource"
          (click)="onSelectionChange(item)"
        >
          <div
            [ngClass]="{
              'text-primary font-semibold': field === item.id,
              'flex items-center space-x-2 w-full': true
            }"
          >
            <div class="flex justify-between items-center w-full">
              <div
                class="flex items-center space-x-2 overflow-hidden justify-between w-full"
              >
                <div class="flex items-center space-x-2">
                  <span
                    class="whitespace-nowrap overflow-hidden text-ellipsis"
                    [innerHTML]="item.name | highlightSearch: query"
                  ></span>
                  @if (item.description) {
                    <mat-icon
                      class="sflex items-center"
                      [matTooltip]="item.description"
                      style="font-size: 20px"
                      >info</mat-icon
                    >
                  }
                </div>

                @if (item.count) {
                  <span class="font-semibold">({{ item.count }})</span>
                }
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-nav-list>
    } @else {
      @if (tagsDataSource) {
        <itq-panel-info
          class="p-7"
          [label]="'No values found!'"
          [message]="'Please update your search term of choose a new field.'"
          [icon]="'exclamation-circle'"
        ></itq-panel-info>
      }
    }
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
