import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IContext,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { IWidgetType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ECharts } from 'echarts';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { DrilldownActorComponent } from '../drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '../drilldown-channel/drilldown-channel.component';
import { DrilldownDiscordComponent } from '../drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '../drilldown-replies/drilldown-replies.component';

@Component({
  selector: 'itq-drilldown-context',
  templateUrl: './drilldown-context.component.html',
  styleUrls: ['./drilldown-context.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,
    MatIconModule,
  ],
})
export class DrilldownContextComponent implements OnInit {
  @Input() channel: IExtensionField;
  @Input() contextDocument: DocumentItem;
  @Input() initialState: QueryFilters;
  @Input() allowCreateTag = true;
  @Input() allowDrilldownProfiles = true;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;

  public form: FormGroup;
  public widgetType: IWidgetType;
  public queryModel: Query;
  private _dataSource: IData;

  public get dataSource(): IData {
    return this._dataSource;
  }

  public set dataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._dataSource = {
        result: value.result,
        count: value.count,
      };
    } else {
      this._dataSource = value;
    }
  }
  public chartInstance: ECharts;
  private subscription = new Subscription();
  public showLoader = true;
  public previousPage: number;

  readonly PillType = PillType;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(''),
    });
  }

  public onCreateTag(): void {
    const queryBuilderModel = this.queryModel.convertToQueryBuilderModel();
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: TagFactory.createTagByType(
          UserTagCategoryLabel.STANDARD.toString(),
        ),
        queryModel: queryBuilderModel,
        dates: this.initialState.where,
        action: Actions.CREATE,
      },
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );

    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextSegment): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private addQueryRules(): void {
    this.queryModel = new Query([
      {
        entity: 'source',
        field: 'Source_Filter to',
        operator: 'in',
        value: ['Discord'],
      },
      {
        entity: 'content',
        field: 'All Text_Search within',
        operator: 'contains',
        value: [this.channel.data?.id],
      },
    ]);

    if (this.form?.get('query')?.value) {
      this.queryModel.addRule({
        entity: 'content',
        field: 'tag',
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    }
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    this.initialState = params;
    this.addQueryRules();
    const context = this.getContext();

    if (this.queryModel.hasRules()) {
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
          context,
        )
        .subscribe((response: IData) => {
          this.previousPage = this.initialState.page;
          this.dataSource = response;
          this.showLoader = false;
        });
    }
  }

  private getContext(): IContext {
    if (this.previousPage) {
      if (this.previousPage < this.initialState.page) {
        return {
          document: this.contextDocument,
          before:
            this.dataSource.result[this.dataSource.result.length - 1]
              .emitDocumentDate,
        };
      } else {
        return {
          document: this.contextDocument,
          after: this.dataSource.result[0].emitDocumentDate,
        };
      }
    } else {
      return {
        document: this.contextDocument,
      };
    }
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.showLoader = true;
    this.onDataBound(this.initialState);
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
