import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  Actions,
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  FormatDatePipe,
  IError,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  TableColumn,
  VirtualTableComponent,
} from '@intorqa-ui/core';

import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { BackEndQuery } from '@portal/shared/models/backend-query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { IUserCategory } from '@portal/tags/interfaces/tag.interface';
import { Tag } from '@portal/tags/models/tag';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { KeycloakService } from 'keycloak-angular';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { catchError, Subscription } from 'rxjs';
import { TagWizardComponent } from '../../tag-wizard/tag-wizard.component';
import { CreateTagTypeComponent } from '../create-tag-type/create-tag-type.component';
import { DeleteTagDependenciesComponent } from '../delete-tag-dependencies/delete-tag-dependencies.component';
import { TagDependenciesComponent } from '../tag-dependencies/tag-dependencies.component';

@Component({
  selector: 'itq-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    FAwesomeModule,
    FormatDatePipe,
    ReactiveFormsModule,
    MatTooltipModule,
    MatRadioModule,
    FormsModule,
    VirtualTableComponent,
    MatIconModule,
    MatSlideToggleModule,
  ],
})
export class TagsComponent implements OnInit {
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public form: FormGroup;
  private subscriptions: Subscription = new Subscription();

  private _dataSource: {
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  };

  public get dataSource() {
    return this._dataSource;
  }

  public set dataSource(value: {
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  }) {
    if (this.initialState.page === 1) {
      this._dataSource = value;
    } else {
      this._dataSource.items = [...this._dataSource.items, ...value.items];
    }
  }
  public tableColumns: TableColumn[];
  public userCategoriesDataSource: Array<IUserCategory>;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Align = Align;

  @ViewChild('alertsTemplate') alertsTemplate: TemplateRef<unknown>;
  @ViewChild('sharedTickTemplate') sharedTickTemplate: TemplateRef<unknown>;
  @ViewChild('intorqaTickTemplate') intorqaTickTemplate: TemplateRef<unknown>;
  @ViewChild('tagTypeTemplate') tagTypeTemplate: TemplateRef<unknown>;
  @ViewChild('editTemplate') editTemplate: TemplateRef<unknown>;
  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;

  constructor(
    readonly sharedService: SharedService,
    private tagService: TagService,
    readonly userService: UserService,
    private snackBar: MatSnackBar,
    public keycloakService: KeycloakService,
    private customOverlayService: CustomOverlayService,
    readonly widgetService: WidgetService,
    readonly ecosystemService: EcosystemsService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit() {
    this.bindReloadTagsSubscription();
    this.bindChangeEcosystemSubscription();
    this.initForm();
    this.onDataBound();
    this.getUserCategories();
  }

  ngAfterViewInit(): void {
    this.initColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getUserCategories(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IUserCategory>) => {
        this.userCategoriesDataSource = response;
      });
  }

  private bindReloadTagsSubscription(): void {
    this.subscriptions.add(
      this.tagService.reloadTags$.subscribe(() => {
        this.initialState.resetPagination();
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }),
    );
  }

  private initForm(): void {
    this.form = new FormGroup({
      sharedTag: new FormControl(false),
      searchTerm: new FormControl(''),
      type: new FormControl(undefined),
    });
    if (this.keycloakService.isUserInRole('super-admin')) {
      this.form.addControl('system', new FormControl(false));
    }
    if (this.keycloakService.isUserInRole('saas-alerts')) {
      this.form.addControl('hasAlerts', new FormControl(false));
    }
  }

  public onChangeType(): void {
    this.sharedService.loader$.next(true);
    this.initialState.resetPagination();
    this.onDataBound();
  }

  public onDataBound(): void {
    this.sharedService.loader$.next(true);
    this.tagService
      .getTags(
        this.initialState,
        this.form.get('searchTerm')?.value,
        this.userService.userPreferences.defaultEcosystemId,
        this.form.get('type')?.value,
        this.form.get('system')?.value,
        this.form.get('sharedTag')?.value,
        this.form.get('hasAlerts')?.value,
      )
      .subscribe(
        (response: {
          items: Array<Tag>;
          page: number;
          pageSize: number;
          totalCount: number;
        }) => {
          this.dataSource = response;
          this.sharedService.loader$.next(false);
        },
      );
  }

  initColumns(): void {
    this.tableColumns = [
      {
        name: 'Updated',
        dataKey: 'updatedDate',
        isSortable: true,
        width: '150px',
      },
      {
        name: 'Name',
        dataKey: 'name',
        isSortable: true,
        width: 'fit',
      },
      {
        name: 'Tag Type',
        dataKey: 'userTagCategory',
        customRender: true,
        template: this.tagTypeTemplate,
        width: '150px',
      },
      {
        name: 'Created',
        dataKey: 'createdDate',
        isSortable: true,
        width: '130px',
      },
      {
        name: 'Shared',
        dataKey: 'sharedTag',
        position: 'center',
        customRender: true,
        template: this.sharedTickTemplate,
        width: '120px',
      },
      {
        name: 'System tag',
        dataKey: 'categoryId',
        position: 'center',
        customRender: true,
        template: this.intorqaTickTemplate,
        width: '140px',
      },
      {
        name: undefined,
        dataKey: 'edit',
        position: 'center',
        customRender: true,
        template: this.editTemplate,
        width: '77px',
      },
    ];

    if (this.keycloakService.isUserInRole('saas-alerts')) {
      this.tableColumns.splice(5, 0, {
        name: 'Has Alerts?',
        dataKey: 'alertTypeId',
        position: 'center',
        customRender: true,
        template: this.alertsTemplate,
        width: '160px',
      });
    }

    if (this.keycloakService.isUserInRole('super-admin')) {
      this.tableColumns.push({
        name: undefined,
        dataKey: 'settings',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '47px',
      });
    }
  }

  public onCreate(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: CreateTagTypeComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  onEdit(tag: Tag): void {
    const overlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: TagWizardComponent,
          inputs: {
            tag,
            action: Actions.EDIT,
            queryModel: new BackEndQuery(
              tag.query.operator,
              tag.query.conditions,
              tag.query.queries,
            ).convertFromBackEndQuery(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    overlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.sharedService.loader$.next(true);
        this.onDataBound();
      }
    });
  }

  onDelete(tag: Tag): void {
    this.customOverlayService.openCustom(
      {
        title: 'Delete tag?',
        message: `Are you sure you want to permanently delete the <strong>${tag?.name}</strong> tag?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          this.tagService
            .delete(tag.tagId)
            .pipe(
              catchError((errorResponse: IError) => {
                if (errorResponse) {
                  this.customOverlayService.openCustom(
                    {
                      title: 'Oooops!',
                      data: errorResponse.description,
                      message: errorResponse.description,
                      component: DeleteTagDependenciesComponent,
                      icon: ['far', 'exclamation-circle'],
                      size: '4x',
                      dialog: {
                        type: DialogTypes.ALERT,
                      },
                    },
                    DialogComponent,
                  );
                }
                throw errorResponse;
              }),
            )
            .subscribe(() => {
              this.sharedService.loader$.next(true);
              this.onDataBound();
              this.snackBar.open('Your tag has been deleted!', 'Close', {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              });
            });
        }
      },
    );
  }

  public onClone(tag: Tag): void {
    tag.name = `${tag.name} (clone)`;

    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: TagWizardComponent,
          inputs: {
            tag,
            action: Actions.CLONE,
            queryModel: new BackEndQuery(
              tag.query.operator,
              tag.query.conditions,
              tag.query.queries,
            ).convertFromBackEndQuery(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(() => {
      this.onSearch();
    });
  }

  public onViewHierarchy(tag: Tag): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        tagId: tag.tagId,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'min-w-[900px]',
      component: TagDependenciesComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(() => {
      this.onSearch();
    });
  }

  public onSearch(): void {
    this.initialState.resetPagination();
    this.sharedService.loader$.next(true);
    this.onDataBound();
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.onSearch();
      }),
    );
  }
}
