import { ChartType } from '@portal/widgets/enums/chart.enum';
import { BarChart } from '../models/charts/bar-chart';
import { Chart } from '../models/charts/chart';
import { LineChart } from '../models/charts/line-chart';
import { NetworkChart } from '../models/charts/network-chart';
import { PieChart } from '../models/charts/pie-chart';

export class ChartFactory {
  static createChart(type: ChartType): Chart {
    if (type === ChartType.BAR) {
      return new BarChart();
    } else if (type === ChartType.LINE) {
      return new LineChart();
    } else if (type === ChartType.PIE) {
      return new PieChart();
    } else if (type === ChartType.NETWORK) {
      return new NetworkChart();
    }
  }
}
