<div
  [ngClass]="{
    'flex justify-between h-[48px] w-full items-center px-4 border-b border-border cursor-pointer': true,
    'bg-primary-over border-l-4 border-l-primary': active
  }"
  matRipple
>
  <div class="flex items-center w-full">
    <mat-icon class="mr-2">{{ icon }}</mat-icon>
    <span class="font-medium">{{ label }}</span>
  </div>

  @if (active) {
    <fa-icon
      [icon]="['far', 'pen-circle']"
      class="font-medium"
      [size]="'xl'"
    ></fa-icon>
  } @else if (errors) {
    <mat-icon
      [matTooltip]="'This form contains errors'"
      class="text-danger font-medium"
      >error</mat-icon
    >
  } @else if (completed) {
    <mat-icon class="text-primary font-medium">check_circle</mat-icon>
  }
</div>
