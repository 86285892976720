import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  Actions,
  AlertTypesIcons,
  AlertTypesLabel,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  PriorityColor,
  PriorityLabel,
  QueryFilters,
  Sizes,
  TransactionNotificationType,
  TransactionNotificationTypeText,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';
import { TransactionalNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileTypeIcon } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { BackEndQuery } from '@portal/shared/models/backend-query-model';
import { SharedModule } from '@portal/shared/shared.module';
import { Tag } from '@portal/tags/models/tag';
import { TagService } from '@portal/tags/services/tag.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ChartType } from '@portal/widgets/enums/chart.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { GetActionPipe } from './notifications-transactional.pipe';
import { ChartExploreTimelineComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-timeline/chart-explore-timeline.component';

@Component({
  selector: 'itq-notifications-transactional',
  templateUrl: './notifications-transactional.component.html',
  styleUrls: ['./notifications-transactional.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    GetActionPipe,
  ],
})
export class NotificationsTransactionalComponent {
  @Input() notification: TransactionalNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly TransactionNotificationTypeText = TransactionNotificationTypeText;
  readonly TransactionNotificationType = TransactionNotificationType;
  readonly AlertTypesLabel = AlertTypesLabel;

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly profileService: ProfileService,
    readonly router: Router,
    readonly widgetService: WidgetService,
  ) {}

  ngOnInit(): void {
    this.class = !this.notification.read ? 'unread' : '';
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: TransactionalNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      });
  }

  public onLoadAction(): void {
    const { triggerMetadata } = this.notification;

    switch (triggerMetadata.action) {
      case TransactionNotificationType.TAG_CREATED:
        this.handleCreateTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_EDITED:
        this.handleEditTagAction(triggerMetadata);
        break;
      case TransactionNotificationType.TAG_DELETED:
        this.handleDeleteTagAction();
        break;
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
        this.handleLoadProfileAction(triggerMetadata);
        break;
      case TransactionNotificationType.PROFILE_DELETED:
        this.handleDeleteProfileAction();
        break;
      default:
        break;
    }
  }

  private handleDeleteTagAction(): void {
    this.router.navigateByUrl('/tags');
    this.notificationService.hideNotifications$.next();
  }

  private handleDeleteProfileAction(): void {
    this.router.navigateByUrl('/profiles');
    this.notificationService.hideNotifications$.next();
  }

  private handleEditTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe((response: Tag) => {
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: TagWizardComponent,
            inputs: {
              tag: response,
              action: Actions.EDIT,
              queryModel: new BackEndQuery(
                response.query.operator,
                response.query.conditions,
                response.query.queries,
              ).convertFromBackEndQuery(),
            },
          },
        },
        closeBtnStyle: 'basic',
        closeBtnClass: 'hidden',
        type: CustomOverlayType['almost-full'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    });
  }

  private handleCreateTagAction(triggerMetadata: { [key: string]: any }): void {
    const { id } = triggerMetadata;
    this.tagService.getTagById(id).subscribe((tag: Tag) => {
      const timeline = this.widgetService.mergeTagIntoTimeline(tag);
      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: ChartExploreTimelineComponent,
            inputs: {
              widget: timeline,
            },
          },
        },
        closeBtnStyle: 'basic',
        closeBtnClass: 'hidden',
        type: CustomOverlayType['almost-full'],
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    });
  }

  private handleLoadProfileAction(triggerMetadata: {
    [key: string]: any;
  }): void {
    const { id } = triggerMetadata;
    this.profileService.getProfileById(id).subscribe((profile: Profile) => {
      let navigationItem: any;
      const queryFilters = new QueryFilters(
        30,
        1,
        undefined,
        undefined,
        {
          direction: 'desc',
          active: 'updatedDate',
        },
        undefined,
      );
      const profileTypeIcon = ProfileTypeIcon[profile?.profileTypeName];
      const timelineChart = {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      };

      if (
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_CREATED ||
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_DELETED ||
        triggerMetadata.action ===
          TransactionNotificationType.PROFILE_NOTE_UPDATED
      ) {
        navigationItem = new ProfilesNavigationItem(
          `${Actions.EXPLORE}_${profile.profileId}`,
          profile,
          Actions.EXPLORE,
          undefined,
          queryFilters,
          new FormGroup({}),
          profileTypeIcon,
          IconType.FONT_AWESOME,
          timelineChart,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          2,
        );
      } else {
        navigationItem = new NavigationHistoryItem(
          `${Actions.EXPLORE}_${profile.profileId}`,
          profile,
          Actions.EXPLORE,
          undefined,
          queryFilters,
          new FormGroup({}),
          profileTypeIcon,
          IconType.FONT_AWESOME,
          timelineChart,
          undefined,
          undefined,
        );
      }

      this.customOverlayService.open({
        data: {
          componentConfig: {
            component: TagWizardComponent,
          },
        },
        closeBtnStyle: 'basic',
        type: CustomOverlayType['almost-full'],
        size: 'lg',
        component: ModalContainerComponent,
        disposeOnNavigation: true,
      });
    });
  }
}
