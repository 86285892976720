import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  FAwesomeModule,
  FieldSections,
  FieldTypes,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  TagCategory,
  UserTagCategoryLabel,
} from '@intorqa-ui/core';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { TagFactory } from '@portal/shared/factories/tag.factory';
import { IWidgetType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { ValuePickerComponent } from '@portal/tags/components/value-picker/value-picker.component';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import { ECharts } from 'echarts';
import {
  ICustomTag,
  IFilterField,
} from 'projects/portal/src/app/tags/interfaces/tag.interface';
import { TagService } from 'projects/portal/src/app/tags/services/tag.service';
import { Subscription } from 'rxjs';
import { TagWizardComponent } from '../../../tags/tag-wizard/tag-wizard.component';
import { DrilldownActorComponent } from '../drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '../drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '../drilldown-context/drilldown-context.component';
import { DrilldownRepliesComponent } from '../drilldown-replies/drilldown-replies.component';

@Component({
  selector: 'itq-drilldown-discord',
  templateUrl: './drilldown-discord.component.html',
  styleUrls: ['./drilldown-discord.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    DocumentsFeedComponent,
    MatIconModule,
    ValuePickerComponent,
  ],
})
export class DrilldownDiscordComponent implements OnInit {
  @Input() channel: IExtensionField;
  @Input() initialState: QueryFilters;
  @Input() allowCreateTag = true;
  @Input() allowDrilldownProfiles = true;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;

  public form: FormGroup;
  public widgetType: IWidgetType;

  public queryModel: Query;
  public dataSource: IData;
  public chartInstance: ECharts;
  private subscription = new Subscription();
  public count = 0;
  public showLoader = true;
  private _postTypeDataSource: Array<ICustomTag>;
  private _postContainsDataSource: Array<ICustomTag>;

  public get postContainsDataSource(): Array<ICustomTag> {
    return this._postContainsDataSource;
  }

  public set postContainsDataSource(value: Array<ICustomTag>) {
    if (this.postContainsInitialState.page === 1) {
      this._postContainsDataSource = value;
    } else {
      this._postContainsDataSource = this._postContainsDataSource.concat(value);
    }
  }

  public get postTypeDataSource(): Array<ICustomTag> {
    return this._postTypeDataSource;
  }

  public set postTypeDataSource(value: Array<ICustomTag>) {
    if (this.postTypeInitialState.page === 1) {
      this._postTypeDataSource = value;
    } else {
      this._postTypeDataSource = this._postTypeDataSource.concat(value);
    }
  }

  public postTypeInitialState: QueryFilters;
  public postContainsInitialState: QueryFilters;

  readonly PillType = PillType;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    readonly userService: UserService,
    readonly tagService: TagService,
    readonly customOverlayService: CustomOverlayService,
    readonly modalContainerService: ModalContainerService,
    readonly categoriesService: CategoryService,
  ) {
    this.customOverlayService.setCssClass$.next('dynamic');
  }

  ngOnInit(): void {
    this.initializeInitialState();
    this.createForm();
    this.loadFields();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadFields(): void {
    this.categoriesService
      .getUserCategoryFieldsByType(
        UserTagCategoryLabel.STANDARD.toString(),
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState,
      )
      .subscribe(() => {
        this.onDataBound(this.initialState);
      });
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private initializeInitialState(): void {
    this.postTypeInitialState = new QueryFilters(
      30,
      1,
      this.initialState.where,
      undefined,
      undefined,
      undefined,
    );
    this.postContainsInitialState = new QueryFilters(
      30,
      1,
      this.initialState.where,
      undefined,
      undefined,
      undefined,
    );
  }

  public onClearActors(): void {
    this.form.get('actor').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearPostContains(): void {
    this.form.get('postContains').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onClearPostType(): void {
    this.form.get('postType').setValue(undefined);
    this.onDataBound(this.initialState);
  }

  public onGetPostContains(params: QueryFilters): void {
    this.postContainsInitialState = params;
    this.addQueryRules();
    this.categoriesService
      .getCategoryValues(
        this.postTypeInitialState.query,
        this.postContainsInitialState,
        this.queryModel.convertToBackEndQuery(),
        TagCategory.POST_CONTAINS,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.SYSTEM_TAG_CATEGORY,
        this.initialState.page > 1
          ? this.postContainsDataSource[this.postContainsDataSource.length - 1]
              .name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.postContainsDataSource = response.items;
        },
      );
  }

  public onGetPostTypes(params: QueryFilters): void {
    this.postTypeInitialState = params;
    this.addQueryRules();
    this.categoriesService
      .getCategoryValues(
        this.postTypeInitialState.query,
        this.postTypeInitialState,
        this.queryModel.convertToBackEndQuery(),
        TagCategory.POST_TYPE,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.SYSTEM_TAG_CATEGORY,
        this.initialState.page > 1
          ? this.postTypeDataSource[this.postTypeDataSource.length - 1].name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.postTypeDataSource = response.items;
        },
      );
  }

  public onChangePostContains(): void {
    this.onChangePostContains();
    this.onDataBound(this.initialState);
  }

  public onChangePostType(): void {
    this.onGetPostTypes(this.postTypeInitialState);
    this.onDataBound(this.initialState);
  }

  public createForm(): void {
    this.form = new FormGroup({
      query: new FormControl(''),
      actor: new FormControl(undefined),
      postType: new FormControl(undefined),
      postContains: new FormControl(undefined),
    });
  }

  public onCreateTag(): void {
    const queryBuilderModel = this.queryModel.convertToQueryBuilderModel();
    this.modalContainerService.navigate$.next({
      component: TagWizardComponent,
      inputs: {
        tag: TagFactory.createTagByType(
          UserTagCategoryLabel.STANDARD.toString(),
        ),
        queryModel: queryBuilderModel,
        dates: this.initialState.where,
        action: Actions.CREATE,
      },
    });
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownContext(context: IContextSegment): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );

    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    this.initialState = params;
    this.addQueryRules();
    if (this.queryModel.hasRules()) {
      this.tagService
        .execute(
          this.initialState,
          this.queryModel.convertToBackEndQuery(),
          this.userService.userPreferences.defaultEcosystemId,
        )
        .subscribe((response: IData) => {
          this.dataSource = response;
          this.count = this.dataSource.count;
          this.showLoader = false;
        });
    }
  }

  private getField(label: string, section: string): IFilterField {
    return this.categoriesService.fields.find(
      (f: IFilterField) => f.label === label && f.section === section,
    );
  }

  private addRuleIfValueExists(
    controlName: string,
    fieldInfo: IFilterField,
  ): void {
    const controlValue = this.form.get(controlName).value;
    if (controlValue) {
      this.queryModel.addRule({
        entity: fieldInfo.field,
        field: fieldInfo.id,
        operator: 'in',
        value: controlValue.map((item: ICustomTag) => item.id),
      });
    }
  }

  private addQueryRules(): void {
    const sourceField = this.getField('Source', FieldSections.FILTER_TO);
    const actorField = this.getField('Actor', FieldSections.FILTER_TO);
    const postTypeField = this.getField('Post Type', FieldSections.FILTER_TO);
    const postContainsField = this.getField(
      'Post Contains',
      FieldSections.FILTER_TO,
    );
    const contentField = this.getField('All Text', FieldSections.SEARCH_WITHIN);
    this.queryModel = new Query([
      {
        entity: sourceField.field,
        field: sourceField.id,
        operator: 'in',
        value: ['Discord'],
      },
      {
        entity: contentField.field,
        field: contentField.id,
        operator: 'in',
        value: [this.channel?.data?.id],
      },
    ]);

    this.addRuleIfValueExists('actor', actorField);
    this.addRuleIfValueExists('postType', postTypeField);
    this.addRuleIfValueExists('postContains', postContainsField);

    if (this.form?.get('query')?.value) {
      this.queryModel.addRule({
        entity: contentField.field,
        field: contentField.id,
        operator: 'contains',
        value: [this.form?.get('query')?.value],
      });
    }
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.postContainsInitialState.where = dates;
    this.postTypeInitialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.showLoader = true;
    this.onDataBound(this.initialState);
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
