<div class="flex flex-col" [formGroup]="form">
  <span class="mb-1 text-13 font-medium">TAG</span>
  <itq-nav-button
    #dataSourceButton
    class="w-full"
    [size]="Sizes.BLOCK"
    [disabled]="form.controls['dataPoints'].disabled"
    [materialStyle]="'stroked'"
    [openDirection]="{ vertical: 'down', horizontal: 'left' }"
    [openPosition]="'inside'"
    [template]="fieldsTemplate"
  >
    @if (form.controls['dataPoints']?.value?.length > 0) {
      <div class="flex items-center justify-between w-full">
        <div
          class="flex items-center overflow-x-auto overflow-y-hidden custom-scrollbar space-x-1"
        >
          @for (item of form.controls['dataPoints']?.value; track item.id) {
            <itq-pill
              [class]="'secondary h-[24px] rounded-full'"
              [type]="PillType.BUTTON"
              [stopPropagation]="true"
              [matTooltip]="item.name"
              (mousedown)="$event.stopPropagation()"
              (select)="onRemoveTag(item)"
            >
              <span
                class="whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis"
              >
                {{ item?.name }}
              </span>
              <fa-icon
                [style.min-width]="'16px !important'"
                [style.min-height]="'16px !important'"
                class="rounded-full ml-1 w-[18px] h-[18px] hover:bg-[#4CAF50]"
                [icon]="['far', 'circle-xmark']"
                [size]="'sm'"
              ></fa-icon>
            </itq-pill>
          }
        </div>
        <fa-icon
          class="border-l border-border pl-1 ml-2"
          [icon]="
            dataSourceButton.isOpen
              ? ['far', 'chevron-up']
              : ['far', 'chevron-down']
          "
        ></fa-icon>
      </div>
    } @else {
      <div
        class="text-gray-400 font-normal justify-between flex items-center w-full"
      >
        Pick a tag...
        <fa-icon
          class="border-l border-border pl-1 ml-2"
          [icon]="
            dataSourceButton.isOpen
              ? ['far', 'chevron-up']
              : ['far', 'chevron-down']
          "
        ></fa-icon>
      </div>
    }
  </itq-nav-button>
  <ng-template #fieldsTemplate>
    <itq-multiple-tag-picker
      class="flex"
      formControlName="dataPoints"
      [initialState]="initialState"
      [field]="getField()"
      (selectionChange)="onChangeDataSource($event)"
    ></itq-multiple-tag-picker>
  </ng-template>
  @if (
    form.controls['dataPoints']?.touched &&
    form.controls['dataPoints']?.hasError('required')
  ) {
    <mat-error class="text-13 pt-1">Data points is required </mat-error>
  }
</div>
