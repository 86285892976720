<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'user']" [size]="'2x'"></fa-icon>

      <h1 class="text-28 font-bold pl-4">
        {{ actor }}
      </h1>
    </div>
    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <fa-icon [icon]="['far', 'refresh']"></fa-icon>
        <span class="whitespace-nowrap">REFRESH</span>
      </itq-mat-button>
      @if (allowCreateTag) {
        <itq-mat-button
          [materialStyle]="'stroked'"
          (clickEvent)="onCreateTag()"
        >
          <mat-icon>add</mat-icon>
          <span class="whitespace-nowrap">CREATE TAG</span>
        </itq-mat-button>
      }
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ count | number }}</itq-pill
        >
      </div>

      <div class="flex flex-col px-7 pt-4 pb-7">
        <h2 class="flex font-bold pb-2 text-xl">Search</h2>
        <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
        <itq-input
          class="w-full"
          [icon]="'search'"
          [placeholder]="'Search...'"
          formControlName="query"
          (search)="onChangeSearch()"
          (clear)="onChangeSearch()"
        ></itq-input>
        <h2 class="flex font-bold pb-4 pt-7 text-xl">Filters</h2>
        <div class="flex flex-col">
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('channel')?.value,
                'justify-start': !form?.get('channel')?.value
              }"
            >
              CHANNEL
              @if (form?.get('channel')?.value) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearChannel()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-value-picker
              [dates]="this.initialState.where"
              [queryModel]="this.queryModel?.convertToQueryBuilderModel()"
              [field]="'Channel_Filter to'"
              [formControlName]="'channel'"
              (valueChange)="onDataBound(this.initialState)"
            ></itq-value-picker>
          </div>
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('postType')?.value,
                'justify-start': !form?.get('postType')?.value
              }"
            >
              POST TYPE
              @if (form?.get('postType')?.value) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearPostType()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-multiple-dropdown
              [placeholder]="'Select post type...'"
              formControlName="postType"
              [initialState]="postTypeInitialState"
              [dataSource]="postTypeDataSource"
              [dataFields]="{ name: 'name', value: 'id' }"
              (changeValue)="onChangePostType()"
              (dataBound)="onGetPostTypes($event)"
            ></itq-multiple-dropdown>
          </div>
          <div class="flex flex-col mb-4">
            <div
              [ngClass]="{
                'mb-1 text-13 font-medium w-full flex items-center': true,
                'justify-between': form?.get('postContains')?.value,
                'justify-start': !form?.get('postContains')?.value
              }"
            >
              POST CONTAINS
              @if (form?.get('postContains')?.value) {
                <button
                  class="text-link hover:underline"
                  [type]="'button'"
                  (click)="onClearPostContains()"
                >
                  CLEAR
                </button>
              }
            </div>
            <itq-multiple-dropdown
              [placeholder]="'Select post contains...'"
              formControlName="postContains"
              [initialState]="postContainsInitialState"
              [dataSource]="postContainsDataSource"
              [dataFields]="{ name: 'name', value: 'id' }"
              (changeValue)="onChangePostContains()"
              (dataBound)="onGetPostContains($event)"
            ></itq-multiple-dropdown>
          </div>
          <div class="flex flex-col">
            <div class="mb-1 text-13 font-medium w-full flex items-center">
              DATE RANGE
            </div>
            <itq-date-range
              class="w-full"
              [dates]="this.initialState?.where"
              (search)="onChangeDate($event)"
            ></itq-date-range>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 border-r border-border flex-col">
      <div class="flex flex-1 overflow-hidden">
        <div class="flex flex-col bg-background border-r border-border flex-1">
          <div class="flex flex-col flex-1 h-full w-full min-w-[700px]">
            @if (dataSource) {
              <itq-documents-feed
                class="h-full flex flex-col overflow-hidden"
                [data]="dataSource"
                [initialState]="initialState"
                [query]="form?.get('query')?.value"
                [drilldownSegment]="actor"
                [allowDrilldownProfiles]="allowDrilldownProfiles"
                (drilldown)="onDrilldown($event)"
                (shareEmail)="onShareEmail($event)"
                (scrollEvent)="onDataBound($event)"
                (createProfile)="onCreateProfile($event)"
              ></itq-documents-feed>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

@if (showLoader) {
  <itq-loader></itq-loader>
}
