<div class="flex items-center w-full space-x-2">
  <div
    class="flex items-center overflow-x-auto overflow-y-hidden custom-scrollbar space-x-1"
  >
    <ng-container *ngFor="let item of dataSource">
      @if (type === PillType.BUTTON) {
        <itq-pill
          [class]="'secondary h-[24px] rounded-full'"
          [type]="type"
          [stopPropagation]="true"
          (mousedown)="$event.stopImmediatePropagation()"
          (select)="onRemoveItem(item)"
        >
          <span
            class="whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis"
          >
            {{ item[this.dataFields.name] || item }}
          </span>
          @if (this.type === PillType.BUTTON) {
            <fa-icon
              [style.min-width]="'16px !important'"
              [style.min-height]="'16px !important'"
              class="rounded-full ml-1 w-[18px] h-[18px] hover:bg-[#4CAF50]"
              [icon]="['far', 'circle-xmark']"
              [size]="'sm'"
            ></fa-icon>
          }
        </itq-pill>
      } @else {
        <itq-pill
          [class]="'font-medium rounded-full bg-link  h-[28px]'"
          [item]="item"
          [type]="PillType.CUSTOM"
          [stopPropagation]="true"
        >
          <span class="text-white">
            {{ item?.name }}
          </span>
        </itq-pill>
      }
    </ng-container>
  </div>
  @if (reachedMaxItems) {
    <div
      class="flex w-[24px] h-[24px]"
      #moreIcon
      (mouseover)="onShowTooltip()"
      (mouseout)="closeOverlay()"
    >
      <fa-icon [icon]="['far', 'circle-ellipsis']" [size]="'lg'"></fa-icon>
    </div>
  }
</div>
