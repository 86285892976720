<div
  class="flex overflow-auto w-full flex-col"
  #listSearch
  infiniteScroll
  (scrolledToEnd)="onScroll()"
>
  @for (item of dataSource?.items; track item.date) {
    <div class="flex flex-col mt-4 w-full">
      <span class="notification__group px-7">
        {{ item.date }}
      </span>
      <div class="flex flex-col">
        @for (
          notification of item.notifications;
          track notification.raisedAlertId
        ) {
          <div
            [ngClass]="{
              active: notification.raisedAlertId === raisedAlertId,
              'mx-7 mb-4 notification__wrapper': true
            }"
          >
            <ng-container #dynamicComponentContainer></ng-container>
          </div>
        }
      </div>
    </div>
  }
</div>
@if (dataSource?.totalCount === 0) {
  <itq-panel-info
    class="m-4 bg-white flex p-7"
    [label]="'Your inbox is empty!'"
    [message]="
      'When new notifications are available there will be shown in here'
    "
    [icon]="'exclamation-circle'"
  ></itq-panel-info>
}
