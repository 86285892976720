<div class="flex w-full max-h-[338px] overflow-hidden">
  <div class="flex flex-col">
    <mat-nav-list
      class="w-[275px]"
      [style.padding-right]="'0 !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        *ngFor="let item of sectionsDataSource"
        [style.background-color]="
          item.action === selectedSection.action ? '#f5f5f5 !important' : ''
        "
        (click)="onNavChange(item)"
      >
        <div
          [ngClass]="{
            'flex justify-between items-center w-full': true,
            'font-semibold': item.action === selectedSection.action
          }"
        >
          <div class="flex items-center space-x-2">
            <span>{{ item.name }}</span>
          </div>
          <mat-icon>{{
            item.action === selectedSection.action
              ? 'chevron_right'
              : 'expand_more'
          }}</mat-icon>
        </div>
      </mat-list-item>
      <mat-list-item (click)="onExplore()">
        <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
        Explore {{ field === ProfileDrildownScope.ACTOR ? 'Actor' : 'Channel' }}
      </mat-list-item>
    </mat-nav-list>
  </div>
  @if (selectedSection.action === 'create') {
    <mat-nav-list
      class="flex-1 border-l w-[200px] border-border overflow-auto"
      [style.margin-left]="'8px !important'"
      [style.padding-left]="'8px !important'"
      [style.padding-bottom]="'0 !important'"
      [style.padding-top]="'0 !important'"
    >
      <mat-list-item
        class="hover:bg-background-over"
        *ngFor="let item of vendorTypesDataSurce"
        (click)="onCreateProfile(item)"
      >
        <div class="flex items-center space-x-2 w-full">
          <div class="flex justify-between items-center w-full">
            <div class="flex items-center space-x-2">
              <fa-icon [icon]="['far', ProfileTypeIcon[item.name]]"></fa-icon>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </mat-list-item>
    </mat-nav-list>
  } @else if (selectedSection.action === 'update') {
    <div
      class="flex flex-col border-border border-l"
      [style.margin-left]="'8px !important'"
    >
      <div class="flex items-center p-2 pt-1">
        <itq-input
          class="w-full"
          [(ngModel)]="query"
          [placeholder]="'Search...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
      </div>
      <mat-selection-list
        [style.margin-left]="'8px !important'"
        class="flex overflow-auto flex-1 w-[275px] min-h-[250px]"
        [(ngModel)]="selections"
        (selectionChange)="onAddProfile()"
      >
        <mat-list-option
          class="hover:bg-background-over rounded-md"
          *ngFor="let item of vendorsDataSource?.items"
          [value]="item"
        >
          <div class="flex items-center space-x-2">
            <span [innerHTML]="item.name | highlightSearch: query"></span>
            @if (item.description) {
              <mat-icon
                class="flex items-center"
                [matTooltip]="item.description"
                style="font-size: 20px"
                >info</mat-icon
              >
            }
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  }
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}
