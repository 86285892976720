<form [formGroup]="form" class="flex h-full flex-col w-full">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex justify-between w-full flex-col space-y-2">
      <div class="flex items-center">
        <itq-svg class="w-7 h-7" [icon]="'ripl'"></itq-svg>

        <h1 class="text-28 font-bold pl-4">
          {{ widget?.name }}
        </h1>
        <itq-pill
          [class]="
            'rounded-2xl px-4 py-2 ml-7 shadow-none font-semibold bg-primary-over uppercase h-[36px]'
          "
          [type]="PillType.CUSTOM"
          >{{ widget.type | mapLabel }}</itq-pill
        >
      </div>
      @if (widget?.description) {
        <span class="text-base"> {{ widget.description }}</span>
      }
    </div>

    <div class="flex space-x-2">
      <itq-mat-button [materialStyle]="'stroked'" (clickEvent)="onRefresh()">
        <fa-icon [icon]="['far', 'refresh']"></fa-icon>
        <span class="whitespace-nowrap">REFRESH</span>
      </itq-mat-button>
      <itq-mat-button
        [disabled]="count === 0"
        [materialStyle]="'stroked'"
        (clickEvent)="onExport()"
      >
        <fa-icon [icon]="['far', 'download']"></fa-icon>
        <span class="whitespace-nowrap">EXPORT</span>
      </itq-mat-button>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <div class="flex flex-col border-r border-border w-[277px]">
      <div
        class="flex justify-between px-7 py-3 border-border border-b items-center h-[61px]"
      >
        <span class="font-medium">Matching content</span>
        <itq-pill
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md h-[36px]'"
          >{{ count | number }}</itq-pill
        >
      </div>

      <div class="flex flex-col px-7 py-4">
        <h2 class="flex font-bold pb-2 text-xl">Search</h2>
        <p class="pb-3">Enter phrase or keywords to narrow your result set</p>
        <itq-input
          class="w-full pb-4"
          [icon]="'search'"
          [placeholder]="'Search...'"
          formControlName="query"
          (search)="onChangeSearch()"
          (clear)="onChangeSearch()"
        ></itq-input>
        <h2 class="flex font-bold pb-4 text-xl">Filters</h2>
        <div class="flex 2 flex-col overflow-hidden">
          <span class="mb-1 text-13 font-medium flex"> DATE RANGE </span>
          <itq-date-range
            class="w-full"
            [dates]="this.initialState?.where"
            (search)="onChangeDate($event)"
          ></itq-date-range>
        </div>
      </div>
    </div>
    <div class="flex flex-1 border-r border-border flex-col">
      <div
        class="flex items-center px-7 py-3 justify-start w-full border-b border-border"
      >
        <span class="font-semibold mr-2">Display:</span>
        <itq-button-thumbnail
          [dataSource]="chartTypesDataSource"
          formControlName="chartType"
          (change)="onChangeChartType($event)"
        ></itq-button-thumbnail>
      </div>
      <div class="flex flex-1 overflow-hidden">
        <div class="flex flex-col bg-background border-r border-border flex-1">
          <div
            class="flex flex-col p-7 flex-1 h-full min-w-[500px]"
            #chartContainer
          >
            <itq-foamtree
              class="flex w-full h-full"
              [id]="widget?.widgetId + '_explore'"
              [settings]="widget?.options"
              [dataSource]="dataSource"
              [cluster]="segment?.data?.value"
              (drilldown)="onExpandCluster($event)"
            ></itq-foamtree>
          </div>
        </div>
        @if (segment?.data?.value) {
          <div class="flex flex-col flex-1 min-w-[500px]">
            <div class="flex flex-1 overflow-hidden">
              @if (documentsData) {
                <itq-documents-feed
                  class="h-full flex flex-col overflow-hidden flex-1"
                  [data]="documentsData"
                  [initialState]="initialState"
                  (scrollEvent)="onDataBound($event)"
                  (shareEmail)="onShareEmail($event)"
                  (drilldown)="onDrilldown($event)"
                  (createProfile)="onCreateProfile($event)"
                ></itq-documents-feed>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</form>

@if (showLoader) {
  <itq-loader></itq-loader>
}
