export enum TagCategory {
  ACTOR = 'Actor',
  CHANNEL = 'Channel',
  POST_CONTAINS = 'Post Contains',
  POST_TYPE = 'Post Type',
}

export enum UserTagCategoryLabel {
  VENDOR = 'Vendor',
  SYSTEM = 'System',
  STANDARD = 'Standard',
}

export enum FieldTypes {
  FIELD = 'field',
  SYSTEM_TAG_CATEGORY = 'systemTagCategory',
  USER_TAG_CATEGORY = 'userTagCategory',
}

export enum FieldSections {
  SEARCH_WITHIN = 'Search within',
  FILTER_TO = 'Filter to',
}
