import { DTOQuery, IRule, IRuleSet } from '@intorqa-ui/core';

import {
  ICustomTag,
  IFilterField,
  ITagMetadata,
} from '../../tags/interfaces/tag.interface';
import { BackEndQuery } from './backend-query-model';

export class QueryBuilderModel {
  constructor(
    public rules: Array<IRule | IRuleSet> = [],
    public condition = 'and',
  ) {}

  public getRulesCount(): number {
    const countRules = (rules: Array<IRule | IRuleSet>): number => {
      let count = 0;
      for (const rule of rules) {
        if ((rule as IRuleSet)?.rules) {
          count += countRules((rule as IRuleSet).rules);
        } else {
          count += 1;
        }
      }
      return count;
    };

    return countRules(this.rules);
  }

  public convertToQueryBuilder(
    query: QueryBuilderModel,
    metadata: Array<ITagMetadata>,
    fields: Array<IFilterField>,
  ): QueryBuilderModel {
    const getRule = (rule: IRule | IRuleSet, metadata: Array<ITagMetadata>) => {
      if ((rule as IRuleSet)?.rules) {
        const ruleset = rule as IRuleSet;
        let rules = [];
        ruleset?.rules.forEach((item: any) => {
          const rule = getRule(item, metadata);
          rules.push(rule);
        });
        return {
          condition: ruleset?.condition,
          rules,
        };
      } else {
        const castedRule = rule as IRule;
        if (castedRule.field === 'tag') {
          const values = castedRule.value.map((value: string) => {
            const tag = metadata.find(
              (tag: ITagMetadata) => tag.tagId === value,
            );
            return {
              name: tag.tagName,
              id: tag.tagId,
              description: '',
            };
          });
          const tag = metadata.find(
            (tag: ITagMetadata) => tag.tagId === castedRule.value[0],
          );
          const field = fields.find(
            (item: IFilterField) => item.field === tag.categoryName,
          );
          return {
            entity: field.field,
            field: field.id,
            operator: castedRule.operator,
            value: values,
          };
        } else if (castedRule.field === 'content') {
          const field = fields.find(
            (item: IFilterField) => item.field === castedRule.field,
          );
          return {
            entity: field.field,
            field: field.id,
            operator: castedRule.operator,
            value: castedRule.value[0],
          };
        } else {
          const field = fields.find(
            (item: IFilterField) => item.field === castedRule.field,
          );
          const values = castedRule.value.map((value: string) => {
            return {
              name: value,
              id: value,
              description: '',
            };
          });
          return {
            entity: field.field,
            field: field.id,
            operator: castedRule.operator,
            value: values,
          };
        }
      }
    };
    let rules = [];
    query.rules.forEach((item: IRule | IRuleSet) => {
      const rule = getRule(item, metadata);
      rules.push(rule);
    });
    return new QueryBuilderModel(rules, query.condition);
  }

  public addRule(rule: IRule): void {
    this.rules.push(rule);
  }

  public removeRuleByField(field: string): void {
    this.rules = this.rules.filter((item: IRule) => item.field !== field);
  }

  public convertToBackEndQuery(): DTOQuery {
    if (!this) {
      console.error('Model cannot/should not be null');
      return null;
    }
    const result = new BackEndQuery();
    result.operator = this.condition.toLowerCase();
    if (this.rules) {
      this.rules.forEach((rule: any) => {
        if (rule['condition']) {
          let rules = [];
          rule.rules.map((item: any) => {
            if (item.condition) {
              return item;
            } else {
              if (
                item.operator === 'contains' ||
                item.operator === 'doesnotcontains'
              ) {
                if (item['value']) {
                  rules.push({
                    entity: item['entity'],
                    field: item['entity'],
                    operator: item['operator'],
                    value: item['value'],
                  });
                }
              } else {
                if (item['value']?.length > 0) {
                  rules.push({
                    entity: item['entity'],
                    field: item['entity'],
                    operator: item['operator'],
                    value: item['value'],
                  });
                }
              }
            }
          });
          const query = new QueryBuilderModel(rules, rule['condition']);
          const model = query.convertToBackEndQuery();
          if (model.conditions?.length > 0 || model.queries?.length > 0) {
            result.queries.push(model);
          }
        } else {
          let queryRule: IRule;
          if (
            rule.operator === 'contains' ||
            rule.operator === 'doesnotcontains'
          ) {
            if (rule['value']) {
              queryRule = {
                field: rule['entity'],
                operator: rule['operator'],
                value: rule.query
                  ? rule?.query
                  : Array.isArray(rule['value'])
                    ? rule['value']
                    : [rule['value']],
              };
              result.conditions.push(queryRule);
            }
          } else {
            if (rule['value']?.length > 0) {
              queryRule = {
                field: rule['entity'],
                operator: rule['operator'],
                value: rule.query
                  ? rule?.query
                  : Array.isArray(rule['value'])
                    ? rule['value']?.map(
                        (item: ICustomTag) => item.id || item.name,
                      )
                    : [rule['value']],
              };
              result.conditions.push(queryRule);
            }
          }
        }
      });
    }

    return result;
  }

  public addRuleToEmptyRuleSet(): void {
    const checkRules = (rules: Array<IRule | IRuleSet>): void => {
      for (const rule of rules) {
        if ((rule as IRuleSet)?.rules?.length > 0) {
          checkRules((rule as IRuleSet).rules);
        } else {
          if ((rule as IRuleSet)?.rules?.length === 0) {
            (rule as IRuleSet).rules.push({
              entity: undefined,
              field: undefined,
              operator: undefined,
              value: undefined,
            });
          }
        }
      }
    };

    return checkRules(this.rules);
  }

  public hasEmptyRules(): boolean {
    const checkRules = (rules: Array<IRule | IRuleSet>): boolean => {
      for (const rule of rules) {
        const isRuleset = (rule as IRuleSet)?.condition;
        if (isRuleset) {
          if (checkRules((rule as IRuleSet).rules)) {
            return true;
          }
        } else {
          const castedRule = rule as IRule;
          if (
            castedRule.value === undefined ||
            castedRule.value === '' ||
            castedRule?.value?.length === 0
          ) {
            return true;
          }
        }
      }
      return false;
    };

    return checkRules(this.rules);
  }

  public hasRules(): boolean {
    const checkRules = (rules: Array<IRule | IRuleSet>): boolean => {
      for (const rule of rules) {
        if ((rule as IRuleSet)?.rules) {
          if (checkRules((rule as IRuleSet).rules)) {
            return true;
          }
        } else {
          const castedRule = rule as IRule;
          if (
            castedRule.value !== undefined &&
            castedRule.value !== '' &&
            castedRule.value?.length > 0
          ) {
            return true;
          }
        }
      }
      return false;
    };

    return checkRules(this.rules);
  }

  public maxLevelNestingReached(): boolean {
    const checkNesting = (
      rules: Array<IRule | IRuleSet>,
      level: number,
    ): boolean => {
      if (level >= 3) return true;
      for (const rule of rules) {
        if ((rule as IRuleSet)?.rules) {
          if (checkNesting((rule as IRuleSet).rules, level + 1)) {
            return true;
          }
        }
      }
      return false;
    };
    return checkNesting(this.rules, 1);
  }

  public getTagIds(): Array<string> {
    const getRuleTagIds = (rule: any) => {
      if (rule.rules?.length > 0) {
        let tagIds = [];
        rule.rules.forEach((item: any) => {
          const ruleTagsIds = getRuleTagIds(item);
          if (ruleTagsIds?.length > 0) {
            tagIds = [...tagIds, ...ruleTagsIds];
          }
        });
        return tagIds;
      } else {
        if (rule.field !== 'tag') {
          return undefined;
        } else {
          return rule.value;
        }
      }
    };
    let tagIds: Array<string> = [];
    this.rules.forEach((item: any) => {
      const ruleTagsIds = getRuleTagIds(item);
      if (ruleTagsIds?.length > 0) {
        tagIds = [...tagIds, ...ruleTagsIds];
      }
    });
    return tagIds;
  }

  public clone(): QueryBuilderModel {
    const cloneRules = (
      rules: Array<IRule | IRuleSet>,
    ): Array<IRule | IRuleSet> => {
      return rules.map((rule) => {
        if ((rule as IRuleSet)?.rules) {
          return {
            ...rule,
            rules: cloneRules((rule as IRuleSet).rules),
          };
        } else {
          return { ...rule };
        }
      });
    };

    return new QueryBuilderModel(cloneRules(this.rules), this.condition);
  }
}
