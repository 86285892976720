import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Actions,
  Align,
  ComponentType,
  CustomOverlayService,
  CustomOverlayType,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { IConnectionsData } from '@portal/profiles/interfaces/profile-connection.interface';
import { ConnectionType } from '@portal/profiles/models/connection-type';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileConnection } from '@portal/profiles/models/profile-connection';
import { ConnectionsService } from '@portal/profiles/services/connections.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AddConnectionsComponent } from '@portal/shared/components/add-connections/add-connections.component';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-profiles-connections',
  templateUrl: './profiles-connections.component.html',
  styleUrls: ['./profiles-connections.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesConnectionsComponent,
    },
  ],
})
export class ProfilesConnectionsComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() action = Actions.CREATE;

  public tableColumns: Array<TableColumn> = [];
  public dataSource: IConnectionsData = { items: [], totalCount: 0 };
  private selection: Array<ProfileConnection>;
  private touched = false;
  private disabled = false;
  private connectionsSubscription: Subscription;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public totalCount: number;
  private resetSubscription: Subscription;
  public isFilterable: boolean;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly Actions = Actions;
  readonly Align = Align;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<unknown>;

  constructor(
    public profileService: ProfileService,
    public connectionsService: ConnectionsService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit(): void {
    if (this.navigationItem.connections?.length > 0) {
      this.connectionsService.connections = {
        items: this.navigationItem.connections,
        totalCount: this.navigationItem.connections.length,
      };
      this.dataSource = cloneDeep(this.connectionsService.connections);
    }
    this.connectionsSubscription =
      this.connectionsService.connections$.subscribe(
        (response: IConnectionsData) => {
          this.form.controls.connections?.setValue(response);
          this.dataSource = cloneDeep(response);
          this.profileService.loader$.next(false);
        },
      );
    this.resetSubscription = this.connectionsService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    this.connectionsService.getConnectionTypes(this.profile).subscribe(() => {
      this.onGetTypes();
    });
    if (
      this.navigationItem.action === Actions.EDIT ||
      this.navigationItem.action === Actions.EXPLORE
    ) {
      this.onDataBound(this.initialState, false);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.connectionsSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ProfileConnection>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Connection type',
        dataKey: 'typeName',
        searchField: 'typeId',
        width: '200px',
        // isFilterable: true,
        isSortable: true,
        // searchFieldType: SearchFieldType.ID,
        // searchComponent: ComponentType.DROPDOWN,
        // dataSource: undefined,
      },
      {
        name: 'Connected profile',
        dataKey: 'targetProfileName',
        searchField: 'targetProfileName',
        width: 'fit',
        // isFilterable: true,
        httpBinding: true,
        isSortable: true,
        // searchFieldType: SearchFieldType.TEXT,
        // dataSource: undefined,
        // searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Description',
        dataKey: 'description',
        searchField: 'description',
        width: 'fit',
        // isFilterable: true,
        isSortable: true,
        // searchFieldType: SearchFieldType.TEXT,
        // dataSource: undefined,
        // searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
    if (this.navigationItem.action === Actions.EXPLORE) {
      this.tableColumns = [
        {
          name: 'Updated date',
          dataKey: 'updatedDate',
          searchField: 'updatedDate',
          isSortable: true,
          width: '180px',
          // sticky: true,
          customRender: true,
          template: this.dateTemplate,
          // isFilterable: true,
          // searchFieldType: SearchFieldType.DATE,
          // searchComponent: ComponentType.DATE,
        },
        {
          name: 'Updated by',
          dataKey: 'updatedBy',
          searchField: 'updatedBy',
          isSortable: true,
          position: 'center',
          customRender: true,
          template: this.ownerTemplate,
          width: '180px',
          // sticky: true,
          // httpBinding: true,
          isFilterable: true,
          // searchFieldType: SearchFieldType.TEXT,
          // searchComponent: ComponentType.DROPDOWN,
          // dataSource: undefined,
          // dataBound: () => {
          //   this.onGetUsers();
          // },
        },
        ...this.tableColumns,
      ];
    }
  }

  private onGetTypes(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'typeId') {
        column.dataSource = this.connectionsService.connectionTypes.map(
          (item: ConnectionType) => ({ name: item.name, value: item.id }),
        );
      }
      return column;
    });
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'updatedBy') {
        this.connectionsService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  public onDeleteConnection(connection: ProfileConnection): void {
    if (this.action === Actions.DELETE) {
      this.connectionsService
        .removeConnections(this.profile.profileId, [connection])
        .subscribe();
    } else {
      this.dataSource.items = this.dataSource.items.filter(
        (item: ProfileConnection) => item.id !== connection.id,
      );
      this.connectionsService.connections.items =
        this.connectionsService.connections.items.filter(
          (item: ProfileConnection) => item.id !== connection.id,
        );
    }
    this.form.controls.notes?.markAsTouched();
  }

  public onEditConnection(connection: ProfileConnection): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddConnectionsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.EDIT,
            connection: connection,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddConnection(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: AddConnectionsComponent,
          inputs: {
            profile: this.profile,
            action: Actions.CREATE,
            connection: new ProfileConnection(
              Utils.generateUUID(),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
            ),
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters, showLoader = true): void {
    this.profileService.loader$.next(showLoader);
    this.connectionsService
      .getConnections(this.profile.profileId, params)
      .subscribe();
  }

  public onExit(): void {
    this.connectionsService.showAddConnections$.next(undefined);
    this.connectionsService.showConnectionsSettings$.next(false);
  }
}
