import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FieldTypes,
  HighlightSearchPipe,
  IsSelectedPipe,
  OverlayService,
  QueryFilters,
} from '@intorqa-ui/core';
import { Query } from '@portal/shared/models/query-model';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import {
  ICustomTag,
  IFilterField,
  IUserCategory,
} from '../../interfaces/tag.interface';

@Component({
  selector: 'itq-multiple-tag-picker',
  templateUrl: './multiple-tag-picker.component.html',
  styleUrls: ['./multiple-tag-picker.component.scss'],
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    FormsModule,
    HighlightSearchPipe,
    IsSelectedPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultipleTagPickerComponent,
    },
  ],
})
export class MultipleTagPickerComponent implements OnInit {
  @Input() field: string;
  @Input() fields: Array<IFilterField>;
  @Input() disabled = false;
  @Input() initialState = new QueryFilters(
    1000,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() selectionChange = new EventEmitter<Array<ICustomTag>>();

  public selections: Array<ICustomTag> = [];
  private _tagsDataSource: Array<ICustomTag>;

  public get tagsDataSource(): Array<ICustomTag> {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: Array<ICustomTag>) {
    if (this.initialState.page === 1) {
      this._tagsDataSource = value;
    } else {
      this._tagsDataSource = this._tagsDataSource.concat(value);
    }
  }

  public selectedUserCategory: string;
  public userCategoriesDataSource: Array<IUserCategory>;
  public showLoader = true;
  private touched = false;
  public query: string;

  constructor(
    readonly userService: UserService,
    readonly categoryService: CategoryService,
    readonly overlayService: OverlayService,
  ) {}

  onChange = (items: Array<ICustomTag>) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<ICustomTag>): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {
    this.getUserCategories();
  }

  public onSelectionChange(): void {
    this.onChange(this.selections);
    this.selectionChange.emit(this.selections);
  }

  public onSearch(): void {
    this.initialState.query = this.query;
    this.onGetTags();
  }

  public getUserCategories(): void {
    this.categoryService
      .getUserCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IUserCategory>) => {
        this.userCategoriesDataSource = response;
        this.selectedUserCategory = this.userCategoriesDataSource[0].name;
        this.onGetTags();
      });
  }

  public onNavChange(userTagCategory: string): void {
    this.selectedUserCategory = userTagCategory;
    this.onGetTags();
  }

  private onGetTags(): void {
    this.categoryService
      .getCategoryValues(
        this.query,
        this.initialState,
        new Query().convertToBackEndQuery(),
        this.selectedUserCategory,
        this.userService.userPreferences.defaultEcosystemId,
        FieldTypes.USER_TAG_CATEGORY,
        this.initialState.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined,
      )
      .subscribe(
        (response: { items: Array<ICustomTag>; totalCount: number }) => {
          this.tagsDataSource = response.items;
          this.showLoader = false;
        },
      );
  }
}
