import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  FAwesomeModule,
  HighlightSearchPipe,
  QueryFilters,
  SearchFieldType,
  Sizes,
  TagCategory,
} from '@intorqa-ui/core';
import { DateFormats } from '@portal/document/enums/document.enum';
import {
  DocumentItem,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentItemActionsComponent } from '../document-item-actions/document-item-actions.component';
import { DocumentLinksComponent } from '../document-links/document-links.component';
import { FieldVendorsLinksComponent } from '@portal/profiles/components/field-vendors-links/field-vendors-links.component';

@Component({
  selector: 'itq-document-item-tile',
  templateUrl: './document-item-tile.component.html',
  styleUrls: ['./document-item-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CoreModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    MatIconModule,
    DocumentItemActionsComponent,
    DocumentLinksComponent,
    HighlightSearchPipe,
    MatRippleModule,
    MatProgressSpinnerModule,
    CommonModule,
    FieldVendorsLinksComponent,
  ],
})
export class DocumentItemTileComponent implements OnInit {
  @Input() document: DocumentItem;
  @Input() query: string;
  @Input() parentDocument: string;
  @Input() isParent: boolean;
  @Input() drilldownSegment: string;
  @Input() shareByEmail = true;
  @Input() allowDrilldownProfiles = true;
  @Input() allowCreateConnections = false;
  @Input() isParentExpanded: boolean;

  @Output() drilldown = new EventEmitter<ISegment>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();
  @Output() showParent = new EventEmitter<DocumentItem>();
  @Output() hideParent = new EventEmitter<void>();
  @Output() shareEmail = new EventEmitter<DocumentItem>();

  public isExpanded = false;
  public profileTypesDataSource: Array<ProfileType> = [];
  public profileInitialState: QueryFilters;
  private subscription = new Subscription();
  public translatedContent: string;
  public translatedTitle: string;
  public repliesCount: number;
  public showLoader = false;
  public showActions = false;
  public hasExpandedActions = false;

  readonly SegmentScope = SegmentScope;
  readonly ProfileDrildownScope = ProfileDrildownScope;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly TagCategory = TagCategory;
  readonly DateFormats = DateFormats;

  constructor(
    private documentService: DocumentItemService,
    private profileService: ProfileService,
    private linkTagsService: LinkTagsService,
    private snackBar: MatSnackBar,
    readonly notificationService: NotificationsService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
    public keycloakService: KeycloakService,
  ) {}

  @HostListener('mouseover')
  onMouseOver(): void {
    this.showActions = true;
    this.cdr.detectChanges();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (!this.hasExpandedActions) {
      this.showActions = false;
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.bindToggleDocumentSubscription();
    this.profileTypesDataSource = this.profileService.types;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.translatedTitle = undefined;
    this.translatedContent = undefined;
  }

  public onHideParent(): void {
    this.hideParent.emit();
  }

  public onShowParent(): void {
    this.showLoader = true;
    this.documentService
      .getParentDocument(this.document)
      .subscribe((response: DocumentItem) => {
        this.showLoader = false;
        this.showParent.emit(response);
      });
  }

  private bindToggleDocumentSubscription(): void {
    this.subscription.add(
      this.documentService.toggleDocument$.subscribe(
        (response: { documentId: string; isExpanded: boolean }) => {
          if (response?.documentId !== this.document.id) {
            this.isExpanded = response.isExpanded;
          }
        },
      ),
    );
  }

  public getDocumentDate(dateItem: any): any {
    if (typeof dateItem === 'number') {
      return new Date(dateItem);
    } else if (typeof dateItem === 'string') {
      return new Date(dateItem);
    } else if (dateItem) {
      return dateItem;
    } else {
      return null;
    }
  }

  public onTranslate(): void {
    this.documentService
      .detectLanguage(this.document.emitBodyText)
      .then((response) => response.json())
      .then((result: any) => {
        if (result?.data?.detections?.length > 0) {
          const language = result.data.detections[0][0].language;
          forkJoin([
            this.translate(language, this.document.emitHeadline),
            this.translate(language, this.document.emitBodyText),
          ]).subscribe({
            next: (response: Array<string>) => {
              this.translatedTitle = response[1];
              this.translatedContent = response[0];
              this.cdr.detectChanges();
            },
            error: (error) => {
              this.notificationService.loader$.next(false);
              this.snackBar.open(
                'Translation failed. Please try again.',
                'Close',
                {
                  horizontalPosition: 'right',
                  duration: 5000,
                  verticalPosition: 'top',
                },
              );
            },
          });
        }
      })
      .catch((error) => console.log('error', error));
  }

  private translate(lang: string, text: string): Observable<string> {
    return new Observable((observer) => {
      this.documentService
        .translate(text, lang)
        .then((response) => response.json())
        .then((response: any) => {
          observer.next(response.data.translations[0].translatedText);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public onExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.documentService.toggleDocument$.next({
      documentId: this.document.id,
      isExpanded: false,
    });
  }

  public onDrilldownContext(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: SegmentScope.CONTEXT,
      value: extensionField,
      context: { document: this.document },
    });
  }

  public onDrilldown(params: ISegment): void {
    this.drilldown.emit(params);
  }

  public onShareEmail(document: DocumentItem): void {
    this.shareEmail.emit(document);
  }

  public onDrilldownReplies(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: SegmentScope.REPLIES,
      value: extensionField,
    });
  }

  onDrilldownActor(): void {
    this.drilldown.emit({
      scope: SegmentScope.ACTOR,
      value: this.document.emitActor,
    });
  }

  onDrilldownChannel(): void {
    this.drilldown.emit({
      scope: SegmentScope.CHANNEL,
      value: this.document.emitChannel,
    });
  }

  public getRepliesCount(): void {
    this.documentService
      .getRepliesCount(this.document)
      .subscribe((response: number) => {
        this.repliesCount = response;
      });
  }

  public onCreateProfile(
    scope: ProfileDrildownScope,
    profileType: ProfileType,
  ): void {
    this.createProfile.emit(
      new ProfileDrilldown(
        scope,
        profileType,
        scope === ProfileDrildownScope.ACTOR
          ? this.document.emitActor
          : this.document.emitChannel,
      ),
    );
  }

  public onLinkProfile(profile: Profile, scope: ProfileDrildownScope): void {
    const link = new LinkTag(
      undefined,
      undefined,
      scope === ProfileDrildownScope.ACTOR
        ? TagCategory.ACTOR
        : TagCategory.CHANNEL,
      true,
      scope === ProfileDrildownScope.ACTOR
        ? this.document.emitActor
        : this.document.emitChannel,
      undefined,
      undefined,
      undefined,
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${
          scope === ProfileDrildownScope.ACTOR
            ? this.document.emitActor
            : this.document.emitChannel
        } has been linked tp profile ${profile.name}!`,
        'Close',
        {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        },
      );
    });
  }
}
