<div class="absolute right-0 space-x-1 mr-4 z-10 flex">
  <itq-mat-button
    [size]="Sizes.SM"
    [padding]="Sizes.SM"
    [type]="'button'"
    [materialStyle]="'stroked'"
    [padding]="Sizes['X-SM']"
    [matTooltip]="'Translate'"
    (clickEvent)="onTranslate()"
  >
    <fa-icon [icon]="['far', 'language']"></fa-icon>
  </itq-mat-button>

  @if (allowDrilldown) {
    @if (document?.emitSource === 'Discord') {
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [materialStyle]="'stroked'"
        matTooltip="View context"
        [matTooltipPosition]="'below'"
        (clickEvent)="onDrilldownContext()"
      >
        <fa-icon [icon]="['far', 'list-timeline']"></fa-icon>
      </itq-mat-button>
    }
    @if (allowCreateConnections) {
      @if (keycloakService.isUserInRole('saas-profiles')) {
        <itq-nav-button
          [size]="Sizes.SM"
          [padding]="Sizes.NONE"
          [openDirection]="{ vertical: 'down', horizontal: 'right' }"
          [openPosition]="'inside'"
          matTooltip="Vendor actions"
          [matTooltipPosition]="'below'"
          [template]="profileActionsTemplate"
          [materialStyle]="'stroked'"
        >
          <div class="flex items-center justify-center w-full h-full">
            <itq-svg class="w-4 h-4" [icon]="'vendor'"></itq-svg>
          </div>
        </itq-nav-button>
      }
    } @else {
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [materialStyle]="'stroked'"
        matTooltip="Add note"
        [matTooltipPosition]="'below'"
        (clickEvent)="onAddNote($event)"
      >
        <fa-icon [icon]="['far', 'note']"></fa-icon>
      </itq-mat-button>
    }
    @if (shareByEmail) {
      <itq-nav-button
        [size]="Sizes.SM"
        [padding]="Sizes.NONE"
        [openDirection]="{ vertical: 'down', horizontal: 'right' }"
        [openPosition]="'inside'"
        [align]="Align.CENTER"
        matTooltip="Share"
        [matTooltipPosition]="'below'"
        [template]="shareTemplate"
        [materialStyle]="'stroked'"
        (opened)="onOpen()"
        (close)="onClose()"
      >
        <fa-icon [icon]="['far', 'share-alt']"></fa-icon>
      </itq-nav-button>
    } @else {
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [materialStyle]="'stroked'"
        matTooltip="Share to slack"
        [matTooltipPosition]="'below'"
        (clickEvent)="onShareSlack($event)"
      >
        <fa-icon [icon]="['fab', 'slack']"></fa-icon>
      </itq-mat-button>
    }
  }
</div>

<ng-template #profileActionsTemplate let-data>
  <div class="flex flex-col w-full space-x-1">
    <itq-mat-button
      [type]="'button'"
      [size]="Sizes.BLOCK"
      [materialStyle]="'basic'"
      [padding]="Sizes['X-SM']"
      (clickEvent)="onAddNote($event)"
    >
      <div class="flex items-center whitespace-pre justify-start w-full">
        <fa-icon [icon]="['far', 'note']"></fa-icon>
        Add note
      </div>
    </itq-mat-button>
    <itq-mat-button
      [type]="'button'"
      [padding]="Sizes['X-SM']"
      [size]="Sizes.BLOCK"
      [materialStyle]="'basic'"
      (clickEvent)="onAddConnection($event)"
    >
      <div class="flex items-center whitespace-pre justify-start w-full">
        <fa-icon [icon]="['far', 'code-merge']"></fa-icon>
        Add connection
      </div>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #shareTemplate let-data>
  <div class="flex flex-col w-full space-x-1">
    <itq-mat-button
      [type]="'button'"
      [size]="Sizes.BLOCK"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      (clickEvent)="onShareSlack($event)"
    >
      <div class="flex items-center whitespace-pre justify-start w-full">
        <fa-icon [icon]="['fab', 'slack']"></fa-icon>
        Share to slack
      </div>
    </itq-mat-button>
    @if (shareByEmail) {
      <itq-mat-button
        [type]="'button'"
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [materialStyle]="'basic'"
        (mousedown)="onShareEmail($event)"
      >
        <div class="flex items-center whitespace-pre justify-start w-full">
          <fa-icon [icon]="['far', 'envelope']"></fa-icon>
          Share by email
        </div>
      </itq-mat-button>
    }
  </div>
</ng-template>
