<input
  readonly
  class="form-control cursor-pointer"
  [placeholder]="placeholder"
  [matDatepicker]="datepicker"
  [(ngModel)]="dateValue"
  (dateChange)="onDateChange($event)"
/>
@if (dateValue) {
  <itq-mat-button
    [class]="'btn--delete'"
    [materialStyle]="'basic'"
    [type]="'button'"
    [disabled]="disabled"
    (mousedown)="onClear()"
  >
    <fa-icon [icon]="['far', 'times']" [size]="'lg'"></fa-icon>
  </itq-mat-button>
}
<mat-datepicker-toggle
  matIconSuffix
  [disabled]="disabled"
  [for]="datepicker"
></mat-datepicker-toggle>
<mat-datepicker #datepicker>
  <mat-datepicker-actions>
    <button mat-button matDatepickerCancel>CANCEL</button>
    <button mat-flat-button color="primary" matDatepickerApply>Apply</button>
  </mat-datepicker-actions>
</mat-datepicker>
