<form [formGroup]="form" class="flex flex-col h-full">
  <ng-container [formGroupName]="'addConnections'">
    <div class="flex items-center p-7 border-b border-border justify-between">
      <div class="flex items-center flex-col bg-white">
        <div class="flex items-center justify-start w-full space-x-2">
          <fa-icon
            [size]="'2x'"
            [icon]="['far', action === Actions.CREATE ? 'plus' : 'pencil']"
          ></fa-icon>
          <h1 class="text-28 font-bold">
            {{
              action === Actions.CREATE ? 'Add connection' : 'Edit connection'
            }}
          </h1>
        </div>
      </div>
      <itq-mat-button
        [matTooltip]="'Close'"
        [materialStyle]="'basic'"
        [type]="'button'"
        (clickEvent)="onClose()"
      >
        <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
      ></itq-mat-button>
    </div>
    <div class="bg-background p-7 flex-1">
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">CONNECTION TYPE</span>
        <itq-dropdown
          [formControlName]="'type'"
          [dataSource]="typesDataSource | filterDuplicates"
          [dataFields]="{ name: 'name', value: 'id' }"
          (changeValue)="onChangeConnectionType()"
          (clear)="onChangeConnectionType()"
        ></itq-dropdown>
        <mat-error
          *ngIf="form.controls?.type?.touched && form.controls?.type?.invalid"
        >
          Connection type is a required field
        </mat-error>
      </div>

      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">VENDOR</span>
        <itq-dropdown
          [formControlName]="'profile'"
          [dataSource]="
            profilesDataSource | filterProfiles: connection : action
          "
          [dataFields]="{ name: 'name', value: 'profileId' }"
          (changeValue)="onChangeProfile()"
          (dataBound)="onGetProfiles($event)"
          (clear)="onClearProfiles()"
        ></itq-dropdown>
        <mat-error
          *ngIf="
            form.controls?.profiles?.touched && form.controls?.profiles?.invalid
          "
        >
          Vendor is a required field
        </mat-error>
      </div>

      <div class="flex flex-col">
        <span class="mb-1 text-13 font-medium">DESCRIPTION</span>
        <itq-input
          [formControlName]="'description'"
          [placeholder]="'Please add connection description...'"
          (search)="onChangeDescription()"
        ></itq-input>
      </div>
    </div>
    <footer class="flex justify-end px-7 py-4 bg-white border-t border-border">
      <ng-container
        *ngIf="
          action === Actions.EDIT ||
            profilesDataSource?.length > 0 ||
            !connection?.typeId;
          else noProfileTemplate
        "
      >
        <ng-container *ngIf="action === Actions.CREATE; else updateTemplate">
          <itq-nav-button
            [materialStyle]="'flat'"
            [openDirection]="{
              vertical: 'down',
              horizontal: 'left'
            }"
            [color]="'primary'"
            [padding]="Sizes.MD"
            [disabled]="!this.form?.get('addConnections')?.valid"
            [openPosition]="'inside'"
            [template]="saveTemplate"
          >
            SAVE
          </itq-nav-button>
          <ng-template #saveTemplate>
            <div class="flex flex-col">
              <itq-mat-button
                *ngIf="typesDataSource"
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onCreate()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="whitespace-nowrap">Add another connection</span>
              </itq-mat-button>
              <itq-mat-button
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onAdd()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="whitespace-nowrap">Add to profile</span>
              </itq-mat-button>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #updateTemplate>
          <itq-mat-button
            [disabled]="!this.form?.get('addConnections')?.valid"
            [materialStyle]="'flat'"
            [style]="'primary'"
            (clickEvent)="onUpdate()"
          >
            <span class="whitespace-nowrap">Update</span>
          </itq-mat-button>
        </ng-template>
      </ng-container>
      <ng-template #noProfileTemplate>
        <itq-mat-button
          [disabled]="!this.form?.get('addConnections')?.valid"
          [materialStyle]="'flat'"
          [style]="'primary'"
          (clickEvent)="onCreateProfile()"
        >
          <span class="whitespace-nowrap">Create profile</span>
        </itq-mat-button>
      </ng-template>
    </footer>
  </ng-container>
</form>
