import { TagNodeType } from 'projects/portal/src/app/tags/enums/tag.enum';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public id: string,
    public name: string,
    public organisation: string,
    public level = 1,
    public type: TagNodeType,
    public expandable = false,
    public isLoading = false,
    public linked = true,
  ) {}
}
