import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagWizardNavItemComponent } from '../tag-wizard-nav-item/tag-wizard-nav-item.component';
import { TagWizardNavItem } from './tag-wizard-navigation.enum';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { CustomOverlayService } from '@intorqa-ui/core';

@Component({
  selector: 'itq-tag-wizard-navigation',
  templateUrl: './tag-wizard-navigation.component.html',
  styleUrls: ['./tag-wizard-navigation.component.scss'],
  standalone: true,
  imports: [TagWizardNavItemComponent, CommonModule],
})
export class TagWizardNavigationComponent implements OnInit {
  @Input() active = TagWizardNavItem.FILTERS;
  @Input() completed: Array<TagWizardNavItem>;
  @Input() form: FormGroup;

  readonly TagWizardNavItem = TagWizardNavItem;

  @Output() change = new EventEmitter<TagWizardNavItem>();

  constructor(
    readonly keycloakService: KeycloakService,
    readonly customOverlayService: CustomOverlayService,
  ) {}

  ngOnInit() {}

  public onClick(action: TagWizardNavItem): void {
    this.change.emit(action);
  }

  public onLoadNotifications(): void {
    this.customOverlayService.setCssClass$.next('dynamic');
    this.onClick(TagWizardNavItem.NOTIFICATIONS);
  }

  public onLoadSave(): void {
    if (this.form?.get('filters')?.valid && this.form?.get('alerts')?.valid) {
      this.customOverlayService.setCssClass$.next('dynamic');
      this.onClick(TagWizardNavItem.SAVE);
    }
  }

  public onLoadFilters(): void {
    this.customOverlayService.setCssClass$.next('');
    this.onClick(TagWizardNavItem.FILTERS);
  }
}
