<itq-nav-button
  #valueButton
  class="w-full"
  [materialStyle]="'stroked'"
  [size]="Sizes.BLOCK"
  [openDirection]="{ vertical: 'down', horizontal: 'left' }"
  [openPosition]="'inside'"
  [template]="valuesTemplate"
>
  @if (selections?.length > 0) {
    <div class="flex items-center justify-between w-full">
      <div
        class="flex items-center overflow-x-auto overflow-y-hidden custom-scrollbar space-x-1"
      >
        @for (item of selections; track item.id) {
          <itq-pill
            [class]="'secondary h-[24px] rounded-full'"
            [type]="PillType.BUTTON"
            [stopPropagation]="true"
            [matTooltip]="item.name"
            (mousedown)="$event.stopPropagation()"
            (select)="onRemoveValue(item)"
          >
            <span
              class="whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis"
            >
              {{ item?.name }}
            </span>
            <fa-icon
              [style.min-width]="'16px !important'"
              [style.min-height]="'16px !important'"
              class="rounded-full ml-1 w-[18px] h-[18px] hover:bg-[#4CAF50]"
              [icon]="['far', 'circle-xmark']"
              [size]="'sm'"
            ></fa-icon>
          </itq-pill>
        }
      </div>
      <fa-icon
        class="border-l border-border pl-1 ml-2"
        [icon]="
          valueButton.isOpen ? ['far', 'chevron-up'] : ['far', 'chevron-down']
        "
      ></fa-icon>
    </div>
  } @else {
    <div
      class="text-gray-400 font-normal justify-between flex items-center w-full"
    >
      Choose an option
      <fa-icon
        class="border-l border-border pl-1 ml-2"
        [icon]="
          valueButton.isOpen ? ['far', 'chevron-up'] : ['far', 'chevron-down']
        "
      ></fa-icon>
    </div>
  }
</itq-nav-button>

<ng-template #valuesTemplate let-rule>
  <itq-value-filters
    class="flex"
    [(ngModel)]="selections"
    [field]="field"
    [queryModel]="queryModel?.clone()"
    [fields]="this.fieldDataSource"
    [preFilter]="preFilter"
    [dates]="this.initialState.where"
    (selectionChange)="onFilterValuesSelectionChange($event)"
  ></itq-value-filters>
</ng-template>
