import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CoreModule, PillType } from '@intorqa-ui/core';
import { QueryBuilderModel } from '@portal/shared/models/qb-query-model';
import { Rule, RuleSet } from 'ngx-angular-query-builder';
import { CastAsQueryModelPipe, GetFieldByIdPipe } from './query-renderer.pipe';

@Component({
  selector: 'itq-query-renderer',
  templateUrl: './query-renderer.component.html',
  styleUrls: ['./query-renderer.component.scss'],
  standalone: true,
  imports: [
    QueryRendererComponent,
    CommonModule,
    CastAsQueryModelPipe,
    CoreModule,
    GetFieldByIdPipe,
  ],
})
export class QueryRendererComponent implements OnInit {
  @Input() query: QueryBuilderModel;

  readonly PillType = PillType;

  constructor() {}

  ngOnInit() {}

  isRuleSet(item: Rule | RuleSet): item is RuleSet {
    return (item as RuleSet).rules !== undefined;
  }
}
