@if (dataSource?.totalHits > 0) {
  <itq-table
    class="overflow-hidden h-full"
    [tableData]="dataSource?.series[0]?.data | sortData"
    [tableColumns]="tableColumns"
    [trackBy]="trackBy"
    [active]="segment?.data?.name"
  >
  </itq-table>
} @else {
  @if (dataSource) {
    <itq-panel-info
      class="p-7 bg-white"
      [label]="'No results found!'"
      [message]="'Please update your settings and try again...'"
    ></itq-panel-info>
  }
}
