import { ILinkTag } from '../interfaces/profile-tags.interface';

export class LinkTag implements ILinkTag {
  constructor(
    public id: string,
    public description: string,
    public type: string,
    public isDefault: boolean,
    public tagName: string,
    public updatedDate: number,
    public updatedBy: string,
    public tagId?: string,
  ) {}
}
