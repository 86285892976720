<ng-container [formGroup]="form">
  <div class="flex h-full px-7 py-4 flex-col w-full" [formGroupName]="'save'">
    <div class="flex flex-col w-full">
      <h2 class="flex font-bold pb-4 text-xl">Review & Save</h2>
      <div
        class="flex rounded-md bg-white border border-border border-b-0 w-full flex-col"
      >
        <div
          class="flex items-center px-4 py-4 w-full border-b border-border justify-start"
        >
          <span class="font-semibold w-[100px]">Tag type:</span>
          <span>{{ tag.userTagCategory }}</span>
        </div>
        <div
          class="flex items-center px-4 py-4 w-full border-b border-border justify-start"
        >
          <span class="font-semibold w-[100px]">Filters:</span>
          @if (form?.get('filters.query')?.valid) {
            <itq-query-renderer
              class="flex flex-1"
              [query]="form?.get('filters.query')?.value | castAsQueryModel"
            ></itq-query-renderer>
          }
        </div>
        @if (form?.get('alerts.create')?.value === 'yes') {
          <div
            class="flex items-center px-4 py-4 w-full border-b border-border justify-start"
          >
            <span class="font-semibold w-[100px]">Alert:</span>
            <div class="items-center space-x-1 flex justify-left">
              <div class="flex">
                <span>{{
                  form?.get('alerts.alertTypeId')?.value
                    | getAlertTypeLabel: alertsService?.types
                }}</span
                ><span>:</span>
              </div>
              <pre></pre>
              @if (
                (form?.get('alerts.alertTypeId')?.value
                  | getAlertTypeLabel: alertsService?.types) ===
                AlertTypes.TAG_MATCH
              ) {
                <div class="flex flex-1 space-x-1">
                  <span>Check every</span>
                  <span>{{
                    form?.get('alerts.delay')?.value | getDelayLabel
                  }}</span>
                </div>
              } @else {
                <div class="flex flex-1 space-x-1">
                  <span>{{ form?.get('alerts.condition')?.value }}</span>
                  <span>{{ form?.get('alerts.count')?.value }}</span>
                  <span>time in the last</span>
                  <span>{{
                    form?.get('alerts.period')?.value | getDelayLabel
                  }}</span>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>

    <div class="flex space-x-4">
      @if (tag.userTagCategory === UserTagCategoryLabel.SYSTEM) {
        <div class="flex flex-col flex-1">
          <span class="mb-1 text-13 font-medium flex"> CATEGORY</span>
          <itq-multiple-dropdown
            [dataSource]="categoriesDataSource"
            [materialStyle]="'stroked'"
            [formControlName]="'category'"
            [dataFields]="{ name: 'name', value: 'id' }"
          >
          </itq-multiple-dropdown>
        </div>
      }
    </div>
    <div class="flex mt-7 flex-col">
      <div class="flex space-x-4">
        <div class="flex flex-col flex-1">
          <span class="mb-1 text-13 font-medium flex"> ADD TAG TO BOARD</span>
          <itq-multiple-dropdown
            [dataSource]="boardsDataSource"
            [materialStyle]="'stroked'"
            [formControlName]="'boardIds'"
            [dataFields]="{ name: 'name', value: 'id' }"
          >
          </itq-multiple-dropdown>
        </div>
        @if (tag.userTagCategory === 'System') {
          <div class="flex flex-col flex-1">
            <span class="mb-1 text-13 font-medium flex"> CATEGORY</span>
            <itq-multiple-dropdown
              [dataSource]="categoriesDataSource"
              [materialStyle]="'stroked'"
              [formControlName]="'category'"
              [dataFields]="{ name: 'name', value: 'id' }"
            >
            </itq-multiple-dropdown>
          </div>
        }
      </div>
    </div>
    <div class="flex mt-4 space-x-4">
      <div class="flex flex-1 flex-col">
        <span class="mb-1 text-13 font-medium flex"> NAME</span>
        <div class="flex space-x-4">
          <itq-input
            class="flex-1"
            [placeholder]="'Enter a tag name...'"
            formControlName="name"
          ></itq-input>
        </div>
        @if (
          form?.get('save.name')?.dirty &&
          form?.get('save.name')?.touched &&
          form?.get('save.name')?.getError('duplicateName')
        ) {
          <mat-error class="mt-2 flex items-center space-x-2 text-danger">
            <mat-icon>warning</mat-icon>
            <span> Tag name already exists!</span>
          </mat-error>
        }
      </div>
      <div class="flex flex-1 flex-col">
        <span class="mb-1 text-13 font-medium flex"> DESCRIPTION</span>
        <div class="flex space-x-4">
          <itq-input
            class="flex-1"
            [placeholder]="'Enter a tag description...'"
            formControlName="description"
          ></itq-input>
        </div>
      </div>
    </div>
  </div>
</ng-container>
