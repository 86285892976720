import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Actions,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRangeComponent,
  DateRangeHelper,
  FAwesomeModule,
  IButtonThumbnail,
  IPreset,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { DocumentsFeedComponent } from '@portal/document/component/documents-feed/documents-feed.component';
import { ShareEmailComponent } from '@portal/document/component/share-email/share-email.component';
import {
  DocumentItem,
  IData,
  IExtensionField,
} from '@portal/document/interfaces/document.interface';
import { DocumentItemService } from '@portal/document/services/document-item.service';
import { DrilldownActorComponent } from '@portal/drilldown/components/drilldown-actor/drilldown-actor.component';
import { DrilldownChannelComponent } from '@portal/drilldown/components/drilldown-channel/drilldown-channel.component';
import { DrilldownContextComponent } from '@portal/drilldown/components/drilldown-context/drilldown-context.component';
import { DrilldownDiscordComponent } from '@portal/drilldown/components/drilldown-discord/drilldown-discord.component';
import { DrilldownRepliesComponent } from '@portal/drilldown/components/drilldown-replies/drilldown-replies.component';
import {
  IActorSegment,
  IChannelSegment,
  IContextSegment,
  IDiscordSegment,
  IRepliesSegment,
} from '@portal/drilldown/interfaces/drilldown.interface';
import { Priority_DataSource } from '@portal/notifications/const/alerts.const';
import { AlertType } from '@portal/notifications/models/alert-type';
import {
  GroupNotification,
  Notification,
} from '@portal/notifications/models/notifications';
import { GroupByDatePipe } from '@portal/notifications/pipes/notifications.pipe';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ProfilesWizardComponent } from '@portal/widget-settings/modules/widget-settings-profiles/profiles-wizard/profiles-wizard.component';
import { AnalysisTypes } from '@portal/widgets/enums/widget.enum';
import { WidgetService } from '@portal/widgets/services/widget.service';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { NotificationsTimelineComponent } from '../notifications-timeline/notifications-timeline.component';

@Component({
  selector: 'itq-notifications-explore',
  templateUrl: './notifications-explore.component.html',
  styleUrls: ['./notifications-explore.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    NotificationsTimelineComponent,
    ReactiveFormsModule,
    SharedModule,
    MatTooltipModule,
    FAwesomeModule,
    DateRangeComponent,
    DocumentsFeedComponent,
  ],
  providers: [NotificationsService, GroupByDatePipe, SharedService],
})
export class NotificationsExploreComponent implements OnInit {
  @Input() widget: Notification;
  @Input() raisedAlertId: string;
  @Input() type: string;
  @Input() query: string;
  @Input() showAll: string;
  @Input() priority: string;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public expandedFilters = true;
  public priorityDataSource = Priority_DataSource;

  public buttonDataSource: Array<IButtonThumbnail>;
  public unreadCount: number;
  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  private _matchesDataSource: IData;

  public get matchesDataSource(): IData {
    return this._matchesDataSource;
  }

  public set matchesDataSource(value: IData) {
    if (this.initialState.page > 1) {
      this._matchesDataSource = {
        result: this._matchesDataSource.result.concat(value.result),
        count: value.count,
      };
    } else {
      this._matchesDataSource = value;
    }
  }
  public alertTypesDataSource: Array<AlertType> = [];
  private subscriptions = new Subscription();
  public form: FormGroup;
  public showLoader = false;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly widgetService: WidgetService,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService,
    readonly userService: UserService,
    readonly documentService: DocumentItemService,
    readonly customOverlayRef: CustomOverlayRef,
    readonly modalContainerService: ModalContainerService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.getAlertTypes();
    this.bindGetUnreadCountSubscription();
    this.bindCreateProfileSubscription();
    this.bindLoadNotificationsSubscription();
    this.bindOtherMatchesSubscription();
    this.createForm();
    this.onDataBound();
    this.loadMatches();
  }

  ngAfterViewInit(): void {
    this.buttonDataSource = [
      {
        id: 'read',
        tooltip: 'All',
      },
      {
        id: 'unread',
        tooltip: 'Unread',
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onShareEmail(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ShareEmailComponent,
          inputs: {
            document,
            initialState: this.initialState,
          },
        },
      },
      closeBtnClass: 'hidden',
      closeBtnStyle: 'basic',
      type: CustomOverlayType['slide-right'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  private bindOtherMatchesSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadOtherMatches$.subscribe(
        (response: { filters: QueryFilters; raisedAlertId: string }) => {
          this.raisedAlertId = response.raisedAlertId;
          this.showLoader = true;
          this.loadMatches();
        },
      ),
    );
  }

  private bindLoadNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount: response.totalCount,
          };
          this.showLoader = false;
        },
      ),
    );
  }

  private bindCreateProfileSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.createProfile$.subscribe(
        (params: { profileDrilldown: ProfileDrilldown }) => {
          this.onCreateProfile(params.profileDrilldown);
        },
      ),
    );
  }

  private bindGetUnreadCountSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === 'unread') {
                if (this.unreadCount === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            },
          );
        },
      ),
    );
  }

  private getAlertTypes(): void {
    this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
      this.alertTypesDataSource = response;
    });
  }

  public onChangeDates(params: IPresetQuery): void {
    this.initialState.where = params;
    this.onDataBound();
  }

  public onMarkAllRead(): void {
    this.showLoader = true;
    this.notificationsService.markAllAsRead().subscribe(() => {
      this.showLoader = false;
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${Actions.CREATE}_profiles`,
      profile,
      Actions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.ACTOR
          ? TagCategory.ACTOR
          : TagCategory.CHANNEL,
        true,
        profileDrilldown.value,
        undefined,
        undefined,
        `Field field:${profileDrilldown.value}:${
          profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.ACTOR
            : TagCategory.CHANNEL
        }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ProfilesWizardComponent,
          inputs: {
            navigationItem,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBoundOtherMatches(params: QueryFilters): void {
    this.initialState = params;
    this.loadMatches();
  }

  private loadMatches(): void {
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(
          new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
          this.raisedAlertId,
        )
        .subscribe(
          (matches: { items: Array<DocumentItem>; totalCount: number }) => {
            this.documentService.toggleDocument$.next({
              documentId: undefined,
              isExpanded: false,
            });
            this.matchesDataSource = {
              result: matches.items,
              count: matches.totalCount,
            };
            this.showLoader = false;
          },
        );
    }
  }

  public createForm(): void {
    this.form = new FormGroup({
      alertType: new FormControl(this.type),
      priority: new FormControl(this.priority),
      showAll: new FormControl(this.showAll || 'read'),
      query: new FormControl(this.query),
    });
  }

  public onDataBound(params?: QueryFilters): void {
    this.showLoader = true;
    if (params) {
      this.initialState = params;
    }
    this.initialState.query = this.form.get('query').value;
    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        params || this.initialState,
        this.form.get('showAll').value === 'unread' ? false : undefined,
        this.form.get('priority').value,
        this.form.get('alertType').value,
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(
        this.initialState,
        this.form.get('priority').value,
        this.form.get('alertType').value,
      );

    forkJoin([getNotificationsObservable, getUnreadCountObservable]).subscribe(
      (response) => {
        this.notificationsDataSource = {
          items: this.groupByDate.transform(
            response[0]?.items as Array<Notification>,
          ),
          totalCount: response[0].totalCount,
        };
        this.showLoader = false;
        this.unreadCount = response[1];
        this.buttonDataSource = this.buttonDataSource.map(
          (item: IDisplayType) => {
            if (item.id === 'unread') {
              if (this.unreadCount === 0) {
                item = { ...item, ...{ disabled: true } };
              } else {
                delete item.disabled;
              }
              return item;
            } else {
              return item;
            }
          },
        );
      },
    );
  }

  public onSearch(): void {
    this.raisedAlertId = undefined;
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDrilldown(segment?: any): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        const actorSegment = segment as IActorSegment;
        this.drilldownActor(actorSegment.value);
        break;
      case SegmentScope.CHANNEL:
        const channelSegment = segment as IChannelSegment;
        this.drilldownChannel(channelSegment.value);
        break;
      case SegmentScope.DISCORD:
        const discordSegment = segment as IDiscordSegment;
        this.drilldownDiscord(discordSegment.value);
        break;
      case SegmentScope.REPLIES:
        const repliesSegment = segment as IRepliesSegment;
        this.drilldownReplies(repliesSegment.value);
        break;
      case SegmentScope.CONTEXT:
        const contextSegment = segment as IContextSegment;
        this.drilldownContext(contextSegment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(context: IContextSegment): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownContextComponent,
      inputs: {
        channel: context?.value,
        contextDocument: context.context.document,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownReplies(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownRepliesComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownDiscord(extensionField: IExtensionField): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownDiscordComponent,
      inputs: {
        channel: extensionField,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownChannel(channelName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownChannelComponent,
      inputs: {
        channel: channelName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  private drilldownActor(actorName: string): void {
    this.modalContainerService.navigate$.next({
      component: DrilldownActorComponent,
      inputs: {
        actor: actorName,
        initialState: this.initialState.cloneDeep(),
      },
    });
  }

  public onDateRangeChange(args: IPreset): void {
    let payload: IPresetQuery;
    if (args.start) {
      payload = this.getPayload(args);
    }
    this.initialState.where = payload;

    this.onSearch();
  }

  private getPayload(dates: IPreset): IPresetQuery {
    return {
      label: DateRangeHelper.getDateQueryText(dates.label),
      start: DateRangeHelper.convertToEpochSec(moment(dates.start).toDate()),
      end:
        dates.label === DateQueryType.Custom
          ? DateRangeHelper.convertToEpochSec(moment(dates.end).toDate())
          : undefined,
    };
  }

  public onRefresh(): void {
    this.initialState.resetPagination();
    this.onDataBound(this.initialState);
  }
}
