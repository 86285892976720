import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  CurrencyDescription,
  CurrencyPrefixDescription,
  PillType,
  Sizes,
} from '@intorqa-ui/core';
import {
  MetadataFields,
  ProfileMetadataGroups,
} from '@portal/profiles/enums/profiles-metadata.enum';
import { GroupMetadata } from '@portal/profiles/models/group-metadata';
import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
@Component({
  selector: 'itq-explore-metadata',
  templateUrl: './explore-metadata.component.html',
  styleUrls: ['./explore-metadata.component.scss'],
})
export class ExploreMetadataComponent implements OnInit {
  @Input() metadata: Array<ProfileMetadata>;
  @Input() metadataGroup: GroupMetadata;

  @ViewChild('buttonWrapper') buttonWrapper: ElementRef;

  readonly MetadataFields = MetadataFields;
  readonly Sizes = Sizes;
  readonly ProfileMetadataGroups = ProfileMetadataGroups;
  readonly CurrencyPrefixDescription = CurrencyPrefixDescription;
  readonly CurrencyDescription = CurrencyDescription;
  readonly PillType = PillType;

  constructor() {}

  ngOnInit() {}

  public getMaxWidth(): number {
    return this.buttonWrapper?.nativeElement?.offsetWidth;
  }
}
