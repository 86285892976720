<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <div class="flex flex-col mb-4" *ngIf="form?.get('addNote.type')?.value">
      <span class="mb-1 text-13 font-medium">TEXT NOTES</span>
      <quill-editor
        [modules]="editorModules"
        [styles]="{
          height: '100%',
          minHeight: '400px',
          width: '100%',
          maxHeight: '400px'
        }"
        [formControlName]="'description'"
        (onContentChanged)="onContentChanged()"
      ></quill-editor>
    </div>
  </ng-container>
</ng-container>
